import React, { FC, useState, useEffect, ElementType } from 'react'
import { ErrorMessage } from 'formik'

// Datepicker PROPS https://reactdatepicker.com
import DatePicker, { registerLocale } from 'react-datepicker'
import es from 'date-fns/locale/es'

import { useIsMobile } from 'app/hooks/useIsMobile'

import 'react-datepicker/dist/react-datepicker.css'

import { InputIcon, Text, IconCalendarEvent } from 'app/components'

import {
  InputTitle,
  InputHint,
  ErrorField,
  InputCalendarContainer,
} from 'app/components/ui/styles'

registerLocale('es', es)

interface ICalendar {
  id: any
  title?: any
  hint?: any
  name: any
  onChangeDate: (date: Date) => void
  onChange: any
  date?: Date
  warning: boolean
  placeholderText?: string
  icon?: ElementType
  showMonthYearPicker?: boolean
}

const Calendar: FC<ICalendar> = props => {
  const { onChange, onChangeDate, name } = props
  const [date, setDate] = useState(props.date)
  const isMobile = useIsMobile()

  useEffect(() => {
    onChange(name, date)
  }, [date, name, onChange])

  return (
    <InputCalendarContainer>
      {props.title && <InputTitle htmlFor={props.id}>{props.title}</InputTitle>}
      {props.hint && <InputHint htmlFor={props.id}>{props.hint}</InputHint>}
      <DatePicker
        showPopperArrow={!isMobile}
        placeholderText={props.placeholderText}
        onChange={(date: Date) => {
          setDate(date)
          onChangeDate(date)
        }}
        locale="es"
        dateFormat={'dd/MM/YYY'}
        showMonthYearPicker={props.showMonthYearPicker}
      />
      <Text color={'cornflowerBlue'}>Calendario</Text>
      <InputIcon>
        <IconCalendarEvent size={'xs'} />
      </InputIcon>
      <ErrorMessage name={name} component={ErrorField} />
    </InputCalendarContainer>
  )
}

export default Calendar
