import { createSlice } from '@reduxjs/toolkit'
import { UseCasePolicy } from 'domain/useCases'
import {
  getPoliciesParams,
  makePolicyReqParams,
  getPolicyRequestModel,
  getPolicyStateByPolicyIdRequestModel,
  putPolicyStateRequestModel,
  extendGetPoliciesParamsWithExportList,
  makePolicyExportReqParams,
} from 'domain/requestModel'
import {
  IPolicyDTO,
  IPolicyResultDTO,
  IPolicyByIdDTO,
  IPolicyStateDTO,
} from 'domain/responseModel'
import { policyService } from 'app/services'

const insuranceController = new UseCasePolicy(policyService)

type initialStateReducer = {
  isPolicyLoading: boolean
  isPoliciesFetching: boolean
  isPolicieStatesLoading: boolean
  isPolicyStateUpdating: boolean
  isPolicyStateUpdated: boolean
  isExportPoliciesLoading: boolean
  error: null
  policies: IPolicyDTO[]
  policy: IPolicyResultDTO | null
  policieStates: IPolicyByIdDTO
  policyState: IPolicyStateDTO
  isExportRejectedPoliciesLoading: boolean
  isExportPoliciesAbandonedQuoteLoading: boolean
}

const INITIAL_STATE: initialStateReducer = {
  isPolicyLoading: true,
  isPoliciesFetching: false,
  isPolicieStatesLoading: false,
  isPolicyStateUpdating: false,
  isPolicyStateUpdated: false,
  isExportPoliciesLoading: false,
  error: null,
  policies: [],
  policy: null,
  policieStates: { estados: [] },
  policyState: { message: '' },
  isExportRejectedPoliciesLoading: false,
  isExportPoliciesAbandonedQuoteLoading: false,
}

export const slice = createSlice({
  name: 'policy',
  initialState: INITIAL_STATE,
  reducers: {
    fetchPoliciesStart: state => {
      state.isPoliciesFetching = true
      state.error = null
    },
    fetchPoliciesSuccess: (state, { payload }) => {
      state.isPoliciesFetching = false
      state.error = null
      state.policies = payload
    },
    fetchPoliciesFail: (state, { payload }) => {
      state.isPoliciesFetching = false
      state.error = payload.message
    },
    fetchPolicyStart: state => {
      state.isPolicyLoading = true
      state.error = null
    },
    fetchPolicySuccess: (state, { payload }) => {
      state.isPolicyLoading = false
      state.error = null
      state.policy = payload
    },
    fetchPolicyFail: (state, { payload }) => {
      state.isPolicyLoading = false
      state.error = payload.message
    },
    fetchPolicieStatesByStateIdStart: state => {
      state.isPolicieStatesLoading = true
      state.error = null
    },
    fetchPolicieStatesByStateIdSuccess: (state, { payload }) => {
      state.isPolicieStatesLoading = false
      state.error = null
      state.policieStates = payload
    },
    fetchPolicieStatesByStateIdFail: (state, { payload }) => {
      state.isPolicieStatesLoading = false
      state.error = payload.message
    },
    fetchPolicyStateStart: state => {
      state.isPolicyStateUpdating = true
      state.error = null
    },
    fetchPolicyStateSuccess: (state, { payload }) => {
      state.isPolicyStateUpdating = false
      state.isPolicyStateUpdated = true
      state.policy!.seguro.estadoBackOffice = payload.estadoBackOffice
      state.error = null
    },
    fetchPolicyStateFail: (state, { payload }) => {
      state.isPolicyStateUpdating = false
      state.error = payload.message
    },
    fetchExportPoliciesStart: state => {
      state.isExportPoliciesLoading = true
      state.error = null
    },
    fetchExportPoliciesSuccess: (state, { payload }) => {
      state.isExportPoliciesLoading = false
      state.error = null
    },
    fetchExportPoliciesFail: (state, { payload }) => {
      state.isExportPoliciesLoading = false
      state.error = payload.message
    },
    fetchExportRejectedPoliciesStart: state => {
      state.isExportRejectedPoliciesLoading = true
      state.error = null
    },
    fetchExportRejectedPoliciesSuccess: (state, { payload }) => {
      state.isExportRejectedPoliciesLoading = false
      state.error = null
    },
    fetchExportRejectedPoliciesFail: (state, { payload }) => {
      state.isExportRejectedPoliciesLoading = false
      state.error = payload.message
    },
    fetchExportPoliciesAbandonedQuoteStart: state => {
      state.isExportPoliciesAbandonedQuoteLoading = true
      state.error = null
    },
    fetchExportPoliciesAbandonedQuoteSuccess: (state, { payload }) => {
      state.isExportPoliciesAbandonedQuoteLoading = false
      state.error = null
    },
    fetchExportPoliciesAbadonedQuoteFail: (state, { payload }) => {
      state.isExportPoliciesAbandonedQuoteLoading = false
      state.error = payload.message
    },
  },
})

const {
  fetchPoliciesStart,
  fetchPoliciesSuccess,
  fetchPoliciesFail,
  fetchPolicyStart,
  fetchPolicySuccess,
  fetchPolicyFail,
  fetchPolicieStatesByStateIdStart,
  fetchPolicieStatesByStateIdSuccess,
  fetchPolicieStatesByStateIdFail,
  fetchPolicyStateStart,
  fetchPolicyStateFail,
  fetchPolicyStateSuccess,
  fetchExportPoliciesStart,
  fetchExportPoliciesSuccess,
  fetchExportPoliciesFail,
  fetchExportRejectedPoliciesStart,
  fetchExportRejectedPoliciesSuccess,
  fetchExportRejectedPoliciesFail,
  fetchExportPoliciesAbandonedQuoteStart,
  fetchExportPoliciesAbandonedQuoteSuccess,
  fetchExportPoliciesAbadonedQuoteFail,
} = slice.actions

export default slice.reducer

/**
 * Thunks
 */
export const getPolicies = (props: getPoliciesParams) => async (
  dispatch: any
) => {
  try {
    dispatch(fetchPoliciesStart())

    const reqParams = makePolicyReqParams(props)

    const response = await insuranceController.getPolicies(reqParams)

    dispatch(fetchPoliciesSuccess(response))
  } catch (error) {
    dispatch(fetchPoliciesFail(error))
  }
}

export const getPolicy = (params: getPolicyRequestModel) => async (
  dispatch: any
) => {
  try {
    dispatch(fetchPolicyStart())

    const response = await insuranceController.getPolicy(params)

    dispatch(fetchPolicySuccess(response))
  } catch (error) {
    dispatch(fetchPolicyFail(error))
  }
}

export const getPolicieStateByPolicyStateId = (
  params: getPolicyStateByPolicyIdRequestModel
) => async (dispatch: any) => {
  try {
    dispatch(fetchPolicieStatesByStateIdStart())

    const response = await insuranceController.getPolicyStatesByPolicyId(params)

    dispatch(fetchPolicieStatesByStateIdSuccess(response))
  } catch (error) {
    dispatch(fetchPolicieStatesByStateIdFail(error))
  }
}

export const putPolicyState = (params: putPolicyStateRequestModel) => async (
  dispatch: any,
  getState: any
) => {
  try {
    dispatch(fetchPolicyStateStart())

    await insuranceController.putPolicyState(params)

    const { policies } = getState()
    const policiesObject = policies.policies
    let stateArray: any[] = []

    if (policiesObject) {
      stateArray = policiesObject.resultados.map((data: any) => {
        let updatedState
        if (data.id === params.idSeguro) {
          updatedState = { estadoBackOffice: params.estadoBackOffice }
        }
        return { ...data, ...updatedState }
      })
    }

    dispatch(fetchPolicyStateSuccess(params))

    dispatch(
      fetchPoliciesSuccess({
        totalPaginas: policiesObject.totalPaginas,
        totalCantidad: policiesObject.totalCantidad,
        resultados: stateArray,
      })
    )
  } catch (error) {
    dispatch(fetchPolicyStateFail(error))
  }
}

export const getPoliciesExport = (
  params: extendGetPoliciesParamsWithExportList
) => async (dispatch: any) => {
  try {
    dispatch(fetchExportPoliciesStart())

    const reqParams = makePolicyExportReqParams(params)

    const response = await insuranceController.getPoliciesExport(reqParams)

    dispatch(fetchExportPoliciesSuccess(response))
  } catch (error) {
    dispatch(fetchExportPoliciesFail(error))
  }
}

export const getPoliciesRejectedExport = () => async (dispatch: any) => {
  try {
    dispatch(fetchExportRejectedPoliciesStart())

    const response = await insuranceController.getPoliciesRejectedExport()

    dispatch(fetchExportRejectedPoliciesSuccess(response))
  } catch (error) {
    dispatch(fetchExportRejectedPoliciesFail(error))
  }
}

export const getPoliciesAbandonQuotedExport = () => async (dispatch: any) => {
  try {
    dispatch(fetchExportPoliciesAbandonedQuoteStart())

    const response = await insuranceController.getPoliciesAbandonQuotedExport()

    dispatch(fetchExportPoliciesAbandonedQuoteSuccess(response))
  } catch (error) {
    dispatch(fetchExportPoliciesAbadonedQuoteFail(error))
  }
}
/**
 * Selectors
 */
export const selectPolicies = (state: any) =>
  state.policies.policies.resultados?.length
    ? state.policies.policies.resultados
    : []
export const selectPoliciesIsFetching = (state: any) =>
  state.policies.isPoliciesFetching
export const selectPoliciesNumberOfPages = (state: any) =>
  state.policies.policies.totalPaginas
    ? state.policies.policies?.totalPaginas
    : 0
export const selectPolicy = (state: any) => state.policies.policy
export const selectPolicyStatesByStateId = (state: any) =>
  state.policies.policieStates.estados
export const selectPolicyStatesByStateIdIsFetching = (state: any) =>
  state.policies.isPolicieStatesLoading
export const selectPolicyStateUpdating = (state: any) =>
  state.policies.isPolicyStateUpdating
export const selectPolicyStateIsUpdated = (state: any) =>
  state.policies.isPolicyStateUpdated
export const selectExportPolicyIsFetching = (state: any) =>
  state.policies.isExportPoliciesLoading
export const selectPolicyIsFetching = (state: any) =>
  state.policies.isPolicyLoading
export const selectExportRejectedPoliciesIsFetching = (state: any) =>
  state.policies.isExportRejectedPoliciesLoading
export const selectPolicyAbandonedQuotesIsFetching = (state: any) =>
  state.policies.isExportPoliciesAbandonedQuoteLoading
