import styled from 'styled-components'

import { shadowedComponent } from 'app/components/ui/styles'

export const Table = styled.table`
  display: block;
  ${shadowedComponent}
  border-color: ${props => props.theme.colors.shipCove};
  border-collapse: collapse;

  @media (min-width: ${({ theme }) => theme.breakPoints.small}) {
    display: table;
    width: 100%;
  }
`

export const THeader = styled.thead`
  height: 50px;
  background-color: ${props => props.theme.colors.foam};
`

export const TBody = styled.tbody``

export const TTh = styled.th`
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.foam};
  border-bottom: 1px solid ${props => props.theme.colors.shipCove};

  :nth-child(n + 1):not(:last-child) {
    border-right: 1px solid ${props => props.theme.colors.alto};
  }
`

export const TTr = styled.tr`
  height: 50px;
`

export const TTd = styled.td`
  border-bottom: 3px solid ${props => props.theme.colors.silver};

  :nth-child(n + 1):not(:last-child) {
    border-right: 1px solid ${props => props.theme.colors.alto};
  }
`
