import React, { FC, useState, useEffect, useRef } from 'react'
import { colors } from 'app/styles/colors'
import styled from 'styled-components'

// Datepicker PROPS https://reactdatepicker.com
import DatePicker, { registerLocale } from 'react-datepicker'
import es from 'date-fns/locale/es'

import 'react-datepicker/dist/react-datepicker.css'

import { useIsMobile } from 'app/hooks/useIsMobile'
import { IconCalendar } from 'app/components/Icons'
import { InputTitle } from 'app/components/ui/styles'

registerLocale('es', es)

const DateContainer = styled.div`
  position: relative;
  .react-datepicker__input-container > input {
    width: 12rem;
    height: 2.3rem;
    background: transparent;
    border: none;
    border-bottom: 1px solid ${props => props.theme.colors.cornflowerBlue};
    color: ${props => props.theme.colors.darkGreyBlue};
    ::placeholder {
      color: ${props => props.theme.colors.placeholderGrey};
    }
    border-radius: 3px;
    outline-color: ${props => props.theme.colors.cornflowerBlue};
    &:focus {
      border: 1px solid ${({ theme }) => theme.colors.mariner}!important;
      outline-offset: 0px;
      outline: none;
    }

    &::placeholder {
      color: ${({ theme }) => theme.colors.silverChalice};
      font-size: 0.8rem;
    }
  }
`

const IconContainer = styled.div`
  position: absolute;
  top: 1.25rem;
  right: 0.5rem;
  cursor: pointer;

  @media (max-width: 799px) {
    display: none;
  }
`

interface IDate {
  label?: any
  name: string
  onChangeDate?: (date: Date) => void
  onChange: any
  date?: any
  placeholder?: string
  validationMsg?: string | any
  showMonthDropdown?: boolean
  showYearDropdown?: boolean
}

const Date: FC<IDate> = ({
  name,
  label,
  onChange,
  onChangeDate,
  date,
  placeholder,
  showMonthDropdown,
  showYearDropdown,
}) => {
  const [newDate, setDate] = useState(date)
  const isMobile = useIsMobile()
  const datePickerRef = useRef<any>(null)

  useEffect(() => {
    onChange(name, newDate)
  }, [newDate, name, onChange])

  const handleIconClick = () => {
    datePickerRef.current.setFocus()
  }

  return (
    <>
      <DateContainer>
        <InputTitle>{label}</InputTitle>
        <DatePicker
          selected={newDate}
          showPopperArrow={!isMobile}
          placeholderText={placeholder}
          onChange={(dateSelected: Date) => {
            if (onChangeDate) {
              onChangeDate(dateSelected)
            }
            setDate(dateSelected)
          }}
          locale="es"
          dateFormat="dd/MM/yyyy"
          showMonthDropdown={showMonthDropdown}
          showYearDropdown={showYearDropdown}
          dropdownMode="select"
          ref={datePickerRef}
        />
        <IconContainer onClick={handleIconClick}>
          <IconCalendar size="xs" color={colors.cornflowerBlue} />
        </IconContainer>
      </DateContainer>
    </>
  )
}

Date.defaultProps = {
  label: '',
  date: '',
  placeholder: 'DD/MM/YYYY',
  validationMsg: '',
  onChangeDate: () => {},
  showMonthDropdown: false,
  showYearDropdown: false,
}

export default Date
