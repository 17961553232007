import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, Link } from 'react-router-dom'

import { PolicyState } from 'domain/entities'
import { IPolicyResultDTO } from 'domain/responseModel/policy/interfaces'
import { IPolicyPage } from 'app/Pages/Policy/interfaces'

import {
  getPolicy,
  selectPolicy,
  getPolicieStateByPolicyStateId,
  putPolicyState,
  selectPolicyStatesByStateId,
  selectPolicyStatesByStateIdIsFetching,
  selectPolicyStateUpdating,
  selectPolicyStateIsUpdated,
  selectPolicyIsFetching,
} from 'app/slices/policySlice'

import { getStates, selectStates } from 'app/slices/stateSlice'

import { getPolicyRequestModel } from 'domain/requestModel'

import {
  LoadingSpinner,
  ISelectFormat,
  Text,
  IconLeftArrowAlt,
  Row,
  Acordion,
} from 'app/components'
import { PolicyStatesForm } from 'app/Pages/Policy/form'

import { PageContent } from 'app/Pages/Policy/style'

import { viewModelPolicy } from 'app/Pages/Policy/presenters'

import { mapDataForDropdown } from 'app/Pages/Policy/helpers'

import { initialValue } from 'app/Pages/Policy/constants'

const Policy = (props: IPolicyPage) => {
  const [initialOption, setInitialOption] = useState(initialValue)
  const [selectedOption, setSelectedOption] = useState(initialValue)
  const dispatch = useDispatch()
  const policy: IPolicyResultDTO = useSelector(selectPolicy)
  const policyIsFetching: boolean = useSelector(selectPolicyIsFetching)
  const policyStates: PolicyState[] = useSelector(selectPolicyStatesByStateId)
  const states = useSelector(selectStates)
  const policyStatesIsFetching: boolean = useSelector(
    selectPolicyStatesByStateIdIsFetching
  )
  const policyStateUpdating: boolean = useSelector(selectPolicyStateUpdating)
  const policyStateIsUpdated: boolean = useSelector(selectPolicyStateIsUpdated)
  const disableSubmitForm = selectedOption
    ? initialOption.value === selectedOption?.value
    : true

  const mapedPolicyForView: IPolicyResultDTO = viewModelPolicy(policy)

  let params: getPolicyRequestModel = useParams()

  useEffect(() => {
    dispatch(getPolicy(params))
  }, [params, dispatch])

  useEffect(() => {
    dispatch(getStates({ idTipoEstado: 2 }))
  }, [dispatch])

  useEffect(() => {
    const idEstado = mapedPolicyForView.seguro?.estadoBackOffice
    idEstado && dispatch(getPolicieStateByPolicyStateId({ idEstado }))
  }, [mapedPolicyForView.seguro?.estadoBackOffice, dispatch])

  useEffect(() => {
    const estadoBackOffice = mapedPolicyForView.seguro?.estadoBackOffice
    const findOption: PolicyState | undefined = states.find(
      (e: PolicyState) => e.id === estadoBackOffice
    )

    const i: ISelectFormat = findOption
      ? { value: findOption.id, label: findOption.descripcion }
      : {
          value: -1,
          label: '',
        }

    setSelectedOption(i)
    setInitialOption(i)
  }, [states, mapedPolicyForView.seguro?.estadoBackOffice])

  const handleOnSubmit = () => {
    dispatch(
      putPolicyState({
        idSeguro: mapedPolicyForView.seguro?.id,
        estadoBackOffice: selectedOption?.value,
      })
    )
  }

  return (
    <>
      {policyIsFetching ? (
        <LoadingSpinner isLoading={policyIsFetching} />
      ) : (
        <PageContent>
          <Link to={`${props.path}`} style={{ textDecoration: 'unset' }}>
            <Row alignItems={'center'}>
              <IconLeftArrowAlt centered={false} color={'cornflowerBlue'} />
              <Text size={'sm'}>Volver al listado</Text>
            </Row>
          </Link>
          {policyStateUpdating}
          <PolicyStatesForm
            onSubmit={handleOnSubmit}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            policy={mapedPolicyForView}
            policyStates={mapDataForDropdown(policyStates)}
            policyStatesIsFetching={policyStatesIsFetching}
            policyStateIsUpdating={policyStateUpdating}
            policyStateIsUpdated={policyStateIsUpdated}
            disableSubmitForm={disableSubmitForm}
          />
          <Acordion title={'Socio'} data={mapedPolicyForView.socio} />
          <Acordion
            title={'Beneficiarios'}
            data={mapedPolicyForView.beneficiarios}
          />
          <Acordion title={'Djs'} data={mapedPolicyForView.djsSocio} />
        </PageContent>
      )}
    </>
  )
}

export default Policy
