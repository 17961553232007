import React, { useEffect, useState, useContext, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useParams } from 'react-router-dom'

import {
  getAfilliation,
  selectAfilliation,
  postEvent,
  selectPostEventIsFetching,
  selectPostEvent,
  selectAfilliationIsFetching,
  postAfilliationStateUpdate,
  selectAfilliationAndAssociateForDropdown,
} from 'app/slices/afilliationSlice'
import { getDniByDocNumber, selectAuthIsLoading } from 'app/slices/dniSlice'
import {
  selectStatesForDropdowns,
  getAvailableStates,
  selectAvailableStates,
} from 'app/slices/stateSlice'

import {
  TGetAfilliationParams,
  TPostAfilliationStateParams,
  TPostEventParam,
} from 'domain/requestModel'

import * as C from 'app/components'

import Modals from './ModalContent'

import * as S from 'app/Pages/Afilliation/styles'
import * as presenters from './presenters'

import Sections from './sections'

import constants, { TForm, TStateForm } from './constants'
import { AfilliationAssociate } from 'domain/entities'

const Afilliation = (props: any) => {
  const dispatch = useDispatch()
  let params: TGetAfilliationParams = useParams()

  const location = useLocation()

  const searchParams = new URLSearchParams(location.search)
  const tipoAfiliacion = searchParams.get('tipoAfiliacion') ?? ''

  const newEventAdded = useRef(false)
  const {
    handleModalContent,
    handleDisplayModal,
    handleOnModalIsFetching,
  } = useContext(C.ModalContext)

  // states
  const [socio, setSocio] = useState<any>({})
  const [detailData, setDetailData] = useState<any>({})
  const [selectedOption, setSelectedOption] = useState(
    constants.nameSelectInitialValue
  )
  // selectors
  const afilliation = useSelector(selectAfilliation)
  const authIsLoading: boolean = useSelector(selectAuthIsLoading)
  const postEventIsFetching: boolean = useSelector(selectPostEventIsFetching)
  const postEventData: boolean = useSelector(selectPostEvent)
  const afilliationIsFetching: boolean = useSelector(
    selectAfilliationIsFetching
  )
  const availableStates: any = useSelector(selectAvailableStates)
  const afilliationAndAssociateForDropdown = useSelector(
    selectAfilliationAndAssociateForDropdown
  )
  const backofficeStates = useSelector(selectStatesForDropdowns)

  useEffect(() => {
    dispatch(
      getAfilliation({
        ...params,
        ...(tipoAfiliacion === '5' && { tipoAfiliacion }),
      })
    )
  }, [params, dispatch, tipoAfiliacion])

  useEffect(() => {
    const getAvailableStatesParams =
      afilliation && afilliation?.afiliacion?.estadoBackOffice
        ? {
            idTipoEstado: afilliation.afiliacion.estadoBackOffice,
            ...(tipoAfiliacion === '5' && { tipoAfiliacion: 5 }),
          }
        : { idTipoEstado: 0 }

    dispatch(getAvailableStates(getAvailableStatesParams))
  }, [dispatch, afilliation, tipoAfiliacion])

  useEffect(() => {
    if (afilliation) {
      setSelectedOption(afilliationAndAssociateForDropdown[0])
    }
  }, [afilliation, afilliationAndAssociateForDropdown])

  useEffect(() => {
    if (afilliation) {
      setDetailData(
        presenters.detailViewModel({
          association: afilliation.afiliacion,
          associate: afilliation.socios,
          associateId: selectedOption.value,
          backofficeStates: backofficeStates,
        })
      )
    }
  }, [afilliation, selectedOption, backofficeStates])

  useEffect(() => {
    if (afilliation) {
      setSocio(
        afilliation.socios.find(
          (s: AfilliationAssociate) => s.id === selectedOption.value
        )
      )
    }
  }, [selectedOption, afilliation])

  useEffect(() => {
    handleOnModalIsFetching(postEventIsFetching)
  }, [postEventIsFetching, handleOnModalIsFetching])

  useEffect(() => {
    if (postEventData && newEventAdded.current) {
      dispatch(
        getAfilliation({
          ...params,
          ...(tipoAfiliacion === '5' && { tipoAfiliacion }),
        })
      )
      handleDisplayModal(false)

      newEventAdded.current = false
    }
  }, [
    postEventData,
    dispatch,
    handleDisplayModal,
    params,
    newEventAdded,
    tipoAfiliacion,
  ])

  const handleOnPressDownloadDni = (dniNumber: string) =>
    dispatch(getDniByDocNumber(dniNumber))

  const handleOnPressModalSubmit = (values: TForm) => {
    const param: TPostEventParam = {
      afilliationEvent: values.observations,
      observation: values.detail,
      idAfilliation: parseInt(params.idAfiliacion),
      tipoAfiliacion: tipoAfiliacion,
    }
    newEventAdded.current = true
    dispatch(postEvent(param))
  }

  const handleOnPressAddNewObservation = () => {
    handleDisplayModal(true)
    handleModalContent(
      <Modals.ObservationModalContent
        onSubmit={handleOnPressModalSubmit}
        onCancel={() => handleDisplayModal(false)}
      />
    )
  }

  const handleOnPressStateUpdateSubmit = (values: TStateForm) => {
    const param: TPostAfilliationStateParams = {
      idAfiliacion: parseInt(params.idAfiliacion),
      observaciones: values.observations,
      estado: values.state.value,
      ...(tipoAfiliacion && { tipoAfiliacion }),
    }

    newEventAdded.current = true
    dispatch(postAfilliationStateUpdate(param))
  }

  const handleOnPressStateUpdate = () => {
    handleDisplayModal(true)
    handleModalContent(
      <Modals.StateModalContent
        availableStates={availableStates}
        onSubmit={handleOnPressStateUpdateSubmit}
        onCancel={() => handleDisplayModal(false)}
      />
    )
  }

  return (
    <S.PageContent>
      {afilliationIsFetching ? (
        <C.LoadingSpinner isLoading={afilliationIsFetching} />
      ) : (
        <>
          <Link to={`${props.path}`} style={{ textDecoration: 'unset' }}>
            <C.Row alignItems={'center'}>
              <C.IconLeftArrowAlt centered={false} color={'cornflowerBlue'} />
              <C.Text size={'sm'}>Volver al listado</C.Text>
            </C.Row>
          </Link>
          <S.DetailsContainer>
            <S.Column>
              <Sections.Details
                formInitialValues={constants.detailFormInitialValues(
                  afilliationAndAssociateForDropdown
                )}
                detailData={detailData}
                authIsLoading={authIsLoading}
                onPressDownloadDni={handleOnPressDownloadDni}
                dropdownOptions={afilliationAndAssociateForDropdown}
                setSelectedOption={setSelectedOption}
                tipoAfiliacion={tipoAfiliacion}
              />
              <C.Acordion title={'DDJJs'} data={socio?.djs?.respuestas} />
              {tipoAfiliacion === '5' && (
                <Sections.Documentation data={afilliation ? afilliation : []} />
              )}
            </S.Column>
            <S.Column>
              <C.Acordion
                title={'Historial'}
                data={afilliation?.historial || []}
              />
              <Sections.Observations
                onPressAddNewObservation={handleOnPressAddNewObservation}
                data={afilliation ? afilliation.observaciones : []}
              />
              <Sections.StateUpdate
                onPressStateUpdate={handleOnPressStateUpdate}
                data={{
                  estado: afilliation?.afiliacion?.estadoBODescripcion
                    ? afilliation.afiliacion.estadoBODescripcion
                    : '',
                  motivo: '',
                }}
              />
            </S.Column>
          </S.DetailsContainer>
        </>
      )}
    </S.PageContent>
  )
}

export default Afilliation
