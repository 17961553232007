import React, { FC, ElementType } from 'react'
import { ErrorMessage } from 'formik'

import { InputBasic, InputIcon } from 'app/components/ui/forms'

import {
  InputContainer,
  InputTitle,
  InputHint,
  ErrorField,
} from 'app/components/ui/styles'

interface IInputField {
  id: string
  title?: string
  hint?: string
  icon?: ElementType
  warning?: boolean | ''
  name: string
  inputMode?: string
  type?: string
  onChange?: any
  hidden?: boolean
}

const InputField: FC<IInputField> = props => (
  <InputContainer hidden={props.hidden}>
    <InputTitle htmlFor={props.id}>{props.title}</InputTitle>
    {props.hint && <InputHint htmlFor={props.id}>{props.hint}</InputHint>}
    <InputBasic {...props} />
    {props.icon && (
      <InputIcon warning={props.warning}>
        <props.icon size="18" />
      </InputIcon>
    )}
    <ErrorMessage name={props.name} component={ErrorField} />
  </InputContainer>
)

export default React.memo(InputField)
