import { createSlice } from '@reduxjs/toolkit'

import { RootState } from 'app/store/reducers'
import { UseCaseHolders } from 'domain/useCases/holders'
import {
  getHoldersRequestParam,
  TPostHolderCommentParams,
} from 'domain/requestModel/holders'
import { holdersService } from 'app/services'

const holderController = new UseCaseHolders(holdersService)

type initialStateReducer = {
  loading: boolean
  error: string
  resultados: []
  totalPaginas: number
  totalCantidad: number
  holders: any | null
  event: boolean
}

const INITIAL_STATE: initialStateReducer = {
  loading: false,
  error: '',
  resultados: [],
  totalPaginas: 0,
  totalCantidad: 0,
  holders: null,
  event: false,
}

export const slice = createSlice({
  name: 'holders',
  initialState: INITIAL_STATE,
  reducers: {
    fetchHoldersStart: state => {
      state.loading = true
      state.error = ''
    },
    fetchHoldersSuccess: (state, { payload }) => {
      state.loading = false
      state.error = ''
      state.resultados = payload.resultados
      state.totalPaginas = payload.totalPaginas
      state.totalCantidad = payload.totalCantidad
    },
    fetchHoldersFail: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    fetchNewCommentStart: state => {
      state.loading = true
      state.error = ''
      state.event = false
    },
    fetchNewCommentSuccess: (state, { payload }) => {
      state.loading = false
      state.error = ''
      state.event = true
    },
    fetchNewCommentFail: (state, { payload }) => {
      state.loading = false
      state.error = payload.message
    },
  },
})

export const {
  fetchHoldersStart,
  fetchHoldersSuccess,
  fetchHoldersFail,
  fetchNewCommentStart,
  fetchNewCommentSuccess,
  fetchNewCommentFail,
} = slice.actions

export default slice.reducer

export const getHolders = (props: getHoldersRequestParam) => async (
  dispatch: any
) => {
  try {
    dispatch(fetchHoldersStart())

    const response = await holderController.getHolders(props)

    dispatch(fetchHoldersSuccess(response))
  } catch (error) {
    dispatch(fetchHoldersFail)
  }
}

export const postComment = (props: TPostHolderCommentParams) => async (
  dispatch: any
) => {
  try {
    dispatch(fetchNewCommentStart())
    const response = await holderController.postEventPyme(props)
    dispatch(fetchNewCommentSuccess(response))
  } catch (error) {
    dispatch(fetchNewCommentFail(error))
  }
}

export const selectHolders = (state: RootState) => state.holders
export const selectPostComment = (state: RootState) => state.holders.event
export const selectPostCommentIsFetching = (state: RootState) =>
  state.holders.loading
