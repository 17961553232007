import styled from 'styled-components'

import { TextProps } from './index'

import {
  variants,
  fontSizes,
  colorsProvider,
  fontWeightsProvider,
} from 'app/components/helpers'

const TextBase = styled.span.attrs((p: any) => ({
  name: p.name,
}))<TextProps>`
  display: block;
  text-align: ${props => props.textAlign || ''};
  float: ${props => props.float || ''};
  margin: ${props => props.margin || ''};
  line-height: 1.5;
  display: ${props => props.display || ''};
  ${props => props.capitalFirstLetter && `text-transform: capitalize;`}
  ${props => props.underlined && `text-decoration: underline;`}
  ${props => props.isCursor && `cursor: pointer;`}
`

const MainText = styled(TextBase)`
  ${({ variant }) => variants[variant]}
  ${({ size }) => fontSizes[size]}
  ${({ color }) => colorsProvider[color]}
  ${({ weight }) => fontWeightsProvider[weight]}
`

export { MainText }
