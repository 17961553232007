import React, { FC } from 'react'
import styled, { css } from 'styled-components'
import { ErrorMessage } from 'formik'

import { IconCloudUpload, IconUpload } from 'app/components/Icons'
import { Text } from 'app/components'

import { ErrorField } from 'app/components/ui/styles'
import { borderColorsProvider } from 'app/components/helpers'

interface IField {
  name: string
  value: any
  onChange: (name: string, value: boolean) => void
  onBlur?: () => void
}

interface IInputFile {
  field: IField
  id: string
  warning?: boolean
  hint?: boolean
  title?: string
  fullWidth?: boolean
  margin?: string
  color?: string
  IconUpload?: boolean
}

const InputFile: FC<IInputFile> = props => {
  return (
    <>
      <Label
        htmlFor={props.id}
        fullWidth={props.fullWidth}
        margin={props.margin}
        color={props.color}
      >
        {props.IconUpload ? (
          <IconUpload centered={false} margin="0 10px 0 0" />
        ) : (
          <IconCloudUpload centered={false} />
        )}
        {props.title}
      </Label>
      <Input
        type="file"
        name={props.field.name}
        id={props.id}
        onChange={(event: any) => {
          props.field.onChange(props.field.name, event.currentTarget.files[0])
        }}
        onBlur={props.field.onBlur}
      />
      <Text>{props.field.value?.name}</Text>
      <ErrorMessage name={props.field.name} component={ErrorField} />
    </>
  )
}

export default InputFile

const Label = styled.label<{
  fullWidth?: boolean
  margin?: string
  color?: string
}>`
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 6px 12px;
  cursor: pointer;
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}
  margin: ${props => props.margin || ''};
  ${({ color }) =>
    color &&
    css`
      ${borderColorsProvider[color]};
    `}
`

const Input = styled.input`
  display: none;
`
