import styled from 'styled-components'

export const HomeBg = styled.img`
  height: 100%;
  width: 100%;
`
export const WelcomeBox = styled.div`
  display: flex;
  position: absolute;
  top: 23rem;
  left: 15rem;
  background-color: #5f9cd8;
  height: 24rem;
  width: 52rem;
`

export const WelcomeText = styled.div`
  color: white;
  font-size: 45px;
`

export const TextBox = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  align-self: center;
  padding: 1.5rem;
  left: 3rem;
`
export const ImportantText = styled.div`
  color: white;
  font-size: 45px;
  font-weight: bolder;
`

export const UpperText = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`

export const BottomText = styled.div`
  margin-top: -3rem;
`
