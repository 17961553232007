import React from 'react'

import * as Styles from 'app/Pages/Afilliations/styles'
import { Table } from 'app/components'
import { Text, IconDotsHorizontal } from 'app/components'

import {
  tableDesktopHeaders,
  tableMobileHeaders,
} from 'app/Pages/Afilliations/constants'

import { TAffiliationViewModel } from 'app/Pages/Afilliations/presenters'
import { afiliationType } from 'app/Pages/Afilliations/constants'
import { IconBusiness } from 'app/components/Icons'
import { colors } from 'app/styles/colors'

export interface IAfilliationsTable {
  data: TAffiliationViewModel[]
  isLoading: boolean
  onPressItem: (element: any, key: any) => void
}

const Item = (element: any, key: any) => (handleOnPressItem: any) => (
  <>
    {key === 'detalle' ? (
      <IconDotsHorizontal
        onClick={() => handleOnPressItem(element, key)}
        color={'midnightBlue'}
        isCursor
      />
    ) : key === 'tipoAfiliacion' ? (
      <>
        {element['tipoAfiliacion'] === afiliationType.Pymes ? (
          <>
            <Styles.IconPyme>
              <IconBusiness size="xs" color={colors.midnightBlue} />
              Pyme
            </Styles.IconPyme>
          </>
        ) : element['tipoAfiliacion'] === afiliationType.PADEmpresas ? (
          <>
            <Styles.IconPyme>
              <IconBusiness size="xs" color={colors.midnightBlue} />
              Empresa
            </Styles.IconPyme>
          </>
        ) : (
          ''
        )}
      </>
    ) : (
      <Text capitalFirstLetter color={'midnightBlue'} textAlign={'center'}>
        {element[key]}
      </Text>
    )}
  </>
)

const AfilliationTable = (props: IAfilliationsTable) => {
  return (
    <Styles.PageContent>
      <Table
        data={props.data}
        tableDesktopHeaders={tableDesktopHeaders}
        tableMobileHeaders={tableMobileHeaders}
        isLoading={props.isLoading}
        item={(element, key) => Item(element, key)(props.onPressItem)}
      />
    </Styles.PageContent>
  )
}

export default AfilliationTable
