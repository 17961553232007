import { createSlice, createSelector } from '@reduxjs/toolkit'

import { RootState } from 'app/store/reducers'
import { UseCaseCompany } from 'domain/useCases/companyPAD'
import {
  changeStateFGParams,
  changeStatusGFParams,
  createCompanyParam,
  fileParams,
  getCompaniesRequestModel,
  getCompanyRequestParam,
  getCompanyStateDisponiblesParam,
  getCompanyStateParam,
  getFamilyGroupDetailParam,
} from 'domain/requestModel/companyPAD'
import { companyPADService } from 'app/services'
import { ISelectFormat } from 'app/components'
import { FamilyGroupDetail } from 'domain/entities/familyGroupPADDetail'
import { State } from 'domain/entities'
import { getAfilliation } from './afilliationSlice'

const companyController = new UseCaseCompany(companyPADService)

type initialStateReducer = {
  loading: boolean
  error: string
  resultados: []
  totalPaginas: number
  totalCantidad: number
  company: any | null
  exportCompanyListIsFetching: boolean
  exportError: string
  companyState: State[] | null
  familyGroupDetail: FamilyGroupDetail | null
  familyGroupDetailIsFetching: boolean
  changesInStatesAndObs: boolean
  changesInStatesAndObsIsFetching: boolean
  saveDocumentation: boolean
  saveDocumentationIsFetching: boolean
  saveDocumentationError: boolean
  changesStatusGFIsFetching: boolean
  changesStatusGF: any
  event: boolean
  createCompanyIsLoading: boolean
  createCompanyError: string
  showCreateCompanyNotification: boolean
  createCompany: any
}

const INITIAL_STATE: initialStateReducer = {
  loading: false,
  error: '',
  resultados: [],
  totalPaginas: 0,
  totalCantidad: 0,
  company: null,
  exportCompanyListIsFetching: false,
  exportError: '',
  companyState: null,
  familyGroupDetail: null,
  familyGroupDetailIsFetching: false,
  changesInStatesAndObs: false,
  changesInStatesAndObsIsFetching: false,
  saveDocumentation: false,
  saveDocumentationIsFetching: false,
  saveDocumentationError: false,
  changesStatusGFIsFetching: false,
  changesStatusGF: false,
  event: false,
  createCompanyIsLoading: false,
  createCompanyError: '',
  showCreateCompanyNotification: false,
  createCompany: {},
}

export const slice = createSlice({
  name: 'company',
  initialState: INITIAL_STATE,
  reducers: {
    fetchCompaniesStart: state => {
      state.loading = true
      state.error = ''
    },
    fetchCompaniesSuccess: (state, { payload }) => {
      state.loading = false
      state.error = ''
      state.resultados = payload.resultados
      state.totalPaginas = payload.totalPaginas
      state.totalCantidad = payload.totalCantidad
    },
    fetchCompaniesFail: state => {
      state.loading = false
      state.error = ''
    },
    fetchCompanyStart: state => {
      state.loading = true
      state.error = ''
    },
    fetchCompanySuccess: (state, { payload }) => {
      state.loading = false
      state.error = ''
      state.company = payload
    },
    fetchCompanyFail: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    fetchCompanyStateStart: state => {
      state.loading = true
      state.error = ''
    },
    fetchCompanyStateSuccess: (state, { payload }) => {
      state.loading = false
      state.error = ''
      state.companyState = payload
    },
    fetchCompanyStateFail: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    fetchCompanyListExcelStart: state => {
      state.exportCompanyListIsFetching = true
    },
    fetchCompanyListExcelSuccess: state => {
      state.exportCompanyListIsFetching = false
    },
    fetchCompanyListExcelFail: state => {
      state.exportCompanyListIsFetching = false
      state.exportError = 'Se produjo un error'
    },
    fetchFamilyGroupDetailStart: state => {
      state.familyGroupDetailIsFetching = true
      state.familyGroupDetail = null
    },
    fetchFamilyGroupDetailSuccess: (state, { payload }) => {
      state.familyGroupDetailIsFetching = false
      state.familyGroupDetail = payload
    },
    fetchFamilyGroupDetailFail: state => {
      state.familyGroupDetailIsFetching = false
      state.exportError = 'Se produjo un error'
    },
    fetchSaveDocumentationStatus: state => {
      state.saveDocumentationIsFetching = false
      state.saveDocumentation = false
      state.saveDocumentationError = false
    },
    fetchSaveDocumentationStart: state => {
      state.saveDocumentationIsFetching = true
      state.saveDocumentation = false
      state.saveDocumentationError = false
    },
    fetchSaveDocumentationSuccess: (state, { payload }) => {
      state.saveDocumentationIsFetching = false
      state.saveDocumentation = true
      state.saveDocumentationError = false
    },
    fetchSaveDocumentationFail: state => {
      state.saveDocumentationIsFetching = false
      state.saveDocumentationError = true
      state.exportError = 'Se produjo un error'
    },
    fetchChangesStatusGFStart: state => {
      state.changesStatusGFIsFetching = true
      state.changesStatusGF = false
      state.event = false
    },
    fetchChangesStatusGFSuccess: (state, { payload }) => {
      state.changesStatusGFIsFetching = false
      state.changesStatusGF = payload
    },
    fetchChangesStatusGFFail: state => {
      state.changesStatusGFIsFetching = false
      state.exportError = 'Se produjo un error'
    },
    fetchChangesInStatesAndObsStart: state => {
      state.changesInStatesAndObsIsFetching = true
      state.changesInStatesAndObs = false
      state.event = false
    },
    fetchChangesInStatesAndObsSuccess: (state, { payload }) => {
      state.changesInStatesAndObsIsFetching = false
      state.changesInStatesAndObs = true
      state.event = true
    },
    fetchChangesInStatesAndObsFail: state => {
      state.changesInStatesAndObsIsFetching = false
      state.exportError = 'Se produjo un error'
    },
    clearChangesAndObs: state => {
      state.changesInStatesAndObs = false
    },
    clearChangesStatusGF: state => {
      state.changesStatusGF = false
    },
    clearSaveDocumentation: state => {
      state.saveDocumentation = false
    },
    fetchCreateCompanyStart: state => {
      state.createCompanyIsLoading = true
      state.createCompanyError = ''
    },
    fetchCreateCompanySuccess: (state, { payload }) => {
      state.createCompanyIsLoading = false
      state.showCreateCompanyNotification = true
      state.createCompany = payload
    },
    fetchCreateCompanyFail: (state, { payload }) => {
      state.createCompanyIsLoading = false
      state.createCompanyError = payload
      state.showCreateCompanyNotification = true
    },
    clearCreateCompanyNotification: state => {
      state.showCreateCompanyNotification = false
      state.createCompanyIsLoading = false
      state.createCompanyError = ''
      state.createCompany = {}
    },
  },
})

export const {
  fetchCompaniesStart,
  fetchCompaniesSuccess,
  fetchCompaniesFail,
  fetchCompanyStart,
  fetchCompanySuccess,
  fetchCompanyFail,
  fetchCompanyListExcelStart,
  fetchCompanyListExcelSuccess,
  fetchCompanyListExcelFail,
  fetchCompanyStateStart,
  fetchCompanyStateSuccess,
  fetchCompanyStateFail,
  fetchFamilyGroupDetailStart,
  fetchFamilyGroupDetailSuccess,
  fetchFamilyGroupDetailFail,
  fetchChangesInStatesAndObsStart,
  fetchChangesInStatesAndObsSuccess,
  fetchChangesInStatesAndObsFail,
  fetchChangesStatusGFStart,
  fetchChangesStatusGFSuccess,
  fetchChangesStatusGFFail,
  fetchSaveDocumentationStatus,
  fetchSaveDocumentationStart,
  fetchSaveDocumentationSuccess,
  fetchSaveDocumentationFail,
  clearChangesAndObs,
  clearChangesStatusGF,
  clearSaveDocumentation,
  fetchCreateCompanyStart,
  fetchCreateCompanySuccess,
  fetchCreateCompanyFail,
  clearCreateCompanyNotification,
} = slice.actions

export default slice.reducer

/**
 * Thunks
 */

export const getCompanies = (props: getCompaniesRequestModel) => async (
  dispatch: any
) => {
  try {
    dispatch(fetchCompaniesStart())

    const response = await companyController.getCompanies(props)

    dispatch(fetchCompaniesSuccess(response))
  } catch (error) {
    dispatch(fetchCompaniesFail())
  }
}

export const getCompany = (props: getCompanyRequestParam) => async (
  dispatch: any
) => {
  try {
    dispatch(fetchCompanyStart())

    const response = await companyController.getCompany(props)

    dispatch(fetchCompanySuccess(response))
  } catch (error) {
    dispatch(fetchCompanyFail)
  }
}

export const getCompanyListExport = () => async (dispatch: any) => {
  try {
    dispatch(fetchCompanyListExcelStart())

    await companyController.getExportCompanyList()

    dispatch(fetchCompanyListExcelSuccess())
  } catch (error) {
    dispatch(fetchCompanyListExcelFail())
  }
}

export const getCompanyState = (props: getCompanyStateParam) => async (
  dispatch: any
) => {
  try {
    dispatch(fetchCompanyStateStart())

    const response = await companyController.getCompanyStates(props)

    dispatch(fetchCompanyStateSuccess(response))
  } catch (error) {
    dispatch(fetchCompanyStateFail('Hubo un error'))
  }
}

export const getCompanyStateDisponibles = (
  props: getCompanyStateDisponiblesParam
) => async (dispatch: any) => {
  try {
    dispatch(fetchCompanyStateStart())

    const response = await companyController.getCompanyStatesDisponibles(props)

    dispatch(fetchCompanyStateSuccess(response))
  } catch (error) {
    dispatch(fetchCompanyStateFail('Hubo un error'))
  }
}

export const getFamilyGroupDetail = (
  props: getFamilyGroupDetailParam
) => async (dispatch: any) => {
  try {
    dispatch(fetchFamilyGroupDetailStart())

    const response = await companyController.getFamilyGroupDetail(props)

    dispatch(fetchFamilyGroupDetailSuccess(response))
  } catch (error) {
    dispatch(fetchFamilyGroupDetailFail())
  }
}

export const changesInStatesAndObs = (props: changeStateFGParams) => async (
  dispatch: any
) => {
  try {
    dispatch(fetchChangesInStatesAndObsStart())

    const response = await companyController.changeStateObsFG(props)

    dispatch(fetchChangesInStatesAndObsSuccess(response))
    dispatch(getFamilyGroupDetail(props))
  } catch (error) {
    dispatch(fetchChangesInStatesAndObsFail())
  }
}

export const changeStatusGF = (props: changeStatusGFParams) => async (
  dispatch: any
) => {
  try {
    dispatch(fetchChangesStatusGFStart())

    const response = await companyController.changeStatusGF(props)
    dispatch(fetchChangesStatusGFSuccess(props))
    return response
  } catch (error) {
    dispatch(fetchChangesStatusGFFail())
    throw new Error(error?.message)
  }
}

export const saveDocumentation = (
  props: fileParams,
  isAfilliation: boolean
) => async (dispatch: any) => {
  try {
    dispatch(fetchSaveDocumentationStart())
    const response = await companyController.saveDocumentation(props)
    dispatch(fetchSaveDocumentationSuccess(response))
    if (!isAfilliation) {
      dispatch(getFamilyGroupDetail(props))
    } else {
      const propsAfi = { idAfiliacion: '1144', tipoAfiliacion: '5' }
      dispatch(getAfilliation(propsAfi))
    }
  } catch (error) {
    dispatch(fetchSaveDocumentationFail())
  }
}

export const clearChanges = () => async (dispatch: any) => {
  dispatch(clearChangesAndObs())
  dispatch(clearChangesStatusGF())
  dispatch(clearSaveDocumentation())
}

export const createCompany = (props: createCompanyParam) => async (
  dispatch: any
) => {
  try {
    dispatch(fetchCreateCompanyStart())

    const response = await companyController.createCompany(props)

    if (response.status !== 200 || response.data.status !== 200) {
      return dispatch(
        fetchCreateCompanyFail(
          response.data.mensaje ||
            'Se produjo un error al dar de alta la empresa'
        )
      )
    }

    dispatch(fetchCreateCompanySuccess(response))
  } catch (error) {
    dispatch(
      fetchCreateCompanyFail(
        'Se produjo un error inesperado al dar de alta la empresa'
      )
    )
  }
}

export const clearCompanyNotification = () => async (dispatch: any) => {
  dispatch(clearCreateCompanyNotification())
}

// SELECTORS
export const selectCompanies = (state: RootState) => state.companyPAD
export const selectCompanySearch = createSelector(
  selectCompanies,
  company => company.resultados
)

export const selectStatesForDropdowns = (state: RootState): ISelectFormat[] =>
  (state.companyPAD?.companyState || []).map(
    (s: State): ISelectFormat => ({
      value: s.id,
      label: s.descripcion,
    })
  )

export const selectCompany = (state: RootState) => state.company.company
export const selectCompanyState = (state: RootState) =>
  state.company.companyState
export const selectFamilyGroupDetail = ({ companyPAD }: RootState) => ({
  isFetching: companyPAD.familyGroupDetailIsFetching,
  familyGroupDetail: companyPAD.familyGroupDetail,
})
export const selectFamilyMembersMappedToDropdown = ({
  company,
}: RootState): ISelectFormat[] =>
  company.familyGroupDetail?.socios.map(s => ({
    label: `${s.apellido}, ${s.nombre}`,
    value: s.id,
  })) || [{ label: '', value: '' }]
export const selectCompanyStatesMappedToDropDown = ({
  company,
}: RootState): ISelectFormat[] =>
  company.companyState?.map(s => ({
    label: s.descripcion,
    value: s.id,
  })) || [{ label: '', value: '' }]

export const selectChangesObsIsFetching = (state: RootState) =>
  state.company.changesInStatesAndObsIsFetching
export const selectChangesSuccess = (state: RootState) =>
  state.company.changesInStatesAndObs

export const selectSaveDocumentationIsFetching = (state: RootState) =>
  state.companyPAD.saveDocumentationIsFetching

export const selectSaveDocumentationSuccess = (state: RootState) =>
  state.companyPAD.saveDocumentation

export const selectSaveDocumentationError = (state: RootState) =>
  state.companyPAD.saveDocumentationError

export const selectChangesStatusGFIsFetching = (state: RootState) =>
  state.companyPAD.changesStatusGFIsFetching

export const selectChangesStatusGFSuccess = (state: RootState) =>
  state.companyPAD.changesStatusGF

export const selectCreateCompany = (state: RootState) => ({
  isLoading: state.company.createCompanyIsLoading,
  error: state.company.createCompanyError,
  companyCode: state.company.createCompany?.data?.codigo,
})

export const selectShowCreateCompanyNotification = (state: RootState) =>
  state.company.showCreateCompanyNotification
