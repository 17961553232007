import { Supervisor } from 'domain/entities'

import { ISupervisorEntityGateway } from 'domain/entityGateways/ISupervisorEntityGateway'

import { getAllAmbaSupervisors } from 'domain/responseModel'

import { getAdvisorsBySupervisorRequestParams } from 'domain/requestModel'

export class UseCaseSupervisor implements ISupervisorEntityGateway {
  private entityGateway: ISupervisorEntityGateway

  constructor(entityGateway: ISupervisorEntityGateway) {
    this.entityGateway = entityGateway
  }

  public async getSupervisorsAmba(): Promise<Supervisor[]> {
    const response = await this.entityGateway.getSupervisorsAmba()

    return getAllAmbaSupervisors(response)
  }

  public async getAdvisorsBySupervisor(
    params: getAdvisorsBySupervisorRequestParams
  ): Promise<any> {
    const response = await this.entityGateway.getAdvisorsBySupervisor(params)

    return response
  }
}

export default {
  UseCaseSupervisor,
}
