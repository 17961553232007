import styled from 'styled-components'

export const InputsContainer = styled.div`
  /* @media (min-width: ${({ theme }) => theme.breakPoints.small}) {
    display: flex;
    justify-content: center;
  } */
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-top: 2rem;
`
export const PageContainer = styled.div`
  width: 100%;
  background-color: #f5fcff;
  border-bottom: 3px solid #91d8f7;
  padding-top: 1.2rem;
`
export const Title = styled.div`
  margin-bottom: 1.2rem;
`
export const BottomContainer = styled.div`
  margin-top: 2rem;
  margin-bottom: 1.5rem;
`
export const PageContent = styled.div`
  padding: 24px;
`
export const FootFields = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`
export const ExportButtonContainer = styled.div`
  padding: 0 2rem;
`

export const FilterContainer = styled.div``

export const ShowFilterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
`

export const ShowFilterButton = styled.div`
  display: inline-flex;
  flex-direction: column;
`
