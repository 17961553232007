import React from 'react'

import { CardImage } from '@styled-icons/bootstrap/CardImage'
import { Search } from '@styled-icons/boxicons-regular/Search'
import { ArrowIosUpwardOutline } from '@styled-icons/evaicons-outline/ArrowIosUpwardOutline'
import { ArrowIosDownwardOutline } from '@styled-icons/evaicons-outline/ArrowIosDownwardOutline'
import { PowerSettingsNew } from '@styled-icons/material/PowerSettingsNew'
import { Close } from '@styled-icons/ionicons-sharp/Close'
import { DotsHorizontal } from '@styled-icons/heroicons-outline/DotsHorizontal'
import { LeftArrowAlt } from '@styled-icons/boxicons-regular/LeftArrowAlt'
import { CalendarEvent } from '@styled-icons/boxicons-regular/CalendarEvent'
import { ArrowBackIos } from '@styled-icons/material/ArrowBackIos'
import { ArrowForwardIos } from '@styled-icons/material/ArrowForwardIos'
import { CloudUpload } from '@styled-icons/boxicons-regular/CloudUpload'
import { Upload } from '@styled-icons/boxicons-regular/Upload'

import { PaperClip } from '@styled-icons/heroicons-outline/PaperClip'
import { Calendar4 } from '@styled-icons/bootstrap/Calendar4'
import { Business } from '@styled-icons/ionicons-sharp/Business'

import { StyledIconWrapper } from 'app/components/Icons/styles'
import { Download } from '@styled-icons/boxicons-regular'

interface IIconWrapper {
  enabled?: boolean
  color?: string
  size?: string
  loading?: boolean
  children?: any
  centered?: boolean
  onClick?: () => void
  isCursor?: boolean
  className?: string
  marginZero?: boolean
  margin?: string
}

export const IconWrapper = ({
  enabled = true,
  size = 'md',
  color = 'biscay',
  loading = false,
  centered = true,
  children = null,
  onClick,
  isCursor = false,
  className = '',
  marginZero = false,
  margin,
}: IIconWrapper) => {
  const props = {
    enabled,
    size,
    color,
    loading,
    centered,
    children,
    onClick,
    isCursor,
    className,
    marginZero,
    margin,
  }

  return (
    <StyledIconWrapper {...props}>
      {loading ? <IconCardImage {...props} /> : children}
    </StyledIconWrapper>
  )
}

export const IconCardImage = (props: IIconWrapper) => (
  <IconWrapper {...props}>
    <CardImage />
  </IconWrapper>
)

export const IconSearch = (props: IIconWrapper) => (
  <IconWrapper {...props}>
    <Search />
  </IconWrapper>
)

export const IconArrowIosUpwardOutline = (props: IIconWrapper) => (
  <IconWrapper {...props}>
    <ArrowIosUpwardOutline />
  </IconWrapper>
)

export const IconArrowIosDownwardOutline = (props: IIconWrapper) => (
  <IconWrapper {...props}>
    <ArrowIosDownwardOutline />
  </IconWrapper>
)

export const IconPowerSettingsNew = (props: IIconWrapper) => (
  <IconWrapper {...props}>
    <PowerSettingsNew />
  </IconWrapper>
)

export const IconClose = (props: IIconWrapper) => (
  <IconWrapper {...props}>
    <Close />
  </IconWrapper>
)

export const IconDotsHorizontal = (props: IIconWrapper) => (
  <IconWrapper {...props}>
    <DotsHorizontal />
  </IconWrapper>
)

export const IconLeftArrowAlt = (props: IIconWrapper) => (
  <IconWrapper {...props}>
    <LeftArrowAlt />
  </IconWrapper>
)

export const IconCalendarEvent = (props: IIconWrapper) => (
  <IconWrapper {...props}>
    <CalendarEvent />
  </IconWrapper>
)

export const IconArrowBackIos = (props: IIconWrapper) => (
  <IconWrapper {...props}>
    <ArrowBackIos />
  </IconWrapper>
)

export const IconArrowForwardIos = (props: IIconWrapper) => (
  <IconWrapper {...props}>
    <ArrowForwardIos />
  </IconWrapper>
)

export const IconCloudUpload = (props: IIconWrapper) => (
  <IconWrapper {...props}>
    <CloudUpload />
  </IconWrapper>
)

export const IconUpload = (props: IIconWrapper) => (
  <IconWrapper {...props}>
    <Upload />
  </IconWrapper>
)

export const IconPaperClip = (props: IIconWrapper) => (
  <IconWrapper {...props}>
    <PaperClip />
  </IconWrapper>
)

export const IconCalendar = (props: IIconWrapper) => (
  <IconWrapper {...props}>
    <Calendar4 />
  </IconWrapper>
)

export const IconBusiness = (props: IIconWrapper) => (
  <IconWrapper {...props}>
    <Business />
  </IconWrapper>
)
export const DownloadIcon = (props: IIconWrapper) => (
  <IconWrapper {...props} marginZero={true}>
    <Download />
  </IconWrapper>
)
