import React from 'react'

import { Row } from 'app/components'

import { Image, BrandText, LogoLink } from 'app/components/Navbar/styles'

import logo_omint_blanco from 'app/images/logo_omint_blanco.svg'

const Brand = () => {
  return (
    <Row>
      <LogoLink>
        <Image src={logo_omint_blanco} alt="Omint" />
      </LogoLink>
      <BrandText color={'white'} size={'lg'} weight={'xs'}>
        gestiones
      </BrandText>
    </Row>
  )
}

export default Brand
