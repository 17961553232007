import { Supervisor, ILogger } from 'domain/entities'

import { endpoints } from 'app/constants'
import { getAdvisorsBySupervisorRequestParams } from 'domain/requestModel/supervisor'

export class SupervisorsService {
  private httpClient: any
  private logger: any

  constructor(clientProvider: any, logger: ILogger) {
    this.httpClient = clientProvider
    this.logger = logger
  }

  public async getSupervisorsAmba(): Promise<Supervisor[]> {
    try {
      const supervisorsAmba = (
        await this.httpClient.get(endpoints.supervisorAmba)
      ).data

      return supervisorsAmba
    } catch (error) {
      this.logger.error({
        message: 'UseCase - getSupervisorsAmba',
        data: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
      })

      throw new Error(error)
    }
  }

  public async getAdvisorsBySupervisor(
    params: getAdvisorsBySupervisorRequestParams
  ): Promise<any> {
    try {
      const advisorsBySupervisor = (
        await this.httpClient.get(endpoints.asesoresPorSupervisor, params)
      ).data

      return advisorsBySupervisor
    } catch (error) {
      this.logger.error({
        message: 'UseCase - getAdvisorsBySupervisor',
        data: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
      })

      throw new Error(error)
    }
  }
}

export default {
  SupervisorsService,
}
