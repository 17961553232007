import { loginRequestModel } from 'domain/requestModel'

import { ICredentialEntityGateway } from 'domain/entityGateways/ICredentialEntityGateway'

export class Login {
  private entityGateway: ICredentialEntityGateway

  constructor(entityGateway: ICredentialEntityGateway) {
    this.entityGateway = entityGateway
  }

  public async login(credential: loginRequestModel) {
    const login = await this.entityGateway.login(credential)

    return login
  }
}
