import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { TGetAdvisorByNameOrCode } from 'domain/requestModel'

import { getAdvisorByNameOrCode } from 'app/slices/advisorSlice'
import { getAllProvincies, selectProvincies } from 'app/slices/provinceSlice'
import CompanyFilter from './CompanyFilter'
import { makeCompanyRequestModel } from 'domain/requestModel/company'
import {
  getCompanies,
  getCompanyListExport,
  selectCompanies,
  getCompanyState,
  selectCompanyStatesMappedToDropDown,
} from 'app/slices/company'
import CompanyTable from './Table'
import { mapCompanyData } from './presenters'
import { useHistory } from 'react-router-dom'
import { Button, Row } from 'app/components'
import * as S from './style'

interface ICompanyInfo {
  path: string
}

const Company = (props: ICompanyInfo) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const allProvincies = useSelector(selectProvincies)
  const companyStates = useSelector(selectCompanyStatesMappedToDropDown)
  const companies = useSelector(selectCompanies)
  const provincies = useSelector(selectProvincies)

  const handleOnChangeAdvisor = (codeOrName: any) => {
    let param: TGetAdvisorByNameOrCode | any = {}

    isNaN(codeOrName)
      ? (param['nombre'] = codeOrName)
      : (param['codAsesor'] = codeOrName)

    dispatch(getAdvisorByNameOrCode(param))
  }

  const handleSubmit = (values: any) => {
    const params = makeCompanyRequestModel(values)
    dispatch(getCompanies(params))
  }

  const handleOnPressItem = (element: any, key: any) =>
    history.push(`empresas/${element[key]}`)

  const handleOnPressExportCompanyList = () => dispatch(getCompanyListExport())

  useEffect(() => {
    dispatch(getAllProvincies())
  }, [dispatch])

  useEffect(() => {
    dispatch(getCompanies(undefined))
  }, [dispatch])

  useEffect(() => {
    dispatch(getCompanyState({ idTipoEstado: 3 }))
  }, [dispatch])

  return (
    <>
      <CompanyFilter
        onSubmit={handleSubmit}
        onChangeAdvisor={handleOnChangeAdvisor}
        provinces={allProvincies}
        companyStates={companyStates}
        paginationPageCount={companies.totalPaginas}
      >
        <Row justifyContent="flex-end">
          <S.ExportButtonContainer>
            <Button outline onClick={handleOnPressExportCompanyList}>
              Exportar
            </Button>
          </S.ExportButtonContainer>
        </Row>
        <CompanyTable
          data={mapCompanyData(companies, provincies).resultados}
          isLoading={companies.loading}
          onPressItem={handleOnPressItem}
        />
      </CompanyFilter>
    </>
  )
}

export default Company
