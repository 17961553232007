import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Formik, Form } from 'formik'

import { Button, LeadsDropdown, LoadingSpinner, Text } from 'app/components'

import { selectAdvisorsBySupervisorIsFetching } from 'app/slices/supervisorSlice'
import { ISupervisorUpdateForm } from 'app/Pages/Lead/interfaces'
import * as S from 'app/Pages/Lead/style'
import {
  selectAdvisorIsUpdated,
  selectPutLeadAdvisorIsLoading,
} from 'app/slices/leadsSlice'

const SupervisorUpdateForm: FC<ISupervisorUpdateForm> = ({
  advisorsBySupervisor,
  onSubmit,
  selectedAdvisor,
  setSelectedAdvisor,
  disableSubmitForm,
}) => {
  const isUpdated = useSelector(selectAdvisorIsUpdated)
  const putLeadAdvisorIsLoading = useSelector(selectPutLeadAdvisorIsLoading)
  const advisorsBySupervisorIsFetching = useSelector(
    selectAdvisorsBySupervisorIsFetching
  )

  return (
    <Formik
      initialValues={{}}
      onSubmit={(values: any) => {
        onSubmit(values.Asesor.value)
      }}
    >
      {(props: {
        values: any
        errors: {
          advisors: boolean
        }
        touched: {
          advisors: boolean
        }
        onChange: any
        setFieldValue: any
        advisorsBySupervisor: any
      }) => (
        <Form>
          <S.FormContainer>
            <S.ButtonContainer>
              <LeadsDropdown
                onChange={(option: any) => {
                  props.setFieldValue('Asesor', option ? option : null)
                  setSelectedAdvisor(option)
                }}
                name={'Asesor'}
                id={'Asesor'}
                value={selectedAdvisor}
                options={
                  advisorsBySupervisor &&
                  advisorsBySupervisor?.map((b: any) => {
                    return {
                      label: b.nombre,
                      value: b.codAsesor,
                    }
                  })
                }
                warning={props.errors.advisors && props.touched.advisors}
                isLoading={advisorsBySupervisorIsFetching}
              />
            </S.ButtonContainer>
            {putLeadAdvisorIsLoading ? (
              <LoadingSpinner isLoading={true} />
            ) : (
              <S.ButtonContainer>
                <Button
                  disabled={disableSubmitForm}
                  type="submit"
                  display={'inline-table'}
                >
                  Modificar Asesor
                </Button>
              </S.ButtonContainer>
            )}
            {isUpdated && !advisorsBySupervisorIsFetching && (
              <Text color={'green'} textAlign={'right'}>
                Asesor Actualizado
              </Text>
            )}
          </S.FormContainer>
        </Form>
      )}
    </Formik>
  )
}

export default SupervisorUpdateForm
