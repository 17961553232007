export const endpoints = {
  login: '/Login',
  provincias: '/Provincias',
  seguros: '/seguros',
  seguro: '/seguro',
  EstadosBODisponibles: '/seguro/EstadosBODisponibles',
  ActualizarEstadoBackOffice: '/seguro/ActualizarEstadoBackOffice',
  exportSeguros: '/seguro/excel',
  exportLeads: '/lead/excel',
  leads: '/leads',
  lead: '/lead',
  actualizarEstado: '/lead/ActualizarEstado',
  actualizarAsesor: '/Lead/AsignarAsesor',
  guardarObservacion: '/Lead/Evento',
  estadosDerivadora: '/EstadosDerivadora',
  estados: '/Estados',
  estadosPAD: '/empresas/estados',
  estadosDisponiblePAD: '/estados/Disponibles',
  estadosDisponiblesBo: '/estados/DisponiblesBO',
  agendaAsesoresListado: '/AgendaAsesores/Listado',
  actualizarAgendaAsesores: '/AgendaAsesores',
  supervisorAmba: '/Supervisor/Amba',
  asesoresPorSupervisor: '/Asesor/Supervisor',
  agendaAsesoresExcel: '/AgendaAsesores/Excel',
  leadsCargaMasiva: '/Lead/CargaMasiva',
  filtrarAsesor: '/Asesor/Filtro',
  exportRejectedPolicies: '/Seguro/RechazosExcel',
  abandonQuote: '/Seguro/ExcelAbandonoCotizacion',
  afilliations: '/afiliaciones',
  afilliation: '/afiliacion',
  dniImage: '/dni/Image',
  availableStates: '/estados/Disponibles',
  stateUpdate: '/CambioDeEstado',
  evento: '/Evento',
  medicAreaReport: '/afiliacion/excelareamedica',
  medicAreaDetail: '/afiliacion/excelareamedicaDetalle',
  getCompanyHolders: '/pyme/titulares',
  getCompanies: '/pymes',
  getCompany: '/pyme',
  getCompanyRequest: '/empresas/solicitudes',
  getExportCompaniesList: '/pymes/excel',
  getFamilyGroupDetail: '/pymes/detalleGrupoFamiliar',
  getFamilyGroupDetailPAD: '/empresas/grupoFamiliar',
  stateChangeFG: '/CambioDeEstadoGF',
  eventChangeFG: '/empresas/eventos',
  getStatusDocuments: '/estados/DisponiblesBO',
  stateDocumentation: '/EstadoDocumentacion',
  changeStatePyme: '/CambioDeEstadoPyme',
  eventoPyme: '/EventoPyme',
  createCompany: '/pymes/Alta',
  documentation: '/documentacion',
  statusChangeFG: '/empresas/grupofamiliar/estado',
  getAcuerdos: '/ooss/modulosacuerdos',
  updateAcuerdo: '/ooss/acuerdo',
}
