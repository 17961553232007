import { IAdvisorAgendaEntityGateway } from 'domain/entityGateways/IAdvisorAgendaEntityGateway'

import { TGetListAdvisorsParam, TUpdateListAdvisors } from 'domain/requestModel'

import { TGetListAdvisors } from 'domain/responseModel'

export class UseCaseAdvisorAgenda implements IAdvisorAgendaEntityGateway {
  private entityGateway: IAdvisorAgendaEntityGateway

  constructor(entityGateway: IAdvisorAgendaEntityGateway) {
    this.entityGateway = entityGateway
  }

  public async getListAdvisors(
    params: TGetListAdvisorsParam
  ): Promise<TGetListAdvisors> {
    const listAdvisors = await this.entityGateway.getListAdvisors(params)

    return listAdvisors
  }

  public async updateListAdvisors(params: TUpdateListAdvisors): Promise<any> {
    const updated = await this.entityGateway.updateListAdvisors(params)

    return updated
  }

  public async exportAdvisorsExcel(
    params: TGetListAdvisorsParam
  ): Promise<any> {
    const exportedList = await this.entityGateway.exportAdvisorsExcel(params)

    return exportedList
  }
}
