import styled from 'styled-components'

import { TTable, TItems, TCards } from 'app/components/ui/GenericTable'

import { shadowedComponent } from 'app/components/ui/styles'

export const TableContainer = styled.div<TTable>`
  height: 100%;
  min-height: 400px;
  margin: 24px 16px;
  ${shadowedComponent}
  ${props => props.isLoading && 'display: flex;'}
`

export const Items = styled.div<TItems>`
  width: 100%;
  min-height: 52px;
  word-break: break-all;

  & .detailIcon {
    cursor: pointer;
  }

  & .cardItemContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    word-break: break-all;
    background-color: white;
    border-top: 0.5px solid ${props => props.theme.colors.silver};

    @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
      display: ${props => (!props.displayItem ? 'flex' : 'none')};
    }
  }

  & .cardItemContainer > div {
    display: flex;
    justify-content: center;
    font-weight: bold;
    align-items: center;
    border-bottom: 3px solid ${props => props.theme.colors.silver};
    word-break: break-all;
  }

  & .cardItem:nth-child(1),
  & .cardItem:nth-child(2),
  & .cardItem:nth-child(10),
  & .cardItem:nth-child(11) {
    width: 70vw;
  }
  & .cardItem:nth-child(3) {
    width: 170vw;
  }
  & .cardItem:nth-child(4),
  & .cardItem:nth-child(5) {
    width: 140vw;
  }
  & .cardItem:nth-child(6) {
    width: 40vw;
  }
  & .cardItem:nth-child(7),
  & .cardItem:nth-child(8),
  & .cardItem:nth-child(9) {
    width: 120vw;
  }

  & div:nth-child(n + 2):not(:last-child) {
    border-left: 1px solid ${props => props.theme.colors.alto};
  }

  @media (min-width: ${({ theme }) => theme.breakPoints.small}) {
    & div:nth-child(n + 2) {
      border-left: 1px solid ${props => props.theme.colors.alto};
    }
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
    & .cardItem:nth-child(n + 4) {
      display: none;
    }
  }

  & span {
    ${props => !props.displayItem && 'width: 100%;'}
    font-weight: bold;
  }
`

export const Cards = styled.div<TCards>`
  display: none;

  @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
    display: ${props => (props.displayCard ? 'block' : 'none')};
    width: calc(100vw - 32px);
  }

  padding: 24px 14px 23px 14px;

  & div:first-child {
    justify-content: space-between;
  }

  & div > span:first-child {
    padding-right: 5px;
    padding-bottom: 8px;
  }

  & div {
    ${props => props.displayCard && `border-left: none!important;`}
  }

  & hr {
    border: 1px solid ${props => props.theme.colors.shipCove};
    width: 85%;
    margin: 0px 0px 16px 0px;
  }
`

export const LinkDetailIcon = styled.div`
  padding-right: 10px;
  float: right;

  @media (min-width: ${({ theme }) => theme.breakPoints.small}) {
    display: none;
  }
`

export const ItemHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 52px;
  border-bottom: 2px solid ${props => props.theme.colors.shipCove};

  @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
    :nth-child(n + 4) {
      display: none;
    }
  }
`

export const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  background-color: ${props => props.theme.colors.foam};

  & > :nth-child(1),
  & > :nth-child(2),
  & > :nth-child(10),
  & > :nth-child(11) {
    width: 70%;
  }
  & > :nth-child(3) {
    width: 170%;
  }
  & > :nth-child(4),
  & > :nth-child(5) {
    width: 140%;
  }
  & > :nth-child(6) {
    width: 40%;
  }
  & > :nth-child(7),
  & > :nth-child(8),
  & > :nth-child(9) {
    width: 120%;
  }

  & div:nth-child(n + 1):not(:last-child) {
    border-right: 1px solid ${props => props.theme.colors.alto};
  }
`
