import React from 'react'
import { Formik, Form } from 'formik'

import { Text, Button, InputFile, Row } from 'app/components'

import { FormFileContent } from 'app/Pages/Leads/styles'
import { ImportExcelValidation } from 'app/Pages/Leads/constants'
import { strings } from 'app/constants/strings'

const {
  importDataForm: {
    downloadTemplate,
    downloadProvAndLoc,
    importData,
    uploadData,
  },
} = strings

type TImportExcelForm = {
  onSubmit: (values: any) => void
  onPressDownloadTemplate: () => void
  onPressDownloadProvAndLoc: () => void
}

const ImportExcelForm = ({
  onSubmit,
  onPressDownloadTemplate,
  onPressDownloadProvAndLoc,
}: TImportExcelForm) => {
  return (
    <Formik
      initialValues={{ leadFile: null }}
      onSubmit={(values: any, { setSubmitting }) => {
        setSubmitting(false)
        onSubmit(values)
      }}
      validationSchema={ImportExcelValidation}
    >
      {(props: {
        values: any
        errors: {
          leadFile: boolean
        }
        touched: {
          leadFile: boolean
        }
        submitForm: any
        setFieldValue: any
        onBlur: any
      }) => (
        <Form>
          <FormFileContent>
            <Row>
              <Button outline onClick={onPressDownloadTemplate}>
                {downloadTemplate}
              </Button>
            </Row>
            <Row>
              <Button outline onClick={onPressDownloadProvAndLoc}>
                {downloadProvAndLoc}
              </Button>
            </Row>
            <Text textAlign={'center'} size={'lg'} weight={'lg'}>
              {importData}
            </Text>
            <InputFile
              field={{
                name: 'leadFile',
                value: props.values.leadFile,
                onChange: props.setFieldValue,
                onBlur: props.onBlur,
              }}
              id={'leadFile'}
              title={uploadData}
              warning={props.errors.leadFile && props.touched.leadFile}
            />
            <Button type="submit">Enviar</Button>
          </FormFileContent>
        </Form>
      )}
    </Formik>
  )
}

export default ImportExcelForm
