import React from 'react'
import styled from 'styled-components'
import { Text, Button } from 'app/components/ui'

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 2rem 1.25rem 2rem;
  gap: 0.5rem;
`

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const PaperModeModalContent = ({
  onPressCancel,
  onPressConfirmCancel,
}: {
  onPressCancel: () => void
  onPressConfirmCancel: () => void
}) => {
  return (
    <ModalContainer>
      <Text color="mariner" weight="md" size="lg">
        Atención
      </Text>
      <BodyContainer>
        <Text color="chathamsBlue" weight="sm" size="mdLg">
          Estás por avanzar con la modalidad papel
        </Text>
        <Text color="chathamsBlue" weight="sm" size="mdLg">
          ¿Deseas continuar?
        </Text>
      </BodyContainer>
      <ButtonsContainer>
        <Button onClick={onPressConfirmCancel}>Si</Button>
        <Button outline={true} onClick={onPressCancel}>
          No
        </Button>
      </ButtonsContainer>
    </ModalContainer>
  )
}

export default PaperModeModalContent
