const upperCaseFirstLetter = (str: string): string => {
  if (typeof str !== 'string') return ''

  return str.charAt(0).toUpperCase() + str.slice(1)
}

const getRandomUniqueId = (len: number): string =>
  Math.random().toString(36).substr(2, len)

export default {
  upperCaseFirstLetter,
  getRandomUniqueId,
}
