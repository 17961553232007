import { ILogger } from 'domain/entities'

import { IProvinceDTO } from 'domain/responseModel'

import { endpoints } from '../constants'

export class ProvinciesServices {
  private httpClient: any
  private localClient: any
  private logger: any

  constructor(clientProvider: any, localProvider: any, logger: ILogger) {
    this.httpClient = clientProvider
    this.localClient = localProvider
    this.logger = logger
  }

  public async getAllProvincies(): Promise<IProvinceDTO> {
    try {
      const provincies = (await this.httpClient.get(endpoints.provincias)).data

      return provincies
    } catch (error) {
      this.logger.error({
        message: 'UseCase - getAllProvinces',
        data: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
      })

      throw new Error(error)
    }
  }

  public async getProvinciesAndLocalitiesCodes(): Promise<any> {
    try {
      const response = (
        await this.localClient.get('Provincias y localidades.xlsx', null, {
          responseType: 'blob',
        })
      ).data

      let url = window.URL.createObjectURL(response)
      let a = document.createElement('a')
      a.href = url
      a.download = 'provincia-y-localidades.xlsx'
      a.click()
    } catch (error) {
      this.logger.error({
        message: 'UseCase - Get Provincies and Localities',
        data: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
      })

      throw new Error(error)
    }
  }
}

export default {
  ProvinciesServices,
}
