import { Leads, LeadState } from 'domain/entities'

import { isString, isNumber } from 'helpers'

export interface ILeadsDTO {
  resultados: Leads[]
  totalPaginas: number
  totalCantidad: number
}

interface HistorialItem {
  id: number
  observacion: string
  fecha: string
  usuario: string
  tipo: number
}

export interface ILeadDTO {
  data: {
    [key: string]: string | number | Date | boolean | undefined | any[]
    asesor: string
    id: number
    tipoLead: number
    nombre: string
    eMail: string
    telefono: string
    edad: number
    provincia: number
    localidad: number
    linkContinuar: string
    idEstadoDerivadora: number
    observaciones: string
    observacionesList: HistorialItem[] | undefined
    historial: HistorialItem[] | undefined
    fecha: string | undefined
    idCanalVenta: number
    supervisor: string
  }
}

export const getLeadToModel = (raw: ILeadDTO): ILeadDTO => {
  const data: ILeadDTO = {
    data: {
      asesor: isString(raw.data.asesor, 'asesor', false),
      nombre: isString(raw.data.nombre, 'nombre', false),
      eMail: raw.data.eMail && isString(raw.data.eMail, 'eMail'),
      telefono: isString(raw.data.telefono, 'telefono'),
      edad: isNumber(raw.data.edad, 'edad', false),
      id: isNumber(raw.data.id, 'id'),
      tipoLead: isNumber(raw.data.tipoLead, 'tipoLead'),
      provincia:
        raw.data.provincia && isNumber(raw.data.provincia, 'provincia'),
      localidad:
        raw.data.localidad && isNumber(raw.data.localidad, 'localidad'),
      linkContinuar:
        raw.data.linkContinuar &&
        isString(raw.data.linkContinuar, 'linkContinuar'),
      idEstadoDerivadora:
        raw.data.idEstadoDerivadora &&
        isNumber(raw.data.idEstadoDerivadora, 'idEstadoDerivadora'),
      idCanalVenta:
        raw.data.idCanalVenta &&
        isNumber(raw.data.idCanalVenta, 'idCanalVenta'),
      fecha: raw.data.fecha,
      observaciones:
        raw.data.observaciones &&
        isString(raw.data.observaciones, 'observaciones'),
      observacionesList: raw.data.observacionesList,
      historial: raw.data.historial,
      supervisor:
        raw.data.supervisor && isString(raw.data.supervisor, 'supervisor'),
    },
  }
  return data
}

export const getLeadsToModel = (raw: ILeadsDTO): ILeadsDTO => {
  return {
    resultados: raw.resultados.map((e: Leads) => ({
      id: isNumber(e.id, 'id'),
      tipoLead: isNumber(e.tipoLead, 'tipoLead'),
      idEstadoDerivadora:
        e.idEstadoDerivadora &&
        isNumber(e.idEstadoDerivadora, 'idEstadoDerivadora'),
      nombre: isString(e.nombre, 'nombre', false),
      eMail: isString(e.eMail, 'eMail', false),
      observaciones: e.observaciones,
      telefono: isString(e.telefono, 'telefono'),
      fecha: e.fecha,
      edad: isNumber(e.edad, 'edad', false),
      provincia: e.provincia && isNumber(e.provincia, 'provincia'),
      localidad: e.localidad && isNumber(e.localidad, 'localidad'),
      linkContinuar:
        e.linkContinuar && isString(e.linkContinuar, 'linkContinuar'),
      idCanalVenta: e.idCanalVenta && isNumber(e.idCanalVenta, 'idCanalVenta'),
      asesor: e.asesor && isString(e.asesor, 'asesor'),
      supervisor: isString(e.supervisor, 'supervisor', false),
      nombreAsesor: isString(e.nombreAsesor, 'nombreAsesor', false),
      nombreSupervisor: isString(e.nombreSupervisor, 'nombreSupervisor', false),
      observacionesList: e.observacionesList,
      historial: e.historial,
    })),
    totalPaginas: isNumber(raw.totalPaginas, 'totalPaginas'),
    totalCantidad: isNumber(raw.totalCantidad, 'totalCantidad'),
  }
}

export const getLeadStatesToModel = (raw: LeadState[]): LeadState[] => {
  return raw.map((e: LeadState) => ({
    id: isNumber(e.id, 'getLeadStatesToModel - id'),
    descripcion: isString(e.descripcion, 'getLeadStatesToModel - descripcion'),
  }))
}
