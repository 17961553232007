import { fields } from 'app/constants'

export const initialValues = {
  fechaIngreso: undefined,
  listaEstados: '',
}

export const tableDesktopHeaders: string[] = [
  fields.fechaIngreso,
  fields.companyName,
  fields.cuit,
  fields.nombreYApellido,
  fields.documento,
  fields.estadoBackOffice,
  fields.detalle,
]

export const tableMobileHeaders: string[] = [
  fields.fechaIngreso,
  fields.companyName,
  fields.estadoBackOffice,
  fields.detalle,
]

export const tableHoldersHeaders: string[] = [
  fields.fechaIngreso,
  fields.documento,
  fields.nombreYApellido,
  fields.estadoBackOffice,
  fields.detalle,
]
