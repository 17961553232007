export const fields: { [keys: string]: string } = {
  fechaCotizacion: 'fechaCotizacion',
  estadoBackOffice: 'estadoBackOffice',
  tipoAfiliacion: 'tipoAfiliacion',
  nombre: 'nombre',
  apellido: 'apellido',
  dni: 'dni',
  nroDocumento: 'nroDocumento',
  cuil: 'cuil',
  provincia: 'provincia',
  asesores: 'asesores',
  credencial: 'credencial',
  fechaVigencia: 'fechaVigencia',
  detalle: 'detalle',
  codpostal: 'codpostal',
  formaPago: 'formaPago',
  nroTarjeta: 'nroTarjeta',
  tipoDoc: 'tipoDoc',
  eMail: 'eMail',
  edad: 'edad',
  localidad: 'localidad',
  linkContinuar: 'linkContinuar',
  idEstadoDerivadora: 'idEstadoDerivadora',
  idCanalVenta: 'idCanalVenta',
  day: 'day',
  assignedSupervisor: 'assignedSupervisor',
  contactNumber: 'contactNumber',
  fecha: 'fecha',
  asesor: 'asesor',
  supervisor: 'supervisor',
  idPregunta: 'idPregunta',
  id: 'id',
  tipoLead: 'tipoLead',
  smEmail: 'smEmail',
  smFirstName: 'smFirstName',
  smLastName: 'smLastName',
  dniTitular: 'dniTitular',
  tieneHijos: 'tieneHijos',
  tipoSocio: 'tipoSocio',
  fechaDeNacimiento: 'fechaDeNacimiento',
  estadoCivil: 'estadoCivil',
  codigoAsesor: 'codigoAsesor',
  numeroSocio: 'numeroSocio',
  motivo: 'motivo',
  years: 'years',
  gender: 'gender',
  phone: 'phone',
  weight: 'weight',
  planName: 'planName',
  effectiveDate: 'effectiveDate',
  afilliation: 'afilliation',
  desregulationState: 'desregulationState',
  height: 'height',
  dateOfEntry: 'dateOfEntry',
  companyName: 'companyName',
  cuit: 'cuit',
  ultimaActualizacion: 'ultimaActualizacion',
  nombreYApellido: 'nombreYApellido',
  documento: 'documento',
  planSeleccionado: 'planSeleccionado',
  estadoDesregulado: 'estadoDesregulado',
  obraSocial: 'obraSocial',
  fechaDeIngresoALaEmpresa: 'fechaDeIngresoALaEmpresa',
  planElegido: 'planElegido',
  aportes: 'aportes',
  sexo: 'sexo',
  recategorizado: 'recategorizado',
  fechaIngreso: 'fechaIngreso',
}

export const fieldsName: { [keys: string]: string } = {
  [fields.fechaCotizacion]: 'Fecha de cotización',
  [fields.estadoBackOffice]: 'Estado',
  [fields.tipoAfiliacion]: 'Tipo',
  [fields.nombre]: 'Nombre',
  [fields.apellido]: 'Apellido',
  [fields.dni]: 'DNI',
  [fields.nroDocumento]: 'N° documento',
  [fields.cuil]: 'CUIL',
  [fields.provincia]: 'Provincia',
  [fields.asesores]: 'Asesores',
  [fields.credencial]: 'N° de Póliza',
  [fields.fechaVigencia]: 'Fecha de vigencia',
  [fields.detalle]: 'Detalle',
  [fields.codpostal]: 'Código Postal',
  [fields.formaPago]: 'Forma de Pago',
  [fields.nroTarjeta]: 'Nro de Tarjeta',
  [fields.tipoDoc]: 'Tipo de Documento',
  [fields.eMail]: 'Email',
  [fields.edad]: 'Edad',
  [fields.localidad]: 'Localidad',
  [fields.linkContinuar]: 'Link continuar',
  [fields.idEstadoDerivadora]: 'Estado',
  [fields.day]: 'Día',
  [fields.assignedSupervisor]: 'Supervisor asignado',
  [fields.contactNumber]: 'Número de contacto',
  [fields.fecha]: 'Fecha',
  [fields.idCanalVenta]: 'Origen',
  [fields.asesor]: 'Asesor',
  [fields.idPregunta]: 'ID Pregunta',
  [fields.id]: 'Id',
  [fields.tipoLead]: 'Tipo de Lead',
  [fields.fechaDeNacimiento]: 'Fecha de Nacimiento',
  [fields.estadoCivil]: 'Estado civil',
  [fields.codigoAsesor]: 'Código Asesor',
  [fields.numeroSocio]: 'Nro Socio',
  [fields.motivo]: 'Motivo',
  [fields.years]: 'Años',
  [fields.gender]: 'Género',
  [fields.phone]: 'Teléfono',
  [fields.weight]: 'Peso',
  [fields.planName]: 'Plan',
  [fields.effectiveDate]: 'Vigencia',
  [fields.afilliation]: 'Afiliación',
  [fields.desregulationState]: 'Estado desregulación',
  [fields.height]: 'Altura',
  [fields.companyName]: 'Razón social',
  [fields.dateOfEntry]: 'Fecha de ingreso',
  [fields.cuit]: 'CUIT',
  [fields.ultimaActualizacion]: 'Fecha de ingreso',
  [fields.nombreYApellido]: 'Nombre y apellido',
  [fields.documento]: 'Documento',
  [fields.planSeleccionado]: 'Plan seleccionado',
  [fields.estadoDesregulado]: 'Estado desregulado',
  [fields.obraSocial]: 'Obra social',
  [fields.fechaDeIngresoALaEmpresa]: 'Fecha de ingreso a la empresa',
  [fields.planElegido]: 'Plan Elegido',
  [fields.aportes]: 'Aportes',
  [fields.sexo]: 'Sexo',
  [fields.recategorizado]: 'Recategorizado',
  [fields.fechaIngreso]: 'Fecha de ingreso de la solicitud',
}
