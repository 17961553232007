import React from 'react'

const UnfoundCredential = () => {
  return (
    <div>
      <i>No posee las credenciales necesarias...</i>
      <br />
      <a href="/">Login</a>
    </div>
  )
}

export default UnfoundCredential
