import React from 'react'
import { Text, LoadingSpinner } from 'app/components'
import ApplicationData from './ApplicationData'
import * as S from './styles'
import { getFormatedDate, DayMonthYearWithLeadingZerosDashed } from 'helpers'

const CompanyData = ({ data, isLoading, idPyme }: any) => {
  const coverageStartDate = getFormatedDate(
    data?.fechaCobertura,
    DayMonthYearWithLeadingZerosDashed
  )

  return (
    <LoadingSpinner isLoading={isLoading}>
      <S.Title>
        <Text
          textAlign={'center'}
          size={'xl'}
          color={'resolutionBlue'}
          weight={'md'}
        >
          {data?.razonSocial}
        </Text>
      </S.Title>
      <Text textAlign={'center'} size={'xl'} weight={'md'}>
        Datos personales de la empresa:
      </Text>
      <S.CompanyData>
        <div>
          <div>
            <label>Razón Social</label>
            <p>{data?.razonSocial}</p>
          </div>
          <div>
            <label>CUIT</label>
            <p>{data?.cuit}</p>
          </div>
          <div>
            <label>Teléfono</label>
            <p>{data?.telefono}</p>
          </div>
          <div>
            <label>Mail</label>
            <p>{data?.mail}</p>
          </div>
        </div>
        <div>
          <div>
            <label>Provincia</label>
            <p>{data?.provincia}</p>
          </div>
          {data?.localidad && (
            <div>
              <label>Localidad</label>
              <p>{data?.localidad}</p>
            </div>
          )}
          <div>
            <label>Cantidad de colaboradores</label>
            <p>{data?.capitas}</p>
          </div>
          <div>
            <label>Fecha de inicio de cobertura</label>
            <p>{coverageStartDate || '00-00-0000'}</p>
          </div>
          <div>
            <label>Ingresos Brutos</label>
            <p>{data?.iibb ? data?.iibb : '0'}</p>
          </div>
        </div>
        <ApplicationData
          idPyme={idPyme}
          idStatusPyme={data?.idEstado}
          idStatusDocumentation={data?.idEstadoDocumentacion}
          infoSubmitted={data?.estadoDocumentacion}
          status={data?.estado}
        />
      </S.CompanyData>
    </LoadingSpinner>
  )
}

export default CompanyData
