import { State, ILogger } from 'domain/entities'

import {
  TGetStates,
  TGetAvailableStateParams,
  TPostStateDocumentsParam,
  TPostUpdateStatePymeParam,
} from 'domain/requestModel'

import { endpoints } from 'app/constants'

export class StateServices {
  private httpClient: any
  private statesByStateTypeId: any
  private logger: any
  private availableStates: any

  constructor(clientProvider: any, logger: ILogger) {
    this.httpClient = clientProvider
    this.statesByStateTypeId = {}
    this.logger = logger
    this.availableStates = {}
  }

  public async getStates(params: TGetStates): Promise<State[]> {
    try {
      if (!this.statesByStateTypeId[params.idTipoEstado]) {
        this.statesByStateTypeId[params.idTipoEstado] = (
          await this.httpClient.get(endpoints.estados, { ...params })
        ).data
      }

      return this.statesByStateTypeId[params.idTipoEstado]
    } catch (error) {
      this.logger.error({
        message: 'UseCase - getStates',
        data: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
      })

      throw new Error(error)
    }
  }

  public async getAvailableStates(
    params: TGetAvailableStateParams
  ): Promise<any> {
    try {
      const availableStates: any = (
        await this.httpClient.get(endpoints.availableStates, params)
      )?.data

      return availableStates
    } catch (error) {
      this.logger.error({
        message: 'UseCase - Available States',
        data: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
      })

      throw new Error(error)
    }
  }

  public async getAvailableStatesBO(
    params: TGetAvailableStateParams
  ): Promise<any> {
    try {
      const availableStatesBO: any = (
        await this.httpClient.get(endpoints.estadosDisponiblesBo, params)
      )?.data
      return availableStatesBO
    } catch (error) {
      this.logger.error({
        message: 'UseCase - Available States',
        data: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
      })
      throw new Error(error)
    }
  }

  public async getDocumentationState(
    param: TGetAvailableStateParams
  ): Promise<any> {
    try {
      if (!this.availableStates[param.idTipoEstado]) {
        this.availableStates[param.idTipoEstado] = (
          await this.httpClient.get(
            `${endpoints.getStatusDocuments}?idTipoEstado=${param.idTipoEstado}`
          )
        )?.data
      }

      return this.availableStates[param.idTipoEstado]
    } catch (error) {
      this.logger.error({
        message: 'UseCase - Documentation sent status',
        data: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
      })

      throw new Error(error)
    }
  }

  public async getAvailablePymeStates(
    param: TGetAvailableStateParams
  ): Promise<any> {
    try {
      if (!this.availableStates[param.idTipoEstado]) {
        this.availableStates[param.idTipoEstado] = (
          await this.httpClient.get(
            `${endpoints.getStatusDocuments}?idTipoEstado=${param.idTipoEstado}`
          )
        )?.data
      }

      return this.availableStates[param.idTipoEstado]
    } catch (error) {
      this.logger.error({
        message: 'UseCase - pyme status',
        data: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
      })

      throw new Error(error)
    }
  }

  public async updateDocumentationStates(
    param: TPostStateDocumentsParam
  ): Promise<any> {
    try {
      const stateUpdate: any = (
        await this.httpClient.post(endpoints.stateDocumentation, param)
      ).data

      return stateUpdate
    } catch (error) {
      this.logger.error({
        message: 'UseCase - update docs',
        data: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
      })
      throw new Error(error)
    }
  }

  public async updateStatusPyme(
    param: TPostUpdateStatePymeParam
  ): Promise<any> {
    try {
      const statePyme: any = (
        await this.httpClient.post(endpoints.changeStatePyme, param)
      ).data

      return statePyme
    } catch (error) {
      this.logger.error({
        message: 'UseCase - update pyme state',
        data: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
      })
      throw new Error(error)
    }
  }
}

export default {
  StateServices,
}
