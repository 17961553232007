import React, { FC, ElementType } from 'react'
import { Field, ErrorMessage } from 'formik'
import styled, { css } from 'styled-components'

import {
  InputContainer,
  InputTitle,
  InputHint,
  ErrorField,
} from 'app/components/ui/styles'

import { baseComponent } from 'app/components/ui/styles'

const InputBasic = styled(({ warning, icon, autoWidth, ...props }) => (
  <Field {...props} />
)).attrs({
  as: 'textarea',
})`
  ${baseComponent};
  min-height: 100px;
  background: transparent;
  border: none;
  border: 1px solid ${props => props.theme.colors.cornflowerBlue};
  color: ${props => props.theme.colors.darkGreyBlue};
  ::placeholder {
    color: ${props => props.theme.colors.placeholderGrey};
  }
  border-radius: 3px;
  margin: 10px 0px;
  outline-color: ${props => props.theme.colors.cornflowerBlue};
  ${props =>
    props.warning &&
    css`
      color: ${props => props.theme.colors.carmine};
      border-bottom-color: ${props => props.theme.colors.carmine};
    `}
  ${props =>
    props.icon &&
    css`
      padding-left: 20px;
    `}

  ${props =>
    props.autoWidth &&
    css`
      width: auto;
    `}

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100% !important;
    `}
  &:disabled {
    border-bottom-color: ${props => props.theme.colors.grey};
    cursor: not-allowed;
  }
`

interface ITextArea {
  id: string
  title?: string
  hint?: string
  icon?: ElementType
  warning?: boolean | ''
  name: string
  inputMode?: string
  type?: string
  onChange?: any
  hidden?: boolean
  placeholder?: string
  color?: string
  fullWidth?: boolean
  value?: string
}

const TextArea: FC<ITextArea> = props => (
  <InputContainer hidden={props.hidden}>
    <InputTitle htmlFor={props.id} color={props.color}>
      {props.title}
    </InputTitle>
    {props.hint && <InputHint htmlFor={props.id}>{props.hint}</InputHint>}
    <InputBasic {...props} />
    <ErrorMessage name={props.name} component={ErrorField} />
  </InputContainer>
)

export default React.memo(TextArea)
