import React, { useEffect, useRef, useState, useContext } from 'react'

import * as C from 'app/components'
import Observations from '../../../Pages/Holder/Component/Observations'
import { useDispatch, useSelector } from 'react-redux'
import { getFamilyGroupDetail } from 'app/slices/company'

import {
  postComment,
  selectPostComment,
  selectPostCommentIsFetching,
} from '../../../slices/holders'
import Modals from '../../../Pages/Holder/ModalContent'
import ModalsCompany from '../../../Pages/HolderCompany/ModalContent'

import {
  getLead,
  getLeadStates,
  selectPostLeadComment,
} from '../../../slices/leadsSlice'
import {
  postLeadsComment,
  selectPostLeadCommentIsFetching,
} from '../../../slices/leadsSlice'
import { getLeadRequestModel } from '../../../../domain/requestModel'
import { useParams } from 'react-router-dom'

type ObservationsAccordionProps = {
  props: any
  addObservationPermission: any
  keyName: string | undefined
  page: string
  lead?: any
  label?: string
  companyObservations?: boolean
}

const ObservationsAccordion: React.FC<ObservationsAccordionProps> = ({
  props,
  addObservationPermission,
  keyName,
  lead,
  page,
  label,
  companyObservations,
}) => {
  const dispatch = useDispatch()
  const newCommentAdded = useRef(false)
  const comments =
    page === 'lead'
      ? lead?.observacionesList
      : props.familyGroupDetail?.observaciones
  const [showObservations, setShowObservations] = useState(false)
  const guuid = props.params?.guid
  const postCommentData: boolean = useSelector(
    page === 'lead' ? selectPostLeadComment : selectPostComment
  )
  const {
    handleModalContent,
    handleDisplayModal,
    handleOnModalIsFetching,
  } = useContext<any>(C.ModalContext)
  const leadId = lead?.id
  const leadType = lead?.tipoLead
  let params: getLeadRequestModel = useParams()

  const seeMoreObservations = () => {
    setShowObservations(!showObservations)
  }

  const postCommentIsFetching: boolean = useSelector(
    page === 'lead'
      ? selectPostLeadCommentIsFetching
      : selectPostCommentIsFetching
  )

  const handleOnPressModalSubmitLead = (values: any) => {
    const param: any = {
      eventoAfiliacion: 0,
      observacion: values.detail,
      idLead: leadId,
      tipoLead: leadType,
    }

    newCommentAdded.current = true
    dispatch(postLeadsComment(param))
  }

  const handleOnPressModalSubmitHolder = (values: any) => {
    const param: any = {
      eventoAfiliacion: values.observations.value,
      observacion: values.detail,
      guid: guuid,
    }
    newCommentAdded.current = true
    dispatch(postComment(param))
  }

  const handleOnPressAddNewObservation = () => {
    handleDisplayModal(true)
    if (companyObservations) {
      handleModalContent(
        <ModalsCompany.ObservationModalContent
          onSubmit={
            page === 'lead'
              ? handleOnPressModalSubmitLead
              : handleOnPressModalSubmitHolder
          }
          onCancel={() => handleDisplayModal(false)}
        />
      )
    } else {
      handleModalContent(
        <Modals.ObservationModalContent
          onSubmit={
            page === 'lead'
              ? handleOnPressModalSubmitLead
              : handleOnPressModalSubmitHolder
          }
          onCancel={() => handleDisplayModal(false)}
        />
      )
    }
  }
  useEffect(() => {
    handleOnModalIsFetching(postCommentIsFetching)
  }, [postCommentIsFetching, handleOnModalIsFetching])

  useEffect(() => {
    if (postCommentData && newCommentAdded.current && page === 'holder') {
      handleDisplayModal(false)
      newCommentAdded.current = false
      dispatch(getFamilyGroupDetail({ guid: guuid }))
    } else if (postCommentData && newCommentAdded.current && page === 'lead') {
      handleDisplayModal(false)
      newCommentAdded.current = false

      dispatch(getLeadStates())
      dispatch(getLead(params))
    }
  }, [
    postCommentData,
    dispatch,
    handleDisplayModal,
    newCommentAdded,
    comments,
    guuid,
    lead,
    params,
    page,
  ])

  return (
    <C.Acordion
      title="Observaciones"
      open={true}
      headerButton={[
        <C.Permission
          permissionRequired={addObservationPermission}
          displayInauthorizedComponent={false}
        >
          <C.Button
            type="button"
            onClick={handleOnPressAddNewObservation}
            outline
            disabled={false}
            isLoading={false}
            key={keyName}
          >
            {label ? label : 'Nueva observación'}
          </C.Button>
        </C.Permission>,
      ]}
    >
      <Observations
        data={comments}
        showObservations={showObservations}
        handleBtn={seeMoreObservations}
      />
    </C.Acordion>
  )
}

export default ObservationsAccordion
