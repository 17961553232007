export const docTypes: { [key: number]: string } = {
  1: 'Cédula Policía Federal',
  2: 'Libreta cívica',
  3: 'Libreta enrolamiento',
  4: 'DNI ',
  5: 'Pasaporte',
  6: 'Otro',
  7: 'CUIT',
  8: 'CUIL',
}
