import { ILogger } from 'domain/entities'
import { loginRequestModel } from 'domain/requestModel/credential'

import { userEntityToModel } from 'domain/responseModel'
import { endpoints } from 'app/constants'

export class Login {
  private httpClient: any
  private logger: any

  constructor(clientProvider: any, logger: ILogger) {
    this.httpClient = clientProvider
    this.logger = logger
  }

  public async login(loginUserParams: loginRequestModel): Promise<any> {
    try {
      const user = (
        await this.httpClient.post(endpoints.login, loginUserParams)
      ).data

      return userEntityToModel({
        name: loginUserParams.userName,
        funcionalidades: user.funcionalidades,
        roles: user.roles,
        token: user.token,
        usuario: user.usuario,
      })
    } catch (error) {
      if (error?.response?.status === 401)
        return {
          error: true,
          message: 'Usuario/Contraseña no coincide',
        }

      this.logger.error({
        message: 'UseCase - Login',
        data: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
      })

      throw new Error(error)
    }
  }
}

export default {
  Login,
}
