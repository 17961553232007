import { AdvisorAgenda } from 'domain/entities'

import { isString, isDateAndNotEmpty } from 'helpers'

export type TGetListAdvisors = {
  asesores: AdvisorAgenda[]
}

export const advisorAgendaToModel = (
  list: TGetListAdvisors
): TGetListAdvisors => ({
  asesores: list.asesores.map((e: AdvisorAgenda) => ({
    fecha: isDateAndNotEmpty(e.fecha, 'AdvisorAgenda - fecha'),
    codigoAsesor: isString(
      e.codigoAsesor,
      'AdvisorAgenda - codigoAsesor',
      false
    ),
    nombre: isString(e.nombre, 'AdvisorAgenda - nombre', false),
    tel: isString(e.tel, 'AdvisorAgenda - tel', false),
  })),
})
