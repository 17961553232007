import React from 'react'
import { useHistory } from 'react-router-dom'
import { Table, Text, IconDotsHorizontal } from 'app/components'
import { tableHoldersHeaders } from '../../Company/constants'
import * as Styles from 'app/Pages/Company/style'
import * as S from '../styles'
import { IconLeftArrowAlt } from 'app/components'
import TableFilter from 'app/components/ui/TableFilter'

export interface IHoldersTable {
  data: any[] & never[]
  isLoading: boolean
  onPress: (element: any, key: any) => void
  filterOptions: any
  filterSubmitHandler: (document: string, options: any) => void
}

const Item = (element: any, key: any) => (handleOnPressItem: any) => (
  <>
    {key === 'detalle' ? (
      <IconDotsHorizontal
        onClick={() => handleOnPressItem(element, key)}
        color={'midnightBlue'}
        isCursor
      />
    ) : (
      <Text color={'midnightBlue'} textAlign={'center'}>
        {element[key]}
      </Text>
    )}
  </>
)

const HolderTable = (props: IHoldersTable) => {
  const history = useHistory()
  const handleReturn = () => {
    history.goBack()
  }

  return (
    <Styles.PageContent>
      <S.OptionsContainer>
        <S.ReturtBt>
          <IconLeftArrowAlt isCursor color={'mariner'} />
          <Text
            onClick={handleReturn}
            color={'flatBlue'}
            weight={'md'}
            isCursor
          >
            Volver al listado de empresas
          </Text>
        </S.ReturtBt>
        {!props.isLoading && (
          <TableFilter
            filterOptions={props.filterOptions}
            filterSubmitHandler={props.filterSubmitHandler}
          />
        )}
      </S.OptionsContainer>
      <S.TitleGF>
        <Text textAlign={'center'} size={'xl'} weight={'md'}>
          Grupos familiares
        </Text>
      </S.TitleGF>
      <Table
        data={props.data}
        isLoading={props.isLoading}
        tableDesktopHeaders={tableHoldersHeaders}
        tableMobileHeaders={tableHoldersHeaders}
        item={(element, key) => Item(element, key)(props.onPress)}
      />
    </Styles.PageContent>
  )
}

export default HolderTable
