import React, { FC } from 'react'
import ReactLoading from 'react-loading'
import styled, { css } from 'styled-components'

import { colors } from 'app/styles/colors'

interface ILoadingSpinner {
  readonly type?: string
  readonly isLoading: boolean | undefined
  readonly children?: any
  readonly noMargin?: boolean
  readonly autoWidth?: boolean
  readonly fullPage?: boolean
}

const Container = styled.div<{
  readonly autoWidth?: boolean
  readonly noMargin?: boolean
  readonly fullPage?: boolean
  readonly isLoading?: boolean
}>`
  width: ${props => (props.autoWidth ? 'auto' : '100%')};
  ${({ fullPage, isLoading }) =>
    fullPage &&
    isLoading &&
    css`
      height: calc(100% - 130px);
    `}
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${({ isLoading }) =>
    isLoading &&
    css`
      padding: 50px 0px;
    `}
`

export const LoadingSpinner: FC<ILoadingSpinner> = props => (
  <Container
    noMargin={props.noMargin}
    autoWidth={props.autoWidth}
    fullPage={props.fullPage}
    isLoading={props.isLoading}
  >
    {props.isLoading ? (
      <ReactLoading
        height={'40px'}
        width={'40px'}
        type="spin"
        color={colors.cornflowerBlue}
      />
    ) : (
      props.children
    )}
  </Container>
)
