import styled, { css } from 'styled-components'
import { lighten } from 'polished'

type IGeneralPropStyles = {
  warning?: boolean | ''
  disabled?: boolean
  focused?: boolean
  selected?: boolean
  darkBlue?: boolean
  inLine?: boolean
  adaptable?: boolean
  bordered?: boolean
  companyDropdown?: boolean
  fullWidth?: boolean
}

const divComponent = styled.div``

const baseComponent = css<{
  adaptable?: boolean
  fullWidth?: boolean
}>`
  display: block;
  width: 100%;
  padding: 0 10px;
  height: 35px;
  max-width: -webkit-fill-available;

  @media (min-width: ${({ theme }) => theme.breakPoints.small}) {
    width: ${props => (props.adaptable ? '100%' : '205px')};
    font-size: ${({ theme }) => theme.fontSizes.md};
  }
`

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: unset;
  position: relative;
  ${({ hidden }) =>
    hidden &&
    css`
      display: none;
    `}
`

const InputTitle = styled.label`
  color: ${({ theme }) => theme.colors.flatBlue};
  font-size: ${({ theme }) => theme.fontSizes.xs};
  font-weight: 400;
  text-align: left;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-word;
  width: 100%;

  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}
`

const InputHint = styled.label`
  color: ${({ theme }) => theme.colors.flatBlue};
  font-size: ${({ theme }) => theme.fontSizes.xxs};
  text-align: left;
  display: block;
  margin: 3px 0 4px 0;
  width: 100%;
`

const ErrorField = styled.label`
  display: block;
  width: 100%;
  color: ${({ theme }) => theme.colors.carmine};
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.xxs};

  @media (min-width: ${({ theme }) => theme.breakPoints.small}) {
    max-width: 180px;
    text-align: left;
  }
`

const SelectContainer = styled(InputContainer)<IGeneralPropStyles>`
  .react-select {
    text-align: left;
    &__control {
      ${baseComponent};
      height: 100%;
      background: transparent;
      display: flex;
      border: none;
      border: 2px solid transparent;
      border-radius: 0px;
      border-bottom: 1px solid ${props => props.theme.colors.cornflowerBlue};
      color: ${props => props.theme.colors.darkGreyBlue};
      min-height: 35px;
      min-width: 100px;
      ${props =>
        props.warning &&
        css`
          border-bottom-color: ${props => props.theme.colors.carmine};
        `}
      ${({ disabled }) =>
        disabled &&
        css`
          border-bottom-color: ${props => props.theme.colors.grey};
        `}
        ${({ fullWidth }) =>
        fullWidth &&
        css`
          width: 100% !important;
        `}
      :hover {
        border: 2px solid transparent;
        border-bottom: 1px solid
          ${props =>
            props.warning
              ? props.theme.colors.carmine
              : props.theme.colors.cornflowerBlue};
        cursor: text;
      }
      &--is-focused {
        border-color: ${props => props.theme.colors.cornflowerBlue};
        box-shadow: none;
        :hover {
          border-color: ${props => props.theme.colors.cornflowerBlue};
          cursor: text;
        }
      }
      .react-select__value-container {
        padding: 0;
      }
      .react-select__placeholder {
        color: ${props => props.theme.colors.placeholderGrey};
      }
      .react-select__input {
        color: ${props => props.theme.colors.darkGreyBlue};
      }
      .react-select__single-value {
        color: ${props => props.theme.colors.darkGreyBlue};
      }
      .react-select__indicator-separator {
        display: none;
      }
      .react-select__indicator {
        cursor: pointer;
        padding: 0;
        color: ${props => props.theme.colors.darkGreyBlue};
        ${({ warning }) =>
          warning &&
          css`
            color: ${props => props.theme.colors.carmine};
          `}
        ${({ disabled }) =>
          disabled &&
          css`
            color: ${props => props.theme.colors.grey};
          `}
        svg {
          width: 14px;
        }
      }
    }
    &__menu {
      display: block;
      background-color: ${props => props.theme.colors.paleGrey};
      border: 2px solid ${props => props.theme.colors.cornflowerBlue};
      border-top: none;
      box-shadow: none;
      margin-top: 0;
      width: 100%;
      font-size: ${({ theme }) => theme.fontSizes.xs};
      z-index: 11111111;
      &-list {
        padding: 0;
      }
      .react-select__option {
        color: ${props => props.theme.colors.warmGrey};
      }
      .react-select__option--is-selected {
        background-color: ${props => props.theme.colors.cornflowerBlue};
        color: white;
      }
    }
  }
`

const SelectOption = styled(divComponent)<IGeneralPropStyles>`
  font-size: ${({ theme }) => theme.fontSizes.xs};
  color: ${props => props.theme.colors.warmGrey};
  padding: 7.5px 0;
  transition: 0.2s all;
  user-select: none;
  cursor: pointer;
  &:hover {
    background-color: ${props =>
      lighten(0.3, props.theme.colors.cornflowerBlue)};
  }
  ${props =>
    props.focused &&
    css`
      background-color: ${props =>
        lighten(0.3, props.theme.colors.cornflowerBlue)};
    `};
  ${props =>
    props.selected &&
    css`
      background-color: ${props => props.theme.colors.cornflowerBlue};
      color: white;
      &:hover {
        background-color: ${props => props.theme.colors.cornflowerBlue};
      }
    `};
`

const DateRangeInputsContainer = styled.div`
  display: flex;
  justify-content: space-around;

  @media (min-width: ${({ theme }) => theme.breakPoints.small}) {
    > div {
      margin-right: 10px;
    }
  }

  & .react-datepicker-popper {
    @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
      position: fixed !important;
      left: 50% !important;
      top: 50% !important;
      transform: translate(-50%, -50%) !important;
    }
  }

  & .react-datepicker-popper {
    @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
      position: fixed !important;
      left: 50% !important;
      top: 50% !important;
      transform: translate(-50%, -50%) !important;
    }
  }

  & .react-datepicker-wrapper {
    @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
      width: 100%;
    }
  }

  & .react-datepicker__input-container {
    @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
      padding: 20px 0 10px 0;
    }
  }

  & .react-datepicker__tab-loop {
    width: 0%;
    margin: 0px;
  }

  & .react-datepicker__tab-loop {
    width: 0%;
    margin: 0px;
  }

  & input {
    width: 180px;
    height: 35px;
    border-style: none;
    border-bottom: 1px solid ${({ theme }) => theme.colors.cornflowerBlue};
    background-color: transparent;

    @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
      width: 100%;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
    flex-wrap: wrap;
  }
`

const DateRangeContainer = styled(InputContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const CheckboxContainer = styled(InputContainer)<IGeneralPropStyles>`
  ${({ inLine }) => (inLine ? `flex-direction: row;` : '')}
  justify-content: center;
  cursor: pointer;
  user-select: none;

  > input {
    ${baseComponent};
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  > input:checked ~ span {
    background-color: ${props => props.theme.colors.darkGreyBlue};
    border: none;
  }
  > input:checked ~ span:after {
    display: block;
  }
  > label {
    a {
      color: ${props => props.theme.colors.darkGreyBlue};
      text-decoration: underline;
    }
  }
`

const Checkmark = styled.span<IGeneralPropStyles>`
  position: relative;
  top: 0;
  right: 0;
  height: 15px;
  width: 15px;
  background-color: white;
  border: 1px solid
    ${props =>
      props.warning
        ? props.theme.colors.carmine
        : props.theme.colors.cornflowerBlue};
  border-radius: 2px;

  margin: 15px 0px;

  @media (min-width: ${({ theme }) => theme.breakPoints.small}) {
    margin: 10px 10px;
  }

  &:after {
    content: '';
    position: absolute;
    display: none;
    left: 5px;
    top: 3px;
    width: 5px;
    height: 7px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`

const shadowedComponent = css`
  border: 1px solid ${({ theme }) => theme.colors.alto};
  box-sizing: border-box;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
`

const DropdownContainer = styled.fieldset`
  margin: 10px 0px;
  width: 220px;
  text-align: center;
  border: 1px solid #d8d8d8;
  float: right;
  box-sizing: border-box;
  box-shadow: 0px 0px 8px rgb(0 0 0 / 12%);
  border-radius: 4px;
  font-family: Source Sans Pro;

  > div {
    margin: 0px;
  }

  .react-select__control {
    border: none;
    padding: 0;
    width: 150px;
    font-size: 0.75rem;
    margin: auto;
  }

  .react-select__control:hover {
    border: none;
    border-bottom: none;
    cursor: pointer;
  }

  .react-select__single-value {
    color: ${props => props.color} !important;
    font-weight: bold;
    font-size: 18px;
  }

  .react-select__placeholder {
    color: ${props =>
      props.color && props.theme.colors[`'${props.color}'`]} !important;
    font-weight: bold;
    font-size: 18px;
  }

  .react-select__menu {
    width: 200px;
    text-align: center;
    border: 1px solid #d8d8d8;
    box-sizing: border-box;
    box-shadow: 0px 0px 8px rgb(0 0 0 / 12%);
    border-radius: 4px;
    font-family: Source Sans Pro;
    margin-right: 10px;
    margin-top: 6px;
    background-color: white !important;
    font-weight: bold;
    font-size: 18px;
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
    width: 150px;
    font-size: 14px;

    .react-select__menu {
      width: 150px;
      font-size: 14px;
    }
  }
`

const InputCalendarContainer = styled(InputContainer)`
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakPoints.small}) {
    width: unset;
  }

  .react-datepicker-wrapper > div > input {
    width: 100%;
    height: 35px;
    background: transparent;
    border: 1px solid ${({ theme }) => theme.colors.cornflowerBlue};
    border-radius: 4px;
    z-index: 1;
    cursor: pointer;

    @media (min-width: ${({ theme }) => theme.breakPoints.small}) {
      width: unset;
    }
  }

  > span {
    position: absolute;
    background: ${({ theme }) => theme.colors.white};
    width: 100%;
    height: 100%;
    text-align: center;
    padding-top: 5px;
    z-index: -1;
  }

  & .react-datepicker-popper {
    @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
      position: fixed !important;
      left: 50% !important;
      top: 50% !important;
      transform: translate(-50%, -50%) !important;
    }
  }
`

const SelectTableContainer = styled(InputContainer)<IGeneralPropStyles>`
  .react-select {
    cursor: pointer;
    text-align: left;
    &__control {
      ${baseComponent};
      cursor: pointer;
      width: 100%;
      height: 100%;
      background: transparent;
      display: flex;
      ${props =>
        props.bordered
          ? 'border: 1px solid #C6C6C6; border-radius: 4px;'
          : 'none;'}
      color: ${props => props.theme.colors.darkGreyBlue};
      ${({ disabled }) =>
        disabled &&
        css`
          border-bottom-color: ${props => props.theme.colors.grey};
        `}
      .react-select__value-container {
        padding: 0;
      }
      .react-select__placeholder {
        color: ${props => props.theme.colors.placeholderGrey};
      }
      .react-select__input {
        color: ${props => props.theme.colors.darkGreyBlue};
      }
      .react-select__single-value {
        color: ${props => props.theme.colors.darkGreyBlue};
        ${({ companyDropdown }) =>
          companyDropdown &&
          css`
            color: ${props => props.theme.colors.chathamsBlue};
            font-weight: 600;
            line-height: 16px;
          `}
      }
      .react-select__indicator-separator {
        display: none;
      }
      .react-select__indicator {
        cursor: pointer;
        padding: 0;
        color: ${props => props.theme.colors.darkGreyBlue};
        ${({ disabled }) =>
          disabled &&
          css`
            color: ${props => props.theme.colors.grey};
          `}
        svg {
          width: 14px;
        }
      }
    }
    &__menu {
      display: block;
      background-color: ${props => props.theme.colors.paleGrey};
      border: none;
      margin-top: 0;
      font-size: ${({ theme }) => theme.fontSizes.xs};
      z-index: 11111111;
      &-list {
        padding: 0;
      }
      .react-select__option {
        color: ${props => props.theme.colors.warmGrey};
      }
      .react-select__option--is-selected {
        background-color: ${props => props.theme.colors.cornflowerBlue};
        color: white;
      }
    }
  }
`

export {
  baseComponent,
  InputContainer,
  InputTitle,
  InputHint,
  ErrorField,
  SelectContainer,
  SelectOption,
  DateRangeInputsContainer,
  DateRangeContainer,
  CheckboxContainer,
  Checkmark,
  shadowedComponent,
  DropdownContainer,
  InputCalendarContainer,
  SelectTableContainer,
}
