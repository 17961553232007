import React, { FC, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Form } from 'formik'
import { Button, Text } from 'app/components'
import { SelectAsync } from 'app/components'
import { initialValuesStatus } from './constants'
import {
  getFamilyGroupDetail,
  selectChangesStatusGFIsFetching,
  selectStatesForDropdowns,
} from 'app/slices/companyPAD'

import * as S from './styles'
import * as C from 'app/components'

import { strings } from 'app/constants/strings'
import TextArea from 'app/components/ui/forms/TextArea'
import { colors } from 'app/styles/colors'
import successIcon from 'app/images/Ok.svg'
import errorIcon from 'app/images/Error.svg'
import { IconClose } from 'app/components/Icons'

const {
  forms: {
    fieldsTitle: { stateTitle },
  },
} = strings

interface IStatesModal {
  onSubmit: (values: any) => void
  show: boolean
  setShow: (values: any) => void
  idTipoEstado: number
  confirmationStatusApproved: any
}

const StatesModal: FC<IStatesModal> = ({
  onSubmit,
  show,
  setShow,
  confirmationStatusApproved,
}) => {
  const params = useParams<{ guid: string }>()
  const initVal = { ...initialValuesStatus, guid: params ? params.guid : '' }
  const [enabled, setEnabled] = useState(true)
  const states = useSelector(selectStatesForDropdowns)
  const dispatch = useDispatch()
  const isFetchingChanges: boolean = useSelector(
    selectChangesStatusGFIsFetching
  )
  const [selectedValue, setSelectedValue] = useState<C.ISelectFormat | null>(
    null
  )

  const refresh = () => {
    dispatch(getFamilyGroupDetail(params))
  }
  return (
    <>
      <Formik
        initialValues={initVal}
        onSubmit={(values: any, { setSubmitting }) => {
          setSubmitting(false)
          onSubmit(values)
        }}
      >
        {(props: {
          values: any
          errors: {
            idEstado: number
            observaciones: string
          }
          touched: {
            idEstado: number
            observaciones: string
          }
          onChange: any
          handleChange: any
          submitForm: any
          setFieldValue: any
          resetForm: any
        }) => (
          <Form>
            <S.ModalBg show={show}>
              {confirmationStatusApproved ? (
                <S.ConfirmationStatus>
                  <>
                    <S.CloseIcon>
                      <IconClose
                        size={'sm'}
                        centered={false}
                        color={'gray'}
                        onClick={() => {
                          refresh()
                        }}
                        isCursor
                      />
                    </S.CloseIcon>
                  </>
                  <>
                    <S.ConfirmationIcon
                      src={
                        confirmationStatusApproved === true
                          ? successIcon
                          : errorIcon
                      }
                      alt="status"
                    />
                    <Text
                      textAlign={'center'}
                      size={'mdlg'}
                      color={'chathamsBlue'}
                      weight={'md'}
                    >
                      {confirmationStatusApproved === true
                        ? 'El grupo familiar se ha dado de alta correctamente'
                        : 'La solicitud no pudo darse de alta'}
                    </Text>
                    {confirmationStatusApproved !== true && (
                      <Text
                        textAlign={'center'}
                        size={'md'}
                        color={'chathamsBlue'}
                        weight={'md'}
                      >
                        Por favor, volvé a intentar
                      </Text>
                    )}

                    <S.ConfirmationButton>
                      <C.Button
                        type="button"
                        onClick={() => {
                          refresh()
                        }}
                      >
                        Aceptar
                      </C.Button>
                    </S.ConfirmationButton>
                  </>
                </S.ConfirmationStatus>
              ) : (
                <S.ModalBox>
                  {isFetchingChanges ? (
                    <C.LoadingSpinner isLoading={isFetchingChanges} />
                  ) : (
                    <>
                      <S.Title>
                        <Text
                          textAlign={'center'}
                          size={'xl'}
                          color={'cornflowerBlue'}
                          weight={'md'}
                        >
                          Modificar estado del grupo familiar
                        </Text>
                      </S.Title>
                      <S.ContainerBox>
                        <S.InputsBox>
                          <SelectAsync
                            onChange={(option: any) => {
                              props.setFieldValue(
                                'idEstado',
                                option ? option.value : null
                              )
                              setEnabled(false)
                              setSelectedValue(option)
                            }}
                            isClearable
                            title={stateTitle}
                            name={'idEstado'}
                            id={'idEstado'}
                            placeholder="Seleccionar"
                            options={states}
                            value={selectedValue}
                            color={colors.cornflowerBlue}
                            fullWidth
                          />
                          {props.values.estado === null ? (
                            <S.ErrorText>
                              {setEnabled(true)}
                              Debe seleccionar un estado
                            </S.ErrorText>
                          ) : null}
                          <TextArea
                            id="observaciones"
                            name="observaciones"
                            onChange={(event: any) => {
                              props.setFieldValue(
                                'observaciones',
                                event ? event.target.value : null
                              )
                            }}
                            value={props.values.observaciones}
                            title="Observaciones"
                            color={colors.cornflowerBlue}
                            fullWidth
                          />
                        </S.InputsBox>
                      </S.ContainerBox>
                      <S.ButtonsBox>
                        <Button
                          outline={true}
                          onClick={() => {
                            setShow(!show)
                            setSelectedValue(null)
                            props.resetForm()
                          }}
                          centered
                        >
                          Cancelar
                        </Button>
                        <Button type="submit" disabled={enabled} centered>
                          Guardar
                        </Button>
                      </S.ButtonsBox>
                    </>
                  )}
                </S.ModalBox>
              )}
            </S.ModalBg>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default StatesModal
