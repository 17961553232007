import * as Yup from 'yup'

import { strings } from 'app/constants/strings'

import * as C from 'app/components'

const {
  forms: {
    fieldsName: { observations, state },
    requiredFields: { requiredField },
  },
} = strings

export type TForm = {
  observations: any
  detail: string
}

export type TStateForm = {
  observations: any
  state: any
}

export type TModalContent = {
  onSubmit: (values: TForm) => void
  onCancel: () => void
}

export type TStateModalContent = {
  availableStates: TAvailableStatesType[]
  onSubmit: (values: TStateForm) => void
  onCancel: () => void
  documentState: string
}

export type TStatePymeModalContent = {
  availableStates: TAvailableStatesType[]
  onSubmit: (values: TStateForm) => void
  onCancel: () => void
  companyState: string
}

type TAvailableStatesType = {
  id: number
  descripcion: string
}

const nameSelectInitialValue = {
  label: '',
  value: 0,
}

const stateUpdateInitialValues = {
  state: {
    label: '',
    value: '',
  },
  observations: '',
}

const newStateUpdateSchema = Yup.object().shape({
  [state]: Yup.mixed().test(state, requiredField, value => value.value),
  [observations]: Yup.string(),
})

const detailFormInitialValues = (
  associates: C.ISelectFormat[]
): {
  associate: C.ISelectFormat
} => ({ associate: associates[0] })

export default {
  nameSelectInitialValue,
  stateUpdateInitialValues,
  newStateUpdateSchema,
  detailFormInitialValues,
}
