const [male, female] = [0, 1]

const sexStrings = {
  [male]: 'masculino',
  [female]: 'femenino',
}

export default {
  male,
  female,
  sexStrings,
}
