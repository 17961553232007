import React from 'react'
import styled from 'styled-components'

import { Text } from 'app/components/ui'

const InauthorizedElement = styled.div`
  display: flex;
  justify-content: center;
  padding: 50px 0px;
`

export const Inauthorized = () => (
  <InauthorizedElement>
    <Text>No posee permisos para esta funcionalidad</Text>
  </InauthorizedElement>
)
