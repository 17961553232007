import React from 'react'
import styled from 'styled-components'

import { colorsProvider } from 'app/components/helpers'

type TLineHR = { color?: string } & typeof LineHRDefaultProps

const LineHRDefaultProps = { color: 'shipCove' }

const LineHRComponent = styled.hr<TLineHR>`
  border: 1px solid;
  ${({ color }) => colorsProvider[color]}
`

const LineHR = (props: TLineHR) => <LineHRComponent {...props} />

LineHR.defaultProps = LineHRDefaultProps

export default LineHR
