import styled, { css } from 'styled-components'

type TInputIcon = {
  warning?: boolean | ''
  alignLeft?: boolean
}

const InputIcon = styled.div<TInputIcon>`
  position: absolute;
  bottom: 12px;
  display: flex;
  align-items: center;
  opacity: 0.6;
  pointer-events: none;
  padding: 0 2px;
  color: ${({ theme }) => theme.colors.cornflowerBlue};
  ${({ alignLeft }) =>
    alignLeft
      ? css`
          left: 5px;
        `
      : css`
          right: 5px;
        `}
`

export default InputIcon
