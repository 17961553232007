import { ILogger } from 'domain/entities'

import { IHoldersDTO } from 'domain/responseModel'

import {
  getHoldersRequestParam,
  TPostHolderCommentParams,
  TPostHolderEven,
  postEventComment,
} from 'domain/requestModel/holders'
import { endpoints } from 'app/constants'

export class HoldersService {
  private httpClient: any
  private logger: any

  constructor(clientProvider: any, logger: ILogger) {
    this.httpClient = clientProvider
    this.logger = logger
  }

  public async getHolders(param: getHoldersRequestParam): Promise<any> {
    try {
      const search = await this.httpClient.get(endpoints.getCompanyHolders, {
        id: param.idPyme,
      })

      return search.data
    } catch (error) {
      this.logger.error({
        message: 'UseCase - Holders (getHolders)',
        data: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
      })
    }
  }

  public async postEventPyme(params: TPostHolderCommentParams): Promise<any> {
    try {
      const mapPostEventPymeParams: TPostHolderEven = postEventComment(params)
      const newComment: IHoldersDTO = (
        await this.httpClient.post(endpoints.eventoPyme, mapPostEventPymeParams)
      ).data

      return newComment
    } catch (error) {
      this.logger.error({
        message: 'UseCase - postEventPyme',
        data: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
      })

      throw new Error(error)
    }
  }
}

export default {
  HoldersService,
}
