import { fields } from 'app/constants'

export const initialValue = {
  value: 0,
  label: '',
}
export const initialValues = { idEstado: 0 }
export const EXCLUDE_PARTNER_KEYS = [
  fields.smEmail,
  fields.smFirstName,
  fields.smLastName,
  fields.dniTitular,
  fields.tieneHijos,
  fields.tipoSocio,
  fields.id,
]

export const EXCLUDE_DJS_KEYS = [fields.idPregunta, fields.id]
export const EXCLUDE_BENEFICIARIES_KEYS = [fields.id]
export const EXLUDE_DJS_ANSWERS_KEYS = [fields.idPregunta]

export const policyStatesIds = {
  pendiente: {
    id: 31,
    description: 'Pendiente',
  },
  enProcesoDePago: {
    id: 32,
    description: 'En Proceso De Pago',
  },
  completo: {
    id: 33,
    description: 'Completo',
  },
  errorPAreaMedica: {
    id: 34,
    description: 'Error p/Area Medica',
  },
  aprobadoAreaMedica: {
    id: 35,
    description: 'Aprobado Area Medica',
  },
  descartado: {
    id: 36,
    description: 'Descartado',
  },
}
