import { css } from 'styled-components'

import theme from 'app/styles/theme'
import { primary, warning } from 'app/components/constants'

let mapColors: any = {}
let mapBorderColors: any = {}
let mapFontSizes: any = {}
let mapFontWeights: any = {}
let mapBackgroundColors: any = {}

for (let a in theme.colors) {
  mapColors[a] = css`
    color: ${({ theme }) => theme.colors[a]};
  `
}

for (let a in theme.colors) {
  mapBorderColors[a] = css`
    border-color: ${({ theme }) => theme.colors[a]};
  `
}

for (let a in theme.fontSizes) {
  mapFontSizes[a] = css`
    font-size: ${({ theme }) => theme.fontSizes[a]};
  `
}

for (let a in theme.fontWeights) {
  mapFontWeights[a] = css`
    font-weight: ${({ theme }) => theme.fontWeights[a]};
  `
}

export const variants: any = {
  [primary]: css`
    color: ${({ theme }) => theme.colors.cornflowerBlue};
  `,
  [warning]: css`
    color: red;
  `,
}

for (let a in theme.colors) {
  mapBackgroundColors[a] = css`
    background-color: ${({ theme }) => theme.colors[a]};
  `
}

export const fontSizes = mapFontSizes
export const colorsProvider = mapColors
export const borderColorsProvider = mapBorderColors
export const fontWeightsProvider = mapFontWeights
export const backgroundColorProvider = mapBackgroundColors
