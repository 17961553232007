import { combineReducers } from '@reduxjs/toolkit'

import authReducer from 'app/slices/authSlice'
import companyReducer from 'app/slices/company'
import companyPADReducer from 'app/slices/companyPAD'
import policySlice from 'app/slices/policySlice'
import provinceSlice from 'app/slices/provinceSlice'
import leadsSlice from 'app/slices/leadsSlice'
import stateSlice from 'app/slices/stateSlice'
import advisorAgendaSlice from 'app/slices/advisorAgendaSlice'
import supervisorSlice from 'app/slices/supervisorSlice'
import advisorSlice from 'app/slices/advisorSlice'
import afilliationSlice from 'app/slices/afilliationSlice'
import dniSlice from 'app/slices/dniSlice'
import holdersSlice from 'app/slices/holders'
import acuerdosSlice from '../slices/acuerdosSlice'

const combinedReducers = combineReducers({
  auth: authReducer,
  company: companyReducer,
  companyPAD: companyPADReducer,
  policies: policySlice,
  provincie: provinceSlice,
  leads: leadsSlice,
  states: stateSlice,
  advisorAgenda: advisorAgendaSlice,
  supervisors: supervisorSlice,
  advisor: advisorSlice,
  afilliations: afilliationSlice,
  dni: dniSlice,
  holders: holdersSlice,
  acuerdo: acuerdosSlice,
})

export type RootState = ReturnType<typeof combinedReducers>
export default combinedReducers
