import { CompanyDTO } from 'domain/responseModel/companyPAD'
import { DayMonthYearWithLeadingZerosDashed, getFormatedDate } from 'helpers'

export const mapCompanyData = (data: CompanyDTO): any => {
  return {
    resultados: data.resultados.map(company => ({
      ...company,
      fechaIngreso: company?.fechaIngreso
        ? getFormatedDate(
            new Date(company.fechaIngreso),
            DayMonthYearWithLeadingZerosDashed
          )
        : '',
      detalle: company.guid,
      documento: company.dni,
      nombreYApellido: `${company.nombre}, ${company.apellido}`,
      estadoBackOffice: company.estado,
    })),
    totalCantidad: data.totalCantidad,
    totalPaginas: data.totalPaginas,
  }
}
