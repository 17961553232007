import React from 'react'
import * as C from 'app/components'
import { Formik, FormikProps, Form } from 'formik'
import * as Yup from 'yup'
import { useDispatch } from 'react-redux'
import {
  getMedicAreaDetail,
  getMedicAreaReport,
} from 'app/slices/afilliationSlice'
import { ButtonContainer, Center } from './style'

interface IMedicAreaReportsScreen {
  path: string
}

const initialValues = {
  fechaReporte: {
    startDate: new Date(),
    endDate: new Date(),
  },
}

const schemaValidation = Yup.object().shape({
  fechaReporte: Yup.object(),
})

type TInitialValues = typeof initialValues

const MedicAreaReportsScreen = (props: IMedicAreaReportsScreen) => {
  const dispatch = useDispatch()

  const handleOnPressSubmit = ({ fechaReporte }: TInitialValues) => {
    dispatch(
      getMedicAreaReport({
        fechaDesde: fechaReporte.startDate,
        fechaHasta: fechaReporte.endDate,
      })
    )
  }
  const downloadDetail = ({ fechaReporte }: TInitialValues) => {
    dispatch(
      getMedicAreaDetail({
        fechaDesde: fechaReporte.startDate,
        fechaHasta: fechaReporte.endDate,
      })
    )
  }

  return (
    <C.InputsFormContainer>
      <C.Text color="resolutionBlue" weight="md" textAlign="center">
        Ingresá el rango de fechas que querés consultar
      </C.Text>
      <Formik
        initialValues={initialValues}
        onSubmit={handleOnPressSubmit}
        validationSchema={schemaValidation}
      >
        {(props: FormikProps<TInitialValues>) => (
          <Form>
            <C.RangeDatePicker
              id={'fechaReporte'}
              name={'fechaReporte'}
              title={''}
              startDate={props.values.fechaReporte.startDate}
              endDate={props.values.fechaReporte.endDate}
              onChange={props.setFieldValue}
            />
            <Center>
              <ButtonContainer>
                <C.Button type="submit">Descargar Reporte</C.Button>
                <C.Button onClick={() => downloadDetail(props.values)}>
                  Descargar Detalle
                </C.Button>
              </ButtonContainer>
            </Center>
          </Form>
        )}
      </Formik>
    </C.InputsFormContainer>
  )
}

export default MedicAreaReportsScreen
