import { extendGetPoliciesParamsWithExportList } from 'domain/requestModel'
import { fields } from 'app/constants'

export const DEFAULT_AMOUNT_ROWS = 10
export const DEFAULT_PAGE = 0
export const [FIELD_MIN_LENGTH, FIELD_MAX_LENGTH] = [1, 100]

export type TForm = extendGetPoliciesParamsWithExportList & {
  resetPageOnSubmit: boolean
}

export const initialValues: TForm = {
  pagina: DEFAULT_PAGE,
  cantidad: DEFAULT_AMOUNT_ROWS,
  dni: 0,
  estadoBackOffice: { value: -1, label: '' },
  provincia: { label: '', value: '' },
  fechaVigencia: {
    startDate: undefined,
    endDate: undefined,
  },
  codAsesor: '',
  exportList: 0,
  resetPageOnSubmit: false,
}

export const tableDesktopHeaders: string[] = [
  fields.fechaCotizacion,
  fields.estadoBackOffice,
  fields.nombre,
  fields.apellido,
  fields.dni,
  fields.provincia,
  fields.asesores,
  fields.credencial,
  fields.fechaVigencia,
  fields.detalle,
]

export const tableMobileHeaders: string[] = [
  fields.fechaCotizacion,
  fields.estadoBackOffice,
  fields.nombre,
  fields.detalle,
]
