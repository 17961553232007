import React, { useEffect, useContext, FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { useAccessControl } from 'app/hooks/useAccessControl'

import {
  getLead,
  getLeadStates,
  putLeadAdvisor,
  putLeadState,
  selectLead,
  selectLeadStates,
} from 'app/slices/leadsSlice'
import { selectProvincies, getAllProvincies } from 'app/slices/provinceSlice'
import {
  getAdvisorsBySupervisor,
  selectAdvisorsBySupervisor,
} from 'app/slices/supervisorSlice'

import { getLeadRequestModel } from 'domain/requestModel'

import { MessageContext } from 'app/components/ui/Message'
import { KeyValue, LoadingSpinner, Text } from 'app/components'

import { ILeadDTO } from 'domain/responseModel'
import {
  FieldContainer,
  DetailName,
  GoBack,
  Container,
  DropdownsContainer,
} from 'app/Pages/Lead/style'
import LeadStateForm from 'app/Pages/Lead/form'

import { getCanalVentaLabel } from 'app/Pages/Lead/presenters'
import { functionalitiesCodes } from 'app/constants'
import SupervisorUpdateForm from './SupervisorUpdateForm'

import * as C from 'app/components'
import * as S from '../../Pages/Holder/styles'

import History from '../../components/ui/HistoryCard'
import ObservationsAccordion from '../../components/ui/ObservationsCard'
import { DataTitle } from './style'

const initialValue = {
  label: '',
  value: 0,
}

const Lead: FC<any> = props => {
  const lead: ILeadDTO = useSelector(selectLead)
  const leadStates = useSelector(selectLeadStates)
  const dispatch = useDispatch()
  const message = useContext(MessageContext)
  const [initialOption, setInitialOption] = useState(initialValue)
  const [selectedOption, setSelectedOption] = useState(initialValue)
  const [initialAdvisor, setInitialAdvisor] = useState(initialValue)
  const [selectedAdvisor, setSelectedAdvisor] = useState(initialValue)
  const isAllowed = useAccessControl([functionalitiesCodes.displaySupervisor])
  const advisorsBySupervisor = useSelector(selectAdvisorsBySupervisor)
  const provincies = useSelector(selectProvincies)

  const disableSubmitForm = selectedOption
    ? initialOption.value === selectedOption?.value
    : true

  const disableSubmitAdvisorForm = selectedAdvisor
    ? initialAdvisor.value === selectedAdvisor?.value
    : true

  useEffect(() => {
    const findState =
      lead &&
      lead.data.idEstadoDerivadora &&
      leadStates &&
      leadStates.find((item: any) => item.id === lead.data.idEstadoDerivadora)
    if (findState) {
      const stateObject = {
        label: findState.descripcion,
        value: lead.data.idEstadoDerivadora,
      }
      setSelectedOption(stateObject)
      setInitialOption(stateObject)
    }
  }, [lead, leadStates])

  useEffect(() => {
    dispatch(getAllProvincies())
  }, [dispatch, provincies])

  let params: getLeadRequestModel = useParams()

  useEffect(() => {
    dispatch(getLeadStates())
    dispatch(getLead(params))
  }, [params, dispatch])

  useEffect(() => {
    const originalAdvisor: any =
      lead &&
      lead.data.asesor &&
      advisorsBySupervisor &&
      advisorsBySupervisor.find(
        (item: any) => item.codAsesor === lead.data.asesor
      )

    if (originalAdvisor) {
      const stateObject = {
        label: originalAdvisor.nombre,
        value: parseInt(originalAdvisor.codAsesor, 10),
      }
      setSelectedAdvisor(stateObject)
      setInitialAdvisor(stateObject)
    }
  }, [lead, advisorsBySupervisor])

  useEffect(() => {
    const advisorsParams =
      lead && lead.data.supervisor
        ? { codSupervisor: lead.data.supervisor }
        : { codSupervisor: '' }
    dispatch(getAdvisorsBySupervisor(advisorsParams))
  }, [dispatch, lead])

  const handleOnCopiedSuccessfully = () =>
    message.setMessageProps({
      displayMessage: true,
      message: '¡Texto Copiado!',
    })

  const handleOnCopiedFailed = () =>
    message.setMessageProps({
      displayMessage: true,
      message: '¡Error al copiar!',
      backgroundColor: 'scarlet',
    })

  const handleCopyToClipboard = async (e: string) => {
    try {
      await navigator.clipboard.writeText(e)

      handleOnCopiedSuccessfully()
    } catch (err) {
      console.error('Error in copying text: ', err)

      handleOnCopiedFailed()
    }
  }

  const handleOnSubmit = () => {
    dispatch(
      putLeadState({
        id: lead.data.id,
        tipoLead: lead.data.tipoLead,
        idEstado: selectedOption?.value,
      })
    )
  }

  const handleAdvisorOnSubmit = () => {
    dispatch(
      putLeadAdvisor({
        id: lead.data.id,
        tipoLead: lead.data.tipoLead,
        codAsesor: selectedAdvisor?.value,
      })
    )
  }

  const mappedLead: any = getCanalVentaLabel(lead, isAllowed, provincies)
  return (
    <>
      {!lead ? (
        <LoadingSpinner isLoading={true} />
      ) : (
        <S.HolderScreenContainer>
          <Link to={`${props.path}`} style={{ textDecoration: 'none' }}>
            <GoBack>{'< Volver al listado'}</GoBack>
          </Link>
          <S.HolderScreenContent>
            <S.FirstColumn>
              <Text
                size={'sm'}
                onClick={() => handleCopyToClipboard(lead.data.linkContinuar)}
                color={'enterpriseBlue'}
                float={'right'}
                margin={'25px 25px 25px 25px'}
                isCursor
                underlined
              >
                Copiar link
              </Text>
              <C.Acordion title="Detalle" open margin="10px">
                <FieldContainer>
                  <Container>
                    <DropdownsContainer>
                      <LeadStateForm
                        pageType={'detail'}
                        onSubmit={handleOnSubmit}
                        lead={lead}
                        selectedOption={selectedOption}
                        setSelectedOption={setSelectedOption}
                        disableSubmitForm={disableSubmitForm}
                      />
                      {advisorsBySupervisor && (
                        <SupervisorUpdateForm
                          advisorsBySupervisor={advisorsBySupervisor}
                          onSubmit={handleAdvisorOnSubmit}
                          selectedAdvisor={selectedAdvisor}
                          setSelectedAdvisor={setSelectedAdvisor}
                          disableSubmitForm={disableSubmitAdvisorForm}
                        />
                      )}
                    </DropdownsContainer>
                    <DetailName>
                      <b>{lead.data.nombre ? lead.data.nombre : ''}</b>
                    </DetailName>
                  </Container>
                  <DataTitle>Datos personales</DataTitle>
                  <hr />
                  {Object.keys(mappedLead.data)
                    .filter(
                      (key: string) =>
                        !key.toLowerCase().includes('observaciones') &&
                        !key.toLowerCase().includes('historial')
                    )
                    .map((e: any, index: number) => (
                      <KeyValue
                        key={index}
                        label={e}
                        value={mappedLead.data[e]}
                      />
                    ))}
                </FieldContainer>
              </C.Acordion>
            </S.FirstColumn>
            <S.SecondColumn>
              <History props={props} lead={lead.data} page="lead" />
              <ObservationsAccordion
                props={props}
                addObservationPermission={'addFamilyGroupObservations'}
                keyName={'new-observation-btn'}
                lead={lead.data}
                page="lead"
              />
            </S.SecondColumn>
          </S.HolderScreenContent>
        </S.HolderScreenContainer>
      )}
    </>
  )
}

export default Lead
