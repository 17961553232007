import { Province } from 'domain/entities'

export interface IProvinceDTO {
  provincias: Province[]
  codigo: string
  mensaje: string
  puedeContinuar: boolean
  status: number
}

export const getAllProvincesToModel = (raw: IProvinceDTO): IProvinceDTO => {
  return {
    provincias: raw.provincias,
    codigo: raw.codigo,
    mensaje: raw.mensaje,
    puedeContinuar: raw.puedeContinuar,
    status: raw.status,
  }
}
