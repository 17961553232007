import { ISelectFormat } from 'app/components'
import { usersRelated } from 'app/constants'
import { asociateTypesStrings } from 'app/constants/relationShips'
import { Province } from 'domain/entities'
import { Socio } from 'domain/entities/familyGroupDetail'
import { DayMonthYearWithLeadingZerosDashed, getFormatedDate } from 'helpers'

export enum HolderScreenActions {
  SET_DROPDOWN_VALUE = 'SET_DROPDOWN_VALUE',
  SET_FAMILY_MEMBER = 'SET_FAMILY_MEMBER',
  SET_PROVINCIES = 'SET_PROVINCIES',
}

interface HolderScreenState {
  dropDownValue: ISelectFormat
  familyMember: Socio | undefined
  familyMemberMappedKeyValueData: any
  provincies: Province[] | undefined
}

interface HolderScreenAction {
  type: HolderScreenActions
  payload: any
}

const holderScreenReducer = (
  state: HolderScreenState,
  action: HolderScreenAction
): HolderScreenState => {
  const { type, payload } = action

  switch (type) {
    case HolderScreenActions.SET_DROPDOWN_VALUE: {
      return {
        ...state,
        dropDownValue: payload,
      }
    }

    case HolderScreenActions.SET_FAMILY_MEMBER: {
      const socio = payload as Socio

      return {
        ...state,
        familyMember: payload,
        familyMemberMappedKeyValueData: socio
          ? {
              familyMemberType: asociateTypesStrings[socio.tipoSocio],
              personalData: [
                { dni: socio.dni },
                { cuil: socio.cuil },
                { sexo: usersRelated.sexStrings[socio.sexo] },
                {
                  fechaDeNacimiento: getFormatedDate(
                    socio.fechaNacimiento,
                    DayMonthYearWithLeadingZerosDashed
                  ),
                },
                { edad: socio.edad },
                { mail: socio.mail },
                { telefonoMovil: socio.telefonoMovil },
              ].map(e => ({
                label: Object.keys(e)[0],
                value: Object.values(e)[0],
              })),
            }
          : null,
      }
    }

    case HolderScreenActions.SET_PROVINCIES: {
      return {
        ...state,
        provincies: payload,
      }
    }

    default:
      return state
  }
}

export default holderScreenReducer
