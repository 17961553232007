import { TGetAfilliationsParams } from 'domain/requestModel'
import { fields } from 'app/constants'

export const DEFAULT_AMOUNT_ROWS = 10
export const DEFAULT_PAGE = 0

export type TForm = TGetAfilliationsParams

export const initialValues: TForm = {
  pagina: DEFAULT_PAGE,
  cantidad: DEFAULT_AMOUNT_ROWS,
  dni: 0,
  fechaCotizacion: {
    startDate: undefined,
    endDate: undefined,
  },
  estadoBackOffice: { value: -1, label: '' },
  advisor: [{ label: '', value: '' }],
  provincia: { label: '', value: '' },
}

export const tableDesktopHeaders: string[] = [
  fields.fechaCotizacion,
  fields.estadoBackOffice,
  fields.tipoAfiliacion,
  fields.motivo,
  fields.nombre,
  fields.apellido,
  fields.dni,
  fields.provincia,
  fields.codigoAsesor,
  fields.numeroSocio,
  fields.detalle,
]

export const tableMobileHeaders: string[] = [
  fields.fechaCotizacion,
  fields.estadoBackOffice,
  fields.nombre,
  fields.detalle,
]

export const afiliationType = {
  Prepaga: 1,
  Seguros: 2,
  Pymes: 3,
  ART: 4,
  PADEmpresas: 5,
}
