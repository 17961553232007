import {
  getPoliciesRequestModel,
  getPolicyRequestModel,
  getPolicyStateByPolicyIdRequestModel,
  putPolicyStateRequestModel,
  TGetPoliciesRequestModel,
} from 'domain/requestModel'

import { IPolicyEntityGateway } from 'domain/entityGateways/IPolicyEntityGateway'

export class UseCasePolicy implements IPolicyEntityGateway {
  private entityGateway: IPolicyEntityGateway

  constructor(entityGateway: IPolicyEntityGateway) {
    this.entityGateway = entityGateway
  }

  public async getPolicies(params: getPoliciesRequestModel) {
    const policies = await this.entityGateway.getPolicies(params)

    return policies
  }

  public async getPolicy(params: getPolicyRequestModel) {
    const policy = await this.entityGateway.getPolicy(params)

    return policy
  }

  public async getPolicyStatesByPolicyId(
    params: getPolicyStateByPolicyIdRequestModel
  ) {
    const policyStates = await this.entityGateway.getPolicyStatesByPolicyId(
      params
    )

    return policyStates
  }

  public async getPoliciesExport(params: TGetPoliciesRequestModel) {
    const policiesExported = await this.entityGateway.getPoliciesExport(params)

    return policiesExported
  }

  public async putPolicyState(params: putPolicyStateRequestModel) {
    const updatedState = await this.entityGateway.putPolicyState(params)

    return updatedState
  }

  public async getPoliciesRejectedExport() {
    const rejectedPolicies = await this.entityGateway.getPoliciesRejectedExport()

    return rejectedPolicies
  }

  public async getPoliciesAbandonQuotedExport() {
    const policiesQuoted = await this.entityGateway.getPoliciesAbandonQuotedExport()

    return policiesQuoted
  }
}
