import styled from 'styled-components'

import imgBack from 'app/images/login_background.jpg'

const StyledInputs = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > div {
    margin: 18px 0;
    &:last-child {
      margin-bottom: 10px;
    }
  }
`

const StyledLogin = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.95);

  @media only screen and (min-width: ${props =>
      props.theme.breakPoints.desktop}) {
    width: 50%;
  }
`

const StyledHomeContainer = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${imgBack});
  background-size: cover;
  background-position: center center;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakPoints.desktop}) {
    display: flex;
    justify-content: flex-end;
  }
`

const LogoImage = styled.img`
  height: 37px;
  margin-bottom: 40px;
`

export { StyledLogin, StyledHomeContainer, StyledInputs, LogoImage }
