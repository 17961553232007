import React, { useState, useEffect } from 'react'

import { useIsMobile } from 'app/hooks/useIsMobile'

import { LoadingSpinner, Text } from 'app/components'
import { Header, Body, Styles, TTable } from 'app/components/ui/Table'

import { TableDefaultProps } from './constants'

const mapItemsFromHeaders = (props: { data: any[]; headers: string[] }) =>
  props.data.map(e => {
    let newItem = {}

    for (let a of props.headers) {
      Object.assign(newItem, { [a]: e[a] })
    }

    return newItem
  })

export const Table = (props: TTable) => {
  const [headers, setHeaders] = useState<string[]>([])
  const [items, setItems] = useState<any[]>([])
  const isMobile = useIsMobile()

  useEffect(() => {
    isMobile
      ? setHeaders(props.tableMobileHeaders)
      : setHeaders(props.tableDesktopHeaders)
  }, [
    props.data,
    props.tableMobileHeaders,
    props.tableDesktopHeaders,
    isMobile,
  ])

  useEffect(() => {
    if (isMobile) {
      const data = mapItemsFromHeaders({
        headers: props.tableMobileHeaders,
        data: props.data,
      })

      return setItems(data)
    }

    const data = mapItemsFromHeaders({
      headers: props.tableDesktopHeaders,
      data: props.data,
    })

    return setItems(data)
  }, [
    props.data,
    props.tableMobileHeaders,
    props.tableDesktopHeaders,
    isMobile,
  ])

  return (
    <LoadingSpinner isLoading={props.isLoading}>
      <Styles.Table>
        <thead>
          <Header headers={headers} noCamelCase={props.noCamelCase} />
        </thead>
        <tbody>
          {props.data.length ? (
            <Body
              headers={headers}
              items={items}
              item={props.item}
              data={props.data}
            />
          ) : (
            <Styles.TTr>
              <Styles.TTd colSpan={headers.length}>
                <Text textAlign={'center'}>Sin resultados...</Text>
              </Styles.TTd>
            </Styles.TTr>
          )}
        </tbody>
      </Styles.Table>
    </LoadingSpinner>
  )
}

Table.defaultProps = TableDefaultProps

export default Table
