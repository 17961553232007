import React from 'react'

import { IconArrowForwardIos, IconArrowBackIos, Text } from 'app/components'

import * as Style from 'app/Pages/AdvisorAgenda/styles'

import {
  getFormatedDate,
  MonthNameWithYearNumber,
  incrementDate,
  momentOptions,
  decreaseDate,
} from 'helpers'

type TMonthSwiper = {
  name: string
  date: Date
  onChange: (fieldName: string, date: Date) => void
  onChangeDate: (date: Date) => void
}

const MonthSwiper = (props: TMonthSwiper) => {
  const handleOnClickArrows = (side: number) => {
    const d = side
      ? incrementDate(props.date, 1, momentOptions.months)
      : decreaseDate(props.date, 1, momentOptions.months)

    props.onChange(props.name, d)
    props.onChangeDate(d)
  }

  return (
    <Style.MonthSwiper>
      <IconArrowBackIos
        isCursor
        color={'mariner'}
        onClick={() => handleOnClickArrows(0)}
      />
      {props.date && (
        <Text capitalFirstLetter color={'mariner'} weight={'lg'} size={'lg'}>
          {getFormatedDate(props.date, MonthNameWithYearNumber).toString()}
        </Text>
      )}
      <IconArrowForwardIos
        isCursor
        color={'mariner'}
        onClick={() => handleOnClickArrows(1)}
      />
    </Style.MonthSwiper>
  )
}

export default MonthSwiper
