import { ILogger } from 'domain/entities'
import { Company } from 'domain/entities/company'

import {
  changeStateFGParams,
  createCompanyParam,
  getCompaniesRequestModel,
  getCompanyRequestParam,
  getCompanyStateParam,
  getFamilyGroupDetailParam,
} from 'domain/requestModel/company'

import { getCompaniesToModel } from 'domain/responseModel/company'
import { endpoints } from 'app/constants'

export class CompanyService {
  private httpClient: any
  private logger: any
  private getCompaniesParams: getCompaniesRequestModel | null

  constructor(clientProvider: any, logger: ILogger) {
    this.httpClient = clientProvider
    this.logger = logger
    this.getCompaniesParams = null
  }

  public async getCompanies(
    searchParamas: getCompaniesRequestModel
  ): Promise<any> {
    try {
      this.getCompaniesParams = searchParamas

      const search = (
        await this.httpClient.get(
          endpoints.getCompanies,
          this.getCompaniesParams
        )
      ).data

      return getCompaniesToModel({
        resultados: search.resultados.map((c: Company) => ({
          id: c.id,
          razonSocial: c.razonSocial,
          cuit: c.cuit,
          mail: c.mail,
          telefono: c.telefono,
          idProvincia: c.idProvincia,
          idLocalidad: c.idLocalidad,
          logo: c.logo,
          codigo: c.codigo,
          guid: c.guid,
          ultimaActualizacion: c.ultimaActualizacion,
          asesor: c.asesor,
          estado: c.estado,
        })),
        totalPaginas: search.totalPaginas,
        totalCantidad: search.totalCantidad,
      })
    } catch (error) {
      this.logger.error({
        message: 'UseCase - Company (getCompanies)',
        data: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
      })
    }
  }

  public async getCompany(param: getCompanyRequestParam): Promise<any> {
    try {
      const company = await this.httpClient.get(endpoints.getCompany, {
        id: param.companyId,
      })

      return company.data
    } catch (error) {
      this.logger.error({
        message: 'UseCase - Company (getCompany)',
        data: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
      })
    }
  }

  public async getCompanyStates(param: getCompanyStateParam): Promise<any> {
    try {
      const companyState = await this.httpClient.get(endpoints.estados, {
        idTipoEstado: param.idTipoEstado,
        subTipo: param.subTipo || 1,
      })

      return companyState.data
    } catch (error) {
      this.logger.error({
        message: 'UseCase - CompanyState (getCompanyState)',
        data: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
      })
    }
  }

  public async getExportCompanyList(): Promise<any> {
    try {
      const report = (
        await this.httpClient.get(
          endpoints.getExportCompaniesList,
          this.getCompaniesParams,
          {
            responseType: 'blob',
          }
        )
      ).data

      let url = window.URL.createObjectURL(report)
      let a = document.createElement('a')
      a.href = url
      a.download = 'listado-de-pymes.xlsx'
      a.click()
    } catch (error) {
      this.logger.error({
        message: 'UseCase - getExportCompanyList (getExportCompanyList)',
        data: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
      })
    }
  }

  public async getFamilyGroupDetail(
    param: getFamilyGroupDetailParam
  ): Promise<any> {
    try {
      const familyGroupDetail = await this.httpClient.get(
        endpoints.getFamilyGroupDetail,
        param
      )

      return familyGroupDetail?.data
    } catch (error) {
      this.logger.error({
        message: 'UseCase - FamilyGroupDetail (getFamilyGroupDetail)',
        data: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
      })
    }
  }

  public async changeStateObsFG(param: changeStateFGParams): Promise<any> {
    try {
      const changes = await this.httpClient.post(endpoints.stateChangeFG, param)
      return changes?.data
    } catch (error) {
      this.logger.error({
        message: 'UseCase - CompanyServices (changeStateObsFG)',
        data: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
      })
    }
  }

  public async createCompany(param: createCompanyParam): Promise<any> {
    try {
      const company = await this.httpClient.post(
        endpoints.createCompany + `?id=${param.id}`
      )

      return company
    } catch (error) {
      this.logger.error({
        message: 'UseCase - CompanyServices (createCompany)',
        data: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
      })
    }
  }
}

export default {
  CompanyService,
}
