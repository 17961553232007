import React, { FC, ReactNode } from 'react'
import { Formik, Form } from 'formik'

import { Province } from 'domain/entities'
import { initialValues } from './constants'
import { strings } from 'app/constants/strings'
import {
  InputsContainer,
  PageContainer,
  Title,
  BottomContainer,
  FootFields,
  FilterContainer,
  ShowFilterContainer,
  ShowFilterButton,
} from './style'
import {
  InputField,
  SelectAsync,
  IconArrowIosUpwardOutline,
  IconArrowIosDownwardOutline,
  Button,
  Pagination,
  Text,
  ISelectFormat,
} from 'app/components'
import Date from 'app/components/ui/forms/Date'
import { useToggle } from 'app/hooks/useToggle'

const {
  forms: {
    fieldsName: {
      cuit,
      province,
      exportList,
      advisor,
      idState,
      afiliationType,
      page,
      resetPageOnSubmit,
    },
    fieldsTitle: {
      cuitTitle,
      provinceTitle,
      advisorTitle,
      stateTitle,
      afiliationTypeTitle,
    },
  },
} = strings

interface ICompanyFilter {
  onSubmit: (values: any) => void
  provinces: Province[]
  companyStates: ISelectFormat[]
  onChangeAdvisor: (codeOrName: string) => void
  paginationPageCount: number
  children: ReactNode
}

const CompanyFilter: FC<ICompanyFilter> = ({
  onSubmit,
  provinces,
  companyStates,
  paginationPageCount,
  children,
}) => {
  const { opened, toggle } = useToggle(true)
  const IconArrowComponent = opened
    ? IconArrowIosUpwardOutline
    : IconArrowIosDownwardOutline

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={(values: any, { setSubmitting }) => {
          setSubmitting(false)
          onSubmit(values)
        }}
      >
        {(props: {
          values: any
          errors: {
            cuit: boolean
            province: boolean
            searchDate: boolean
            advisor: boolean
            state: boolean
            afiliationType: boolean
          }
          touched: {
            cuit: boolean
            province: boolean
            searchDate: boolean
            advisor: boolean
            afiliationType: boolean
          }
          onChange: any
          handleChange: any
          submitForm: any
          setFieldValue: any
          resetForm: any
        }) => (
          <Form>
            <PageContainer>
              <Title>
                <Text
                  textAlign={'center'}
                  size={'xl'}
                  color={'resolutionBlue'}
                  weight={'md'}
                >
                  Empresas ingresadas
                </Text>
              </Title>
              {opened && (
                <FilterContainer>
                  <Text textAlign={'center'} size={'xl'} weight={'md'}>
                    Filtrar
                  </Text>
                  <InputsContainer>
                    <InputField
                      name={cuit}
                      id={cuit}
                      title={cuitTitle}
                      type="string"
                    />
                    <InputField
                      name={afiliationType}
                      id={afiliationType}
                      title={afiliationTypeTitle}
                      type="string"
                    />
                    <Date
                      showMonthDropdown
                      showYearDropdown
                      label="Fecha de ingreso"
                      placeholder="DD/MM/YYYY"
                      name="ultimaActualizacion"
                      onChange={props.setFieldValue}
                      onChangeDate={() => {}}
                    />
                  </InputsContainer>
                  <InputsContainer>
                    <InputField
                      name={'asesor'}
                      id={advisor}
                      title={advisorTitle}
                      type="string"
                    />
                    <SelectAsync
                      onChange={(option: any) => {
                        props.setFieldValue(province, option ? option : null)
                      }}
                      title={provinceTitle}
                      name={province}
                      id={province}
                      placeholder="Seleccionar"
                      options={provinces?.map((b: any) => ({
                        label: b.nombre,
                        value: b.id,
                      }))}
                      isClearable
                    />
                    <SelectAsync
                      onChange={(option: any) => {
                        props.setFieldValue(idState, option ? option : null)
                      }}
                      title={stateTitle}
                      name={idState}
                      id={idState}
                      placeholder="Seleccione"
                      options={companyStates}
                      isClearable
                    />
                  </InputsContainer>
                  <BottomContainer>
                    <Button
                      type="submit"
                      onClick={() => {
                        props.setFieldValue(exportList, 0)
                        props.submitForm()
                      }}
                    >
                      Buscar
                    </Button>
                  </BottomContainer>
                </FilterContainer>
              )}
              <ShowFilterContainer>
                <ShowFilterButton onClick={toggle}>
                  <IconArrowComponent color={'cornflowerBlue'} isCursor />
                  <Text
                    textAlign={'center'}
                    color={'cornflowerBlue'}
                    size={'sm'}
                    isCursor
                  >
                    {opened ? 'Ocultar filtros' : 'Mostrar filtros'}
                  </Text>
                </ShowFilterButton>
              </ShowFilterContainer>
            </PageContainer>
            {children}
            <FootFields>
              <Pagination
                pageFieldName={page}
                resetFieldName={resetPageOnSubmit}
                setFieldValue={props.setFieldValue}
                pageCount={paginationPageCount}
                submit={props.submitForm}
              />
            </FootFields>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default CompanyFilter
