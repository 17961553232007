import StateDocumentsModalContent from './StateDocumentsModalContent'
import StateCompanyModalContent from './StateCompanyModalContent'
import PaperModeModalContent from './PaperModeModalContent'

export {
  StateCompanyModalContent,
  StateDocumentsModalContent,
  PaperModeModalContent,
}

export default {
  StateDocumentsModalContent,
  StateCompanyModalContent,
  PaperModeModalContent,
}
