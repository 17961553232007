import React, { useState } from 'react'
import AsyncSelect from 'react-select/async'
import { SelectTableContainer } from 'app/components/ui/styles'
import { ISelectFormat } from 'app/components'

export type TSelectAsync = {
  value?: any
  options: ISelectFormat[]
  onChange: (option: any) => void
  id: number | string
  name: string
  placeholder?: string
  isDisabled?: boolean
  isLoading?: boolean
  isClearable?: boolean
  isMulti?: boolean
  color?: string
  defaultValue?: ISelectFormat
  adaptable?: boolean
  bordered?: boolean
  companyDropdown?: boolean
  isPadEmpresas?: boolean
} & typeof SelectAsyncDefaultProps

export const SelectAsyncDefaultProps = {
  placeholder: '',
  isDisabled: false,
  isLoading: false,
  isClearable: false,
  isMulti: false,
  adaptable: false,
  bordered: false,
}

const TableDropdown = (props: TSelectAsync) => {
  const [inputValue, setInputValue] = useState('')

  const handleInputChange = (newValue: string) => {
    if (props.isPadEmpresas) {
      setInputValue('')
      return ''
    } else {
      const value = newValue.replace(/\W/g, '')
      return value
    }
  }

  const filterInputValues = (inputValue: string) => {
    return props.options?.filter((i: any) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    )
  }

  const loadOptions = (inputValue: any) =>
    new Promise(resolve => {
      setTimeout(() => {
        resolve(filterInputValues(inputValue))
      }, 1000)
    })

  return (
    <SelectTableContainer
      disabled={props.isDisabled}
      adaptable={props.adaptable}
      bordered={props.bordered}
      companyDropdown={props.companyDropdown}
    >
      <AsyncSelect
        isMulti={props.isMulti}
        value={props.value}
        isLoading={props.isLoading || !props.options}
        classNamePrefix={'react-select'}
        // cacheOptions
        isClearable={props.isClearable}
        defaultOptions={props.options}
        defaultValue={props.defaultValue}
        loadOptions={loadOptions}
        onInputChange={handleInputChange}
        onChange={props.onChange}
        isDisabled={props.isDisabled}
        placeholder={props.placeholder}
        inputValue={inputValue}
      />
    </SelectTableContainer>
  )
}

TableDropdown.defaultProps = SelectAsyncDefaultProps

export default React.memo(TableDropdown)
