import { ILogger } from 'domain/entities'

import {
  getAfilliationsDTO,
  IAfilliationsDTO,
  TGetAfilliationDTO,
} from 'domain/responseModel'

import {
  TGetAfilliationsParams,
  TGetAfilliationParams,
  getAfilliationsByFilter,
  TPostAfilliationStateParams,
  TPostEventParam,
  postEvent,
  TPostEvent,
  TGetReportMedicArea,
} from 'domain/requestModel'

import { endpoints } from 'app/constants'
import { getReportMedicAreaDates } from 'domain/requestModel/afilliation'

export class AfilliationServices {
  private httpClient: any
  private logger: any

  constructor(clientProvider: any, logger: ILogger) {
    this.httpClient = clientProvider
    this.logger = logger
  }

  public async getAfilliations(
    params: TGetAfilliationsParams
  ): Promise<IAfilliationsDTO> {
    try {
      const filters = getAfilliationsByFilter(params)
      const afiliaciones: IAfilliationsDTO = (
        await this.httpClient.get(endpoints.afilliations, filters)
      ).data

      return getAfilliationsDTO(afiliaciones)
    } catch (error) {
      this.logger.error({
        message: 'UseCase - Afilliaciones',
        data: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
      })

      throw new Error(error)
    }
  }

  public async getAfilliation(
    params: TGetAfilliationParams
  ): Promise<TGetAfilliationDTO> {
    try {
      let afiliacion: TGetAfilliationDTO

      afiliacion = (
        await this.httpClient.get(`${endpoints.afilliation}`, params)
      ).data

      return afiliacion
    } catch (error) {
      this.logger.error({
        message: 'UseCase - Afilliacion',
        data: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
      })

      throw new Error(error)
    }
  }

  public async postAfilliationStateUpdate(
    params: TPostAfilliationStateParams
  ): Promise<any> {
    try {
      const stateUpdate: any = (
        await this.httpClient.post(
          `${endpoints.stateUpdate}${
            params.tipoAfiliacion
              ? `?tipoAfiliacion=${params.tipoAfiliacion}`
              : ''
          }`,
          params
        )
      ).data

      return stateUpdate
    } catch (error) {
      this.logger.error({
        message: 'UseCase - State Update',
        data: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
      })

      throw new Error(error)
    }
  }

  public async postEvent(params: TPostEventParam): Promise<any> {
    try {
      const mapPostEventParams: TPostEvent = postEvent(params)

      const endpoint =
        params.tipoAfiliacion === '5'
          ? `${endpoints.evento}?tipoAfiliacion=5`
          : endpoints.evento

      const newEvent: IAfilliationsDTO = (
        await this.httpClient.post(endpoint, mapPostEventParams)
      ).data

      return newEvent
    } catch (error) {
      this.logger.error({
        message: 'UseCase - postEvent',
        data: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
      })

      throw new Error(error)
    }
  }

  public async getReportMedicArea(params: TGetReportMedicArea): Promise<any> {
    try {
      const param = getReportMedicAreaDates(params)
      const report = (
        await this.httpClient.get(endpoints.medicAreaReport, param, {
          responseType: 'blob',
        })
      ).data

      let url = window.URL.createObjectURL(report)
      let a = document.createElement('a')
      a.href = url
      a.download = 'reporte-area-medica.xlsx'
      a.click()
    } catch (error) {
      this.logger.error({
        message: 'UseCase - getReportMedicArea',
        data: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
      })

      throw new Error(error)
    }
  }

  public async getDetailMedicArea(params: TGetReportMedicArea): Promise<any> {
    try {
      const param = getReportMedicAreaDates(params)
      const report = (
        await this.httpClient.get(endpoints.medicAreaDetail, param, {
          responseType: 'blob',
        })
      ).data

      let url = window.URL.createObjectURL(report)
      let a = document.createElement('a')
      a.href = url
      a.download = 'detalle-area-medica.xlsx'
      a.click()
    } catch (error) {
      this.logger.error({
        message: 'UseCase - getDetailMedicArea',
        data: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
      })

      throw new Error(error)
    }
  }
}

export default {
  AfilliationServices,
}
