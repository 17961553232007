import React from 'react'

import useModal from 'app/hooks/useModal'

import { Modal } from 'app/components'

let ModalContext: any
const { Provider } = (ModalContext = React.createContext({}))

const ModalProvider = ({ children }: { children: any }) => {
  const {
    displayModal,
    handleDisplayModal,
    handleModalContent,
    modalContent,
    modalIsFetching,
    handleOnModalIsFetching,
    error,
    handleOnModalError,
  } = useModal()

  return (
    <Provider
      value={{
        displayModal,
        handleDisplayModal,
        handleModalContent,
        modalContent,
        modalIsFetching,
        handleOnModalIsFetching,
        error,
        handleOnModalError,
      }}
    >
      <Modal />
      {children}
    </Provider>
  )
}

export { ModalContext, ModalProvider }
