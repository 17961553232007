import { createSelector, createSlice } from '@reduxjs/toolkit'

import { RootState } from 'app/store/reducers'
import { Login } from 'domain/useCases'
import { loginRequestModel } from 'domain/requestModel/credential'
import { loginService } from 'app/services'

const loginController = new Login(loginService)

type initialStateReducer = {
  loading: boolean
  error: string
  roles: string[]
  funcionalidades: string[]
  token: string
  usuario: string
}

const INITIAL_STATE: initialStateReducer = {
  loading: false,
  error: '',
  roles: [],
  funcionalidades: [],
  token: '',
  usuario: '',
}

export const slice = createSlice({
  name: 'auth',
  initialState: INITIAL_STATE,
  reducers: {
    fetchLoginStart: state => {
      state.loading = true
      state.error = ''
    },
    fetchLoginSuccess: (state, { payload }) => {
      state.loading = false
      state.error = ''
      state.roles = payload.roles
      state.funcionalidades = payload.funcionalidades
      state.token = payload.token
      state.usuario = payload.usuario
    },
    fetchLoginFail: (state, { payload }) => {
      state.loading = false
      state.error = payload.message
    },
    logout: () => INITIAL_STATE,
  },
})

export const {
  fetchLoginStart,
  fetchLoginSuccess,
  fetchLoginFail,
  logout,
} = slice.actions

export default slice.reducer

/**
 * Thunks
 */
export const loginUser = (props: loginRequestModel) => async (
  dispatch: any
) => {
  try {
    dispatch(fetchLoginStart())

    const response = await loginController.login({ ...props })

    if (response.error) return dispatch(fetchLoginFail(response))

    dispatch(fetchLoginSuccess(response))
  } catch (error) {
    dispatch(fetchLoginFail(error))
  }
}

export const logoutUser = () => (dispatch: any) => {
  dispatch(logout())
}

/**
 * Selectors
 */

export const selectAuth = (state: RootState) => state.auth
export const selectAuthFunctionalities = createSelector(
  selectAuth,
  auth => auth.funcionalidades
)

export const selectAuthUserName = createSelector(
  selectAuth,
  auth => auth.usuario
)
