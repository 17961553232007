import React from 'react'

import { Text } from 'app/components/ui'

import { Styles, THeader } from 'app/components/ui/Table'

import { fieldKeyToName } from 'helpers'

export const Header = (props: THeader) => {
  return (
    <Styles.TTr>
      {props.headers &&
        props.headers.length > 0 &&
        props.headers.map((e: string, i: number) => (
          <Styles.TTh key={i}>
            <Text
              color={'mariner'}
              weight={'md'}
              textAlign={'center'}
              {...(!props.noCamelCase && {
                capitalFirstLetter: true,
              })}
            >
              {fieldKeyToName(e)}
            </Text>
          </Styles.TTh>
        ))}
    </Styles.TTr>
  )
}

export default Header
