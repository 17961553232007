import { useEffect, useRef } from 'react'

const useEffectOnlyOnce = (
  callback: any,
  dependencies: any[],
  condition: any
) => {
  const calledOnce = useRef(false)

  useEffect(() => {
    if (calledOnce.current) return

    if (condition(dependencies)) {
      callback(dependencies)

      calledOnce.current = true
    }
  }, [callback, condition, dependencies])
}

export default useEffectOnlyOnce
