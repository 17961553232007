import React, { FC, useState } from 'react'
import * as S from 'app/components/ui/TableFilter/styles'
import { Button } from 'app/components'
import {
  IconArrowIosDownwardOutline,
  IconArrowIosUpwardOutline,
} from 'app/components/Icons'

type TOptions = {
  id: number
  descripcion: string
  isChecked: boolean
}

const Acordion: FC<{ headTitle: string }> = ({ headTitle, children }) => {
  const [open, setOpen] = useState(false)
  const IconComponent = open
    ? IconArrowIosUpwardOutline
    : IconArrowIosDownwardOutline

  return (
    <S.AcordionContainer>
      <S.HeadContainer onClick={() => setOpen(!open)}>
        <S.Head>{headTitle}</S.Head>
        <S.IconArrowsContainer>
          <IconComponent color={'tundora'} size={'xxs'} />
        </S.IconArrowsContainer>
      </S.HeadContainer>
      {open && <>{children}</>}
    </S.AcordionContainer>
  )
}

const TableFilter: FC<{
  filterOptions: { id: number; descripcion: string }[]
  filterSubmitHandler: (document: string, options: TOptions[]) => void
}> = ({ filterOptions, filterSubmitHandler }) => {
  const defaultOptions = filterOptions.map(({ id, descripcion }) => ({
    id,
    descripcion,
    isChecked: false,
  }))

  const [showFilter, setShowFilter] = useState(false)
  const [document, setDocument] = useState('')
  const [options, setOptions] = useState(defaultOptions)

  const checkedOptions = options.filter(options => options.isChecked)

  const resetOptions = () => {
    setDocument('')
    setOptions(defaultOptions)
  }

  const handleCheckbox = (e: any) => {
    const auxOptions = JSON.parse(JSON.stringify(options))

    const selectedOption: TOptions = auxOptions.find(
      ({ id }: { id: number }) => id === +e.target.id
    )
    const selectedOptionIndex: number = auxOptions.findIndex(
      ({ id }: { id: number }) => id === +e.target.id
    )

    selectedOption.isChecked = !selectedOption?.isChecked
    auxOptions[selectedOptionIndex] = selectedOption

    setOptions(auxOptions)
  }

  return (
    <S.TableFilterContainer>
      <Button outline onClick={() => setShowFilter(!showFilter)}>
        Filtrar
      </Button>
      <S.FilterContainer showFilter={showFilter}>
        <S.TitleContainer>
          <S.Title>Filtrar por:</S.Title>
          <S.ClearLink onClick={resetOptions}>Limpiar</S.ClearLink>
        </S.TitleContainer>
        <Acordion headTitle="DNI">
          <S.InputContainer>
            <S.Input
              value={document}
              onChange={e => {
                setDocument(e.target.value)
              }}
            />
          </S.InputContainer>
        </Acordion>
        <Acordion headTitle="Estado">
          <S.OptionsContainer>
            {(options || []).map(option => (
              <S.Option key={option.id}>
                <S.Checkbox
                  type="checkbox"
                  id={`${option.id}`}
                  checked={option.isChecked}
                  onChange={handleCheckbox}
                />
                <S.Label htmlFor={`${option.id}`}>{option.descripcion}</S.Label>
              </S.Option>
            ))}
          </S.OptionsContainer>
        </Acordion>
        <Button
          onClick={() => {
            filterSubmitHandler(document, checkedOptions)
          }}
        >
          Aplicar
        </Button>
      </S.FilterContainer>
    </S.TableFilterContainer>
  )
}

export default TableFilter
