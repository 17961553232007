import ApiProd from 'api/prod'
import ApiDev from 'api/dev'
import ApiLocal from 'api/local'

const appEnv = process.env.REACT_APP_ENV
const backofficeApi = process.env.REACT_APP_BACKOFFICE_API || ''
const ventaApi = process.env.REACT_APP_VENTA_API || ''

const httpClient = (() => {
  if (appEnv === 'production') return new ApiProd(backofficeApi)
  if (appEnv === 'dev') return new ApiDev(backofficeApi)
  if (appEnv === 'local') return new ApiLocal()

  return new ApiLocal()
})()
const httpVentaDigitalClient = (() => {
  if (appEnv === 'production') return new ApiProd(ventaApi)
  if (appEnv === 'dev') return new ApiDev(ventaApi)
  if (appEnv === 'local') return new ApiLocal()

  return new ApiLocal()
})()
const localClient = (() => {
  if (appEnv === 'production') new ApiProd(process.env.PUBLIC_URL)
  if (appEnv === 'dev') return new ApiDev('../')
  if (appEnv === 'local') return new ApiDev('../')

  return new ApiDev('../')
})()

export { httpClient, httpVentaDigitalClient, localClient }
