import React, { FC, ReactNode } from 'react'
import { Formik, FormikHelpers, Form } from 'formik'

import { Province, State } from 'domain/entities'
import { IAfilliationsDTO } from 'domain/responseModel'

import { TForm, initialValues } from 'app/Pages/Afilliations/constants'
import FormInputs from 'app/Pages/Afilliations/AfilliationForm/formInputs'
import { afilliationSchema } from 'app/Pages/Afilliations/AfilliationForm/AfilliationFormValidation'
import * as S from 'app/Pages/Afilliations/styles'

import { ISelectFormat, Pagination } from 'app/components'

import { strings } from 'app/constants/strings'

const {
  forms: {
    fieldsName: { page, resetPageOnSubmit },
  },
} = strings

export interface IAfilliationForm {
  afilliations: IAfilliationsDTO
  provincies: Province[]
  states: State[]
  onSubmit: (values: TForm) => void
  onChangeAdvisor: (codeOrName: string) => void
  advisors: ISelectFormat[]
  advisorsIsFetching: boolean
}

const AfilliationForm: FC<
  IAfilliationForm & {
    children: ReactNode
    paginationPageCount: number
  }
> = ({
  afilliations,
  provincies,
  states,
  onSubmit,
  onChangeAdvisor,
  advisors,
  advisorsIsFetching,
  paginationPageCount,
  children,
}) => {
  return (
    <S.PageContainer>
      <Formik
        initialValues={initialValues}
        validationSchema={afilliationSchema}
        onSubmit={(values: TForm, { setSubmitting }: FormikHelpers<TForm>) => {
          setSubmitting(false)
          onSubmit(values)
        }}
      >
        {props => (
          <Form>
            <FormInputs
              {...props}
              afilliations={afilliations}
              provincies={provincies}
              states={states}
              onSubmit={onSubmit}
              onChangeAdvisor={onChangeAdvisor}
              advisors={advisors}
              advisorsIsFetching={advisorsIsFetching}
            />
            {children}
            <S.FootFields>
              <Pagination
                pageFieldName={page}
                resetFieldName={resetPageOnSubmit}
                setFieldValue={props.setFieldValue}
                pageCount={paginationPageCount}
                submit={props.submitForm}
              />
            </S.FootFields>
          </Form>
        )}
      </Formik>
    </S.PageContainer>
  )
}

export default AfilliationForm
