import { User } from 'domain/entities'

import { isString, isNonEmptyArrayOfStrings } from 'helpers'

interface IUserDTO {
  name: string
  funcionalidades: string[]
  roles: string[]
  token: string
  usuario: string
}

export const userEntityToModel = (raw: IUserDTO): User => {
  return {
    funcionalidades: isNonEmptyArrayOfStrings(
      raw.funcionalidades,
      'funcionalidades'
    ),
    name: isString(raw.name, 'name'),
    roles: isNonEmptyArrayOfStrings(raw.roles, 'roles'),
    token: isString(raw.token, 'token'),
    usuario: isString(raw.usuario, 'usuario'),
  }
}
