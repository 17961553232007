export const strings = {
  loginScreen: {},
  general: {
    hideSearch: 'Ocultar búsqueda',
    showSearch: 'Mostrar búsqueda',
    searchPolicies: 'Búsqueda de Pólizas',
    filterSearch: 'Filtrar búsqueda',
    filterAfilliations: 'Filtrar',
  },
  forms: {
    fieldsTitle: {
      userTitle: 'Nombre',
      passwordTitle: 'Clave',
      pageTitle: 'Página',
      quantityTitle: 'Cantidad',
      dniTitle: 'DNI',
      estadoBackOfficeTitle: 'Estado BackOffice',
      provinceTitle: 'Provincia',
      fechaVigenciaTitle: 'Fecha de vigencia',
      fechaCotizacionTitle: 'Fecha de cotización',
      codAsesorTitle: 'Cod Asesor',
      sharedTitle: 'Compartido',
      stateTitle: 'Estado',
      contractTitle: 'Tipo de contrato',
      acuerdoTitle: 'Tipo de acuerdo',
      dateTitle: 'Fecha',
      advisorTitle: 'Asesor',
      idsSellChannelTitle: 'Canales de Venta',
      observationsTitle: 'Tipo de observación',
      stateUpdateTitle: 'Estado',
      documentationStatusTitle: '¿Documentación enviada?',
      stateUpdateObservationTitle: 'Observaciones',
      detailTitle: 'Detalle',
      cuitTitle: 'CUIT',
      afiliationTypeTitle: 'Razón social',
      tipoDeDocumentacionTitle: 'Tipo de documentación',
      tipoDeObservacion: 'Tipo de observación',
    },
    fieldsHint: {
      userHint: 'Ingrese su nombre de usuario',
      passwordHint: 'Ingrese su clave',
      amountPagesHint: 'Cantidad de paginas',
      quantityHint: 'Cantidad de registros a mostrar',
      dniHint: 'Ingrese un DNI',
      estadoBackOfficeHint: 'Ingrese estado en BackOffice',
      provinceHint: 'Seleccione una provincia',
      fechaVigenciaHint: 'Fecha de vigencia',
      fechaCotizacionHint: 'Fecha de cotización',
      codAsesorHint: 'Codigo de asesor',
    },
    fieldsName: {
      userName: 'userName',
      password: 'password',
      page: 'pagina',
      quantity: 'cantidad',
      dni: 'dni',
      estadoBackOffice: 'estadoBackOffice',
      province: 'provincia',
      fechaVigencia: 'fechaVigencia',
      fechaCotizacion: 'fechaCotizacion',
      codAsesor: 'codAsesor',
      exportList: 'exportList',
      shared: 'compartido',
      idProvince: 'idProvincia',
      idsLeadState: 'idsEstado',
      searchDate: 'fechaBusqueda',
      advisors: 'asesores',
      resetPageOnSubmit: 'resetPageOnSubmit',
      advisor: 'advisor',
      idsSellChannel: 'idsSellChannel',
      observations: 'observations',
      state: 'state',
      detail: 'detail',
      cuit: 'cuit',
      afiliationType: 'razonSocial',
      idState: 'idState',
      listaEstados: 'listaEstados',
    },
    requiredFields: {
      userName: 'Debe ingresar el usuario',
      password: 'Debe ingresar su clave',
      pageRequired: 'Debe ingresar un número de pagina',
      policiesRequired: 'Debe ingresar una cantidad de polizas a listar',
      provinceRequired: 'Debe seleccionar una provincia',
      requiredField: 'Campo requerido',
    },
    fields: {
      userName: 'Usuario',
      password: 'Contraseña',
    },
    validationFields: {
      userName: 'El usuario debe tener entre 2 y 20 caracteres',
      password: 'La clave debe tener entre 2 y 20 caracteres',
      minAmountOfRecords: (amount: number) =>
        `La cantidad de registros no puede ser menor a ${amount}`,
      maxAmountOfRecords: (amount: number) =>
        `La cantidad de registros no puede ser mayor a ${amount}`,
      dniMinNumber: 'Número de documento no válido',
      estadoBackOfficeMinNumber: 'Número de estado no válido',
      date: 'Debe ingresar una fecha válida',
      mustBeExcelFile: 'Debe ser un archivo Excel',
    },
  },
  importDataForm: {
    downloadTemplate: 'Descargar Template',
    downloadProvAndLoc: 'Descargar Prov y Loc',
    importData: 'Importar datos',
    uploadData: 'Ingresar datos',
  },
}
