import React, { useEffect } from 'react'

import {
  getFamilyGroupDetail,
  selectFamilyGroupDetail,
} from 'app/slices/companyPAD'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import { FamilyGroupDetail } from 'domain/entities/familyGroupPADDetail'
import { selectProvincies } from 'app/slices/provinceSlice'

import HolderDataCompanyScreen from './HolderDataCompanyScreen'

import * as C from 'app/components'

const HolderScreen = () => {
  const params = useParams<{ guid: string }>()
  const dispatch = useDispatch()
  const history = useHistory()

  // selectors
  const familyGroupDetail: {
    isFetching: boolean
    familyGroupDetail: FamilyGroupDetail | null
  } = useSelector(selectFamilyGroupDetail)

  const provincies = useSelector(selectProvincies)

  useEffect(() => {
    dispatch(getFamilyGroupDetail(params))
  }, [dispatch, params])

  const handleOnPressBack = () => history.goBack()

  return (
    <C.LoadingSpinner isLoading={familyGroupDetail.isFetching}>
      <HolderDataCompanyScreen
        onPressBack={handleOnPressBack}
        provincies={provincies}
        familyGroupDetail={familyGroupDetail.familyGroupDetail}
        params={params}
      />
    </C.LoadingSpinner>
  )
}

export default HolderScreen
