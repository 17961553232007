import { fields } from 'app/constants'

import { TAdvisorListViewModel } from 'app/Pages/AdvisorAgenda/types'

export const tableDesktopHeaders: string[] = [
  fields.day,
  fields.assignedSupervisor,
  fields.contactNumber,
]

export const tableMobileHeaders: string[] = [
  fields.day,
  fields.assignedSupervisor,
  fields.contactNumber,
]

type TInitialValues = {
  fechaBusqueda: Date
  asesores: TAdvisorListViewModel[]
}

export const initialValues = ({
  fechaBusqueda = new Date(),
  asesores,
}: TInitialValues) => {
  return {
    fechaBusqueda,
    asesores,
    exportList: 0,
  }
}
