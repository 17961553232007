import {
  changeStateFGParams,
  changeStatusGFParams,
  createCompanyParam,
  fileParams,
  getCompaniesRequestModel,
  getCompanyRequestParam,
  getCompanyStateDisponiblesParam,
  getCompanyStateParam,
  getFamilyGroupDetailParam,
} from 'domain/requestModel/companyPAD'
import { ICompanyEntityGateway } from 'domain/entityGateways/ICompanyPADEntityGateway'
import { FamilyGroupDetail } from 'domain/entities/familyGroupDetail'

export class UseCaseCompany implements ICompanyEntityGateway {
  private entityGateway: ICompanyEntityGateway

  constructor(entityGateway: ICompanyEntityGateway) {
    this.entityGateway = entityGateway
  }

  public async getCompanies(params: getCompaniesRequestModel) {
    const companies = await this.entityGateway.getCompanies(params)
    return companies
  }

  public async getCompany(param: getCompanyRequestParam): Promise<any> {
    const company = await this.entityGateway.getCompany(param)

    return company
  }

  public async getExportCompanyList(): Promise<any> {
    const companyList = await this.entityGateway.getExportCompanyList()

    return companyList
  }

  public async getCompanyStates(param: getCompanyStateParam): Promise<any> {
    const states = await this.entityGateway.getCompanyStates(param)

    return states
  }

  public async getCompanyStatesDisponibles(
    param: getCompanyStateDisponiblesParam
  ): Promise<any> {
    const states = await this.entityGateway.getCompanyStatesDisponibles(param)

    return states
  }

  public async getFamilyGroupDetail(
    param: getFamilyGroupDetailParam
  ): Promise<FamilyGroupDetail> {
    const familyGroupDetail = await this.entityGateway.getFamilyGroupDetail(
      param
    )

    return familyGroupDetail
  }

  public async changeStateObsFG(params: changeStateFGParams): Promise<any> {
    const newStateObs = await this.entityGateway.changeStateObsFG(params)

    return newStateObs
  }

  public async changeStatusGF(params: changeStatusGFParams): Promise<any> {
    const newStateObs = await this.entityGateway.changeStatusGF(params)

    return newStateObs
  }

  public async saveDocumentation(params: fileParams): Promise<any> {
    const newStateObs = await this.entityGateway.saveDocumentation(params)

    return newStateObs
  }

  public async createCompany(param: createCompanyParam): Promise<any> {
    const newCompany = await this.entityGateway.createCompany(param)

    return newCompany
  }
}
