import React from 'react'
import { Field } from 'formik'
import styled, { css } from 'styled-components'

import { baseComponent } from 'app/components/ui/styles'

const InputBasic = styled(({ warning, icon, autoWidth, ...props }) => (
  <Field {...props} />
)).attrs(props => ({
  name: props.name || '',
}))`
  ${baseComponent};
  background: transparent;
  border: none;
  border-bottom: 1px solid ${props => props.theme.colors.cornflowerBlue};
  color: ${props => props.theme.colors.darkGreyBlue};
  ::placeholder {
    color: ${props => props.theme.colors.placeholderGrey};
  }
  border-radius: 0;
  outline-color: ${props => props.theme.colors.cornflowerBlue};
  ${props =>
    props.warning &&
    css`
      color: ${props => props.theme.colors.carmine};
      border-bottom-color: ${props => props.theme.colors.carmine};
    `}
  ${props =>
    props.icon &&
    css`
      padding-left: 20px;
    `}

  ${props =>
    props.autoWidth &&
    css`
      width: auto;
    `}
  &:disabled {
    border-bottom-color: ${props => props.theme.colors.grey};
    cursor: not-allowed;
  }
`

export default InputBasic
