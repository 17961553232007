import React from 'react'
import * as C from 'app/components'
import * as S from '../styles'

import {
  getFormatedDate,
  DayMonthYearWithLeadingZerosSlashedWithHrAndDash,
} from 'helpers'

const commentType: { [key: string]: string } = {
  0: 'Observación',
  1: 'Error',
  2: 'Llamado',
  3: 'CambioDeEstado',
  4: 'Evento',
}

const Observations = ({ data, showObservations, handleBtn }: any) => {
  return (
    <>
      {data?.map((item: any, index: any) => {
        const commentDate = getFormatedDate(
          item.fecha,
          DayMonthYearWithLeadingZerosSlashedWithHrAndDash
        )

        return (
          <div
            key={index}
            style={{
              display: !showObservations && index >= 1 ? 'none' : 'block',
            }}
          >
            <S.CommentHead>
              <C.Text color="chathamsBlue" weight="md">
                {commentType[item.tipo]}
              </C.Text>
              <p>
                Usuario:{' '}
                <span>
                  <strong>{item.usuario}</strong>
                </span>
              </p>
            </S.CommentHead>
            <C.Text color="chathamsBlue" size="md">
              {item.observacion}
            </C.Text>
            <C.Text size="md">
              <span>{commentDate}</span>
            </C.Text>
          </div>
        )
      })}
      {data && data.length > 1 && (
        <C.Text
          margin="10px 0 0 0"
          display="block"
          textAlign="center"
          color="mariner"
          weight="md"
          underlined
          onClick={handleBtn}
        >
          {!showObservations ? 'Ver más' : 'Ver menos'}
        </C.Text>
      )}
    </>
  )
}

export default Observations
