import { colors } from 'app/styles/colors'
import { breakPoints } from 'app/styles/breakPoints'

const iconSizes = {
  xxs: '16px',
  xs: '20px',
  sm: '24px',
  md: '28px',
  mdlg: '32px',
  lg: '36px',
}

// in rems if root font = 16px = 1rem;
const fontSizes = {
  xxs: '0.625rem',
  xs: '0.75rem',
  sm: '0.875rem',
  md: '1rem',
  mdLg: '1.125rem',
  lg: '1.25rem',
  xl: '1.375rem',
}

const fontWeights = {
  xxs: 200,
  xs: 300,
  sm: 400,
  m: 600,
  md: 700,
  lg: 800,
}

const theme = {
  colors,
  breakPoints,
  iconSizes,
  fontSizes,
  fontWeights,
}

export default theme
