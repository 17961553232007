import {
  Policy,
  Advisor,
  PrePolicy,
  Associate,
  Beneficiary,
  Answer,
  Djs,
  PolicyState,
} from 'domain/entities'
import { IPolicyResultsDTO } from 'domain/responseModel/policy/interfaces'

import { isString, isNumber, isDateAndNotEmpty, isBoolean } from 'helpers'

export const mapPolicysResults = (policies: Policy[]): IPolicyResultsDTO[] => {
  return policies.map((insurance: Policy) => ({
    id: isNumber(insurance.id, 'id'),
    idSocio: isNumber(insurance.idSocio, 'idSocio'),
    dni: isString(insurance.dni, 'dni'),
    nombre: isString(insurance.nombre, 'nombre'),
    apellido: insurance.apellido,
    credencial: insurance.credencial,
    provincia: insurance.provincia,
    estadoBackOffice: insurance.estadoBackOffice,
    fechaVigencia:
      insurance.fechaVigencia &&
      isDateAndNotEmpty(insurance.fechaVigencia, 'fechaVigencia'),
    fechaCotizacion:
      insurance.fechaCotizacion &&
      isDateAndNotEmpty(insurance.fechaCotizacion, 'fechaCotizacion'),
    asesores: insurance.asesores?.map((advisor: Advisor) => ({
      codAsesor: isString(advisor.codAsesor, 'codAsesor'),
      nombre: advisor.nombre,
      porcentaje:
        advisor.porcentaje && isNumber(advisor.porcentaje, 'porcentaje'),
    })),
    detalle: isNumber(insurance.id, 'id'),
  }))
}

export const mapPolicy = (prePolicy: PrePolicy): PrePolicy => ({
  calle: isString(prePolicy.calle, 'calle', false),
  cbu: isNumber(prePolicy.cbu, 'cbu', false),
  codpostal: isString(prePolicy.codpostal, 'codpostal', false),
  cupon: isNumber(prePolicy.cupon, 'cupon', false),
  fechaVigencia:
    prePolicy.fechaVigencia &&
    isDateAndNotEmpty(prePolicy.fechaVigencia, 'fechaVigencia'),
  formaPago: isNumber(prePolicy.formaPago, 'formaPago', false),
  fuma: isBoolean(prePolicy.fuma, 'fuma'),
  id: isNumber(prePolicy.id, 'id'),
  localidad: isString(prePolicy.localidad, 'localidad', false),
  nroTarjeta: isNumber(prePolicy.nroTarjeta, 'nroTarjeta', false),
  numero: isString(prePolicy.numero, 'numero', false),
  piso: isString(prePolicy.piso, 'piso', false),
  poliza: isString(prePolicy.poliza, 'poliza', false),
  tipoDoc: isNumber(prePolicy.tipoDoc, 'tipoDoc', false),
  estadoBackOffice: isNumber(
    prePolicy.estadoBackOffice,
    'estadoBackOffice',
    false
  ),
})

export const mapAssociate = (associate: Associate): Associate => ({
  altura: isNumber(associate.altura, 'altura', false),
  apellido: isString(associate.apellido, 'apellido', false),
  credencial: isString(associate.credencial, 'credencial', false),
  cuil: isString(associate.cuil, 'cuil', false),
  dni: isString(associate.dni, 'dni'),
  dniTitular: isNumber(associate.dniTitular, 'dniTitular', false),
  eMail: isString(associate.eMail, 'eMail', false),
  edad: isNumber(associate.edad, 'edad', false),
  estadoCivil: isNumber(associate.estadoCivil, 'estadoCivil', false),
  fechaDeNacimiento: isString(
    associate.fechaDeNacimiento,
    'fechaDeNacimiento',
    false
  ),
  id: isNumber(associate.id, 'id'),
  nombre: isString(associate.nombre, 'nombre'),
  peso: isNumber(associate.peso, 'peso', false),
  sexo: isNumber(associate.sexo, 'sexo'),
  smEmail: isString(associate.smEmail, 'smEmail', false),
  smFirstName: isString(associate.smFirstName, 'smFirstName', false),
  smLastName: isString(associate.smLastName, 'smLastName', false),
  telefonoMovil: isString(associate.telefonoMovil, 'telefonoMovil', false),
  tieneHijos: isString(associate.tieneHijos, 'tieneHijos', false),
  tipoSocio: isNumber(associate.tipoSocio, 'tipoSocio'),
})

export const mapBeneficiaries = (
  beneficiaries: Beneficiary[]
): Beneficiary[] => {
  return beneficiaries.map((beneficiary: Beneficiary) => ({
    apellido: isString(beneficiary.apellido, 'apellido', false),
    documento: isString(beneficiary.documento, 'documento'),
    genero: isNumber(beneficiary.genero, 'genero', false),
    id: isNumber(beneficiary.id, 'id'),
    nombre: isString(beneficiary.nombre, 'nombre'),
    parentesco: isNumber(beneficiary.parentesco, 'parentesco'),
    porcentaje: isNumber(beneficiary.porcentaje, 'porcentaje'),
    tipoDoc: isNumber(beneficiary.tipoDoc, 'tipoDoc'),
  }))
}

export const mapDjs = (djs: Djs): Djs => ({
  altura: isNumber(djs.altura, 'altura'),
  apellido: isString(djs.apellido, 'apellido', false),
  dni: isString(djs.dni, 'dni'),
  imc: isString(djs.imc, 'imc'),
  imcValor: isNumber(djs.imcValor, 'imcValor'),
  nombre: isString(djs.nombre, 'nombre'),
  peso: isNumber(djs.peso, 'peso'),
  respuestas: djs.respuestas.map(
    (answer: Answer): Answer => ({
      idPregunta: isNumber(answer.idPregunta, 'idPregunta'),
      opciones: answer.opciones,
      pregunta: isString(answer.pregunta, 'pregunta'),
      resultado: isBoolean(answer.resultado, 'resultado'),
    })
  ),
  tipoSocio: isNumber(djs.tipoSocio, 'tipoSocio'),
})

export const statesByPolicyId = (states?: PolicyState[]): PolicyState[] => {
  return states
    ? states.map((state: PolicyState) => ({
        id: isNumber(state.id, 'id'),
        descripcion: isString(state.descripcion, 'descripcion'),
      }))
    : []
}
