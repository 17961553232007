import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import Brand from 'app/components/Navbar/Brand'
import MenuBurger from 'app/components/Navbar/MenuBurger'
import CollapseMenu from 'app/components/Navbar/CollapseMenu'
import { IconPowerSettingsNew } from 'app/components/Icons'
import { pages } from 'app/constants'

import {
  NavBar,
  FlexContainer,
  NavLinks,
  BurgerWrapper,
  Line,
  SeparatorNavbar,
} from 'app/components/Navbar/styles'
import { Text } from 'app/components/ui'
import { Row } from 'app/components'

export interface INavBar {
  name: string
  path: string
  permission: string
}

type NavBarProps = {
  path: string
  navBarOptions: INavBar[]
  handleOnPressLogout: () => void
}

const Navbar = (props: NavBarProps) => {
  const [navbarOpen, setNavbarOpen] = useState(false)
  const [indexSelected, setIndexSelected] = useState(-1)

  const { backoffice } = pages

  const handleNavbar = () => setNavbarOpen(!navbarOpen)
  const setFontWeight = (index: number) =>
    index === indexSelected ? 'md' : 'sm'

  return (
    <>
      <NavBar>
        <FlexContainer>
          <Link to={backoffice.path} style={{ textDecoration: 'none' }}>
            <Brand />
          </Link>
          <NavLinks>
            <Row>
              {props.navBarOptions.map((page, index) => (
                <Link
                  onClick={() => setIndexSelected(index)}
                  key={index}
                  to={`${props.path}${page.path}`}
                >
                  <Text weight={setFontWeight(index)} color={'white'}>
                    {page.name}
                  </Text>
                </Link>
              ))}
            </Row>
            <div id="logout" onClick={props.handleOnPressLogout}>
              <IconPowerSettingsNew
                centered={false}
                color={'white'}
                size={'xs'}
              />
              <Text weight={'md'} color={'white'}>
                Salir
              </Text>
            </div>
          </NavLinks>
          <BurgerWrapper>
            <MenuBurger navbarState={navbarOpen} handleNavbar={handleNavbar} />
          </BurgerWrapper>
        </FlexContainer>
      </NavBar>
      <Line />
      <SeparatorNavbar />
      <CollapseMenu
        navbarState={navbarOpen}
        handleNavbar={handleNavbar}
        navBarOptions={props.navBarOptions}
        pathTo={props.path}
        handleOnPressLogout={props.handleOnPressLogout}
      />
    </>
  )
}

export default Navbar
