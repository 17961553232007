import React, { FC } from 'react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Formik, Form } from 'formik'
import { Button, Text } from 'app/components'
import { SelectAsync } from 'app/components'
import constants, { initialValues } from './constants'

import { selectChangesObsIsFetching } from 'app/slices/company'

import * as S from './styles'
import * as C from 'app/components'

import { strings } from 'app/constants/strings'
import { colors } from 'app/styles/colors'

const {
  forms: {
    fieldsTitle: { tipoDeObservacion },
  },
} = strings

interface IStatesModal {
  onSubmit: (values: any) => void
  show: boolean
  setShow: (values: any) => void
  idTipoEstado: number
}

const ObservationModal: FC<IStatesModal> = ({ onSubmit, show, setShow }) => {
  const params = useParams<{ guid: string }>()
  const initVal = { ...initialValues, guid: params ? params.guid : '' }
  const [enabled, setEnabled] = useState(true)
  const isFetchingChanges: boolean = useSelector(selectChangesObsIsFetching)
  const [selectedValue, setSelectedValue] = useState<C.ISelectFormat | null>(
    null
  )

  return (
    <>
      <Formik
        initialValues={initVal}
        onSubmit={(values: any, { setSubmitting }) => {
          setSubmitting(false)
          onSubmit(values)
        }}
      >
        {(props: {
          values: any
          errors: {
            estado: boolean
            observaciones: boolean
          }
          touched: {
            estado: boolean
            observaciones: boolean
          }
          onChange: any
          handleChange: any
          submitForm: any
          setFieldValue: any
          resetForm: any
        }) => (
          <Form>
            <S.ModalBg show={show}>
              <S.ModalBoxObservation>
                {isFetchingChanges ? (
                  <C.LoadingSpinner isLoading={isFetchingChanges} />
                ) : (
                  <>
                    <S.Title>
                      <Text
                        textAlign={'center'}
                        size={'xl'}
                        color={'cornflowerBlue'}
                        weight={'md'}
                      >
                        Nueva observación
                      </Text>
                    </S.Title>
                    <S.ContainerBox>
                      <S.InputsBox>
                        <SelectAsync
                          onChange={(option: any) => {
                            props.setFieldValue(
                              'eventoAfiliacion',
                              option ? option.value : null
                            )
                            setEnabled(false)
                            setSelectedValue(option)
                          }}
                          isClearable
                          title={tipoDeObservacion}
                          value={selectedValue}
                          name={'eventoAfiliacion'}
                          id={'eventoAfiliacion'}
                          placeholder="Seleccionar"
                          options={constants.observationsType}
                          color={colors.cornflowerBlue}
                          fullWidth
                        />
                        {props.values.observacion === null ? (
                          <S.ErrorText>
                            {setEnabled(true)}
                            Seleccionar
                          </S.ErrorText>
                        ) : null}
                      </S.InputsBox>
                      <S.InputsBox>
                        <C.Text color="cornflowerBlue" weight="s" size="s">
                          Detalle
                          <C.TextArea
                            id="observacion"
                            name="observacion"
                            onChange={(event: any) => {
                              props.setFieldValue(
                                'observacion',
                                event ? event.target.value : null
                              )
                            }}
                            value={props.values.observacion}
                            title="Observaciones"
                            color={colors.cornflowerBlue}
                            fullWidth
                          />
                        </C.Text>
                      </S.InputsBox>
                    </S.ContainerBox>
                    <S.ButtonsBox>
                      <Button
                        outline={true}
                        onClick={() => {
                          setSelectedValue(null)
                          setShow(!show)
                          props.resetForm()
                        }}
                        centered
                      >
                        Cancelar
                      </Button>
                      <Button type="submit" disabled={enabled} centered>
                        Guardar
                      </Button>
                    </S.ButtonsBox>
                  </>
                )}
              </S.ModalBoxObservation>
            </S.ModalBg>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default ObservationModal
