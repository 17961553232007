import styled from 'styled-components'

import * as C from 'app/components'

export const HolderScreenContainer = styled(C.PagesContainer)`
  padding: 1rem 1rem;
`

export const HolderScreenContent = styled(C.PagesContainer)`
  display: flex;
  gap: 1rem;
  justify-content: center;
  padding: 3rem 0;

  > div {
    width: 668px;
  }
`

//historial
export const HistoryContainer = styled.div`
  padding: 1rem 0.9rem 0 0;
`

export const HistoryDualColumn = styled.div`
  display: flex;
  justify-content: space-between;
`

export const HistoryButton = styled.div`
  padding-top: 2rem;
  display: flex;
  justify-content: center;
`

export const RecentStateTitle = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.5rem;
`

export const PreviousStatesTitle = styled.div`
  margin-top: 2rem;
`

export const UserState = styled.div`
  display: flex;
  gap: 0.2rem;
`

export const PreviousStatesParent = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 0.5rem;
`

export const PreviousStatesChildrens = styled.div`
  padding-top: 0.5rem;
`

export const FirstColumn = styled.div``
export const SecondColumn = styled.div``
export const GroupData = styled.div`
  padding: 1rem 0;
`
export const DjsRows = styled.div`
  display: flex;
  justify-content: space-between;
  width: 95%;
  margin: auto;

  > span:first-child {
    width: 80%;
  }
`
export const AcordionContent = styled.div`
  > span {
    padding: 1rem 0;
  }
`

export const ModalBg = styled.div<{ show: boolean }>`
  display: ${props => (props.show ? 'none' : 'flex')};
  justify-content: center;
  background: rgba(0, 56, 126, 0.75);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 3.3rem;
  margin-left: -1rem;
`

export const ModalBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-color: white;
  gap: 2rem;
  margin-top: 4rem;
  width: 40rem;
  height: 30rem;
  border: 1px solid ${props => props.theme.colors.cornflowerBlue};
  border-radius: 5px;
`

export const InputsBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6rem;
`

export const Title = styled.div`
  margin-top: 1rem;
`
export const ButtonsBox = styled.div`
  display: flex;
  gap: 1rem;
`

export const ErrorText = styled.div`
  color: red;
  position: fixed;
  margin-top: 3.2rem;
`

export const StateTitle = styled.div`
  display: flex;
  margin-left: 26rem;
  margin-top: -2.5rem;
`

export const CommentHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0 0;
`

export const ObservationFormContainer = styled.div`
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`

export const ButtonsContainer = styled(C.Row)`
  > button {
    margin: 0px 20px;
    align-items: center;
    justify-content: center;
  }
`

export const MessageContainer = styled.div`
  width: 95%;
  position: absolute;
  bottom: 0;
`

export default {
  HolderScreenContainer,
  HolderScreenContent,
  HistoryContainer,
  HistoryDualColumn,
  HistoryButton,
  RecentStateTitle,
  PreviousStatesTitle,
  PreviousStatesChildrens,
  FirstColumn,
  SecondColumn,
  ModalBg,
  ModalBox,
  InputsBox,
  ButtonsBox,
  Title,
  StateTitle,
  GroupData,
  DjsRows,
  AcordionContent,
  CommentHead,
  ErrorText,
  MessageContainer,
}
