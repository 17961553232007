import React, { ReactNode } from 'react'

import { useAccessControl } from 'app/hooks/useAccessControl'

import { Inauthorized } from 'app/components'

import { TFunctionalitiesCodes, functionalitiesCodes } from 'app/constants'

const defaultProps = {
  displayInauthorizedComponent: true,
}

type TPermission = {
  children: ReactNode
  permissionRequired: TFunctionalitiesCodes
  displayInauthorizedComponent?: boolean
} & typeof defaultProps

const Permission = (props: TPermission) => {
  const hasAccess = useAccessControl([
    functionalitiesCodes[props.permissionRequired],
  ])

  return (
    <>
      {hasAccess ? (
        props.children
      ) : props.displayInauthorizedComponent ? (
        <Inauthorized />
      ) : null}
    </>
  )
}

Permission.defaultProps = defaultProps

export default Permission
