import React, { useState, useEffect } from 'react'
import { ErrorMessage } from 'formik'

import AsyncSelect from 'react-select/async'

import {
  InputTitle,
  InputHint,
  ErrorField,
  SelectContainer,
} from 'app/components/ui/styles'

export interface ISelectFormat {
  value: any
  label: string
}

export type TSelectAsync = {
  value?: any
  options: ISelectFormat[]
  warning?: boolean | ''
  onChange: (option: any) => void
  id: number | string
  title?: string
  hint?: string
  name: string
  placeholder?: string
  isDisabled?: boolean
  isLoading?: boolean
  isClearable?: boolean
  isMulti?: boolean
  color?: string
  fullWidth?: boolean
  onInputChange?: (v: any) => void
} & typeof SelectAsyncDefaultProps

export const SelectAsyncDefaultProps = {
  title: '',
  hint: '',
  placeholder: '',
  isDisabled: false,
  isLoading: false,
  isClearable: false,
  isMulti: false,
  onInputChange: (v: any) => {},
}

// warning on this component will be fixed in next release
// https://github.com/JedWatson/react-select/issues/3915
// https://github.com/JedWatson/react-select/issues/4293#issuecomment-743781662
const SelectAsync = (props: TSelectAsync) => {
  const [selectOptions, setSelectOptions] = useState<ISelectFormat[]>([
    {
      label: '',
      value: '',
    },
  ])

  useEffect(() => {
    setSelectOptions(props.options)
  }, [props.options])

  const handleInputChange = (newValue: string) => {
    const value = newValue.replace(/\W/g, '')

    value && props.onInputChange(value)

    return value
  }

  const filterInputValues = (inputValue: string) => {
    return selectOptions.filter((i: any) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    )
  }

  const loadOptions = (inputValue: any) =>
    new Promise(resolve => {
      setTimeout(() => {
        resolve(filterInputValues(inputValue))
      }, 1000)
    })

  return (
    <SelectContainer
      warning={props.warning}
      disabled={props.isDisabled}
      fullWidth={props.fullWidth}
    >
      {props.title && (
        <InputTitle color={props.color}>{props.title}</InputTitle>
      )}
      {props.hint && <InputHint>{props.hint}</InputHint>}
      <AsyncSelect
        isMulti={props.isMulti}
        value={props.value}
        isLoading={props.isLoading || !props.options}
        classNamePrefix={'react-select'}
        cacheOptions
        isClearable={props.isClearable}
        defaultOptions={selectOptions}
        loadOptions={loadOptions}
        onInputChange={handleInputChange}
        onChange={props.onChange}
        isDisabled={props.isDisabled}
        placeholder={props.placeholder}
      />
      <ErrorMessage name={props.name} component={ErrorField} />
    </SelectContainer>
  )
}

SelectAsync.defaultProps = SelectAsyncDefaultProps

export default React.memo(SelectAsync)
