import { IAfilliationEntityGateway } from 'domain/entityGateways/IAfilliationEntityGateway'
import {
  TPostAfilliationStateParams,
  TGetAfilliationsParams,
  TPostEventParam,
  TGetReportMedicArea,
} from 'domain/requestModel'

export class UseCaseAfilliation implements IAfilliationEntityGateway {
  private entityGateway: IAfilliationEntityGateway

  constructor(entityGateway: IAfilliationEntityGateway) {
    this.entityGateway = entityGateway
  }

  public async getAfilliations(params: TGetAfilliationsParams) {
    const result = await this.entityGateway.getAfilliations(params)

    return result
  }

  public async getAfilliation(params: any) {
    const result = await this.entityGateway.getAfilliation(params)

    return result
  }

  public async postAfilliationStateUpdate(params: TPostAfilliationStateParams) {
    const result = await this.entityGateway.postAfilliationStateUpdate(params)

    return result
  }

  public async postEvent(params: TPostEventParam) {
    const result = await this.entityGateway.postEvent(params)

    return result
  }

  public async getReportMedicArea(param: TGetReportMedicArea): Promise<any> {
    const result = await this.entityGateway.getReportMedicArea(param)

    return result
  }

  public async getDetailMedicArea(param: TGetReportMedicArea): Promise<any> {
    const result = await this.entityGateway.getDetailMedicArea(param)

    return result
  }
}
