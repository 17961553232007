import React, { FC } from 'react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Form } from 'formik'
import { Button, Text } from 'app/components'
import { SelectAsync } from 'app/components'
import { initialValuesDocumentation } from './constants'

import {
  fetchSaveDocumentationStatus,
  selectSaveDocumentationError,
  selectSaveDocumentationIsFetching,
} from 'app/slices/companyPAD'

import * as S from './styles'
import * as C from 'app/components'

import { strings } from 'app/constants/strings'
import { colors } from 'app/styles/colors'

const {
  forms: {
    fieldsTitle: { tipoDeDocumentacionTitle },
  },
} = strings

interface IStatesModal {
  onSubmit: (values: any) => void
  show: boolean
  setShow: (values: any) => void
  idTipoEstado: number
  idTitular: string | undefined
}

const DocumentationModal: FC<IStatesModal> = ({
  onSubmit,
  show,
  setShow,
  idTitular,
}) => {
  const dispatch = useDispatch()
  const params = useParams<{ guid: string }>()
  const initVal = {
    ...initialValuesDocumentation,
    guid: params ? params.guid : '',
    dniTitular: idTitular,
  }
  const [enabled, setEnabled] = useState(true)
  const isFetchingChanges: boolean = useSelector(
    selectSaveDocumentationIsFetching
  )
  const saveDocumentationFail: boolean = useSelector(
    selectSaveDocumentationError
  )
  const [selectedValue, setSelectedValue] = useState<C.ISelectFormat | null>(
    null
  )

  const fileType = [
    { value: 1, label: 'Alta de obra social' },
    { value: 2, label: 'Recién Nacido - Certificado del pediatra' },
    { value: 3, label: 'Recién Nacido - FEI' },
    { value: 4, label: 'Recién Nacido - Otoemisión acústica' },
  ]

  return (
    <>
      <Formik
        initialValues={initVal}
        onSubmit={(values: any, { setSubmitting }) => {
          setSubmitting(false)

          onSubmit(values)
        }}
      >
        {(props: {
          values: any
          errors: {
            archivo: File
            dniTitular: boolean
            tipoArchivo: number
          }
          touched: {
            archivo: File
            dniTitular: boolean
            tipoArchivo: number
          }
          onChange: any
          handleChange: any
          submitForm: any
          setFieldValue: any
          resetForm: any
        }) => (
          <Form>
            <S.ModalBg show={show}>
              <S.ModalBoxDocumentation>
                {isFetchingChanges ? (
                  <C.LoadingSpinner isLoading={isFetchingChanges} />
                ) : (
                  <>
                    <S.Title>
                      <Text
                        textAlign={'center'}
                        size={'xl'}
                        color={'cornflowerBlue'}
                        weight={'md'}
                      >
                        Agregar Documentación
                      </Text>
                    </S.Title>
                    <S.ContainerBox>
                      <S.InputsBox>
                        <SelectAsync
                          onChange={(option: any) => {
                            props.setFieldValue(
                              'tipoArchivo',
                              option ? option.value : null
                            )
                            setEnabled(false)
                            setSelectedValue(option)
                          }}
                          isClearable
                          value={selectedValue}
                          title={tipoDeDocumentacionTitle}
                          name={'tipoArchivo'}
                          id={'tipoArchivo'}
                          placeholder="Seleccionar"
                          options={fileType}
                          color={colors.cornflowerBlue}
                          fullWidth
                        />
                      </S.InputsBox>
                      <S.InputsBox>
                        <C.Text color="cornflowerBlue" weight="s" size="s">
                          Importar documentación
                          <C.InputFile
                            field={{
                              name: 'archivo',
                              value: props.values.archivo,
                              onChange: props.setFieldValue,
                            }}
                            id={'archivo'}
                            title={'Adjuntar PDF/JPG/PNG'}
                            fullWidth
                            margin="8px 0 0 0"
                            color="cornflowerBlue"
                            IconUpload
                            warning={Boolean(
                              props.errors.archivo && props.touched.archivo
                            )}
                          />
                        </C.Text>
                      </S.InputsBox>
                      {saveDocumentationFail && (
                        <S.MessageError>
                          {' '}
                          Hubo un error al guardar su documentación.{' '}
                        </S.MessageError>
                      )}
                    </S.ContainerBox>
                    <S.ButtonsBox>
                      <Button
                        outline={true}
                        onClick={() => {
                          setShow(!show)
                          setSelectedValue(null)
                          props.resetForm()
                          dispatch(fetchSaveDocumentationStatus())
                        }}
                        centered
                      >
                        Cancelar
                      </Button>
                      <Button type="submit" disabled={enabled} centered>
                        Guardar
                      </Button>
                    </S.ButtonsBox>
                  </>
                )}
              </S.ModalBoxDocumentation>
            </S.ModalBg>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default DocumentationModal
