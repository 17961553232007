import { createSlice } from '@reduxjs/toolkit'

import { RootState } from 'app/store/reducers'
import { LeadState } from 'domain/entities'
import { UseCaseLeads } from 'domain/useCases'
import {
  getLeadsRequestParams,
  getLeadRequestModel,
  putLeadStateRequestModel,
  extendGetLeadsParamsWithExportList,
  makeLeadsExportReqParams,
  putLeadAdvisorRequestModel,
  TPostImportLeads,
  TPostEventLeadsParams,
} from 'domain/requestModel/leads'
import { ILeadsDTO } from 'domain/responseModel'
import { leadsService } from 'app/services'

const leadsController = new UseCaseLeads(leadsService)

type initialStateReducer = {
  loading: boolean
  leadStateIsLoading: boolean
  event: boolean
  error: null | string
  leads: ILeadsDTO[]
  lead: ILeadResultDTO | null
  leadStates: LeadState[]
  putLeadStateIsLoading: boolean
  leadIsUpdated: boolean
  isExportLeadsLoading: boolean
  advisorIsUpdated: boolean
  putLeadAdvisorIsLoading: boolean
  isImportLeadsLoading: boolean
  importedLeadsWereUploaded: boolean
  isExportTemplateMasiveLeadsLoading: boolean
}

interface ILeadResultDTO {
  data: any
}

const INITIAL_STATE: initialStateReducer = {
  loading: false,
  error: null,
  leads: [],
  lead: null,
  event: false,
  leadStates: [],
  leadStateIsLoading: false,
  leadIsUpdated: false,
  putLeadStateIsLoading: false,
  isExportLeadsLoading: false,
  putLeadAdvisorIsLoading: false,
  advisorIsUpdated: false,
  isImportLeadsLoading: false,
  importedLeadsWereUploaded: false,
  isExportTemplateMasiveLeadsLoading: false,
}

export const slice = createSlice({
  name: 'auth',
  initialState: INITIAL_STATE,
  reducers: {
    fetchLeadsStart: state => {
      state.loading = true
      state.error = null
    },
    fetchLeadsSuccess: (state, { payload }) => {
      state.loading = false
      state.error = null
      state.leads = payload
    },
    fetchLeadsFail: (state, { payload }) => {
      state.loading = false
      state.error = payload.message
    },
    fetchLeadStart: state => {
      state.loading = true
      state.error = null
      state.leadIsUpdated = false
      state.advisorIsUpdated = false
    },
    fetchLeadSuccess: (state, { payload }) => {
      state.loading = false
      state.error = null
      state.lead = payload
    },
    fetchLeadFail: (state, { payload }) => {
      state.loading = false
      state.error = payload.message
    },
    fetchLeadStatesStart: state => {
      state.leadStateIsLoading = true
      state.error = null
    },
    fetchLeadStatesSuccess: (state, { payload }) => {
      state.leadStateIsLoading = false
      state.error = null
      state.leadStates = payload
    },
    fetchLeadStatesFail: (state, { payload }) => {
      state.leadStateIsLoading = false
      state.error = payload.message
    },
    putLeadStateStart: state => {
      state.putLeadStateIsLoading = true
      state.leadIsUpdated = false
      state.error = null
    },
    putLeadStateSuccess: (state, { payload }) => {
      state.putLeadStateIsLoading = false
      state.leadIsUpdated = true
      state.error = null
      state.lead!.data.idEstadoDerivadora = payload.idEstado
    },
    putLeadStateFail: (state, { payload }) => {
      state.putLeadStateIsLoading = false
      state.leadIsUpdated = false
      state.error = payload.message
    },
    putLeadsStateSuccess: (state, { payload }) => {
      state.putLeadStateIsLoading = false
      state.leadIsUpdated = true
      state.error = null
    },
    fetchExportLeadsStart: state => {
      state.isExportLeadsLoading = true
      state.error = null
    },
    fetchExportLeadsSuccess: (state, { payload }) => {
      state.isExportLeadsLoading = false
      state.error = null
    },
    fetchExportLeadsFail: (state, { payload }) => {
      state.isExportLeadsLoading = false
      state.error = payload.message
    },
    putLeadAdvisorStart: state => {
      state.putLeadAdvisorIsLoading = true
      state.advisorIsUpdated = false
      state.error = null
    },
    putLeadAdvisorSuccess: (state, { payload }) => {
      state.putLeadAdvisorIsLoading = false
      state.advisorIsUpdated = true
      state.error = null
      state.lead!.data.asesor = payload.nombre
    },
    putLeadAdvisorFail: (state, { payload }) => {
      state.putLeadAdvisorIsLoading = false
      state.advisorIsUpdated = false
      state.error = payload.message
    },
    fetchImportLeadsStart: state => {
      state.isImportLeadsLoading = true
      state.importedLeadsWereUploaded = false
      state.error = null
    },
    fetchImportLeadsSuccess: (state, { payload }) => {
      state.isImportLeadsLoading = false
      state.importedLeadsWereUploaded = true
      state.error = null
    },
    fetchImportLeadsFail: (state, { payload }) => {
      state.isImportLeadsLoading = false
      state.importedLeadsWereUploaded = false
      state.error = payload.message
    },
    fetchExportTemplateMasiveLeadsStart: state => {
      state.isExportTemplateMasiveLeadsLoading = true
      state.error = null
    },
    fetchExportTemplateMasiveLeadsSuccess: (state, { payload }) => {
      state.isExportTemplateMasiveLeadsLoading = false
      state.error = null
    },
    fetchExportTemplateMasiveLeadsFail: (state, { payload }) => {
      state.isExportTemplateMasiveLeadsLoading = false
      state.error = payload.message
    },
    fetchNewCommentStart: state => {
      state.loading = true
      state.error = ''
      state.event = false
    },
    fetchNewCommentSuccess: (state, { payload }) => {
      state.loading = false
      state.error = ''
      state.event = true
    },
    fetchNewCommentFail: (state, { payload }) => {
      state.loading = false
      state.error = payload.message
    },
  },
})

export const {
  fetchLeadsStart,
  fetchLeadsSuccess,
  fetchLeadsFail,
  fetchLeadStart,
  fetchLeadSuccess,
  fetchLeadFail,
  fetchLeadStatesStart,
  fetchLeadStatesSuccess,
  fetchLeadStatesFail,
  putLeadStateStart,
  putLeadStateSuccess,
  putLeadsStateSuccess,
  putLeadStateFail,
  fetchExportLeadsStart,
  fetchExportLeadsSuccess,
  fetchExportLeadsFail,
  putLeadAdvisorStart,
  putLeadAdvisorSuccess,
  putLeadAdvisorFail,
  fetchImportLeadsStart,
  fetchImportLeadsSuccess,
  fetchImportLeadsFail,
  fetchExportTemplateMasiveLeadsStart,
  fetchExportTemplateMasiveLeadsSuccess,
  fetchExportTemplateMasiveLeadsFail,
  fetchNewCommentStart,
  fetchNewCommentSuccess,
  fetchNewCommentFail,
} = slice.actions

export default slice.reducer

/**
 * Thunks
 */
export const getLeads = (props: getLeadsRequestParams) => async (
  dispatch: any
) => {
  try {
    dispatch(fetchLeadsStart())

    const response = await leadsController.getLeads({ ...props })

    dispatch(fetchLeadsSuccess(response))
  } catch (error) {
    dispatch(fetchLeadsFail(error))
  }
}

export const getLead = (params: getLeadRequestModel) => async (
  dispatch: any
) => {
  try {
    dispatch(fetchLeadStart())

    const response = await leadsController.getLead(params)

    dispatch(fetchLeadSuccess(response))
  } catch (error) {
    dispatch(fetchLeadFail(error))
  }
}

export const getLeadStates = () => async (dispatch: any) => {
  try {
    dispatch(fetchLeadStatesStart())

    const response = await leadsController.getLeadStates()

    dispatch(fetchLeadStatesSuccess(response))
  } catch (error) {
    dispatch(fetchLeadStatesFail(error))
  }
}

export const getLeadsExport = (
  params: extendGetLeadsParamsWithExportList
) => async (dispatch: any) => {
  try {
    dispatch(fetchExportLeadsStart())

    const reqParams = makeLeadsExportReqParams(params)

    const response = await leadsController.getLeadsExport(reqParams)

    dispatch(fetchExportLeadsSuccess(response))
  } catch (error) {
    dispatch(fetchExportLeadsFail(error))
  }
}

export const putLeadState = (params: putLeadStateRequestModel) => async (
  dispatch: any,
  getState: any
) => {
  try {
    dispatch(putLeadStateStart())

    const { leads } = getState()
    const leadsObject = leads.leads
    let stateArray: any[] = []

    if (leadsObject) {
      stateArray = leadsObject.resultados.map((data: any) => {
        let updatedState
        if (data.id === params.id) {
          updatedState = { idEstadoDerivadora: params.idEstado }
        }
        return { ...data, ...updatedState }
      })
    }

    const response = await leadsController.putLeadState(params)

    if (params.pageType && params.pageType === 'list') {
      dispatch(putLeadsStateSuccess(params))
    } else {
      dispatch(putLeadStateSuccess(params))
    }

    dispatch(
      fetchLeadsSuccess({
        totalPaginas: leadsObject.totalPaginas,
        totalCantidad: leadsObject.totalCantidad,
        resultados: stateArray,
      })
    )

    return response
  } catch (error) {
    dispatch(putLeadStateFail(error))
  }
}

export const putLeadAdvisor = (params: putLeadAdvisorRequestModel) => async (
  dispatch: any,
  getState: any
) => {
  try {
    dispatch(putLeadAdvisorStart())

    const { leads } = getState()
    const leadsObject = leads.leads
    let stateArray: any[] = []

    if (leadsObject) {
      stateArray = leadsObject.resultados.map((data: any) => {
        let updatedAdvisor
        if (data.id === params.id) {
          updatedAdvisor = { advisor: params.codAsesor }
        }
        return { ...data, ...updatedAdvisor }
      })
    }

    dispatch(putLeadAdvisorSuccess(params))

    const response = await leadsController.putLeadAdvisor(params)

    dispatch(
      fetchLeadsSuccess({
        totalPaginas: leadsObject.totalPaginas,
        totalCantidad: leadsObject.totalCantidad,
        resultados: stateArray,
      })
    )

    return response
  } catch (error) {
    dispatch(putLeadAdvisorFail(error))
  }
}

export const postImportLeads = (params: TPostImportLeads) => async (
  dispatch: any
) => {
  try {
    dispatch(fetchImportLeadsStart())

    const response = await leadsController.postImportLeads(params)

    if (response.error) return dispatch(fetchImportLeadsFail(response))

    dispatch(fetchImportLeadsSuccess(response))
  } catch (error) {
    dispatch(fetchImportLeadsFail(error))
  }
}

export const postLeadsComment = (params: TPostEventLeadsParams) => async (
  dispatch: any
) => {
  try {
    dispatch(fetchNewCommentStart())
    const response = await leadsController.postEventLeads(params)
    dispatch(fetchNewCommentSuccess(response))
  } catch (error) {
    dispatch(fetchNewCommentFail(error))
  }
}

export const getTemplateMasiveImport = () => async (dispatch: any) => {
  try {
    dispatch(fetchExportTemplateMasiveLeadsStart())

    const response = await leadsController.getTemplateMasiveImport()

    dispatch(fetchExportTemplateMasiveLeadsSuccess(response))
  } catch (error) {
    dispatch(fetchExportTemplateMasiveLeadsFail(error))
  }
}

/**
 * Selectors
 */
export const selectLeads = (state: any) =>
  state.leads.leads.resultados?.length ? state.leads.leads.resultados : []
export const selectLeadsNumberOfPages = (state: any) =>
  state.leads.leads?.totalPaginas ? state.leads.leads?.totalPaginas : 0
export const selectLead = (state: any) => state.leads.lead
export const selectLeadsIsFetching = (state: RootState) => state.leads.loading
export const selectLeadStates = (state: RootState) => state.leads.leadStates
export const selectLeadStatesIsFetching = (state: RootState) =>
  state.leads.leadStateIsLoading
export const selectputLeadStateIsLoading = (state: RootState) =>
  state.leads.putLeadStateIsLoading
export const selectLeadIsUpdated = (state: RootState) =>
  state.leads.leadIsUpdated
export const selectExportLeadsIsFetching = (state: RootState) =>
  state.leads.isExportLeadsLoading
export const selectAdvisorIsUpdated = (state: any) =>
  state.leads.advisorIsUpdated
export const selectPutLeadStateIsLoading = (state: any) =>
  state.leads.putLeadStateIsLoading
export const selectPutLeadAdvisorIsLoading = (state: any) =>
  state.leads.putLeadAdvisorIsLoading
export const selectImportLeadsIsFetching = (state: RootState) =>
  state.leads.isImportLeadsLoading
export const selectError = (state: RootState) => state.leads.error
export const selectImportedLeadsWereUploaded = (state: RootState) =>
  state.leads.importedLeadsWereUploaded
export const selectPostLeadComment = (state: RootState) => state.leads.event
export const selectPostLeadCommentIsFetching = (state: RootState) =>
  state.leads.loading
