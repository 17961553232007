import { Afilliation, AfilliationAssociate } from 'domain/entities'

import { isNumber, isString, isDateAndNotEmpty } from 'helpers'

export interface IAfilliationsDTO {
  resultados: Afilliation[]
  totalPaginas: number
  totalCantidad: number
}

export const getAfilliationsDTO = (raw: IAfilliationsDTO): IAfilliationsDTO => {
  return {
    resultados: raw.resultados.map((afilliated: Afilliation) => ({
      id: isNumber(afilliated.id, 'getAfilliationsDTO - id'),
      dni: isString(afilliated.dni, 'getAfilliationsDTO - dni'),
      numeroSocio:
        afilliated.numeroSocio &&
        isString(afilliated.numeroSocio, 'getAfilliationsDTO - numeroSocio'),
      estadoBackOffice: isNumber(
        afilliated.estadoBackOffice,
        'getAfilliationsDTO - estadoBackOffice',
        false
      ),
      estadoBODescripcion: afilliated.estadoBODescripcion,
      motivo:
        afilliated.motivo &&
        isString(afilliated.motivo, 'getAfilliationsDTO - motivo'),
      tipoAfiliacion: isNumber(
        afilliated.tipoAfiliacion,
        'getAfilliationsDTO - tipoAfiliacion'
      ),
      nombre:
        afilliated.nombre &&
        isString(afilliated.nombre, 'getAfilliationsDTO - nombre'),
      apellido:
        afilliated.apellido &&
        isString(afilliated.apellido, 'getAfilliationsDTO - apellido'),
      idProvincia:
        afilliated.idProvincia &&
        isNumber(afilliated.idProvincia, 'getAfilliationsDTO - idProvincia'),
      asesor:
        afilliated.asesor &&
        isString(afilliated.asesor, 'getAfilliationsDTO - asesor'),
      nombreAsesor: isString(
        afilliated.nombreAsesor,
        'getAfilliationsDTO - nombreAsesor',
        false
      ),
      fechaCotizacion: isDateAndNotEmpty(
        afilliated.fechaCotizacion,
        'getAfilliationsDTO - fechaCotizacion'
      ),
      fechaVigencia:
        afilliated.fechaVigencia &&
        isDateAndNotEmpty(
          afilliated.fechaVigencia,
          'getAfilliationsDTO - fechaVigencia'
        ),
      nombrePlan: isString(
        afilliated.nombrePlan,
        'getAfilliationsDTO - nombrePlan',
        false
      ),
    })),
    totalPaginas: isNumber(
      raw.totalPaginas,
      'getAfilliationsDTO - totalPaginas'
    ),
    totalCantidad: isNumber(
      raw.totalCantidad,
      'getAfilliationsDTO - totalCantidad'
    ),
  }
}

type TItem = {
  id: number
  observacion: string
  fecha: Date
}
type TDocumentation = {
  id: number
  dniTitular: string
  nombre: string
  plataforma: number
  tipo: string
  urlArchivo: string
}

type TAfilliationDetail = Afilliation & { aportes: number }

export type TGetAfilliationDTO = {
  afiliacion: TAfilliationDetail
  socios: AfilliationAssociate[]
  historial: TItem[]
  observaciones: TItem[]
  documentaciones: TDocumentation[]
}
