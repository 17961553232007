import React, { FC, ReactNode } from 'react'
import styled from 'styled-components'

interface IContent {
  opened: boolean
  readonly children: ReactNode
}

const Content = styled.div<IContent>`
  min-height: 30px;
  max-height: ${props => (props.opened ? '100%' : '0')};
  padding: ${props => (props.opened ? '25px 0' : '0')};
  transition: all 0.3s ease-out;

  @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
    overflow: hidden;
  }
`

export const AnimatedCollapsableContainer: FC<IContent> = props => (
  <Content opened={props.opened}>{props.children}</Content>
)
