import { ILeadsEntityGateway } from 'domain/entityGateways/ILeadsEntityGateway'

import { getLeadsRequestParams, getLeadRequestModel } from 'domain/requestModel'

import { ILeadsDTO, ILeadDTO, getLeadStatesToModel } from 'domain/responseModel'

import { LeadState } from 'domain/entities'
import {
  TGetLeadsRequestModel,
  putLeadStateRequestModel,
  putLeadAdvisorRequestModel,
  TPostImportLeads,
  TPostEventLeadsParams,
} from 'domain/requestModel/leads'

export class UseCaseLeads implements ILeadsEntityGateway {
  private entityGateway: ILeadsEntityGateway

  constructor(entityGateway: ILeadsEntityGateway) {
    this.entityGateway = entityGateway
  }

  public async getLeads(params: getLeadsRequestParams): Promise<ILeadsDTO> {
    const response = await this.entityGateway.getLeads(params)

    return response
  }

  public async getLead(params: getLeadRequestModel): Promise<ILeadDTO> {
    const response = await this.entityGateway.getLead(params)

    return response
  }

  public async getLeadStates(): Promise<LeadState[]> {
    const response = await this.entityGateway.getLeadStates()

    return getLeadStatesToModel(response)
  }

  public async putLeadState(params: putLeadStateRequestModel) {
    const response = await this.entityGateway.putLeadState(params)

    return response
  }

  public async putLeadAdvisor(params: putLeadAdvisorRequestModel) {
    const response = await this.entityGateway.putLeadAdvisor(params)

    return response
  }

  public async getLeadsExport(params: TGetLeadsRequestModel) {
    const leadsExported = await this.entityGateway.getLeadsExport(params)

    return leadsExported
  }

  public async postImportLeads(params: TPostImportLeads) {
    const leadsImported = await this.entityGateway.postImportLeads(params)

    return leadsImported
  }

  public async getTemplateMasiveImport() {
    const leadsTemplate = await this.entityGateway.getTemplateMasiveImport()

    return leadsTemplate
  }

  public async postEventLeads(params: TPostEventLeadsParams) {
    const result = await this.entityGateway.postEventLeads(params)

    return result
  }
}

export default {
  UseCaseLeads,
}
