import { Supervisor } from 'domain/entities'

import { isString } from 'helpers'

export const getAllAmbaSupervisors = (raw: Supervisor[]): Supervisor[] =>
  raw.map(e => ({
    codAsesor: isString(e.codAsesor, 'Supervisor - codAsesor'),
    foto: e.foto && isString(e.foto, 'Supervisor - foto', false),
    telefono: isString(e.telefono, 'Supervisor - telefono'),
    instagram:
      e.instagram && isString(e.instagram, 'Supervisor - instagram', false),
    facebook:
      e.facebook && isString(e.facebook, 'Supervisor - facebook', false),
    nombre: isString(e.nombre, 'Supervisor - nombre'),
    mail: isString(e.mail, 'Supervisor - mail'),
  }))
