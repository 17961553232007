import React, { FC, useState, useEffect } from 'react'
import { ErrorMessage } from 'formik'
import DatePicker, { registerLocale } from 'react-datepicker'
import es from 'date-fns/locale/es'

import 'react-datepicker/dist/react-datepicker.css'

import {
  InputTitle,
  InputHint,
  ErrorField,
  DateRangeContainer,
  DateRangeInputsContainer,
} from 'app/components/ui/styles'

registerLocale('es', es)

interface IRangeDatePicker {
  id: any
  title?: any
  hint?: any
  name: any
  onChange: any
  startDate?: Date
  endDate?: Date
}

const RangeDatePicker: FC<IRangeDatePicker> = props => {
  const { onChange, name } = props
  const [startDate, setStartDate] = useState(props.startDate)
  const [endDate, setEndDate] = useState(props.endDate)

  useEffect(() => {
    onChange(name, { startDate, endDate })
  }, [startDate, endDate, name, onChange])

  return (
    <DateRangeContainer>
      {props.title && <InputTitle htmlFor={props.id}>{props.title}</InputTitle>}
      {props.hint && <InputHint htmlFor={props.id}>{props.hint}</InputHint>}
      <DateRangeInputsContainer>
        <DatePicker
          placeholderText={'fecha desde'}
          selected={startDate}
          onChange={(date: Date) => setStartDate(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          locale="es"
          dateFormat={'dd/MM/YYY'}
        />
        <DatePicker
          placeholderText={'fecha hasta'}
          selected={endDate}
          onChange={(date: Date) => setEndDate(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          locale="es"
          dateFormat={'dd/MM/YYY'}
        />
      </DateRangeInputsContainer>
      <ErrorMessage name={name} component={ErrorField} />
    </DateRangeContainer>
  )
}

export default RangeDatePicker
