import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { HomeContainer } from 'app/Pages/Home/HomeContainer'
import { LoginForm } from 'app/Pages/Home/LoginForm'

import { loginUser, selectAuth } from 'app/slices/authSlice'

import { LoginSchema } from 'app/Pages/Home/validationSchema'

import { pages } from 'app/constants/pages'

import { Credential } from 'domain/entities'

const { backoffice } = pages

const initialValues: Credential = {
  userName: '',
  password: '',
}

const Home = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { token, loading, error } = useSelector(selectAuth)

  useEffect(() => {
    token && history.push(backoffice.path)
  }, [token, history])

  const handleOnPressSubmit = (values: Credential) =>
    dispatch(loginUser(values))

  return (
    <HomeContainer>
      <LoginForm
        validationSchema={LoginSchema}
        onSubmit={handleOnPressSubmit}
        initialValues={initialValues}
        isLoading={loading}
        error={error}
      />
    </HomeContainer>
  )
}

export default Home
