import { Supervisor, Advisor } from 'domain/entities'
import { ISelectFormat } from 'app/components/ui/forms'

export const mapSupervisorsAmbaForDropdown = (
  data: Supervisor[]
): ISelectFormat[] =>
  data.map((supervisor: Supervisor) => {
    return {
      value: supervisor.codAsesor,
      label: supervisor.nombre,
    }
  })

export const mapAdvisorForDropdown = (data: Advisor[]): ISelectFormat[] =>
  data.map((advisor: Advisor) => {
    return {
      value: advisor.codAsesor,
      label: advisor.nombre,
    }
  })
