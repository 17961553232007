import Details from './Details'
import Documentation from './Documentation'
import Observations from './Observations'
import StateUpdate from './StateUpdate'

export default {
  Details,
  Observations,
  StateUpdate,
  Documentation,
}
