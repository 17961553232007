import { Logger } from './logger'

// extends or take complete list if is needed in the future (https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes)
const localeStrings = {
  enUs: 'en-US',
  esEs: 'es-ES',
}

type TTypeOfLocaleStrings = typeof localeStrings

type TLocaleStrings = keyof TTypeOfLocaleStrings

/**
 * Given number return a number with separators (commas, dots) depending on the localeString setting
 * @params { number } - number
 * @params { string } - localeString
 * @params { number } - maximumFractionDigits
 *
 * @return { string }
 */
export const numberWithSeparators = ({
  number,
  localeString = 'esEs',
  maximumFractionDigits = 2,
}: {
  number: number | null | undefined
  localeString?: TLocaleStrings
  maximumFractionDigits?: number
}): string => {
  try {
    if (number == null) {
      throw new Error('Number is null or undefined')
    }
    if (isNaN(number)) {
      throw new Error('Not a number')
    }

    return number.toLocaleString(localeStrings[localeString], {
      maximumFractionDigits,
    })
  } catch (err) {
    Logger.error({
      message: 'numberWithSeparators:',
      data: err,
    })

    return number !== null && number !== undefined ? number.toString() : ''
  }
}
