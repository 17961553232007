import { getLeadsRequestParams, getLeadRequestModel } from 'domain/requestModel'
import { LeadState, ILogger } from 'domain/entities'
import {
  ILeadsDTO,
  getLeadsToModel,
  getLeadToModel,
  getLeadStatesToModel,
} from 'domain/responseModel'

import { endpoints } from 'app/constants/endpoints'
import {
  putLeadStateRequestModel,
  makeLeadsRequestModel,
  TGetLeadsRequestModel,
  putLeadAdvisorRequestModel,
  TPostImportLeads,
  TPostEventLeadsParams,
  TPostEventLeadsEven,
  postEventLeadsComment,
} from 'domain/requestModel/leads'

export class LeadsServices {
  private httpClient: any
  private localClient: any
  private logger: ILogger

  constructor(clientProvider: any, localClientProvider: any, logger: ILogger) {
    this.httpClient = clientProvider
    this.localClient = localClientProvider
    this.logger = logger
  }

  public async getLeads(params: getLeadsRequestParams): Promise<ILeadsDTO> {
    try {
      const reqParams = makeLeadsRequestModel(params)

      const leads = (
        await this.httpClient.get(endpoints.leads, { ...reqParams })
      ).data

      return getLeadsToModel(leads)
    } catch (error) {
      this.logger.error({
        message: 'UseCase - Leads (getLeads)',
        data: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
      })

      throw new Error(error)
    }
  }

  // modificar el DTO
  public async getLead(params: getLeadRequestModel): Promise<any> {
    const endpointWithParams =
      endpoints.lead + `/${params.idLead}/${params.tipoLead}`
    try {
      const lead = await await this.httpClient.get(endpointWithParams)

      return getLeadToModel(lead)
    } catch (error) {
      this.logger.error({
        message: 'UseCase - Lead (getLead)',
        data: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
      })

      throw new Error(error)
    }
  }

  public async getLeadStates(): Promise<LeadState[]> {
    try {
      const leads = (await this.httpClient.get(endpoints.estadosDerivadora))
        .data

      return getLeadStatesToModel(leads)
    } catch (error) {
      this.logger.error({
        message: 'UseCase - Lead (getLeadStates)',
        data: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
      })

      throw new Error(error)
    }
  }

  public async putLeadState(params: putLeadStateRequestModel): Promise<any> {
    try {
      const leads = (
        await this.httpClient.put(endpoints.actualizarEstado, params)
      ).data

      return leads
    } catch (error) {
      this.logger.error({
        message: 'UseCase - Lead (putLeadState)',
        data: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
      })

      throw new Error(error)
    }
  }

  public async putLeadAdvisor(
    params: putLeadAdvisorRequestModel
  ): Promise<any> {
    try {
      const updatedAdvisor = (
        await this.httpClient.put(endpoints.actualizarAsesor, params)
      ).data

      return updatedAdvisor
    } catch (error) {
      this.logger.error({
        message: 'UseCase - Lead putLeadAdvisor',
        data: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
      })

      throw new Error(error)
    }
  }

  public async getLeadsExport(params: TGetLeadsRequestModel): Promise<any> {
    try {
      const leadsExported = (
        await this.httpClient.get(endpoints.exportLeads, params, {
          responseType: 'blob',
        })
      ).data

      let url = window.URL.createObjectURL(leadsExported)
      let a = document.createElement('a')
      a.href = url
      a.download = 'listado-de-leads.xlsx'
      a.click()
    } catch (error) {
      this.logger.error({
        message: 'UseCase - Leads (getLeadsExport)',
        data: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
      })

      throw new Error(error)
    }
  }

  public async postImportLeads(params: TPostImportLeads): Promise<any> {
    try {
      const formData = new FormData()

      // Update the formData object
      formData.append(
        params.leadFile.name,
        params.leadFile,
        params.leadFile.name
      )

      const leads = (
        await this.httpClient.post(endpoints.leadsCargaMasiva, formData)
      ).data

      return leads
    } catch (error) {
      this.logger.error({
        message: 'UseCase - Leads (ImportLeads)',
        data: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
      })

      return {
        error: true,
        message: error.response.data.message,
      }
    }
  }

  public async postEventLeads(params: TPostEventLeadsParams): Promise<any> {
    try {
      const mapPostEventLeadsParams: TPostEventLeadsEven = postEventLeadsComment(
        params
      )
      const newComment: ILeadsDTO = (
        await this.httpClient.post(
          endpoints.guardarObservacion,
          mapPostEventLeadsParams
        )
      ).data

      return newComment
    } catch (error) {
      this.logger.error({
        message: 'UseCase - postEventLeads',
        data: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
      })

      throw new Error(error)
    }
  }

  public async getTemplateMasiveImport(): Promise<any> {
    try {
      const leadsTemplate = (
        await this.localClient.get('leadsMasiveTemplate.xlsx', null, {
          responseType: 'blob',
        })
      ).data

      let url = window.URL.createObjectURL(leadsTemplate)
      let a = document.createElement('a')
      a.href = url
      a.download = 'leads-masive-template.xlsx'
      a.click()
    } catch (error) {
      this.logger.error({
        message: 'UseCase - Leads (Masive Template)',
        data: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
      })

      return {
        error: true,
        message: 'No se pudo obtener el template',
      }
    }
  }
}

export default {
  LeadsServices,
}
