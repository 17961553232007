import React, { FC, ReactNode } from 'react'
import styled from 'styled-components'

interface IPagesContainer {
  readonly children: ReactNode
  readonly padding?: string
}

const Container = styled.div<IPagesContainer>`
  width: 100%;
  padding: ${props => (props.padding ? props.padding : '')};
`

const FlexContainerViewPortHeigh = styled(Container)`
  @media (min-width: ${({ theme }) => theme.breakPoints.small}) {
    height: calc(100vh - 50px);
  }

  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`

export const PagesContainer: FC<IPagesContainer> = props => (
  <Container {...props}>{props.children}</Container>
)

export const PagesFlexContainerViewPortHeigh: FC<IPagesContainer> = props => (
  <FlexContainerViewPortHeigh {...props}>
    {props.children}
  </FlexContainerViewPortHeigh>
)
