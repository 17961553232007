import React from 'react'

import { Text } from 'app/components/ui'

import {
  HeaderContainer,
  ItemHeader,
} from 'app/components/ui/GenericTable/styles'

import { fieldKeyToName } from 'helpers'

export type HeaderProps = {
  data: string[]
}

export const Header = (props: HeaderProps) => {
  return (
    <HeaderContainer>
      {props.data.map((e, i) => (
        <ItemHeader key={i}>
          <Text
            capitalFirstLetter
            color={'mariner'}
            weight={'md'}
            textAlign={'center'}
          >
            {fieldKeyToName(e)}
          </Text>
        </ItemHeader>
      ))}
    </HeaderContainer>
  )
}

export default Header
