import styled from 'styled-components'

export const PageContainer = styled.div`
  width: 100%;
`

export const InsuranceList = styled.table`
  display: block;
  width: 100%;
  height: 100%;
  overflow-x: auto;
  text-align: center;

  thead > tr > th {
    padding: 0 15px;
  }
`

export const FootFields = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

export const StyledPaginateContainer = styled.div`
  .pagination {
    color: #0366d6;
    display: inline-flex;
    list-style-type: none;
  }
  .break-me {
    cursor: default;
  }
  .active {
    border-color: transparent;
    background-color: #0366d6;
    color: white;
    border-radius: 5px;
  }
  ul > li {
    padding: 0 10px;
  }
`

export const ListItem = styled.tr`
  height: 30px;
`

export const InsuranceListContainer = styled.div`
  width: 95vw;
  height: 60vh;
  max-height: 60vh;
  border-style: solid;
  border-color: grey;
  border-radius: 5px;
`

export const TableHeaders = styled.thead`
  background-color: ${({ theme }) => theme.colors.sky};
`

export const TableBody = styled.tbody`
  tr:nth-child(odd) {
    background: ${({ theme }) => theme.colors.lightGrey};
  }
`

export const TableTd = styled.td``

export const InputsContainer = styled.div`
  @media (min-width: ${({ theme }) => theme.breakPoints.small}) {
    display: flex;
    justify-content: center;
  }
`

export const FormButtons = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  > div {
    width: 100%;
    justify-content: center;

    > div {
      padding: 0px 10px;
    }
  }
`

export const FormFileContent = styled.div`
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`
