import React, { useCallback, useEffect, useState } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useRouteMatch,
  useHistory,
} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// Pages
import Home from 'app/Pages/Home'
import LandingPage from 'app/Pages/LandingPage'
import UnfoundCredential from 'app/Pages/UnfoundCredential'
import Policies from 'app/Pages/Policies'
import Policy from 'app/Pages/Policy'
import Leads from 'app/Pages/Leads'
import Lead from 'app/Pages/Lead'
import AdvisorAgenda from 'app/Pages/AdvisorAgenda'
import Afilliations from 'app/Pages/Afilliations'
import Afilliation from 'app/Pages/Afilliation'
import Company from 'app/Pages/Company'
import PADCompanies from 'app/Pages/PADCompanies'

// Components
import { Navbar, Permission, Message } from 'app/components'

import { httpClient } from 'api'

import {
  logoutUser,
  selectAuth,
  selectAuthFunctionalities,
  selectAuthUserName,
} from 'app/slices/authSlice'

import { pages } from 'app/constants'

import { navBarOptions } from 'app/routes/NavBarOptions'
import MedicAreaReportsScreen from 'app/Pages/MedicAreaReports'
import CompanyDetailScreen from 'app/Pages/CompanyDetail'
import HolderScreen from 'app/Pages/Holder'
import HolderCompanyScreen from 'app/Pages/HolderCompany'

const {
  home,
  unfoundCredential,
  policies,
  backoffice,
  leads,
  assignments,
  afilliations,
  medicAreaReports,
  companyInfo,
  padEmpresas,
} = pages

// declare internal path (permissions, etc will depends on each component or page, etc)
// so can be more detailed on differents features that user have
const Internal = () => {
  const [setting, setSetting] = useState(true)
  const { path } = useRouteMatch()
  const history = useHistory()
  const dispatch = useDispatch()

  const authSlice = useSelector(selectAuth)
  const functionalities = useSelector(selectAuthFunctionalities)
  const userName = useSelector(selectAuthUserName)

  const handleOnPressLogout = useCallback(() => {
    history.push(home.path)
    dispatch(logoutUser())
  }, [dispatch, history])

  // run set and configurations, then display
  useEffect(() => {
    if (authSlice?.token) {
      httpClient.setBearerToken(authSlice.token)
      httpClient.setLogoutUser(handleOnPressLogout)
    }

    setSetting(false)
  }, [authSlice, handleOnPressLogout])

  if (setting) return <h1>Cargando...</h1>

  return (
    <Router>
      <Navbar
        path={path}
        handleOnPressLogout={handleOnPressLogout}
        navBarOptions={navBarOptions(functionalities)}
      />
      <Message>
        <Switch>
          <Route
            exact
            path={backoffice.path}
            component={() => <LandingPage userName={userName} />}
          />
          <Route
            exact
            path={`${path}${afilliations.path}`}
            component={() => (
              <Permission permissionRequired={'afilliations'}>
                <Afilliations path={`${path}${afilliations.path}`} />
              </Permission>
            )}
          />
          <Route
            exact
            path={`${path}${afilliations.path}/:idAfiliacion`}
            component={() => (
              <Permission permissionRequired={'afilliations'}>
                <Afilliation path={`${path}${afilliations.path}`} />
              </Permission>
            )}
          />
          <Route
            exact
            path={`${path}${policies.path}`}
            component={() => (
              <Permission permissionRequired={'policies'}>
                <Policies path={`${path}${policies.path}`} />
              </Permission>
            )}
          />
          <Route
            exact
            path={`${path}${policies.path}/:idSeguro`}
            component={() => (
              <Permission permissionRequired={'policies'}>
                <Policy path={`${path}${policies.path}`} />
              </Permission>
            )}
          />
          <Route
            exact
            path={`${path}${leads.path}`}
            component={() => (
              <Permission permissionRequired={'leads'}>
                <Leads path={`${path}${leads.path}`} />
              </Permission>
            )}
          />
          <Route
            exact
            path={`${path}${leads.path}/:idLead/:tipoLead`}
            component={() => (
              <Permission permissionRequired={'leads'}>
                <Lead path={`${path}${leads.path}`} />
              </Permission>
            )}
          />
          <Route
            exact
            path={`${path}${companyInfo.path}`}
            component={() => (
              <Permission permissionRequired={'companyInfo'}>
                <Company path={`${path}${companyInfo.path}`} />
              </Permission>
            )}
          />
          {/* Pending permissionRequired for PAD empresas */}
          <Route
            exact
            path={`${path}${padEmpresas.path}`}
            component={() => (
              <Permission permissionRequired={'companyInfo'}>
                <PADCompanies path={`${path}${padEmpresas.path}`} />
              </Permission>
            )}
          />
          <Route
            exact
            path={`${path}${companyInfo.path}/titularCompany/:guid`}
            component={() => (
              <Permission permissionRequired={'companyInfo'}>
                <HolderCompanyScreen />
              </Permission>
            )}
          />
          <Route
            exact
            path={`${path}${assignments.path}`}
            component={() => (
              <AdvisorAgenda path={`${path}${assignments.path}`} />
            )}
          />
          <Route
            exact
            path={`${path}${medicAreaReports.path}`}
            component={() => (
              <MedicAreaReportsScreen
                path={`${path}${medicAreaReports.path}`}
              />
            )}
          />
          <Route
            exact
            path={`${path}${companyInfo.path}/:companyId`}
            component={() => (
              <Permission permissionRequired={'companyInfo'}>
                <CompanyDetailScreen />
              </Permission>
            )}
          />
          <Route
            exact
            path={`${path}${companyInfo.path}/titular/:guid`}
            component={() => (
              <Permission permissionRequired={'companyInfo'}>
                <HolderScreen />
              </Permission>
            )}
          />
        </Switch>
      </Message>
    </Router>
  )
}

const Routes = () => (
  <Router>
    <Route exact path={home.path} component={Home} />
    <Route exact path={unfoundCredential.path} component={UnfoundCredential} />
    <Route path={backoffice.path} component={Internal} />
  </Router>
)

export default Routes
