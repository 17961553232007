import React, { useEffect, useState } from 'react'

import { Row } from 'app/components'
import { Text } from 'app/components/ui'
import { Cards, TCard, CardDefaultProps } from 'app/components/ui/GenericTable'

import { IconArrowIosUpwardOutline } from 'app/components/Icons'
import LeadStateForm from 'app/Pages/Lead/form'
import { useDispatch } from 'react-redux'
import { putLeadState } from 'app/slices/leadsSlice'

export type CardProps = TCard & {
  onPressCopy: (e: any) => void
} & typeof CardDefaultProps

export const Card = (props: CardProps) => {
  const initialValue = {
    label: '',
    value: 0,
  }

  const [initialOption, setInitialOption] = useState(initialValue)
  const [selectedOption, setSelectedOption] = useState(initialValue)
  const [updatedObject, setUpdatedObject] = useState(false)

  const excludedValues = [
    'id',
    'tipoLead',
    'provincia',
    'localidad',
    'linkContinuar',
    'idEstadoDerivadora',
    'stateId',
    'detalle',
  ]

  const disableSubmitForm = selectedOption
    ? initialOption.value === selectedOption?.value
    : true

  useEffect(() => {
    const stateObject = {
      label: props.data.idEstadoDerivadora,
      value: props.data.stateId,
    }
    setSelectedOption(stateObject)
    setInitialOption(stateObject)
  }, [props.data.idEstadoDerivadora, props.data.stateId])

  const dispatch = useDispatch()
  const handleOnSubmit = () => {
    setUpdatedObject(!updatedObject)
    dispatch(
      putLeadState({
        id: props.data.detalle,
        tipoLead: props.data.tipoLead,
        idEstado: selectedOption?.value,
        pageType: 'list',
      })
    )
  }

  const info = Object.keys(props.data)
    .filter((e: any) => !excludedValues.includes(e))
    .map((e: any, i: number) => (
      <React.Fragment key={i}>
        {i === 0 ? (
          <>
            <IconArrowIosUpwardOutline
              centered={false}
              color={'mariner'}
              onClick={props.onPress}
              size={'sm'}
            />
            <Text color={'midnightBlue'}>{props.data['nombre']}</Text>
            <hr />
          </>
        ) : (
          <Row>
            <Text size={'sm'} color={'shipCove'} capitalFirstLetter={true}>
              {e}
            </Text>
            <Text size={'sm'} color={'midnightBlue'} weight={'md'}>
              {props.data[e]}
            </Text>
          </Row>
        )}
      </React.Fragment>
    ))

  return (
    <Cards
      hrColor={'alto'}
      className={'cardTable'}
      displayCard={props.displayCard}
    >
      {info.map(e => e)}
      <Text
        size={'sm'}
        onClick={() => props.onPressCopy(props.data['linkContinuar'])}
        color={'midnightBlue'}
        underlined
      >
        Copiar link
      </Text>
      <LeadStateForm
        pageType={'list'}
        onSubmit={handleOnSubmit}
        lead={props.data}
        updatedObject={updatedObject}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        disableSubmitForm={disableSubmitForm}
      />
    </Cards>
  )
}

Card.defaultProps = CardDefaultProps

export default Card
