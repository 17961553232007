import { Province } from 'domain/entities'

import { fields, idsCanalVenta } from 'app/constants'

import {
  getFormatedDate,
  DayMonthYearWithLeadingZerosSlashedWithHr,
  objectHelpers,
} from 'helpers'

const excludedValues = [
  fields.id,
  fields.tipoLead,
  fields.linkContinuar,
  fields.idEstadoDerivadora,
]

export const getCanalVentaLabel = (
  lead: any,
  isAllowed: boolean | void,
  provincies: Province[]
) => {
  if (lead) {
    let mappedLead: { [key: string]: any } = {}

    mappedLead = objectHelpers.excludeKeys(lead.data, excludedValues)

    Object.keys(mappedLead)
      .filter(e => {
        if (e === 'supervisor' && !isAllowed) return null

        return e
      })
      .forEach((key: any) => {
        const canalVenta =
          lead.data.idCanalVenta &&
          idsCanalVenta.find((item: any) => item.id === lead.data.idCanalVenta)

        if (key === 'idCanalVenta' && canalVenta)
          return (mappedLead[key] = canalVenta.label)

        if (key === 'fecha' && canalVenta)
          return (mappedLead[key] = getFormatedDate(
            lead.data[key],
            DayMonthYearWithLeadingZerosSlashedWithHr
          ))

        if (key === 'provincia') {
          return (mappedLead[key] = provincies.find(
            p => p.id === mappedLead[key]
          )?.nombre)
        }

        if (key === 'localidad') {
          return (mappedLead[key] = provincies
            ?.find(p => p.nombre === mappedLead['provincia'])
            ?.localidades.find(l => l.id === mappedLead[key])?.nombre)
        }

        return (mappedLead[key] = lead.data[key])
      })

    return { data: mappedLead }
  } else {
    return ''
  }
}
