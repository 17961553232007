import { Company } from 'domain/entities/companyPAD'
import { isString, isNumber } from 'helpers'

export interface ICompanyDTO {
  resultados: Company[]
  totalPaginas: number
  totalCantidad: number
}

export type CompanyDTO = {
  resultados: {
    id: number
    companyName?: string
    cuit?: string
    mail?: string
    codEmpresa?: string
    phone?: string
    provincia?: number
    localidad?: number
    logo?: string
    codigo?: string
    guid?: string
    dateOfEntry?: string
    codigoAsesor?: string
    estadoBackOffice?: string
    fechaIngreso?: string
    nombre?: string
    apellido?: string
    estado?: string
    dni?: string
  }[]
  totalPaginas: number
  totalCantidad: number
}

export const getCompaniesToModel = (raw: ICompanyDTO): CompanyDTO => {
  return {
    resultados: raw.resultados.map((c: Company) => ({
      id: isNumber(c.id, 'id'),
      companyName: isString(c.razonSocial, 'razonSocial', false),
      cuit: isString(c.cuit, 'cuit', false),
      dni: c.dni ? isString(c.dni, 'dni') : undefined,
      codigo: c.codigo ? isString(c.codigo, 'codigo', false) : undefined,
      guid: c.guid ? isString(c.guid, 'guid') : undefined,
      fechaIngreso: c.fechaIngreso
        ? isString(c.fechaIngreso, 'fechaIngreso')
        : undefined,
      nombre: c.nombre ? isString(c.nombre, 'nombre') : undefined,
      apellido: c.apellido ? isString(c.apellido, 'apellido') : undefined,
      estado: c.estado ? isString(c.estado, 'estado') : undefined,
    })),
    totalPaginas: isNumber(raw.totalPaginas, 'totalPaginas'),
    totalCantidad: isNumber(raw.totalCantidad, 'totalCantidad'),
  }
}
