import {
  IPolicyDTO,
  IPolicyResultDTO,
  IPolicyByIdDTO,
  IPolicyStateDTO,
} from 'domain/responseModel/policy/interfaces'
import {
  mapAssociate,
  mapBeneficiaries,
  mapDjs,
  mapPolicysResults,
  mapPolicy,
  statesByPolicyId,
} from 'domain/responseModel/policy/mappers'
import { isString } from 'helpers'

export const getPoliciesToModel = (raw: IPolicyDTO): IPolicyDTO => {
  return {
    totalCantidad: raw.totalCantidad,
    totalPaginas: raw.totalPaginas,
    resultados: mapPolicysResults(raw.resultados),
  }
}

export const getPolicyToModel = (raw: IPolicyResultDTO): IPolicyResultDTO => {
  return {
    seguro: mapPolicy(raw.seguro),
    socio: mapAssociate(raw.socio),
    beneficiarios: mapBeneficiaries(raw.beneficiarios),
    djsSocio: mapDjs(raw.djsSocio),
  }
}

export const getPoliciesByPolicyIdToModel = (
  raw: IPolicyByIdDTO
): IPolicyByIdDTO => {
  return {
    estados: statesByPolicyId(raw.estados),
  }
}

export const putPolicyStateToModel = (
  raw: IPolicyStateDTO
): IPolicyStateDTO => {
  return {
    message: isString(raw.message, 'message', false),
  }
}
