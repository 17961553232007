import { ProvinceParams, pagination } from './types'

import { ISelectFormat } from 'app/components'

export type getCompaniesRequestParams = {
  cuit: number
  razonSocial: string
  asesor: string
  ultimaActualizacion?: string
  provincia: ProvinceParams
  idState: ISelectFormat
  cantidad: number
  pagina: number
}

export type TGetCompaniesRequestModel = {
  cuit?: number
  razonSocial?: string
  ultimaActualizacion?: string
  asesor?: string
  idProvincia?: string
  idEstado?: number
  cantidad?: number
  pagina?: number
  orderBy?: string
  filtro?: string
  orderDirection?: string
}

export type getCompaniesRequestModel =
  | (pagination & TGetCompaniesRequestModel)
  | undefined

export const makeCompanyRequestModel = (
  raw: getCompaniesRequestParams
): getCompaniesRequestModel => {
  return {
    pagina: raw.pagina,
    cantidad: raw.cantidad,
    cuit: raw.cuit,
    razonSocial: raw.razonSocial,
    ultimaActualizacion: raw.ultimaActualizacion,
    asesor: raw.asesor,
    idProvincia: raw.provincia.value,
    idEstado: raw.idState?.value !== -1 ? raw.idState?.value : null,
  }
}

export type getCompanyRequestParam = {
  companyId: string
}

export type getCompanyStateParam = {
  idTipoEstado: number
  subTipo?: number
}

export type getFamilyGroupDetailParam = {
  guid: string
}

export type changeStateFGParams = {
  guid: string
  observaciones: string
  estado: number
}

export type createCompanyParam = {
  id: number
}
