import { Policy, Advisor, State } from 'domain/entities'

import { getFormatedDate, DayMonthYearWithLeadingZerosSlashed } from 'helpers'

type viewModelPoliciesType = {
  policies: Policy[]
  states: State[]
}

export const viewModelPolicies = (props: viewModelPoliciesType) => {
  return props.policies.map((e: Policy) => {
    const asesores = e.asesores?.map((e: Advisor) => e.nombre).join(', ')
    const stateDescription: State | undefined = props.states.find(
      s => s.id === e.estadoBackOffice
    )

    return {
      id: e.id,
      idSocio: e.idSocio,
      nombre: e.nombre,
      apellido: e.apellido,
      dni: e.dni,
      credencial: e.credencial,
      asesores: asesores,
      estadoBackOffice: stateDescription?.descripcion,
      fechaCotizacion:
        e.fechaCotizacion &&
        getFormatedDate(e.fechaCotizacion, DayMonthYearWithLeadingZerosSlashed),
      fechaVigencia:
        e.fechaVigencia &&
        getFormatedDate(e.fechaVigencia, DayMonthYearWithLeadingZerosSlashed),
      provincia: e.provincia,
      detalle: e.id,
    }
  })
}
