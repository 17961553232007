import styled from 'styled-components'

import { Text } from 'app/components/ui/Text'

export const NavBar = styled.nav`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background: ${({ theme }) => theme.colors.resolutionBlue};
  z-index: 1;
`

export const FlexContainer = styled.div`
  max-width: 120rem;
  display: flex;
  margin: auto;
  padding: 0 2rem;
  justify-content: space-between;
  height: 50px;
`

export const LogoLink = styled.div`
  display: flex;
  margin-top: 0.8rem;
`

export const NavLinks = styled.ul`
  display: flex;
  justify-content: space-between;
  width: 100%;
  justify-self: end;
  list-style-type: none;

  & div > a,
  span,
  #logout {
    display: flex;
    border-bottom: 1px solid transparent;
    margin: 0 1.5rem;
    transition: all 300ms linear 0s;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      font-weight: ${({ theme }) => theme.fontWeights.md};
      border-bottom: 1px solid ${({ theme }) => theme.colors.lightNavy};
    }

    @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
      display: none;
    }
  }

  #logout > span {
    margin: 0;
  }

  & div > a,
  span,
  div ::first-letter {
    text-transform: capitalize;
  }
`

export const BurgerWrapper = styled.div`
  margin: auto 0;

  @media (min-width: ${({ theme }) => theme.breakPoints.small}) {
    display: none;
  }
`
export const Wrapper = styled.div`
  position: relative;
  padding-top: 0.7rem;
  cursor: pointer;
  display: block;

  & span {
    background: ${({ theme }) => theme.colors.white};
    display: block;
    position: relative;
    width: 1.5rem;
    height: 0.1rem;
    margin-bottom: 0.5rem;
    transition: all ease-in-out 0.2s;
  }

  .open span:nth-child(2) {
    opacity: 0;
  }

  .open span:nth-child(3) {
    transform: rotate(45deg);
    top: -8px;
  }

  .open span:nth-child(1) {
    transform: rotate(-45deg);
    top: 11px;
  }
`

export const CollapseWrapper = styled.div`
  background: ${({ theme }) => theme.colors.resolutionBlue};
  position: fixed;
  width: 75vw;
  height: calc(100vh - 54px);
  top: 54px;
  right: 0;
  z-index: 1;
  box-shadow: 0px 0px 8px 8px rgba(0, 0, 0, 0.25);
`

export const CollapsedNavLinks = styled.ul`
  list-style-type: none;

  & li {
    transition: all 300ms linear 0s;
    margin: 10px 0;
  }

  & li:last-child {
    position: absolute;
    bottom: 50px;
  }

  & li:last-child {
    position: absolute;
    bottom: 50px;
  }

  & a {
    line-height: 2;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: ${({ theme }) => theme.colors.lightNavy};
      border-bottom: 1px solid ${({ theme }) => theme.colors.lightNavy};
    }
  }
`

export const Image = styled.img`
  width: 84px;
  height: 23px;
  margin: auto 0;
`

export const Line = styled.div`
  position: fixed;
  width: 100%;
  height: 4px;
  left: 0px;
  top: 50px;
  z-index: 1;

  background: ${({ theme }) => theme.colors.sail};
`

export const BrandText = styled(Text)`
  position: relative;
  top: 12px;
  margin-left: 2px;
`

export const SeparatorNavbar = styled.div`
  min-height: 50px;
`
