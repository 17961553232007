import { IDniEntityGateway } from 'domain/entityGateways/IDniEntityGateway'

export class UseCaseDni {
  private entityGateway: IDniEntityGateway

  constructor(entityGateway: IDniEntityGateway) {
    this.entityGateway = entityGateway
  }

  public async getDniByDocNumber(docNumber: string) {
    const response = this.entityGateway.getDniByDocNumber(docNumber)

    return response
  }
}
