// Use this url in order to name the color: http://chir.ag/projects/name-that-color
export const colors: Readonly<any> = {
  deepBlue: '#001689',
  powderBlue: '#a9d4ff',
  powderBlueTwo: '#c0dffe',
  darkishBlue: '#023979',
  darkishBlueTwo: '#00347c',
  flatBlue: '#396fa5',
  sapphire: '#2136a5',
  facebookBlue: '#4267B2',
  darkGreyBlue: '#34495e',
  paleGrey: '#edf6ff',
  paleGreyTwo: '#dfdce0',
  paleGreyThree: '#f3f3f3',
  paleGreyFour: '#cce8ff',
  paleGreyFive: '#f4faff',
  sky: '#7fbefe',
  lightPeriwinkle: '#d4e0ec',
  metallicBlue: '#517293',
  brownishGrey: '#696969',
  warmGrey: '#808080',
  warmGreyTwo: '#979797',
  warmGreyThree: '#9b9b9b',
  warmGreyFour: '#919191',
  carmine: '#ab0015',
  cornflowerBlue: '#5f9cd8',
  grey: '#d6d6d6',
  greyBlue: '#8aa7c3',
  paleBlue: '#e7f2fd',
  perrywinkle: '#88b7e6',
  chatbotGrey: '#464646',
  placeholderGrey: '#b6b6b6',
  green: '#417505',
  greenTwo: '#e7efde',
  cloudyBlue: '#bbccde',
  greyishBrown: '#4a4a4a',
  yellow: '#ebbf21',
  greyishBrownTwo: '#585858',
  lightGrey: '#dddddd',
  maize: '#ffdc4e',
  maize20: 'rgba(255, 220, 78, 0.2)',
  scarlet: '#d0021b',
  scarlet10: 'rgba(208, 2, 27, 0.1)',
  lightNavy: '#122c76',
  lightPink: '#ffe2e6',
  white: '#f4f4f4',
  emergency: '#910A0A',
  warning: '#FFED48',
  error: '#F3A3AF',
  red: '#FF0000',
  info: '#94CBFF',
  success: '#ABDA7B',
  toastSuccess: '#E7EFDE',
  concrete: '#F2F2F2',
  aliceBlue: '#F5FCFF',
  resolutionBlue: '#00387E',
  sail: '#A0D9F7',
  emperor: '#4F4F4F',
  mariner: '#2F79C2',
  alto: '#D8D8D8',
  midnightBlue: '#00205C',
  eucalyptus: '#208149',
  silver: '#C4C4C4',
  shipCove: '#678FB8',
  foam: '#ECF7FD',
  tundora: '#4D4D4D',
  chathamsBlue: '#123979',
  greyC6: '#C6C6C6',
  blueLight: '#3076D0',
  enterpriseBlue: '#2F6FC2',
}
