export const idsCanalVenta = [
  {
    label: 'FacebookQuieroSerContactado',
    id: 1,
  },
  {
    label: 'FacebookQuieroCotizar',
    id: 2,
  },
  {
    label: 'InstagramQuieroSerContactado',
    id: 3,
  },
  {
    label: 'InstagramQuieroCotizar',
    id: 4,
  },
  {
    label: 'WhatsappQuieroSerContactado',
    id: 5,
  },
  {
    label: 'OmintDigitalCotizacion',
    id: 6,
  },
  {
    label: 'WhatsappWebOmint',
    id: 7,
  },
  {
    label: 'Marketing',
    id: 8,
  },
]
