import React from 'react'
import { FormikProps } from 'formik'

import { TForm } from 'app/Pages/Policies/constants'
import { IPoliciesForm } from 'app/Pages/Policies/Form'

import { Row } from 'app/components'
import {
  InputField,
  SelectAsync,
  RangeDatePicker,
} from 'app/components/ui/forms'
import { Button, Text } from 'app/components/ui'
import { InputsFormContainer } from 'app/components/InputsFormContainer'

import * as Styles from 'app/Pages/Policies/style'

import { strings } from 'app/constants/strings'

const {
  forms: {
    fieldsName: {
      quantity,
      dni,
      estadoBackOffice,
      province,
      fechaCotizacion,
      codAsesor,
      exportList,
      resetPageOnSubmit,
    },
    fieldsTitle: {
      quantityTitle,
      dniTitle,
      estadoBackOfficeTitle,
      provinceTitle,
      fechaCotizacionTitle,
      codAsesorTitle,
    },
  },
  general: { searchPolicies },
} = strings

const FormInputs = (props: IPoliciesForm & FormikProps<TForm>) => (
  <InputsFormContainer>
    <Text textAlign={'center'} color={'resolutionBlue'} size={'lg'}>
      {searchPolicies}
    </Text>
    <Styles.FormInsurance>
      <InputField
        name={exportList}
        id={exportList}
        type="number"
        hidden={true}
        warning={props.errors.exportList && props.touched.exportList}
      />
      <Row flexDirectionColumn={true}>
        <InputField
          name={dni}
          id={dni}
          title={dniTitle}
          type="number"
          warning={props.errors.dni && props.touched.dni}
        />
        <RangeDatePicker
          id={fechaCotizacion}
          name={fechaCotizacion}
          title={fechaCotizacionTitle}
          onChange={props.setFieldValue}
        />
        <SelectAsync
          onChange={(option: any) => {
            props.setFieldValue(estadoBackOffice, option ? option : null)
          }}
          isClearable
          title={estadoBackOfficeTitle}
          name={estadoBackOffice}
          id={estadoBackOffice}
          placeholder="Seleccione"
          options={props.states?.map((b: any) => ({
            label: b.descripcion,
            value: b.id,
          }))}
          warning={
            props.errors.estadoBackOffice && props.touched.estadoBackOffice
          }
        />
      </Row>
      <Row flexDirectionColumn={true}>
        <SelectAsync
          onChange={(option: any) => {
            props.setFieldValue(province, option ? option : null)
          }}
          isClearable
          title={provinceTitle}
          name={province}
          id={province}
          placeholder="Seleccione"
          options={props.provincies?.map((b: any) => ({
            label: b.nombre,
            value: b.id,
          }))}
          warning={props.errors.provincia && props.touched.provincia}
        />
        <InputField
          name={codAsesor}
          id={codAsesor}
          title={codAsesorTitle}
          type="number"
          warning={props.errors.codAsesor && props.touched.codAsesor}
        />
        <InputField
          name={quantity}
          id={quantity}
          title={quantityTitle}
          warning={props.errors.cantidad && props.touched.cantidad}
        />
      </Row>
      <Row flexDirectionColumn={true}>
        <div>
          <Button
            type="submit"
            onClick={() => {
              props.setFieldValue(resetPageOnSubmit, true)
              props.setFieldValue(exportList, 0)
            }}
          >
            Buscar
          </Button>
        </div>
        <div>
          <Button
            type="button"
            onClick={() => {
              props.setFieldValue(resetPageOnSubmit, false)
              props.setFieldValue(exportList, 1)
              props.submitForm()
            }}
            outline={true}
          >
            Exportar
          </Button>
        </div>
        <div>
          <Button
            type="button"
            onClick={props.onPressExportRejectedPolicies}
            outline={true}
          >
            Exportar Rechazados
          </Button>
        </div>
        <div>
          <Button
            type="button"
            onClick={props.onPressExportAbandonedQuotes}
            outline={true}
          >
            Exportar Cotizaciones
          </Button>
        </div>
      </Row>
    </Styles.FormInsurance>
  </InputsFormContainer>
)

export default FormInputs
