import { Company } from 'domain/entities/company'
import { isString, isNumber } from 'helpers'

export interface ICompanyDTO {
  resultados: Company[]
  totalPaginas: number
  totalCantidad: number
}

export type CompanyDTO = {
  resultados: {
    id: number
    companyName?: string
    cuit?: string
    mail?: string
    phone?: string
    provincia?: number
    localidad?: number
    logo?: string
    codigo?: string
    guid?: string
    dateOfEntry?: string
    codigoAsesor?: string
    estadoBackOffice?: string
  }[]
  totalPaginas: number
  totalCantidad: number
}

export const getCompaniesToModel = (raw: ICompanyDTO): CompanyDTO => {
  return {
    resultados: raw.resultados.map((c: Company) => ({
      id: isNumber(c.id, 'id'),
      companyName: isString(c.razonSocial, 'razonSocial', false),
      cuit: isString(c.cuit, 'cuit', false),
      mail: isString(c.mail, 'mail', false),
      phone: isString(c.telefono, 'telefono'),
      provincia: isNumber(c.idProvincia, 'idProvincia'),
      localidad: isNumber(c.idLocalidad, 'idLocalidad', false),
      logo: c.logo ? isString(c.logo, 'logo') : undefined,
      codigo: c.codigo ? isString(c.codigo, 'codigo', false) : undefined,
      guid: c.guid ? isString(c.guid, 'guid') : undefined,
      dateOfEntry: isString(
        c.ultimaActualizacion,
        'ultimaActualizacion',
        false
      ),
      codigoAsesor: c.asesor && isString(c.asesor, 'asesor'),
      estadoBackOffice: c.estado && isString(c.estado, 'estadoBackOffice'),
    })),
    totalPaginas: isNumber(raw.totalPaginas, 'totalPaginas'),
    totalCantidad: isNumber(raw.totalCantidad, 'totalCantidad'),
  }
}
