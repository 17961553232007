import * as Yup from 'yup'

import { strings } from 'app/constants/strings'

const {
  forms: {
    requiredFields: { requiredField },
    validationFields: { date },
  },
} = strings

export const advisorAgendaSchema = Yup.object().shape({
  fechaBusqueda: Yup.date().typeError(date).required(requiredField),
})
