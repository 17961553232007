import { ILogger } from 'domain/entities'

import { endpoints } from 'app/constants'

export class DniServices {
  private httpClient: any
  private logger: any

  constructor(clientProvider: any, logger: ILogger) {
    this.httpClient = clientProvider
    this.logger = logger
  }

  public async getDniByDocNumber(dni: string): Promise<any> {
    try {
      const dniDownloaded = (
        await this.httpClient.get(
          endpoints.dniImage,
          { dni },
          {
            responseType: 'blob',
          }
        )
      ).data

      if (
        process.env.REACT_APP_ENV === 'test' ||
        process.env.REACT_APP_ENV === 'testing' ||
        process.env.REACT_APP_ENV === 'local'
      )
        return

      let url = window.URL.createObjectURL(dniDownloaded)
      let a = document.createElement('a')
      a.href = url
      a.download = `${dni}.png`
      a.click()
    } catch (error) {
      this.logger.error({
        message: 'Dni services',
        data: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
      })

      throw new Error(error)
    }
  }
}

export default {
  DniServices,
}
