import { httpClient, httpVentaDigitalClient, localClient } from 'api'

import { Logger } from 'helpers'
import { Login } from 'app/services/loginServices'
import { PoliciesService } from 'app/services/segurosServices'
import { ProvinciesServices } from 'app/services/provinciesServices'
import { LeadsServices } from 'app/services/leadsServices'
import { StateServices } from 'app/services/estadosServices'
import { AdvisorAgendaService } from 'app/services/agendaAsesoresServices'
import { SupervisorsService } from 'app/services/supervisorServices'
import { AdvisorServices } from 'app/services/asesorServices'
import { AfilliationServices } from 'app/services/afiliacionesServices'
import { DniServices } from 'app/services/dniServices'
import { CompanyService } from 'app/services/companyServices'
import { CompanyPADService } from 'app/services/companyPADServices'
import { HoldersService } from 'app/services/holderServices'
import { AcuerdoService } from './acuerdos'

const loginService = new Login(httpClient, Logger)
const policyService = new PoliciesService(httpClient, Logger)
const provinciesService = new ProvinciesServices(
  httpVentaDigitalClient,
  localClient,
  Logger
)
const leadsService = new LeadsServices(httpClient, localClient, Logger)
const stateService = new StateServices(httpClient, Logger)
const advisorAgendaService = new AdvisorAgendaService(httpClient, Logger)
const supervisorsService = new SupervisorsService(httpClient, Logger)
const asesorService = new AdvisorServices(httpClient, Logger)
const afilliationService = new AfilliationServices(httpClient, Logger)
const dniServices = new DniServices(httpClient, Logger)
const companyService = new CompanyService(httpClient, Logger)
const companyPADService = new CompanyPADService(httpClient, Logger)
const holdersService = new HoldersService(httpClient, Logger)
const acuerdoService = new AcuerdoService(httpClient, Logger)

export {
  loginService,
  policyService,
  provinciesService,
  leadsService,
  stateService,
  advisorAgendaService,
  supervisorsService,
  asesorService,
  afilliationService,
  dniServices,
  companyService,
  companyPADService,
  holdersService,
  acuerdoService,
}
