import { isString, isDateAndNotEmpty } from 'helpers'

export type TAdvisorListItem = {
  fecha: Date
  codigo: string
}

export type TUpdateListAdvisors = {
  asesores: TAdvisorListItem[]
}

export type TGetListAdvisorsParam = {
  fechaBusqueda: Date
}

export const makeUpdateListAdvisor = (
  raw: TUpdateListAdvisors
): TUpdateListAdvisors => {
  return {
    asesores: raw.asesores.map((e: TAdvisorListItem) => ({
      fecha: isDateAndNotEmpty(e.fecha, 'makeUpdateListAdvisor - fecha'),
      codigo: isString(e.codigo, 'makeUpdateListAdvisor - codigo'),
    })),
  }
}
