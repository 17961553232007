import { createSlice } from '@reduxjs/toolkit'
import { UseCaseProvince } from 'domain/useCases'
import { IProvinceDTO } from 'domain/responseModel'
import { provinciesService } from 'app/services'

const provinceController = new UseCaseProvince(provinciesService)

type initialStateReducer = {
  loading: boolean
  error: null
  provincies: IProvinceDTO
  isExportProvAndLocLoading: boolean
}

const INITIAL_STATE: initialStateReducer = {
  loading: false,
  error: null,
  provincies: {
    provincias: [],
    codigo: '',
    mensaje: '',
    puedeContinuar: false,
    status: 0,
  },
  isExportProvAndLocLoading: false,
}

export const slice = createSlice({
  name: 'province',
  initialState: INITIAL_STATE,
  reducers: {
    fetchProvinciesStart: state => {
      state.loading = true
      state.error = null
    },
    fetchProvinciesSuccess: (state, { payload }) => {
      state.loading = false
      state.error = null
      state.provincies = payload
    },
    fetchProvinciesFail: (state, { payload }) => {
      state.loading = false
      state.error = payload.message
    },
    fetchProvinciesAndLocalitiesStart: state => {
      state.isExportProvAndLocLoading = true
      state.error = null
    },
    fetchProvinciesAndLocalitiesSuccess: (state, { payload }) => {
      state.loading = false
      state.error = null
      state.isExportProvAndLocLoading = false
    },
    fetchProvinciesAndLocalitiesFail: (state, { payload }) => {
      state.isExportProvAndLocLoading = false
      state.error = payload.message
    },
  },
})

const {
  fetchProvinciesStart,
  fetchProvinciesSuccess,
  fetchProvinciesFail,
  fetchProvinciesAndLocalitiesStart,
  fetchProvinciesAndLocalitiesSuccess,
  fetchProvinciesAndLocalitiesFail,
} = slice.actions

export default slice.reducer

/**
 * Thunks
 */
export const getAllProvincies = () => async (dispatch: any, getState: any) => {
  try {
    dispatch(fetchProvinciesStart())

    const provincie: initialStateReducer = getState().provincie

    if (provincie.provincies.provincias.length)
      return dispatch(fetchProvinciesSuccess(provincie.provincies))

    const provs = await provinceController.getAllProvincies()

    dispatch(fetchProvinciesSuccess(provs))
  } catch (error) {
    dispatch(fetchProvinciesFail(error))
  }
}

export const getProvinciesAndLocalitiesCodes = () => async (
  dispatch: any,
  getState: any
) => {
  try {
    dispatch(fetchProvinciesAndLocalitiesStart())

    const response = await provinceController.getProvinciesAndLocalitiesCodes()

    dispatch(fetchProvinciesAndLocalitiesSuccess(response))
  } catch (error) {
    dispatch(fetchProvinciesAndLocalitiesFail(error))
  }
}

/**
 * Selectors
 */

export const selectProvincies = (state: any) =>
  state.provincie.provincies?.provincias
