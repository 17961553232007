import React, { useState, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { IconDotsHorizontal } from 'app/components/Icons'
import { MessageContext } from 'app/components/ui/Message'
import { Text } from 'app/components/ui'
import { Card } from 'app/components/ui/LeadsTable/card'

import {
  Items,
  LinkDetailIcon,
  TItem,
  ItemDefaultProps,
} from 'app/components/ui/GenericTable'

export type ItemProps = TItem & {
  cardProps: string[]
  path: string
} & typeof ItemDefaultProps

export const Item = (props: ItemProps) => {
  const [mappedFields, setMappedFields] = useState({})
  const message = useContext(MessageContext)
  const [displayItem, setDisplayItem] = useState(false)
  const [displayDots, setDisplayDots] = useState(true)
  const [displayProps, setDisplayProps] = useState('')
  let history = useHistory()

  const handleOnPressItem = () => {
    setDisplayItem(!displayItem)
    setDisplayDots(!displayDots)
  }

  useEffect(() => {
    if (displayItem) {
      setDisplayProps('none')
    } else {
      setDisplayProps('block')
    }
  }, [displayItem])

  useEffect(() => {
    let mapDataFromHeaders = {}

    for (let a of props.tableHeaders) {
      Object.assign(mapDataFromHeaders, { [a]: props.data[a] })
    }

    setMappedFields(mapDataFromHeaders)
  }, [props.tableHeaders, props.data])

  const handleOnCopiedSuccessfully = () =>
    message.setMessageProps({
      displayMessage: true,
      message: '¡Texto Copiado!',
    })

  const handleOnCopiedFailed = () =>
    message.setMessageProps({
      displayMessage: true,
      message: '¡Error al copiar!',
      backgroundColor: 'scarlet',
    })

  const handleCopyToClipboard = async (e: string) => {
    try {
      await navigator.clipboard.writeText(e)

      handleOnCopiedSuccessfully()
    } catch (err) {
      console.error('Error in copying text: ', err)

      handleOnCopiedFailed()
    }
  }

  const itemFields = Object.keys(mappedFields).map((e: any, i: number) => (
    <Items key={i} className={'cardItem'}>
      {e === 'linkContinuar' ? (
        <Text
          textAlign={'center'}
          size={'sm'}
          onClick={() => handleCopyToClipboard(props.data['linkContinuar'])}
          color={'midnightBlue'}
          underlined
          isCursor
        >
          Copiar link
        </Text>
      ) : (
        <>
          {e === 'detalle' && props.detailColumnExist ? (
            <IconDotsHorizontal
              className={'detailIcon'}
              color={'darkGreyBlue'}
              size={'xs'}
              onClick={() =>
                history.push(
                  `${props.path}/${props.data['detalle']}/${props.data.tipoLead}`
                )
              }
            />
          ) : (
            <>
              <Text
                size={'sm'}
                color={'midnightBlue'}
                textAlign={'center'}
                display={displayProps}
              >
                {props.data[e]}
              </Text>
              {/* Mobile only */}
              {i === 2 && (
                <>
                  <LinkDetailIcon>
                    {displayDots && (
                      <IconDotsHorizontal
                        className={'detailIcon'}
                        color={'darkGreyBlue'}
                        size={'xs'}
                        onClick={() => handleOnPressItem()}
                      />
                    )}
                  </LinkDetailIcon>
                  <Card
                    data={props.data}
                    displayCard={displayItem}
                    onPress={handleOnPressItem}
                    onPressCopy={handleCopyToClipboard}
                  />
                </>
              )}
            </>
          )}
        </>
      )}
    </Items>
  ))

  return (
    <Items>
      <div className={'cardItemContainer'}>{itemFields.map(e => e)}</div>
    </Items>
  )
}

Item.defaultProps = ItemDefaultProps

export default Item
