import React from 'react'
import {
  SelectAsync,
  TSelectAsync,
  SelectAsyncDefaultProps,
} from 'app/components/ui/forms'
import {
  DropdownContainer,
  InputTitle,
  InputHint,
} from 'app/components/ui/styles'

import { colors } from 'app/styles/colors'

const LeadsDropdown = (props: TSelectAsync) => {
  const { color, title, hint, ...rest } = props
  const valueColor = props.isDisabled === true ? colors.placeholderGrey : color

  return (
    <>
      {props.title && <InputTitle>{props.title}</InputTitle>}
      {props.hint && <InputHint>{props.hint}</InputHint>}
      <DropdownContainer color={valueColor}>
        <SelectAsync {...rest} />
      </DropdownContainer>
    </>
  )
}

LeadsDropdown.defaultProps = SelectAsyncDefaultProps

export default LeadsDropdown
