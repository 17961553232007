import React from 'react'

import styled, { css, keyframes } from 'styled-components'

const fadeIn = keyframes`
  from { opacity: 0.3; }
`

export const iconSizes: any = {
  xxs: css`
    width: ${({ theme }) => theme.iconSizes.xxs};
    height: ${({ theme }) => theme.iconSizes.xxs};
  `,
  xs: css`
    width: ${({ theme }) => theme.iconSizes.xs};
    height: ${({ theme }) => theme.iconSizes.xs};
  `,
  md: css`
    width: ${({ theme }) => theme.iconSizes.md};
    height: ${({ theme }) => theme.iconSizes.md};
  `,
  sm: css`
    width: ${({ theme }) => theme.iconSizes.sm};
    height: ${({ theme }) => theme.iconSizes.sm};
  `,
}

export const StyledIconWrapper = styled(
  ({ loading, enabled, centered, isCursor, ...rest }) => <div {...rest} />
)`
  ${({ styles }) =>
    css`
      ${styles}
    `}
  ${({ size }) => iconSizes[size]}
  color: ${({ enabled, color, theme }) => {
    return enabled ? theme.colors[color] || color : theme.silver
  }}!important;
  ${({ loading }) =>
    loading &&
    css`
      animation: ${fadeIn} 0.7s infinite alternate;
    `}
  ${({ centered }) =>
    centered &&
    css`
      margin: auto;
    `}
  ${({ marginZero }) =>
    marginZero &&
    css`
      margin: 0;
    `}
  ${props => props.isCursor && `cursor: pointer;`}
  margin: ${props => props.margin || ''};
`
