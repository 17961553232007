import { createGlobalStyle } from 'styled-components'
import { normalize } from 'styled-normalize'

const GlobalStyle = createGlobalStyle`
  ${normalize}

  html {
    box-sizing: border-box;
  }

 *,
 *::before,
 *::after {
    box-sizing: inherit;
  }

  html, body{
    height: 100%;
    margin: 0;
  }

  #root{
    height: 100%;
  }

  p {
    margin: 0;
  }

  input, textarea, select, button {
  }
  
  button, a {
    cursor: pointer;
  }

  button {
    :focus {outline:0;}
  }
`

export default GlobalStyle
