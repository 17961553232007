import React from 'react'
import ReactLoading from 'react-loading'

import StyledComponent from 'app/components/ui/Button/style'

import { colors } from 'app/styles/colors'
import { DownloadIcon } from '../../Icons'

export type ButtonProps = {
  small?: boolean
  outline?: boolean
  fullWidth?: boolean
  disabled?: boolean
  type?: string
  onClick?: (data: any) => void
  variant?: string
  children: string
  centered?: boolean
  display?: string
  isLoading?: boolean
  showIcon?: boolean
} & typeof defaultProps

const defaultProps = {
  small: false,
  outline: false,
  fullWidth: false,
  disabled: false,
  type: 'button',
  onClick: (data: any) => {},
  variant: 'primary',
  children: '',
  centered: true,
  isLoading: false,
  showIcon: false,
}

export const Button = (props: ButtonProps) => {
  return (
    <StyledComponent {...props} name={props.children}>
      {props.showIcon && <DownloadIcon />}
      {props.isLoading ? (
        <ReactLoading
          height={'30px'}
          width={'30px'}
          type="spin"
          color={colors.cornflowerBlue}
        />
      ) : (
        props.children
      )}
    </StyledComponent>
  )
}

Button.defaultProps = defaultProps
