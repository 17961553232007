import * as Yup from 'yup'
import { strings } from 'app/constants/strings'

const {
  forms: { requiredFields, validationFields },
} = strings

const [fieldMinLength, fieldMaxLength] = [2, 20]

export const LoginSchema = Yup.object().shape({
  userName: Yup.string()
    .min(fieldMinLength, validationFields.userName)
    .max(fieldMaxLength, validationFields.userName)
    .required(requiredFields.userName),
  password: Yup.string()
    .min(fieldMinLength, validationFields.password)
    .max(fieldMaxLength, validationFields.password)
    .required(requiredFields.password),
})
