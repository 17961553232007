import styled from 'styled-components'

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 6px;
  > button {
    width: 180px;
    margin-top: 32px;
  }
`

export const Center = styled.div`
  display: flex;
  justify-content: space-around;
  margin-right: 10px;
`
