import { createSlice, createSelector } from '@reduxjs/toolkit'

import { RootState } from 'app/store/reducers'
import { AdvisorAgenda } from 'domain/entities'
import { UseCaseAdvisorAgenda } from 'domain/useCases'
import { advisorAgendaService } from 'app/services'

import {
  TGetListAdvisorsParam,
  TUpdateListAdvisors,
  TAdvisorListItem,
} from 'domain/requestModel'

const advisorAgendaController = new UseCaseAdvisorAgenda(advisorAgendaService)

type initialStateReducer = {
  loading: boolean
  error: null
  asesores: AdvisorAgenda[]
  isExportAdvisorListLoading: boolean
}

const INITIAL_STATE: initialStateReducer = {
  loading: false,
  error: null,
  asesores: [],
  isExportAdvisorListLoading: false,
}

export const slice = createSlice({
  name: 'auth',
  initialState: INITIAL_STATE,
  reducers: {
    fetchListAdvisorAgenda: state => {
      state.loading = true
      state.error = null
    },
    fetchListAdvisorAgendaSuccess: (state, { payload }) => {
      state.loading = false
      state.error = null
      state.asesores = payload.asesores
    },
    fetchListAdvisorAgendaFail: (state, { payload }) => {
      state.loading = false
      state.error = payload.message
    },
    fetchUpdateListAdvisorAgenda: state => {
      state.loading = true
      state.error = null
    },
    fetchUpdateListAdvisorAgendaSuccess: (state, { payload }) => {
      state.loading = false
      state.error = null
      state.asesores = payload.asesores
    },
    fetchUpdateListAdvisorAgendaFail: (state, { payload }) => {
      state.loading = false
      state.error = payload.message
    },
    fetchExportAdvisorListStart: state => {
      state.isExportAdvisorListLoading = true
      state.error = null
    },
    fetchExportAdvisorListSuccess: (state, { payload }) => {
      state.isExportAdvisorListLoading = false
      state.error = null
    },
    fetchExportAdvisorListFail: (state, { payload }) => {
      state.isExportAdvisorListLoading = false
      state.error = payload.message
    },
  },
})

export const {
  fetchListAdvisorAgenda,
  fetchListAdvisorAgendaSuccess,
  fetchListAdvisorAgendaFail,
  fetchUpdateListAdvisorAgenda,
  fetchUpdateListAdvisorAgendaSuccess,
  fetchUpdateListAdvisorAgendaFail,
  fetchExportAdvisorListStart,
  fetchExportAdvisorListSuccess,
  fetchExportAdvisorListFail,
} = slice.actions

export default slice.reducer

/**
 * Thunks
 */
export const getAvisorsAgenda = (params: TGetListAdvisorsParam) => async (
  dispatch: any
) => {
  try {
    dispatch(fetchListAdvisorAgenda())

    const response = await advisorAgendaController.getListAdvisors({
      ...params,
    })

    dispatch(fetchListAdvisorAgendaSuccess(response))
  } catch (error) {
    dispatch(fetchListAdvisorAgendaFail(error))
  }
}

export const updateListAdvisors = (params: TUpdateListAdvisors) => async (
  dispatch: any,
  getState: any
) => {
  try {
    dispatch(fetchUpdateListAdvisorAgenda())

    const state: RootState = getState()

    // just send only where changes and not all the month
    const filteredAdvisors: TUpdateListAdvisors = {
      asesores: params.asesores.filter((a: TAdvisorListItem, index: number) => {
        return (
          a.codigo !== state.advisorAgenda.asesores[index].codigoAsesor && a
        )
      }),
    }

    const AdvisorAgendaDefault: AdvisorAgenda = {
      fecha: new Date(),
      codigoAsesor: '',
      nombre: '',
      tel: '',
    }

    await advisorAgendaController.updateListAdvisors(filteredAdvisors)

    // update item locally avoiding another api call to display updated data
    const updatedAdvisorList: AdvisorAgenda[] = state.advisorAgenda.asesores.map(
      (advisor: AdvisorAgenda) => {
        const findItemByDate = params.asesores.find(
          a => a.fecha === advisor.fecha
        )
        const findItemByCode: AdvisorAgenda =
          state.advisorAgenda.asesores.find(
            a => a.codigoAsesor === findItemByDate?.codigo
          ) || AdvisorAgendaDefault

        // change advisor data but not the date of the item
        const updateItem: AdvisorAgenda = Object.assign(
          { ...findItemByCode },
          { fecha: advisor.fecha }
        )

        return findItemByDate ? updateItem : advisor
      }
    )

    dispatch(
      fetchUpdateListAdvisorAgendaSuccess({ asesores: updatedAdvisorList })
    )
  } catch (error) {
    dispatch(fetchUpdateListAdvisorAgendaFail(error))
  }
}

export const exportAdvisorsExcel = (params: TGetListAdvisorsParam) => async (
  dispatch: any
) => {
  try {
    dispatch(fetchExportAdvisorListStart())

    const response = await advisorAgendaController.exportAdvisorsExcel(params)

    dispatch(fetchExportAdvisorListSuccess(response))
  } catch (error) {
    dispatch(fetchExportAdvisorListFail(error))
  }
}

/**
 * Selectors
 */

export const selectSelf = (state: RootState) => state.advisorAgenda
export const selectAdvisorsList = createSelector(
  selectSelf,
  advisorAgenda => advisorAgenda.asesores
)
export const selectAdvisorsListIsLoading = createSelector(
  selectSelf,
  advisorAgenda => advisorAgenda.loading
)
