import React, { FC } from 'react'
import styled from 'styled-components'
import ReactPaginate from 'react-paginate'

type TPagination = {
  pageFieldName: string
  resetFieldName: string
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void
  pageCount: number
  submit: () => void
}

const Pagination: FC<TPagination> = props => {
  const handleOnPressPageChange = (params: any) => {
    // on pagination, sets to false this field in order to NOT reset to 0 the number of page in the request
    props.setFieldValue(props.resetFieldName, false)
    // set the number
    props.setFieldValue(props.pageFieldName, params.selected)
    props.submit()
  }

  return (
    <StyledPaginateContainer>
      <ReactPaginate
        previousLabel={'<'}
        nextLabel={'>'}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={props.pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={1}
        onPageChange={handleOnPressPageChange}
        containerClassName={'pagination'}
        activeClassName={'active'}
      />
    </StyledPaginateContainer>
  )
}

export default React.memo(Pagination)

/**
 * STYLES
 */

const StyledPaginateContainer = styled.div`
  .pagination {
    color: #0366d6;
    display: inline-flex;
    list-style-type: none;
  }
  .break-me {
    cursor: default;
  }
  .active {
    border-color: transparent;
    background-color: #0366d6;
    color: white;
    border-radius: 5px;
  }
  ul > li {
    padding: 10px 10px;
  }
`
