import { ILogger } from 'domain/entities'

import {
  TGetListAdvisorsParam,
  makeUpdateListAdvisor,
  TUpdateListAdvisors,
} from 'domain/requestModel'
import { TGetListAdvisors, advisorAgendaToModel } from 'domain/responseModel'

import { endpoints } from 'app/constants'

export class AdvisorAgendaService {
  private httpClient: any
  private logger: any

  constructor(clientProvider: any, logger: ILogger) {
    this.httpClient = clientProvider
    this.logger = logger
  }

  public async getListAdvisors(
    params: TGetListAdvisorsParam
  ): Promise<TGetListAdvisors> {
    try {
      const advisorList = (
        await this.httpClient.get(endpoints.agendaAsesoresListado, params)
      ).data

      return advisorAgendaToModel(advisorList)
    } catch (error) {
      this.logger.error({
        message: 'UseCaseAdvisorAgenda - getListAdvisors',
        data: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
      })

      throw new Error(error)
    }
  }

  public async updateListAdvisors(params: TUpdateListAdvisors): Promise<any> {
    try {
      const listAdvisors: TUpdateListAdvisors = makeUpdateListAdvisor(params)

      if (!listAdvisors.asesores.length) return

      const advisorList = (
        await this.httpClient.post(
          endpoints.actualizarAgendaAsesores,
          listAdvisors
        )
      ).data

      return advisorList
    } catch (error) {
      this.logger.error({
        message: 'UseCaseAdvisorAgenda - updateListAdvisors',
        data: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
      })

      throw new Error(error)
    }
  }

  public async exportAdvisorsExcel(
    params: TGetListAdvisorsParam
  ): Promise<any> {
    try {
      const advisorListExported = (
        await this.httpClient.get(endpoints.agendaAsesoresExcel, params, {
          responseType: 'blob',
        })
      ).data

      let url = window.URL.createObjectURL(advisorListExported)
      let a = document.createElement('a')
      a.href = url
      a.download = 'listado-de-asesores.xlsx'
      a.click()
    } catch (error) {
      this.logger.error({
        message: 'UseCaseAdvisorAgenda - exportAdvisorList',
        data: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
      })

      throw new Error(error)
    }
  }
}

export default {
  AdvisorAgendaService,
}
