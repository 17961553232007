import moment from 'moment'
import 'moment/locale/es'

moment.locale('es')

// TODO: define what type of value will return each function.
// now all will return a (moment formated date/ empty string/ throw an error/ same input)
// force to always return a date

type TMomentOptions = {
  years: moment.unitOfTime.DurationConstructor
  quarters: moment.unitOfTime.DurationConstructor
  months: moment.unitOfTime.DurationConstructor
  weeks: moment.unitOfTime.DurationConstructor
  days: moment.unitOfTime.DurationConstructor
  hours: moment.unitOfTime.DurationConstructor
  minutes: moment.unitOfTime.DurationConstructor
  seconds: moment.unitOfTime.DurationConstructor
  milliseconds: moment.unitOfTime.DurationConstructor
}

export const momentOptions: TMomentOptions = {
  years: 'years',
  quarters: 'quarters',
  months: 'months',
  weeks: 'weeks',
  days: 'days',
  hours: 'hours',
  minutes: 'minutes',
  seconds: 'seconds',
  milliseconds: 'milliseconds',
}

// https://docs.oracle.com/cd/E41183_01/DR/Date_Format_Types.html
export const DayMonthYearWithLeadingZerosSlashed = 'DD/MM/YYYY'
export const DayMonthYearWithLeadingZerosDashed = 'DD-MM-YYYY'
export const DayWithDayNumber = 'dddd D'
export const MonthNameWithYearNumber = 'MMMM YYYY'
export const YearMonthDayWithLeadingZerosDashed = 'YYYY-MM-DD'
export const DayMonthYearWithLeadingZerosSlashedWithHr =
  'DD/MM/YYYY, HH:mm [hrs]'
export const DayMonthYearWithLeadingZerosSlashedWithHrAndDash =
  'DD/MM/YYYY - HH:mm [hrs]'

export const getFormatedDate = (date: Date, format?: string): string => {
  try {
    if (!date) return ''

    const isValidDate = moment(date).isValid()
    if (!isValidDate) throw new Error('Invalid Date')

    return moment(date).format(format)
  } catch (e) {
    console.log(e)

    return ''
  }
}

export const incrementDate = (
  date: Date,
  quantity: number,
  momentOption: moment.unitOfTime.DurationConstructor
): Date => {
  try {
    if (!date) throw new Error('Provide a Date')

    const isValidDate = moment(date).isValid()

    if (!isValidDate) throw new Error('Invalid Date')

    const incrementedDate = moment(date)
      .add(quantity, momentOption)
      .format('MM/DD/YYYY')

    return new Date(incrementedDate)
  } catch (e) {
    console.log(e)

    return date
  }
}

export const decreaseDate = (
  date: Date,
  quantity: number,
  momentOption: moment.unitOfTime.DurationConstructor
): Date => {
  try {
    if (!date) throw new Error('Provide a Date')

    const isValidDate = moment(date).isValid()

    if (!isValidDate) throw new Error('Invalid Date')

    return new Date(
      moment(date).subtract(quantity, momentOption).format('MM/DD/YYYY')
    )
  } catch (e) {
    console.log(e)

    return date
  }
}
