export const pages = {
  home: {
    name: 'home',
    path: '/',
  },
  dashboard: {
    name: 'dashboard',
    path: '/',
  },
  unfoundCredential: {
    name: 'unfoundCredential',
    path: '/unfoundCredential',
  },
  policies: {
    name: 'pólizas',
    path: '/polizas',
  },
  backoffice: {
    name: 'backoffice',
    path: '/backoffice',
  },
  policy: {
    name: 'poliza',
    path: '/poliza',
  },
  leads: {
    name: 'derivadora',
    path: '/derivadora',
  },
  assignments: {
    name: 'asignaciones',
    path: '/asignaciones',
  },
  afilliations: {
    name: 'area médica',
    path: '/afiliaciones',
  },
  medicAreaReports: {
    name: 'reportes AM',
    path: '/reportesam',
  },
  companyInfo: {
    name: 'Empresas',
    path: '/empresas',
  },
  padEmpresas: {
    name: 'PAD Empresas',
    path: '/padEmpresas',
  },
}
