import { PolicyState } from 'domain/entities'
import { ISelectFormat } from 'app/components/ui/forms'

export const mapDataForDropdown = (data: PolicyState[]): ISelectFormat[] =>
  data.map((poliza: PolicyState) => {
    return {
      value: poliza.id,
      label: poliza.descripcion,
    }
  })
