import { fields } from 'app/constants'

export const initialValues = {
  cuit: '',
  provincia: { label: '', value: '' },
  advisor: { label: '', value: '' },
  fechaCotizacion: undefined,
  exportList: 0,
  estadoBackOffice: { value: -1, label: '' },
  afiliationType: '',
}

export const tableDesktopHeaders: string[] = [
  fields.dateOfEntry,
  fields.companyName,
  fields.cuit,
  fields.provincia,
  fields.codigoAsesor,
  fields.estadoBackOffice,
  fields.detalle,
]

export const tableMobileHeaders: string[] = [
  fields.dateOfEntry,
  fields.companyName,
  fields.estadoBackOffice,
  fields.detalle,
]

export const tableHoldersHeaders: string[] = [
  fields.documento,
  fields.nombreYApellido,
  fields.estadoBackOffice,
  fields.detalle,
]
