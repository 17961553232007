import { ILogger, LoggerType } from 'domain/entities'

class LocalLogger implements ILogger {
  private loggerClient: any = console

  public log(loggerType: LoggerType): void {
    this.loggerClient['log'](loggerType.message, loggerType.data)
  }

  public warn(loggerType: LoggerType): void {
    this.loggerClient['warn'](loggerType.message, loggerType.data)
  }

  public error(loggerType: LoggerType): void {
    this.loggerClient['error'](loggerType.message, loggerType.data)
  }

  public info(loggerType: LoggerType): void {
    this.loggerClient['info'](loggerType.message, loggerType.data)
  }
}

// Another implementation
export class DevelopLogger implements ILogger {
  private loggerClient: any = console

  public log(loggerType: LoggerType): void {
    this.loggerClient['log'](loggerType.message, loggerType.data)
  }

  public warn(loggerType: LoggerType): void {
    this.loggerClient['warn'](loggerType.message, loggerType.data)
  }

  public error(loggerType: LoggerType): void {
    this.loggerClient['error'](loggerType.message, loggerType.data)
  }

  public info(loggerType: LoggerType): void {
    this.loggerClient['info'](loggerType.message, loggerType.data)
  }
}

export const Logger = (() => {
  const reactAppEnv = process.env.REACT_APP_ENV

  if (reactAppEnv === 'dev') return new DevelopLogger()

  return new LocalLogger()
})()
