import * as Yup from 'yup'

import { HolderEventEnum } from 'domain/entities/holderEventEnum'

import { strings } from 'app/constants/strings'

import * as C from 'app/components'

export const initialValues = {
  estado: 0,
  observaciones: '',
  guid: '',
}

const {
  forms: {
    fieldsName: { observations, detail },
    requiredFields: { requiredField },
  },
} = strings

export type TForm = {
  observations: any
  detail: string
}

export type TModalContent = {
  onSubmit: (values: TForm) => void
  onCancel: () => void
}

type HolderObservationsType = {
  label: string
  value: HolderEventEnum
}

const newObservationInitialValues = {
  observations: {
    label: '',
    value: '',
  },
  detail: '',
}

const observationsType: HolderObservationsType[] = [
  {
    label: 'Observación',
    value: HolderEventEnum.observation,
  },
  {
    label: 'Llamado',
    value: HolderEventEnum.call,
  },
]

const newObservationValidationSchema = Yup.object().shape({
  [observations]: Yup.mixed().test(
    observations,
    requiredField,
    value => value.value
  ),
  [detail]: Yup.string().required(requiredField),
})

const detailFormInitialValues = (
  associates: C.ISelectFormat[]
): {
  associate: C.ISelectFormat
} => ({ associate: associates[0] })

export default {
  newObservationValidationSchema,
  newObservationInitialValues,
  observationsType,
  detailFormInitialValues,
}
