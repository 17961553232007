import { Beneficiary } from 'domain/entities'

import {
  EXCLUDE_PARTNER_KEYS,
  EXCLUDE_DJS_KEYS,
  EXCLUDE_BENEFICIARIES_KEYS,
  EXLUDE_DJS_ANSWERS_KEYS,
} from 'app/Pages/Policy/constants'

import {
  DayMonthYearWithLeadingZerosSlashed,
  objectHelpers,
  getFormatedDate,
} from 'helpers'

import { docTypes, fields, relationShips } from 'app/constants'

export const viewModelPolicy = (props: any) => {
  const mappedPolicy = props?.seguro && {
    ...props.seguro,
    tipoDoc: docTypes[props.seguro.tipoDoc],
  }
  let mapedPartner: any = {}

  props?.socio &&
    Object.keys(props.socio).forEach((e: string) => {
      if (e === fields.fechaDeNacimiento) {
        const formatedDate = getFormatedDate(
          props.socio[e],
          DayMonthYearWithLeadingZerosSlashed
        )

        return Object.assign(mapedPartner, {
          [fields.fechaDeNacimiento]: formatedDate,
        })
      }

      return Object.assign(mapedPartner, { [e]: props.socio[e] })
    })

  let exludedPartnerKeys =
    props?.socio &&
    objectHelpers.excludeKeys(mapedPartner, EXCLUDE_PARTNER_KEYS)

  const mappedBeneficiaries = props?.beneficiarios?.map((e: Beneficiary) => {
    let data = { ...e }

    Object.assign(data, {
      tipoDoc: docTypes[e.tipoDoc],
      parentesco: relationShips[e.parentesco],
    })

    return objectHelpers.excludeKeys(data, EXCLUDE_BENEFICIARIES_KEYS)
  })

  const mappedDjs: any =
    props?.djsSocio &&
    objectHelpers.excludeKeys(props.djsSocio, EXCLUDE_DJS_KEYS)

  const mappedDjsQuestions =
    mappedDjs &&
    mappedDjs?.respuestas?.map((e: any) =>
      objectHelpers.excludeKeys(e, EXLUDE_DJS_ANSWERS_KEYS)
    )
  const mappeDjs =
    props?.djsSocio &&
    Object.assign(mappedDjs, { respuestas: mappedDjsQuestions })

  return {
    seguro: mappedPolicy,
    socio: exludedPartnerKeys,
    beneficiarios: mappedBeneficiaries,
    djsSocio: mappeDjs,
  }
}
