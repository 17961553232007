import { AdvisorAgenda } from 'domain/entities'

import {
  TAdvisorList,
  TAdvisorListViewModel,
} from 'app/Pages/AdvisorAgenda/types'

import { getFormatedDate, DayWithDayNumber } from 'helpers'

export const viewModelPolicies = (
  props: TAdvisorList
): TAdvisorListViewModel[] => {
  return props.advisorsList.map((e: AdvisorAgenda) => {
    return {
      date: e.fecha,
      day: getFormatedDate(e.fecha, DayWithDayNumber),
      assignedSupervisor: { value: e.codigoAsesor, label: e.nombre },
      contactNumber: e.tel,
    }
  })
}
