import { createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store/reducers'
import { ISelectFormat } from 'app/components'
import { UseCaseAcuerdo } from '../../domain/useCases/acuerdos'
import { acuerdoService } from 'app/services'
import {
  Acuerdo,
  TGetAcuerdosParams,
  TUpdateAcuerdoParams,
} from '../../domain/requestModel/acuerdos'

const acuerdoController = new UseCaseAcuerdo(acuerdoService)

type initialStateReducer = {
  loading: boolean
  acuerdos: Acuerdo[]
  error: string
}

const INITIAL_STATE: initialStateReducer = {
  loading: false,
  acuerdos: [],
  error: '',
}

export const slice = createSlice({
  name: 'acuerdo',
  initialState: INITIAL_STATE,
  reducers: {
    fetchAcuerdosStart: state => {
      state.loading = true
      state.error = ''
    },
    fetchAcuerdosSuccess: (state, { payload }) => {
      state.loading = false
      state.error = ''
      state.acuerdos = payload
    },
    fetchAcuerdosFail: (state, { payload }) => {
      state.loading = false
      state.error = payload.message
    },
    updateAcuerdoStart: state => {
      state.loading = true
      state.error = ''
    },
    updateAcuerdoSuccess: state => {
      state.loading = false
      state.error = ''
    },
    updateAcuerdoFail: (state, { payload }) => {
      state.loading = false
      state.error = payload.message
    },
  },
})

const {
  fetchAcuerdosStart,
  fetchAcuerdosSuccess,
  fetchAcuerdosFail,
  updateAcuerdoStart,
  updateAcuerdoSuccess,
  updateAcuerdoFail,
} = slice.actions

export default slice.reducer

export const getAcuerdos = (params: TGetAcuerdosParams) => async (
  dispatch: any
) => {
  try {
    dispatch(fetchAcuerdosStart())
    const response = await acuerdoController.getAcuerdos(params)
    dispatch(fetchAcuerdosSuccess(response))
  } catch (error) {
    dispatch(fetchAcuerdosFail(error))
  }
}

export const updateAcuerdo = (params: TUpdateAcuerdoParams) => async (
  dispatch: any
) => {
  try {
    dispatch(updateAcuerdoStart())
    await acuerdoController.updateAcuerdo(params)
    dispatch(updateAcuerdoSuccess())
  } catch (error) {
    dispatch(updateAcuerdoFail(error))
  }
}

export const selectAcuerdos = (state: RootState): ISelectFormat[] =>
  state.acuerdo.acuerdos.map(
    (a: Acuerdo): ISelectFormat => ({
      value: a.idPlan,
      label: a.codi,
    })
  )

export const selectAcuerdosIsFetching = (state: RootState) =>
  state.acuerdo.loading
