import React, { FC } from 'react'
import styled from 'styled-components'
import { Button, Text } from './ui'

import successIcon from 'app/images/Ok.svg'
import errorIcon from 'app/images/Alerta.svg'

const Logo = styled.img`
  width: 60px;
  height: 60px;
`

const Title = styled(Text)``

const Label = styled(Text)``

const Code = styled(Text)``

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`

const MessageStatusContainer = styled.div`
  width: 77%;
  display: flex;
  flex-direction: column;
  gap: 48px;
  background-color: #fff;
  border-radius: 12px;
  border: 1px solid #fff;
  padding: 60px 0;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
`

const PaperModeStatus: FC<{
  onClickHandler: () => void
}> = ({ onClickHandler }) => (
  <>
    <InfoContainer>
      <Logo src={successIcon} alt="success" />
      <Title color={'mariner'} weight={'md'} size={'xl'}>
        La solicitud continua en modalidad papel
      </Title>
    </InfoContainer>
    <Button onClick={onClickHandler}>Volver al inicio</Button>
  </>
)

const SuccessRegisterStatus: FC<{
  companyCode?: number | string
  onClickHandler: () => void
}> = ({ companyCode, onClickHandler }) => (
  <>
    <InfoContainer>
      <Logo src={successIcon} alt="success" />
      <Title color={'mariner'} weight={'md'} size={'xl'}>
        La solicitud fue dada de alta
      </Title>
      <Label color={'chathamsBlue'} weight={'md'} size={'mdLg'}>
        El código de tu empresa es:
      </Label>
      <Code color={'chathamsBlue'} weight={'sm'} size={'mdLg'}>
        {companyCode}
      </Code>
      <Label color={'chathamsBlue'} weight={'md'} size={'mdLg'}>
        Los grupos familiares serán dados de alta en las próximas horas
      </Label>
    </InfoContainer>
    <Button onClick={onClickHandler}>Volver al inicio</Button>
  </>
)

const ErrorStatus: FC<{
  errorMsg?: string
  onClickHandler: () => void
}> = ({ errorMsg, onClickHandler }) => (
  <>
    <InfoContainer>
      <Logo src={errorIcon} alt="error" />
      <Title color={'mariner'} weight={'md'} size={'xl'}>
        La solicitud no pudo darse de alta
      </Title>
      <Label color={'chathamsBlue'} weight={'md'} size={'mdLg'}>
        {errorMsg}
      </Label>
    </InfoContainer>
    <Button onClick={onClickHandler}>Volver al inicio</Button>
  </>
)

const MessageStatus: FC<{
  type: string
  onClickHandler: () => void
  companyCode?: number | string
  errorMsg?: string
}> = props => {
  const status: any = {
    PAPER_MODE: <PaperModeStatus {...props} />,
    SUCCESS_REGISTER: <SuccessRegisterStatus {...props} />,
    ERROR: <ErrorStatus {...props} />,
  }

  return <MessageStatusContainer>{status[props.type]}</MessageStatusContainer>
}

export default MessageStatus
