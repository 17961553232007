import React from 'react'
import styled from 'styled-components'
import { Text } from './ui'

import successIcon from 'app/images/Ok.svg'
import errorIcon from 'app/images/Ok.svg'
import { IconClose } from './Icons'
import { useDispatch } from 'react-redux'
import { clearChanges } from 'app/slices/company'

const variants = {
  success: 'success',
  error: 'error',
}

const Logo = styled.img`
  width: 60px;
  height: 40px;
`

const Container = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
`

const MessageBasic = styled.div<{ variant: keyof typeof variants }>`
  border-radius: 10px;
  min-width: 7rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3rem;
  background-color: ${props => {
    if (props.variant === variants.success) {
      return '#E7EFDE'
    }
    if (props.variant === variants.error) return '#F3A3AF'

    return props.theme.colors.tundora
  }};
  padding: 8px 12px 8px 12px;
`

const Wrapper = styled.div<{ showMsg: boolean }>`
  display: ${props => (props.showMsg ? 'flex' : 'none')};
  justify-content: center;
`

type TSavedChangesMsg = {
  variant: keyof typeof variants
  children: string
  showMsg: boolean
}

const SavedChangesMsg = ({ variant, children, showMsg }: TSavedChangesMsg) => {
  const dispatch = useDispatch()
  return (
    <Wrapper showMsg={showMsg}>
      <MessageBasic variant={variant}>
        <Container>
          {variant === variants.success && <Logo src={successIcon} />}
          {variant === variants.error && <Logo src={errorIcon} />}
          <Text>{children}</Text>
        </Container>
        <IconClose
          size={'sm'}
          color={'black'}
          onClick={() => {
            dispatch(clearChanges())
          }}
          isCursor
        />
      </MessageBasic>
    </Wrapper>
  )
}

export default SavedChangesMsg
