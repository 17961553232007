import { State } from 'domain/entities'
import { HoldersDTO } from 'domain/responseModel/holders'

export const mapHoldersData = (data: HoldersDTO, states: State[]): any => {
  return {
    resultados: data.resultados.map(holder => ({
      ...holder,
      documento: holder.dni,
      nombreYApellido: `${holder.nombre}, ${holder.apellido}`,
      estadoBackOffice:
        states.find(s => s.id === holder.estadoBackOffice)?.descripcion || '',
      idEstadoBackOffice: holder.estadoBackOffice,
      detalle: holder.guid,
    })),
    totalCantidad: data.totalCantidad,
    totalPaginas: data.totalPaginas,
  }
}
