import React, { FC } from 'react'
import { Formik, Form } from 'formik'
import { IdCard } from '@styled-icons/boxicons-regular/IdCard'
import { Lock } from '@styled-icons/boxicons-regular/Lock'

import { InputField } from 'app/components/ui/forms'
import { Button } from 'app/components/ui'
import { Text } from 'app/components/ui'
import { LoadingSpinner } from 'app/components'

import { StyledLogin, StyledInputs, LogoImage } from 'app/Pages/Home/style'

import { warning } from 'app/components/constants'

import OMINT_LOGO from 'app/images/logo_omint_azul.svg'

import { strings } from 'app/constants/strings'

const {
  forms: {
    fieldsTitle: { userTitle, passwordTitle },
    fieldsName: { userName, password },
    fieldsHint: { userHint, passwordHint },
  },
} = strings

export interface ILoginProps {
  isLoading?: boolean
  initialValues: object
  validationSchema: object
  onSubmit: any
  error: string
}

/**
 * Primary UI component for user interaction
 */
export const LoginForm: FC<ILoginProps> = ({
  initialValues,
  validationSchema,
  onSubmit,
  isLoading,
  error,
  ...props
}) => (
  <StyledLogin {...props}>
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        actions.setSubmitting(false)
        onSubmit(values)
      }}
    >
      {(props: {
        errors: { userName: boolean; password: boolean }
        touched: { userName: boolean; password: boolean }
      }) => (
        <Form>
          <StyledInputs>
            <LogoImage src={OMINT_LOGO} alt="Omint" />
            <InputField
              name={userName}
              id={userName}
              title={userTitle}
              hint={userHint}
              inputMode="text"
              warning={props.errors.userName && props.touched.userName}
              icon={IdCard}
            />
            <InputField
              name={password}
              id={password}
              title={passwordTitle}
              hint={passwordHint}
              inputMode="password"
              warning={props.errors.password && props.touched.password}
              icon={Lock}
              type="password"
            />

            <LoadingSpinner
              isLoading={isLoading}
              autoWidth={false}
              noMargin={true}
            >
              <Button type="submit">Ingresar</Button>
            </LoadingSpinner>
            <Text variant={warning}>{error}</Text>
          </StyledInputs>
        </Form>
      )}
    </Formik>
  </StyledLogin>
)
