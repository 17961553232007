import React from 'react'

import * as S from 'app/components/ui/Modal/styles'
import { IconClose } from 'app/components/Icons'
import { Text, LoadingSpinner, ModalContext } from 'app/components'

const Modal = () => {
  let {
    modalContent,
    displayModal,
    handleDisplayModal,
    modalIsFetching,
    error,
  } = React.useContext(ModalContext)

  return (
    <S.Modal displayElement={displayModal}>
      <S.ModalMain>
        <>
          <S.CloseButtonContainer displayElement={true}>
            <IconClose
              size={'sm'}
              centered={false}
              color={''}
              onClick={() => handleDisplayModal(false)}
              isCursor={true}
            />
          </S.CloseButtonContainer>
          <LoadingSpinner isLoading={modalIsFetching}>
            {modalContent}
          </LoadingSpinner>
          {error && (
            <Text textAlign={'center'} color={'scarlet'}>
              {error}
            </Text>
          )}
        </>
      </S.ModalMain>
    </S.Modal>
  )
}

export default Modal
