import React, { FC, ReactNode } from 'react'
import { StyledHomeContainer } from 'app/Pages/Home/style'

export interface IHomeContainerProps {
  children: ReactNode
  onClick?: () => void
}

/**
 * Primary UI component for user interaction
 */
export const HomeContainer: FC<IHomeContainerProps> = ({
  children,
  ...props
}) => {
  return <StyledHomeContainer {...props}>{children}</StyledHomeContainer>
}
