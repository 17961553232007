import {
  ProvinceParams,
  pagination,
  RangeDates,
} from 'domain/requestModel/types'

import { getFormatedDate, YearMonthDayWithLeadingZerosDashed } from 'helpers'

import { ISelectFormat } from 'app/components/ui/forms'
import { LeadEventEnum } from '../entities/lead'

export type getLeadsRequestParams = {
  fechaVigencia?: RangeDates
  pagina: number
  cantidad: number
  provincia?: ProvinceParams
  idsEstado?: ISelectFormat[]
  advisor?: ISelectFormat
  idsSellChannel?: ISelectFormat[]
}

export type getLeadsRequestModel = pagination & {
  idProvincia?: number
  fechaDesde?: string
  fechaHasta?: string
  idsEstado?: ISelectFormat[]
  codAsesor?: string
  idsOrigen?: ISelectFormat[]
}

export type TGetLeadsRequestModel = {
  idProvincia?: number
  fechaDesde?: string
  fechaHasta?: string
  idsEstado?: ISelectFormat[]
  codAsesor?: string
  idsOrigen?: ISelectFormat[]
}

export const makeLeadsRequestModel = (
  raw: getLeadsRequestParams
): getLeadsRequestModel => {
  const mappIdsEstado = raw.idsEstado?.map((e: ISelectFormat) => e.value)
  const mappIdsSellChannel = raw.idsSellChannel?.map(
    (e: ISelectFormat) => e.value
  )

  return {
    fechaDesde: raw.fechaVigencia?.startDate
      ? getFormatedDate(
          raw.fechaVigencia?.startDate,
          YearMonthDayWithLeadingZerosDashed
        ).toString()
      : undefined,
    fechaHasta: raw.fechaVigencia?.endDate
      ? getFormatedDate(
          raw.fechaVigencia?.endDate,
          YearMonthDayWithLeadingZerosDashed
        ).toString()
      : undefined,
    pagina: raw.pagina,
    cantidad: raw.cantidad,
    idProvincia: raw.provincia?.value
      ? parseInt(raw.provincia.value)
      : undefined,
    idsEstado: mappIdsEstado || [2],
    codAsesor: raw.advisor?.value,
    idsOrigen: mappIdsSellChannel,
  }
}

export type extendGetLeadsParamsWithExportList = getLeadsRequestParams & {
  exportList: number
}

export const makeLeadsExportReqParams = (
  raw: extendGetLeadsParamsWithExportList
): TGetLeadsRequestModel => {
  const mappIdsEstado: any = raw.idsEstado?.map((e: ISelectFormat) => e.value)
  const mappIdsSellChannel = raw.idsSellChannel?.map(
    (e: ISelectFormat) => e.value
  )
  return {
    idsEstado: mappIdsEstado || undefined,
    idProvincia: raw.provincia?.value
      ? parseInt(raw.provincia.value)
      : undefined,
    fechaDesde: raw.fechaVigencia?.startDate
      ? getFormatedDate(
          raw.fechaVigencia?.startDate,
          YearMonthDayWithLeadingZerosDashed
        ).toString()
      : undefined,
    fechaHasta: raw.fechaVigencia?.endDate
      ? getFormatedDate(
          raw.fechaVigencia?.endDate,
          YearMonthDayWithLeadingZerosDashed
        ).toString()
      : undefined,
    idsOrigen: mappIdsSellChannel,
    codAsesor: raw.advisor?.value,
  }
}

export type getLeadRequestModel = {
  idLead?: string
  tipoLead?: string
}

export type putLeadStateRequestModel = {
  id: number
  tipoLead: number
  idEstado: number
  pageType?: string
}

export type putLeadAdvisorRequestModel = {
  id: number
  tipoLead: number
  codAsesor: number
}

export type TPostImportLeads = {
  leadFile: any
}
export type TPostEventLeadsParams = {
  eventoAfiliacion: number
  observacion: string
  idLead: number
  tipoLead: number
}

export type TPostEventLeadsEven = {
  eventoAfiliacion: LeadEventEnum.call | LeadEventEnum.observation
  observacion: string
  idLead: number
  tipoLead: number
}

export const postEventLeadsComment = (
  raw: TPostEventLeadsParams
): TPostEventLeadsEven => ({
  eventoAfiliacion: raw.eventoAfiliacion,
  observacion: raw.observacion,
  idLead: raw.idLead,
  tipoLead: raw.tipoLead,
})
