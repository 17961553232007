import React from 'react'
import { Form, Formik } from 'formik'

import * as C from 'app/components'
import * as S from 'app/Pages/Afilliation/styles'

type TDetails = {
  formInitialValues: {
    associate: C.ISelectFormat
  }
  detailData: any
  authIsLoading: boolean
  onPressDownloadDni: (dni: string) => void
  dropdownOptions: C.ISelectFormat[]
  setSelectedOption: (option: any) => void
  tipoAfiliacion?: string | null
}

const Details = ({
  formInitialValues,
  detailData,
  authIsLoading,
  onPressDownloadDni,
  dropdownOptions,
  setSelectedOption,
  tipoAfiliacion,
}: TDetails) => (
  <C.Acordion
    title={'Detalle'}
    data={detailData}
    open={true}
    {...(!tipoAfiliacion && {
      actionButtons: [
        <C.Button
          type="button"
          onClick={() => onPressDownloadDni(detailData?.dni)}
          outline
          disabled={!detailData?.dni || authIsLoading}
          isLoading={authIsLoading}
          key={detailData?.dni || '1'}
        >
          Descargar DNI
        </C.Button>,
      ],
    })}
  >
    <S.SelectContainer>
      <Formik
        initialValues={formInitialValues}
        onSubmit={(v: any, { setSubmitting }) => {
          setSubmitting(false)
        }}
      >
        {(props: { values: any; onChange: any; setFieldValue: any }) => (
          <Form>
            <C.SelectAsync
              onChange={(option: any) => {
                props.setFieldValue('associate', option ? option : null)
                setSelectedOption(option)
              }}
              name={'associate'}
              id={'associate'}
              options={dropdownOptions}
              value={props.values.associate}
            />
          </Form>
        )}
      </Formik>
    </S.SelectContainer>
  </C.Acordion>
)

export default Details
