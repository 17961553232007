import moment from 'moment'

const checkIsString = (value: string) => typeof value === 'string'
const checkIsUndefined = (value: any) => typeof value === 'undefined'
const checkIsNumber = (value: number) => typeof value === 'number'

export const isString = (
  value: string,
  fieldName: string,
  required: boolean = true
): string => {
  if (!required) {
    if (value && !checkIsString(value))
      throw new Error(
        `Expected type String for FIELD: ${fieldName} | VALUE-PROVIDED: ${value}`
      )
    if (!value) return ''

    return value
  }

  if (checkIsUndefined(value))
    throw new Error(
      `Expected non-undefined value for field ${fieldName} | VALUE-PROVIDED: ${value}`
    )
  if (!checkIsString(value))
    throw new Error(
      `Expected type String for FIELD: ${fieldName} | VALUE-PROVIDED: ${value}`
    )
  if (!value)
    throw new Error(
      `Expected non-empty string for FIELD: ${fieldName} | VALUE-PROVIDED: ${value}`
    )

  return value
}

export const isArrayOfStrings = (
  value: string[],
  fieldName: string
): string[] => {
  if (!Array.isArray(value))
    throw new Error(
      `Expected array type for field ${fieldName} | VALUE-PROVIDED: ${value}`
    )
  if (!value.every(item => checkIsString(item)))
    throw new Error(
      `Expected array of strings for field ${fieldName} | VALUE-PROVIDED: ${value}`
    )

  return value
}

const checkIsObject = (item: any): item is Record<string, unknown> => {
  return typeof item === 'object' && item !== null && !Array.isArray(item)
}

export const isArrayOfObjects = (
  value: any[],
  fieldName: string
): Record<string, unknown>[] => {
  if (!Array.isArray(value)) {
    throw new Error(
      `Expected array type for field ${fieldName} | VALUE-PROVIDED: ${JSON.stringify(
        value
      )}`
    )
  }
  if (!value.every(checkIsObject)) {
    throw new Error(
      `Expected array of objects for field ${fieldName} | VALUE-PROVIDED: ${JSON.stringify(
        value
      )}`
    )
  }

  return value
}

export const isNonEmptyArrayOfStrings = (
  value: string[],
  fieldName: string
): string[] => {
  if (checkIsUndefined(value))
    throw new Error(
      `Expected non-undefined value for field ${fieldName} | VALUE-PROVIDED: ${value}`
    )
  if (!Array.isArray(value))
    throw new Error(
      `Expected array type for field ${fieldName} | VALUE-PROVIDED: ${value}`
    )
  if (!value.length)
    throw new Error(
      `Expected non-empty array for field ${fieldName} | VALUE-PROVIDED: ${value}`
    )
  if (!value.every(item => checkIsString(item)))
    throw new Error(
      `Expected array of strings for field ${fieldName} | VALUE-PROVIDED: ${value}`
    )

  return value
}

export const isNumber = (
  value: number,
  fieldName: string,
  required: boolean = true
): number => {
  if (!required) {
    if (value && !checkIsNumber(value)) return value
    if (!value) return 0
  }

  if (checkIsUndefined(value))
    throw new Error(
      `Expected non-undefined value for field ${fieldName} | VALUE-PROVIDED: ${value}`
    )
  if (!checkIsNumber(value))
    throw new Error(
      `Expected type number value for FIELD: ${fieldName} | VALUE-PROVIDED: ${value}`
    )

  return value
}

export const isDateAndNotEmpty = (value: Date, fieldName: string): Date => {
  if (checkIsUndefined(value))
    throw new Error(
      `Expected non-undefined value for field ${fieldName} | VALUE-PROVIDED: ${value}`
    )

  const isValidDate = moment(value).isValid()

  if (!isValidDate)
    throw new Error(
      `Expected Date value for FIELD: ${fieldName} | VALUE-PROVIDED: ${value}`
    )

  return value
}

export const isBoolean = (value: boolean, fieldName: string): boolean => {
  if (typeof value === 'boolean') return value

  throw new Error(
    `Expected boolean value for FIELD: ${fieldName} | VALUE-PROVIDED: ${value}`
  )
}
