import styled from 'styled-components'

import { MessageProps } from 'app/components/ui/Message'

import { backgroundColorProvider } from 'app/components/helpers'

export const MessageContainer = styled.div<MessageProps>`
  position: fixed;
  width: 100%;
  top: 54px;
  height: 41px;
  display: ${props => (props.displayMessage ? 'flex' : 'none')};
  z-index: 1;
  justify-content: center;
  align-items: center;
  ${props => backgroundColorProvider[props.backgroundColor]}
`

export const MessageContent = styled.div`
  position: absolute;
  right: 0;
  padding-right: 10px;
`
