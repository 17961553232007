import React, { FC } from 'react'
import { Formik, FormikHelpers, Form } from 'formik'

import * as Components from 'app/components'
import * as S from '../styles'

import constants, { TModalContent, TForm } from '../constants'

import { strings } from 'app/constants/strings'
import { colors } from 'app/styles/colors'

const {
  forms: {
    fieldsName: { observations, detail },
    fieldsTitle: { observationsTitle, detailTitle },
  },
} = strings

const ModalContent: FC<TModalContent> = ({ onSubmit, onCancel }) => {
  return (
    <Formik
      initialValues={constants.newObservationInitialValues}
      validationSchema={constants.newObservationValidationSchema}
      onSubmit={(values: TForm, { setSubmitting }: FormikHelpers<TForm>) => {
        setSubmitting(false)
        onSubmit(values)
      }}
    >
      {(props: {
        values: any
        onChange: any
        setFieldValue: any
        errors: any
        touched: any
      }) => (
        <Form>
          <S.ObservationFormContainer>
            <Components.Text size={'lg'} weight={'md'} color={'cornflowerBlue'}>
              Nueva observación
            </Components.Text>
            <Components.SelectAsync
              onChange={(option: any) => {
                props.setFieldValue(observations, option ? option : null)
              }}
              title={observationsTitle}
              name={observations}
              id={observations}
              options={constants.observationsType}
              placeholder={'Seleccionar'}
              warning={props.errors.observations && props.touched.observations}
              color={colors.cornflowerBlue}
            />
            <Components.TextArea
              name={detail}
              id={detail}
              title={detailTitle}
              warning={props.errors.detail && props.touched.detail}
              onChange={(e: any) => props.setFieldValue(detail, e.target.value)}
              placeholder={'Comentarios u observaciones'}
              color={colors.cornflowerBlue}
            />
            <S.ButtonsContainer flexDirectionColumn={true}>
              <Components.Button type="button" outline onClick={onCancel}>
                Cancelar
              </Components.Button>
              <Components.Button type="submit">Confirmar</Components.Button>
            </S.ButtonsContainer>
          </S.ObservationFormContainer>
        </Form>
      )}
    </Formik>
  )
}

export default ModalContent
