import styled from 'styled-components'

export const FieldSet = styled.fieldset`
  width: 750px;
  margin: 20px;
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  font-family: 'Source Sans Pro', sans-serif;
  line-height: 20px;

  > hr {
    color: #678fb8;
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
    width: 90%;
  }
`

export const FieldContainer = styled.div`
  padding: 10px;
  line-height: 1.5;

  label {
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.32px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #678fb8;
  }

  b {
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    color: #00205c;
  }
`

export const Row = styled.div`
  display: flex;

  > div {
    margin: 0px;
  }

  > div {
    display: flex;
    margin: 0 50px;
  }
`

export const DetailTitle = styled.div`
  display: flex;

  > b {
    font-weight: 600;
    font-size: 16px;
    color: #2f79c2;
    margin-bottom: 24px;
    padding: 10px;
  }
`
export const DetailName = styled.div`
  display: inline-block;
  margin-top: 20px;
  min-width: 200px;

  > b {
    font-weight: 600;
    font-size: 1.125rem;
    color: #2f6fc2;
  }
`
export const DataTitle = styled.p`
  color: #4d4d4d;
  font-family: Source Sans Pro;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.3125rem;
  letter-spacing: 0.01563rem;
`

export const Container = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
`
export const GoBack = styled.div`
  font-size: 14px;
  line-height: 21px;
  color: #00387e;
  text-decoration: none !important;
  margin: 18px 0px 0px 20px;
`
export const ButtonContainer = styled.div`
  float: right;
  text-align: right;
`

export const FormContainer = styled.div`
  margin: 2px;
  display: flex;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.breakPoints.small}) {
    width: 300px;
    justify-content: flex-end;
  }

  > label {
    display: block;
  }
`

export const DropdownsContainer = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: column;
`
