import React from 'react'
import { Link } from 'react-router-dom'

import { IconPowerSettingsNew } from 'app/components/Icons'
import { Row } from 'app/components'
import { Text } from 'app/components/ui'

import {
  CollapseWrapper,
  CollapsedNavLinks,
} from 'app/components/Navbar/styles'

const CollapseMenu = (props: any) => {
  if (props.navbarState === true) {
    return (
      <CollapseWrapper>
        <CollapsedNavLinks>
          {props.navBarOptions.map((page: any, index: number) => (
            <li key={index}>
              <Link
                onClick={props.handleNavbar}
                to={`${props.pathTo}${page.path}`}
              >
                <Text size={'lg'} color={'white'}>
                  {page.name}
                </Text>
              </Link>
            </li>
          ))}
          <li>
            <a
              href="/"
              onClick={() => {
                props.handleOnPressLogout()
                props.handleNavbar()
              }}
            >
              <Row>
                <IconPowerSettingsNew
                  centered={false}
                  color={'white'}
                  size={'sm'}
                />{' '}
                <Text size={'lg'} color={'white'}>
                  Salir
                </Text>
              </Row>
            </a>
          </li>
        </CollapsedNavLinks>
      </CollapseWrapper>
    )
  }

  return null
}

export default CollapseMenu
