import React from 'react'

import * as Styles from 'app/Pages/Company/style'
import { Table } from 'app/components'
import { Text, IconDotsHorizontal } from 'app/components'

import {
  tableDesktopHeaders,
  tableMobileHeaders,
} from 'app/Pages/Company/constants'

export interface ICompanyTable {
  data: any[] & never[]
  isLoading: boolean
  onPressItem: (element: any, key: any) => void
}

const Item = (element: any, key: any) => (handleOnPressItem: any) => (
  <>
    {key === 'detalle' ? (
      <IconDotsHorizontal
        onClick={() => handleOnPressItem(element, key)}
        color={'midnightBlue'}
        isCursor
      />
    ) : (
      <Text capitalFirstLetter color={'midnightBlue'} textAlign={'center'}>
        {element[key]}
      </Text>
    )}
  </>
)

const CompanyTable = (props: ICompanyTable) => {
  return (
    <Styles.PageContent>
      <Table
        data={props.data}
        tableDesktopHeaders={tableDesktopHeaders}
        tableMobileHeaders={tableMobileHeaders}
        isLoading={props.isLoading}
        item={(element, key) => Item(element, key)(props.onPressItem)}
      />
    </Styles.PageContent>
  )
}

export default CompanyTable
