import { IProvince } from 'domain/entities'
import { CompanyDTO } from 'domain/responseModel/company'
import {
  DayMonthYearWithLeadingZerosSlashedWithHr,
  getFormatedDate,
} from 'helpers'

export const mapCompanyData = (
  data: CompanyDTO,
  provincies: IProvince[] | null
): any => {
  return {
    resultados: data.resultados.map(company => ({
      ...company,
      provincia:
        company.provincia &&
        provincies?.find(p => p.id === company.provincia)?.nombre,
      dateOfEntry:
        company.dateOfEntry &&
        getFormatedDate(
          new Date(company.dateOfEntry),
          DayMonthYearWithLeadingZerosSlashedWithHr
        ),
      detalle: company.id,
    })),
    totalCantidad: data.totalCantidad,
    totalPaginas: data.totalPaginas,
  }
}
