import React, { FC } from 'react'
import { Formik, Form } from 'formik'

import { Province, LeadState } from 'domain/entities'

import {
  Button,
  Text,
  LeadsTable,
  Pagination,
  InputsFormContainer,
  Permission,
  RangeDatePicker,
  InputField,
  SelectAsync,
  ISelectFormat,
} from 'app/components'

import { InputsContainer, FormButtons } from 'app/Pages/Leads/styles'

import { initialValues, tableHeaders } from 'app/Pages/Leads/constants'
import { xl } from 'app/components/constants'
import { strings, idsCanalVenta } from 'app/constants'
import { Row } from 'app/components'

import { FootFields, StyledPaginateContainer } from 'app/Pages/Policies/style'

const {
  general: { filterSearch },
  forms: {
    fieldsName: {
      fechaVigencia,
      quantity,
      province,
      idsLeadState,
      page,
      resetPageOnSubmit,
      exportList,
      advisor,
      idsSellChannel,
    },
    fieldsTitle: {
      fechaVigenciaTitle,
      quantityTitle,
      provinceTitle,
      stateTitle,
      advisorTitle,
      idsSellChannelTitle,
    },
  },
} = strings

interface ILeadsForm {
  onSubmit: (values: any) => void
  provinces: Province[]
  leadStates: LeadState[]
  leadStatesIsFetching: boolean
  leadsForView: any
  leadsIsFetching: any
  paginationPageCount: number
  path: any
  isAllowed: boolean | void
  onPressImport: () => void
  onChangeAdvisor: (codeOrName: string) => void
  advisors: ISelectFormat[]
  advisorsIsFetching: boolean
}

export const LeadsForm: FC<ILeadsForm> = ({
  onSubmit,
  provinces,
  leadStates,
  leadStatesIsFetching,
  leadsForView,
  leadsIsFetching,
  paginationPageCount,
  path,
  isAllowed,
  onPressImport,
  onChangeAdvisor,
  advisors,
  advisorsIsFetching,
}) => {
  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={(values: any, { setSubmitting }) => {
          setSubmitting(false)
          onSubmit(values)
        }}
      >
        {(props: {
          values: any
          errors: {
            quantity: boolean
            fechaVigencia: Date
            provinces: boolean
            idsLeadState: boolean
            advisor: boolean
            idsSellChannel: boolean
          }
          touched: {
            quantity: boolean
            fechaVigencia: Date
            provinces: boolean
            idsLeadState: boolean
            idsSellChannel: boolean
          }
          onChange: any
          submitForm: any
          setFieldValue: any
          onBlur: any
        }) => (
          <Form>
            <InputsFormContainer>
              <Text textAlign={'center'} size={xl} color={'resolutionBlue'}>
                {filterSearch}
              </Text>
              <InputsContainer>
                <RangeDatePicker
                  id={fechaVigencia}
                  name={fechaVigencia}
                  title={fechaVigenciaTitle}
                  onChange={props.setFieldValue}
                />
                <InputField
                  name={quantity}
                  id={quantity}
                  title={quantityTitle}
                  warning={props.errors.quantity && props.touched.quantity}
                />
                <SelectAsync
                  onChange={(option: any) => {
                    props.setFieldValue(province, option ? option : null)
                  }}
                  title={provinceTitle}
                  name={province}
                  id={province}
                  placeholder="Seleccione"
                  options={provinces?.map(b => ({
                    label: b.nombre,
                    value: b.id,
                  }))}
                  warning={props.errors.provinces && props.touched.provinces}
                  isClearable
                />
                <SelectAsync
                  onChange={(option: any) => {
                    props.setFieldValue(idsLeadState, option ? option : null)
                  }}
                  isMulti={true}
                  title={stateTitle}
                  name={idsLeadState}
                  id={idsLeadState}
                  placeholder="Seleccione"
                  options={leadStates?.map(b => ({
                    label: b.descripcion,
                    value: b.id,
                  }))}
                  warning={
                    props.errors.idsLeadState && props.touched.idsLeadState
                  }
                  isClearable
                  isLoading={leadStatesIsFetching}
                />
                <SelectAsync
                  isLoading={advisorsIsFetching}
                  onChange={(option: any) => {
                    props.setFieldValue(advisor, option ? option : null)
                  }}
                  onInputChange={onChangeAdvisor}
                  title={advisorTitle}
                  name={advisor}
                  id={advisor}
                  placeholder="Seleccione"
                  options={advisors}
                  warning={
                    props.errors.idsLeadState && props.touched.idsLeadState
                  }
                  isClearable
                />
                <SelectAsync
                  onChange={(option: any) => {
                    props.setFieldValue(idsSellChannel, option ? option : null)
                  }}
                  isMulti={true}
                  title={idsSellChannelTitle}
                  name={idsSellChannel}
                  id={idsSellChannel}
                  placeholder="Seleccione"
                  options={idsCanalVenta.map(b => ({
                    label: b.label,
                    value: b.id,
                  }))}
                  warning={
                    props.errors.idsSellChannel && props.touched.idsSellChannel
                  }
                  isClearable
                />
              </InputsContainer>
              <FormButtons>
                <Row flexDirectionColumn={true} justifyContent={'center'}>
                  <div>
                    <Button
                      type="submit"
                      onClick={() => {
                        props.setFieldValue(exportList, 0)
                      }}
                    >
                      Buscar
                    </Button>
                  </div>
                  <div>
                    <Button
                      type="button"
                      onClick={() => {
                        props.setFieldValue(exportList, 1)
                        props.submitForm()
                      }}
                      outline={true}
                    >
                      Exportar
                    </Button>
                  </div>
                  <Permission
                    displayInauthorizedComponent={false}
                    permissionRequired={'leadsMasiveLoad'}
                  >
                    <div>
                      <Button
                        type="button"
                        onClick={onPressImport}
                        outline={true}
                      >
                        Importar
                      </Button>
                    </div>
                  </Permission>
                </Row>
              </FormButtons>
            </InputsFormContainer>
            <LeadsTable
              data={leadsForView}
              tableHeaders={tableHeaders(isAllowed)}
              isLoading={leadsIsFetching}
              path={path}
            />
            <FootFields>
              <StyledPaginateContainer>
                <Pagination
                  pageFieldName={page}
                  resetFieldName={resetPageOnSubmit}
                  setFieldValue={props.setFieldValue}
                  pageCount={paginationPageCount}
                  submit={props.submitForm}
                />
              </StyledPaginateContainer>
            </FootFields>
          </Form>
        )}
      </Formik>
    </>
  )
}
