import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { decode } from 'jsonwebtoken'

import { selectAuth } from 'app/slices/authSlice'

import { pages } from 'app/constants/index'

const { unfoundCredential } = pages

/*
 * Decodea un jwt.
 * Si no es un JSON valido, lo rechaza.
 * @params token { Jwt }
 * @return Payload { Object } - Payload del token
 */
const payload = (token: string) => {
  try {
    return decode(token)
  } catch (error) {
    return false
  }
}

/*
 * @params permissionsRequired<Array> - required permission by the page/component
 * @return havePermission<Boolean>
 */
// TODO: increase security on jwt validations
export const useAccessControl = (permissionsRequired: any[]) => {
  const history = useHistory()
  const { token, funcionalidades } = useSelector(selectAuth)

  if (!token) return history.push(unfoundCredential.path)

  const claim = payload(token)

  if (!claim) return history.push(unfoundCredential.path)

  const isAllow = permissionsRequired.every(
    p => funcionalidades.indexOf(p) >= 0
  )

  return isAllow
}
