import { createSelector, createSlice } from '@reduxjs/toolkit'

import { Advisor } from 'domain/entities'
import { RootState } from 'app/store/reducers'
import { UseCaseAdvisor } from 'domain/useCases'
import { TGetAdvisorByNameOrCode } from 'domain/requestModel'
import { asesorService } from 'app/services'
import { mapAdvisorForDropdown } from 'app/slices/helper'

const advisorController = new UseCaseAdvisor(asesorService)

type initialStateReducer = {
  loading: boolean
  error: string
  advisors: Advisor[]
}

const INITIAL_STATE: initialStateReducer = {
  loading: false,
  error: '',
  advisors: [],
}

export const slice = createSlice({
  name: 'advisor',
  initialState: INITIAL_STATE,
  reducers: {
    fetchFilterAdvisorsStart: state => {
      state.loading = true
      state.error = ''
    },
    fetchFilterAdvisorsSuccess: (state, { payload }) => {
      state.loading = false
      state.error = ''
      state.advisors = payload
    },
    fetchFilterAdvisorsFail: (state, { payload }) => {
      state.loading = false
      state.error = payload.message
    },
    logout: () => INITIAL_STATE,
  },
})

export const {
  fetchFilterAdvisorsStart,
  fetchFilterAdvisorsSuccess,
  fetchFilterAdvisorsFail,
  logout,
} = slice.actions

export default slice.reducer

/**
 * Thunks
 */
export const getAdvisorByNameOrCode = (
  props: TGetAdvisorByNameOrCode
) => async (dispatch: any) => {
  try {
    dispatch(fetchFilterAdvisorsStart())

    const response = await advisorController.getAdvisorByNameOrCode(props)

    dispatch(fetchFilterAdvisorsSuccess(response))
  } catch (error) {
    dispatch(fetchFilterAdvisorsFail(error))
  }
}

/**
 * Selectors
 */

export const selectAdvisor = (state: RootState) => state.advisor
export const selectAdvisorFilteredByNameOrCode = createSelector(
  selectAdvisor,
  advisor => ({
    loading: advisor.loading,
    advisors: mapAdvisorForDropdown(advisor.advisors),
    error: advisor.error,
  })
)
