import * as Yup from 'yup'

import { fields } from 'app/constants'
import { extendGetLeadsParamsWithExportList } from 'domain/requestModel/leads'

import { strings, mimeTypes } from 'app/constants'

const {
  forms: {
    requiredFields: { requiredField },
    validationFields: { mustBeExcelFile },
  },
} = strings

const SUPPORTED_FORMATS = [
  mimeTypes.xls,
  mimeTypes.xlt,
  mimeTypes.xla,
  mimeTypes.xlsx,
]

export type TForm = extendGetLeadsParamsWithExportList & {
  resetPageOnSubmit: boolean
}

export const initialValues: TForm = {
  fechaVigencia: undefined,
  cantidad: 10,
  pagina: 0,
  provincia: { label: '', value: '' },
  exportList: 0,
  resetPageOnSubmit: false,
  advisor: { label: '', value: '' },
}

export const tableHeaders = (isAllowed: boolean | void): string[] =>
  [
    fields.fecha,
    fields.idEstadoDerivadora,
    fields.eMail,
    fields.idCanalVenta,
    fields.nombre,
    fields.edad,
    fields.provincia,
    fields.asesor,
    fields.supervisor,
    fields.linkContinuar,
    fields.detalle,
  ].filter(e => {
    if (e === fields.supervisor) {
      if (isAllowed === true) return e
    }

    return e !== fields.supervisor
  })

export const ImportExcelValidation = Yup.object().shape({
  leadFile: Yup.mixed()
    .required(requiredField)
    .test(
      'fileFormat',
      mustBeExcelFile,
      value => value && SUPPORTED_FORMATS.includes(value.type)
    ),
})
