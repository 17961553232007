export const [primary, warning, action] = ['primary', 'warning', 'action']
export const [xxs, xs, sm, md, mdlg, lg, xl] = [
  'xxs',
  'xs',
  'sm',
  'md',
  'mdlg',
  'lg',
  'xl',
]
export const mapedVariants = {
  [primary]: primary,
  [warning]: warning,
  [action]: action,
}
