import { HolderEventEnum } from 'domain/entities'

export type getHoldersRequestParam = {
  idPyme: number
}

export type TPostHolderCommentParams = {
  eventoAfiliacion: HolderEventEnum
  observacion: string
  guid: string
}

export type TPostHolderEven = {
  eventoAfiliacion: HolderEventEnum.call | HolderEventEnum.observation
  observacion: string
  guid: string
}

export const postEventComment = (
  raw: TPostHolderCommentParams
): TPostHolderEven => ({
  eventoAfiliacion: raw.eventoAfiliacion,
  observacion: raw.observacion,
  guid: raw.guid,
})
