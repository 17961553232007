import React from 'react'

import { LoadingSpinner } from 'app/components'

import {
  Header,
  TableContainer,
  TTable,
  TableDefaultProps,
} from 'app/components/ui/GenericTable'
import { Item } from 'app/components/ui/LeadsTable/Item'

const defaultProps = Object.assign(TableDefaultProps, {
  cardProps: [],
})

export type TableProps = TTable & {
  path: string
  cardProps?: string[]
} & typeof defaultProps

export const LeadsTable = (props: TableProps) => {
  const detailColumnExist = props.tableHeaders.some(e => e === 'detalle')

  return (
    <TableContainer {...props}>
      <LoadingSpinner isLoading={props.isLoading}>
        <Header data={props.tableHeaders} />
        {props.data.map((e: any, i: number) => (
          <Item
            path={props.path}
            key={i}
            data={e}
            detailColumnExist={detailColumnExist}
            tableHeaders={props.tableHeaders}
            cardProps={props.cardProps}
          />
        ))}
      </LoadingSpinner>
    </TableContainer>
  )
}

LeadsTable.defaultProps = defaultProps

export default LeadsTable
