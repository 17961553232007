import { createSlice } from '@reduxjs/toolkit'

import { RootState } from 'app/store/reducers'

import { UseCaseDni } from 'domain/useCases'

import { dniServices } from 'app/services'

const dniController = new UseCaseDni(dniServices)

type initialStateReducer = {
  loading: boolean
  error: string
}

const INITIAL_STATE: initialStateReducer = {
  loading: false,
  error: '',
}

export const slice = createSlice({
  name: 'dni',
  initialState: INITIAL_STATE,
  reducers: {
    fetchDownloadDniStart: state => {
      state.loading = true
      state.error = ''
    },
    fetchDownloadDniSuccess: (state, { payload }) => {
      state.loading = false
      state.error = ''
    },
    fetchDownloadDniFail: (state, { payload }) => {
      state.loading = false
      state.error = payload.message
    },
  },
})

export const {
  fetchDownloadDniStart,
  fetchDownloadDniSuccess,
  fetchDownloadDniFail,
} = slice.actions

export default slice.reducer

/**
 * Thunks
 */
export const getDniByDocNumber = (props: string) => async (dispatch: any) => {
  try {
    dispatch(fetchDownloadDniStart())

    const response: any = await dniController.getDniByDocNumber(props)

    if (response?.error) return dispatch(fetchDownloadDniFail(response))

    dispatch(fetchDownloadDniSuccess(response))
  } catch (error) {
    dispatch(fetchDownloadDniFail(error))
  }
}

/**
 * Selectors
 */

export const selectAuth = (state: RootState) => state.dni
export const selectAuthIsLoading = (state: RootState) => state.dni.loading
