import React, { FC, ReactNode } from 'react'
import styled from 'styled-components'

import { useToggle } from 'app/hooks/useToggle'

import { AnimatedCollapsableContainer } from 'app/components'
import {
  IconArrowIosDownwardOutline,
  IconArrowIosUpwardOutline,
} from 'app/components/Icons'
import { Text } from 'app/components/ui'

import { strings } from 'app/constants/strings'

const {
  general: { hideSearch, showSearch },
} = strings

interface IInputsFormContainer {
  readonly children: ReactNode
}

const Container = styled.div`
  min-height: 50px;
  background-color: ${({ theme }) => theme.colors.aliceBlue};
  border-bottom: 1px solid rgba(160, 217, 247, 0.75);
  padding: 0 16px;

  @media (min-width: ${({ theme }) => theme.breakPoints.small}) {
    padding: 0 40px;
  }
`

const ToggleContainer = styled.div`
  cursor: pointer;

  @media (min-width: ${({ theme }) => theme.breakPoints.small}) {
    display: none;
  }
`

export const InputsFormContainer: FC<IInputsFormContainer> = props => {
  const { opened, toggle } = useToggle(true)

  return (
    <Container>
      <AnimatedCollapsableContainer opened={opened}>
        {props.children}
      </AnimatedCollapsableContainer>
      <ToggleContainer onClick={toggle}>
        {opened ? (
          <>
            <IconArrowIosUpwardOutline color={'cornflowerBlue'} size={'md'} />
            <Text color={'mariner'} textAlign={'center'}>
              {hideSearch}
            </Text>
          </>
        ) : (
          <>
            <IconArrowIosDownwardOutline color={'cornflowerBlue'} size={'md'} />
            <Text color={'mariner'} textAlign={'center'}>
              {showSearch}
            </Text>
          </>
        )}
      </ToggleContainer>
    </Container>
  )
}
