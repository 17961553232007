import styled, { css } from 'styled-components'
import { darken } from 'polished'
import { primary, action } from 'app/components/constants'

import { baseComponent } from 'app/components/ui/styles'

const variant: any = {
  [primary]: css`
    background-color: ${({ theme }) => theme.colors.cornflowerBlue};
  `,
  [action]: css`
    background-color: ${({ theme }) => theme.colors.green};
  `,
}

const StyledComponent = styled.button.attrs(props => ({
  type: props.type || 'text',
  name: props.name || '',
}))`
  ${baseComponent};
  ${props => variant[`${props.variant}`]}
  display: ${props => (props.showIcon ? 'flex' : 'inherit')};
  align-items: ${props => (props.showIcon ? 'center' : 'inherit')};
  justify-content: ${props => (props.showIcon ? 'center' : 'inherit')};
  gap: ${props => (props.showIcon ? '5px' : 'inherit')};
  color: white;
  border-radius: 3px;
  font-weight: 600;
  display: ${props => props.display};
  border: 0;
  text-align: center;
  transition: all 0.2s;
  align-items: center;
  justify-content: center;

  :hover {
    background-color: ${({ theme }) =>
      darken(0.1, theme.colors.cornflowerBlue)};
  }
  ${({ small }) =>
    small &&
    css`
      max-width: 170px;
      height: 35px;
    `}
  ${({ outline }) =>
    outline &&
    css`
      background: white;
      border: 1px solid ${({ theme }) => theme.colors.cornflowerBlue};
      color: ${({ theme }) => theme.colors.cornflowerBlue};
      :hover {
        color: white;
      }
    `}
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      max-width: 100%;
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      background: ${({ theme }) => theme.colors.grey};
      color: ${({ theme }) => theme.colors.greyishBrownTwo};
      pointer-events: none;
    `}

  @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
    font-size: 16px;
  }

  @media (min-width: ${({ theme }) => theme.breakPoints.small}) {
    max-width: 240px;
  }

  ${({ centered }) =>
    centered &&
    css`
      margin: 10px auto;
    `}

  ${({ isLoading }) =>
    isLoading &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
    `}
`

export default StyledComponent
