import { endpoints } from 'app/constants'

import {
  getPoliciesRequestModel,
  getPolicyRequestModel,
  getPolicyStateByPolicyIdRequestModel,
  putPolicyStateRequestModel,
  TGetPoliciesRequestModel,
} from 'domain/requestModel'

import {
  IPolicyDTO,
  IPolicyResultDTO,
  IPolicyByIdDTO,
  IPolicyStateDTO,
  getPoliciesToModel,
  getPolicyToModel,
  getPoliciesByPolicyIdToModel,
  putPolicyStateToModel,
} from 'domain/responseModel'

import { ILogger } from 'domain/entities'

export class PoliciesService {
  private httpClient: any
  private logger: any

  constructor(clientProvider: any, logger: ILogger) {
    this.httpClient = clientProvider
    this.logger = logger
  }

  public async getPolicies(
    params: getPoliciesRequestModel
  ): Promise<IPolicyDTO> {
    try {
      const user = (await this.httpClient.get(endpoints.seguros, params)).data

      return getPoliciesToModel(user)
    } catch (error) {
      this.logger.error({
        message: 'UseCase - Policy (getPolicies)',
        data: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
      })

      throw new Error(error)
    }
  }

  public async getPolicy(
    params: getPolicyRequestModel
  ): Promise<IPolicyResultDTO> {
    try {
      const policy = (await this.httpClient.get(endpoints.seguro, params)).data

      return getPolicyToModel(policy)
    } catch (error) {
      this.logger.error({
        message: 'UseCase - Policy (getPolicy)',
        data: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
      })

      throw new Error(error)
    }
  }

  public async getPolicyStatesByPolicyId(
    params: getPolicyStateByPolicyIdRequestModel
  ): Promise<IPolicyByIdDTO> {
    try {
      const policieStates = (
        await this.httpClient.get(endpoints.EstadosBODisponibles, params)
      ).data

      return getPoliciesByPolicyIdToModel(policieStates)
    } catch (error) {
      this.logger.error({
        message: 'UseCase - Policy (getPolicyStatesByPolicyId)',
        data: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
      })

      throw new Error(error)
    }
  }

  public async getPoliciesExport(
    params: TGetPoliciesRequestModel
  ): Promise<any> {
    try {
      const policiesExported = (
        await this.httpClient.get(endpoints.exportSeguros, params, {
          responseType: 'blob',
        })
      ).data

      let url = window.URL.createObjectURL(policiesExported)
      let a = document.createElement('a')
      a.href = url
      a.download = 'listado-de-polizas.xlsx'
      a.click()
    } catch (error) {
      this.logger.error({
        message: 'UseCase - Policy (getPoliciesExport)',
        data: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
      })

      throw new Error(error)
    }
  }

  public async putPolicyState(
    params: putPolicyStateRequestModel
  ): Promise<IPolicyStateDTO> {
    try {
      const updatedState = (
        await this.httpClient.put(endpoints.ActualizarEstadoBackOffice, params)
      ).data

      return putPolicyStateToModel(updatedState)
    } catch (error) {
      this.logger.error({
        message: 'UseCase - Policy (putPolicyState)',
        data: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
      })

      throw new Error(error)
    }
  }

  public async getPoliciesRejectedExport(): Promise<any> {
    try {
      const rejectedPolicies = (
        await this.httpClient.get(endpoints.exportRejectedPolicies, null, {
          responseType: 'blob',
        })
      ).data

      let url = window.URL.createObjectURL(rejectedPolicies)
      let a = document.createElement('a')
      a.href = url
      a.download = 'listado-rechazados.xlsx'
      a.click()
    } catch (error) {
      this.logger.error({
        message: 'UseCase - Policy (getPoliciesRejectedExport)',
        data: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
      })

      throw new Error(error)
    }
  }

  public async getPoliciesAbandonQuotedExport(): Promise<any> {
    try {
      const abandonedQuotes = (
        await this.httpClient.get(endpoints.abandonQuote, null, {
          responseType: 'blob',
        })
      ).data

      let url = window.URL.createObjectURL(abandonedQuotes)
      let a = document.createElement('a')
      a.href = url
      a.download = 'listado-de-cotizaciones.xlsx'
      a.click()
    } catch (error) {
      this.logger.error({
        message: 'UseCase - Policy (getPoliciesAbandonQuotedExport)',
        data: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
      })

      throw new Error(error)
    }
  }
}

export default {
  PoliciesService,
}
