import { createSlice } from '@reduxjs/toolkit'

import { AfilliationAssociate } from 'domain/entities'
import { RootState } from 'app/store/reducers'
import { UseCaseAfilliation } from 'domain/useCases'
import { TGetReportMedicArea, TPostEventParam } from 'domain/requestModel'
import { IAfilliationsDTO, TGetAfilliationDTO } from 'domain/responseModel'
import { afilliationService } from 'app/services'
import { TPostAfilliationStateParams } from 'domain/requestModel'
import { ISelectFormat } from 'app/components'

const afilliationController = new UseCaseAfilliation(afilliationService)

type initialStateReducer = {
  loading: boolean
  error: string
  afilliations: IAfilliationsDTO
  afilliation: TGetAfilliationDTO | null
  event: boolean
}

const INITIAL_STATE: initialStateReducer = {
  loading: false,
  error: '',
  afilliation: null,
  afilliations: {
    resultados: [],
    totalCantidad: 0,
    totalPaginas: 0,
  },
  event: false,
}

export const slice = createSlice({
  name: 'afilliation',
  initialState: INITIAL_STATE,
  reducers: {
    fetchAfilliationsByFiltersStart: state => {
      state.loading = true
      state.error = ''
    },
    fetchAfilliationsByFiltersSuccess: (state, { payload }) => {
      state.loading = false
      state.error = ''
      state.afilliations = payload
    },
    fetchAfilliationsByFiltersFail: (state, { payload }) => {
      state.loading = false
      state.error = payload.message
    },
    fetchAfilliationStart: state => {
      state.loading = true
      state.error = ''
    },
    fetchAfilliationSuccess: (state, { payload }) => {
      state.loading = false
      state.error = ''
      state.afilliation = payload
    },
    fetchAfilliationFail: (state, { payload }) => {
      state.loading = false
      state.error = payload.message
    },
    fetchAfilliationStateUpdateStart: state => {
      state.loading = true
      state.error = ''
      state.event = false
    },
    fetchAfilliationStateUpdateSuccess: (state, { payload }) => {
      state.loading = false
      state.error = ''
      state.afilliation = payload
      state.event = true
    },
    fetchAfilliationStateUpdateFail: (state, { payload }) => {
      state.loading = false
      state.error = payload.message
    },
    fetchNewEventStart: state => {
      state.loading = true
      state.error = ''
      state.event = false
    },
    fetchNewEventSuccess: (state, { payload }) => {
      state.loading = false
      state.error = ''
      state.event = true
    },
    fetchNewEventFail: (state, { payload }) => {
      state.loading = false
      state.error = payload.message
    },
    fetchMedicAreaReportStart: state => {
      state.loading = true
      state.error = ''
    },
    fetchMedicAreaReportSuccess: (state, { payload }) => {
      state.loading = false
      state.error = ''
    },
    fetchMedicAreaReportFail: (state, { payload }) => {
      state.loading = false
      state.error = payload.message
    },
  },
})

export const {
  fetchAfilliationsByFiltersStart,
  fetchAfilliationsByFiltersSuccess,
  fetchAfilliationsByFiltersFail,
  fetchAfilliationStart,
  fetchAfilliationSuccess,
  fetchAfilliationFail,
  fetchAfilliationStateUpdateStart,
  fetchAfilliationStateUpdateSuccess,
  fetchAfilliationStateUpdateFail,
  fetchNewEventStart,
  fetchNewEventSuccess,
  fetchNewEventFail,
  fetchMedicAreaReportStart,
  fetchMedicAreaReportSuccess,
  fetchMedicAreaReportFail,
} = slice.actions

export default slice.reducer

/**
 * Thunks
 */
export const getAfilliationsByFilters = (props: any) => async (
  dispatch: any
) => {
  try {
    if (props.resetPageOnSubmit) {
      const resetedPageNumber = Object.assign(props, { pagina: 0 })
      dispatch(fetchAfilliationsByFiltersStart())
      const response = await afilliationController.getAfilliations(
        resetedPageNumber
      )
      dispatch(fetchAfilliationsByFiltersSuccess(response))
    } else {
      dispatch(fetchAfilliationsByFiltersStart())
      const response = await afilliationController.getAfilliations(props)
      dispatch(fetchAfilliationsByFiltersSuccess(response))
    }
  } catch (error) {
    dispatch(fetchAfilliationsByFiltersFail(error))
  }
}

export const getAfilliation = (props: any) => async (dispatch: any) => {
  try {
    dispatch(fetchAfilliationStart())

    const response = await afilliationController.getAfilliation(props)

    dispatch(fetchAfilliationSuccess(response))
  } catch (error) {
    dispatch(fetchAfilliationFail(error))
  }
}

export const postAfilliationStateUpdate = (
  params: TPostAfilliationStateParams
) => async (dispatch: any) => {
  try {
    dispatch(fetchAfilliationStateUpdateStart())

    const response = await afilliationController.postAfilliationStateUpdate(
      params
    )

    dispatch(fetchAfilliationStateUpdateSuccess(response))
  } catch (error) {
    dispatch(fetchAfilliationStateUpdateFail(error))
  }
}

export const postEvent = (props: TPostEventParam) => async (dispatch: any) => {
  try {
    dispatch(fetchNewEventStart())

    const response = await afilliationController.postEvent(props)

    dispatch(fetchNewEventSuccess(response))
  } catch (error) {
    dispatch(fetchNewEventFail(error))
  }
}

export const getMedicAreaReport = (props: TGetReportMedicArea) => async (
  dispatch: any
) => {
  try {
    dispatch(fetchMedicAreaReportStart())

    const response = await afilliationController.getReportMedicArea(props)

    dispatch(fetchMedicAreaReportSuccess(response))
  } catch (error) {
    dispatch(fetchMedicAreaReportFail(error))
  }
}

export const getMedicAreaDetail = (props: TGetReportMedicArea) => async (
  dispatch: any
) => {
  try {
    dispatch(fetchMedicAreaReportStart())

    const response = await afilliationController.getDetailMedicArea(props)

    dispatch(fetchMedicAreaReportSuccess(response))
  } catch (error) {
    dispatch(fetchMedicAreaReportFail(error))
  }
}

/**
 * Selectors
 */

export const selectAfilliations = (state: RootState) => state.afilliations
export const selectAfilliation = (
  state: RootState
): TGetAfilliationDTO | null => state.afilliations.afilliation
export const selectAfilliationIsFetching = (state: RootState) =>
  state.afilliations.loading
export const selectPostEventIsFetching = (state: RootState) =>
  state.afilliations.loading
export const selectPostEventError = (state: RootState) =>
  state.afilliations.error
export const selectPostEvent = (state: RootState) => state.afilliations.event
export const selectAfilliationAndAssociateForDropdown = (
  state: RootState
): ISelectFormat[] =>
  state.afilliations.afilliation
    ? state.afilliations.afilliation?.socios.map(
        (associate: AfilliationAssociate): ISelectFormat => ({
          label: `${associate.apellido}, ${associate.nombre}`,
          value: associate.id,
        })
      )
    : [{ label: '', value: '' }]
export const selectAfilliationsNumberOfPages = (state: RootState) =>
  state.afilliations.afilliations.totalPaginas
    ? state.afilliations.afilliations.totalPaginas
    : 0
