import { IAcuerdoEntityGateway } from '../entityGateways/IAcuerdosEntityGateway'
import {
  Acuerdo,
  TGetAcuerdosParams,
  TUpdateAcuerdoParams,
} from '../requestModel/acuerdos'

export class UseCaseAcuerdo implements IAcuerdoEntityGateway {
  private entityGateway: IAcuerdoEntityGateway

  constructor(entityGateway: IAcuerdoEntityGateway) {
    this.entityGateway = entityGateway
  }

  public async getAcuerdos(params: TGetAcuerdosParams): Promise<Acuerdo[]> {
    return this.entityGateway.getAcuerdos(params)
  }

  public async updateAcuerdo(params: TUpdateAcuerdoParams): Promise<any> {
    return this.entityGateway.updateAcuerdo(params)
  }
}
export default {
  UseCaseAcuerdo,
}
