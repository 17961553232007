import React from 'react'

import { Text, Row } from 'app/components'

import { fieldKeyToName } from 'helpers'

type TKeyValue = {
  label: string
  value: any
  estadoBackoffice?: string
  noCamelCase: boolean
} & typeof KeyValueDefaultProps

const KeyValueDefaultProps = {
  label: '',
  value: '',
  noCamelCase: false,
}

const mapValues = (value: any) => {
  if (value === 0) return ''
  if (value === '0') return ''
  if (value === false || value === 'false') return 'No'
  if (value === true || value === 'true') return 'Si'
  if (value === null || value === 'null') return ''
  if (
    value === undefined ||
    value === 'undefined' ||
    value === typeof 'undefined'
  )
    return ''

  return value
}

const KeyValue = (props: TKeyValue) => {
  const value = mapValues(props.value)

  return (
    <Row>
      <Text
        color={'enterpriseBlue'}
        size={'md'}
        margin={'0 10px 0 0'}
        {...(!props.noCamelCase && {
          capitalFirstLetter: true,
        })}
      >
        {`${fieldKeyToName(props.label)}:`}
      </Text>
      <Text color={'chathamsBlue'} size={'md'} weight={'md'}>
        {props.label === 'estadoBackOffice' ? props.estadoBackoffice : value}
      </Text>
    </Row>
  )
}

KeyValue.defaultProps = KeyValueDefaultProps

export default KeyValue
