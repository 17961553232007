import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import localForage from 'localforage'

import combinedReducers from 'app/store/reducers'

const persistConfig = {
  key: 'rootbackoffice',
  storage: localForage,
  whitelist: ['auth', 'provincies'],
}

const persistRootReducer = (state: any, action: any) => {
  if (action.type === 'auth/logout') {
    state = undefined
    localForage.clear()
  }

  return combinedReducers(state, action)
}

const persistedReducer = persistReducer(persistConfig, persistRootReducer)

const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: {
    ignoredActions: ['persist/PERSIST'],
  },
})

export const store = configureStore({
  reducer: persistedReducer,
  middleware: customizedMiddleware,
})
export const persistor = persistStore(store)
