import { INavBar } from 'app/components'

import { pages, functionalitiesCodes } from 'app/constants'

const {
  policies,
  leads,
  assignments,
  afilliations,
  medicAreaReports,
  companyInfo,
  padEmpresas,
} = pages

// filter navbar options depending on the users permissions
export const navBarOptions: (permissions: any[]) => INavBar[] = (
  permissions: any[]
) =>
  [
    {
      name: afilliations.name,
      path: afilliations.path,
      permission: functionalitiesCodes.afilliations,
    },
    {
      name: policies.name,
      path: policies.path,
      permission: functionalitiesCodes.policies,
    },
    {
      name: leads.name,
      path: leads.path,
      permission: functionalitiesCodes.leads,
    },
    {
      name: assignments.name,
      path: assignments.path,
      permission: functionalitiesCodes.advisorAgendaList,
    },
    {
      name: medicAreaReports.name,
      path: medicAreaReports.path,
      permission: functionalitiesCodes.medicAreaReports,
    },
    {
      name: companyInfo.name,
      path: companyInfo.path,
      permission: functionalitiesCodes.companyInfo,
    },
    {
      name: padEmpresas.name,
      path: padEmpresas.path,
      permission: functionalitiesCodes.companyInfo,
    },
  ].filter((e: INavBar) => permissions.find(p => p === e.permission) && e)
