import { Advisor } from 'domain/entities'

import { TGetAdvisorByNameOrCode } from 'domain/requestModel'

import { endpoints } from 'app/constants'

export class AdvisorServices {
  private httpClient: any
  private logger: any

  constructor(clientProvider: any, logger: any) {
    this.httpClient = clientProvider
    this.logger = logger
  }

  public async getAdvisorByNameOrCode(
    params: TGetAdvisorByNameOrCode
  ): Promise<Advisor[]> {
    try {
      const advisors = (
        await this.httpClient.get(endpoints.filtrarAsesor, params)
      ).data

      return advisors
    } catch (error) {
      this.logger.error({
        message: 'UseCaseAdvisor - getAdvisorByNameOrCode',
        data: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
      })

      throw new Error(error)
    }
  }
}

export default {
  AdvisorServices,
}
