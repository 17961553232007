import styled from 'styled-components'

export const TableFilterContainer = styled.div`
  position: relative;
`

export const FilterContainer = styled.div<{
  showFilter: boolean
}>`
  position: absolute;
  top: 60px;
  right: 0;
  width: 275px;
  border-radius: 3px;
  background: #ffffff;
  border: 1px solid ${props => props.theme.colors.silver};
  display: ${({ showFilter }) => (showFilter ? '' : 'none')};
`

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 12px;
`

export const Title = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: ${props => props.theme.colors.tundora};
`

export const ClearLink = styled.p`
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  color: ${props => props.theme.colors.cornflowerBlue};
`

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  background: ${props => props.theme.colors.aliceBlue};
`

export const Option = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px 0;
`
export const InputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 0;
`

export const Input = styled.input`
  padding: 6px;
  border-radius: 4px;
  border: 1px solid ${props => props.theme.colors.warmGreyThree};
`

export const Checkbox = styled.input``

export const Label = styled.label`
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  color: ${props => props.theme.colors.tundora};
`

export const AcordionContainer = styled.div`
  &:not(:last-child) {
    border-bottom: 1px solid ${props => props.theme.colors.paleGreyThree};
  }
`

export const HeadContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  cursor: pointer;
  padding: 9px 12px;
`

export const Head = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: ${props => props.theme.colors.tundora};
`

export const IconArrowsContainer = styled.div``
