import React, { FC } from 'react'
import OMINT_HOME from 'app/images/home_background.jpg'
import {
  HomeBg,
  WelcomeBox,
  WelcomeText,
  TextBox,
  ImportantText,
  UpperText,
  BottomText,
} from './style'

interface ILandingPage {
  userName: string
}

const LandingPage: FC<ILandingPage> = ({ userName }) => {
  return (
    <>
      <HomeBg src={OMINT_HOME} />
      <WelcomeBox>
        <TextBox>
          <UpperText>
            <ImportantText>{userName}</ImportantText>
            <WelcomeText>te damos la bienvenida</WelcomeText>
            <WelcomeText>al</WelcomeText>
          </UpperText>
          <ImportantText>Back Office</ImportantText>
          <BottomText>
            <ImportantText>__</ImportantText>
          </BottomText>
        </TextBox>
      </WelcomeBox>
    </>
  )
}

export default LandingPage
