import { IProvinceDTO } from 'domain/responseModel'

import { IProvinciesEntityGateway } from 'domain/entityGateways/IProvinciesEntityGateway'

import { getAllProvincesToModel } from 'domain/responseModel'

export class UseCaseProvince implements IProvinciesEntityGateway {
  private entityGateway: IProvinciesEntityGateway

  constructor(entityGateway: IProvinciesEntityGateway) {
    this.entityGateway = entityGateway
  }

  public async getAllProvincies(): Promise<IProvinceDTO> {
    const response = await this.entityGateway.getAllProvincies()

    return getAllProvincesToModel(response)
  }

  public async getProvinciesAndLocalitiesCodes(): Promise<any> {
    const response = await this.entityGateway.getProvinciesAndLocalitiesCodes()

    return response
  }
}

export default {
  UseCaseProvince,
}
