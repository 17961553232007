import styled from 'styled-components'

type TModal = {
  displayElement: boolean
}

export const Modal = styled.div<TModal>`
  display: ${props => (props.displayElement ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;
`

export const ModalMain = styled.div`
  position: fixed;
  background: white;
  min-width: 300px;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 6px;
  padding: 15px;
`

export const CloseButtonContainer = styled.div<TModal>`
  display: ${props => (props.displayElement ? 'flex' : 'none')};
  justify-content: flex-end;
`
