import * as Yup from 'yup'
import { strings } from 'app/constants/strings'

import {
  FIELD_MIN_LENGTH,
  FIELD_MAX_LENGTH,
} from 'app/Pages/Policies/constants'

const {
  forms: {
    requiredFields: { pageRequired, policiesRequired },
    validationFields: { minAmountOfRecords, maxAmountOfRecords, dniMinNumber },
  },
} = strings

export const policySchema = Yup.object().shape({
  pagina: Yup.number().required(pageRequired),
  cantidad: Yup.number()
    .min(FIELD_MIN_LENGTH, minAmountOfRecords(FIELD_MIN_LENGTH))
    .max(FIELD_MAX_LENGTH, maxAmountOfRecords(FIELD_MAX_LENGTH))
    .required(policiesRequired),
  dni: Yup.number().min(0, dniMinNumber),
  provincia: Yup.object().nullable(),
  fechaVigencia: Yup.object(),
  codAsesor: Yup.string(),
})
