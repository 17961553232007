import React from 'react'
import { MainText } from './style'

export type TextProps = {
  textAlign?: string
  children: any
  variant?: string
  onClick?: () => void
  color?: string
  size?: string
  margin?: string
  weight?: string
  float?: string
  cursor?: string
  capitalFirstLetter?: boolean
  underlined?: boolean
  isCursor?: boolean
  display?: string
  name?: string
} & typeof defaultProps

const defaultProps = {
  variant: '',
  onClick: () => {},
  color: 'emperor',
  size: 'md',
  weight: 'sm',
  underlined: false,
  isCursor: false,
  name: '',
}

export const Text = (props: TextProps) => {
  return (
    <MainText {...props} name={props.children}>
      {props.children}
    </MainText>
  )
}

Text.defaultProps = defaultProps

export default Text
