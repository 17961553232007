import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import HolderTable from './HoldersTable'
import {
  getCompany,
  selectCompany,
  clearCompanyNotification,
  selectCreateCompany,
  selectShowCreateCompanyNotification,
} from 'app/slices/company'
import { getHolders, selectHolders } from 'app/slices/holders'
import { mapHoldersData } from './presenters'
import {
  getStates,
  selectStates,
  clearStatusNotificationStatePyme,
  selectShowNotificationStatus,
} from 'app/slices/stateSlice'

import CompanyData from './CompanyData'
import * as S from './styles'
import MessageStatus from 'app/components/MessageStatus'
import { notificationStates } from 'app/constants/notificationStates'

const CompanyDetailScreen = () => {
  const history = useHistory()
  const { companyId } = useParams<{ companyId: string }>()
  const idPyme = Number(companyId)
  const dispatch = useDispatch()
  const company = useSelector(selectCompany)
  const holders = useSelector(selectHolders)
  const filterOptions = useSelector(selectStates)
  const showNotificationStatus = useSelector(selectShowNotificationStatus)
  const createdCompany = useSelector(selectCreateCompany)
  const showCreateCompanyNotification = useSelector(
    selectShowCreateCompanyNotification
  )

  const tableFilterData = mapHoldersData(holders, filterOptions).resultados
  const [filteredData, setFilteredData] = useState(tableFilterData)

  const handleOnPressItem = (element: any, key: any) =>
    history.push(`titular/${element[key]}`)

  useEffect(() => {
    dispatch(getHolders({ idPyme }))
  }, [dispatch, idPyme])

  useEffect(() => {
    dispatch(getCompany({ companyId }))
  }, [dispatch, companyId])

  useEffect(() => {
    dispatch(getStates({ idTipoEstado: 3, subTipo: 2 }))
  }, [dispatch])

  useEffect(() => {
    setFilteredData(mapHoldersData(holders, filterOptions).resultados)
  }, [holders, filterOptions])

  const filterSubmitHandler = (document: string, options: any) => {
    const isDocumentEmpty = document.trim() === ''
    const isNoOptionsSelected = options.length === 0

    if (isDocumentEmpty && isNoOptionsSelected) {
      setFilteredData(tableFilterData)
      return
    }

    const filterData = tableFilterData.filter(
      ({
        idEstadoBackOffice,
        dni,
      }: {
        idEstadoBackOffice: number
        dni: string
      }) => {
        if (!isDocumentEmpty && !isNoOptionsSelected) {
          return (
            options.some((option: any) => option.id === idEstadoBackOffice) &&
            document === dni.trim()
          )
        } else {
          return (
            options.some((option: any) => option.id === idEstadoBackOffice) ||
            document === dni.trim()
          )
        }
      }
    )

    setFilteredData(filterData)
  }

  const getNotification = () => {
    const notification: any = {}

    if (showNotificationStatus) {
      notification.type = notificationStates.PAPER_MODE
    }
    if (showCreateCompanyNotification) {
      notification.type = createdCompany.error
        ? notificationStates.ERROR
        : notificationStates.SUCCESS_REGISTER
      notification.errorMsg = createdCompany.error
      notification.companyCode = createdCompany.companyCode
    }

    notification.onClickHandler = () => {
      dispatch(clearStatusNotificationStatePyme())
      dispatch(clearCompanyNotification())
      dispatch(getCompany({ companyId }))
    }

    return notification
  }

  return (
    <>
      {showNotificationStatus || showCreateCompanyNotification ? (
        <S.MessageStatusWrapper>
          <MessageStatus
            type={getNotification().type}
            onClickHandler={getNotification().onClickHandler}
            companyCode={getNotification().companyCode}
            errorMsg={getNotification().errorMsg}
          />
        </S.MessageStatusWrapper>
      ) : (
        <>
          <S.CompanyDataWrapper>
            <CompanyData
              data={company}
              isLoading={holders.loading}
              idPyme={idPyme}
            />
          </S.CompanyDataWrapper>
          <HolderTable
            data={filteredData}
            isLoading={holders.loading}
            onPress={handleOnPressItem}
            filterOptions={filterOptions}
            filterSubmitHandler={filterSubmitHandler}
          />
        </>
      )}
    </>
  )
}

export default CompanyDetailScreen
