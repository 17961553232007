import React, { FC } from 'react'
import { Formik, Form } from 'formik'

import { Supervisor } from 'domain/entities'

import {
  Calendar,
  Button,
  Text,
  Table,
  TableDropdown,
  ISelectFormat,
  InputField,
} from 'app/components'

import MonthSwiper from 'app/Pages/AdvisorAgenda/form/MonthSwiper'
import { initialValues } from 'app/Pages/AdvisorAgenda/constants'
import { advisorAgendaSchema } from 'app/Pages/AdvisorAgenda/validationSchema'
import { FormContent, Block } from 'app/Pages/AdvisorAgenda/styles'
import { TForm, TAdvisorListViewModel } from 'app/Pages/AdvisorAgenda/types'

import { strings } from 'app/constants/strings'

const {
  forms: {
    fieldsName: { searchDate: searchDateFieldName, advisors, exportList },
  },
} = strings

type IAdvisorAgenda = {
  onSubmitForm: (values: any) => void
  onChangeDate: (date: Date) => void
  advisorsList: TAdvisorListViewModel[]
  searchDate: Date
  tableDesktopHeaders: any
  tableMobileHeaders: any
  isLoading: boolean
  supervisorsAmba: Supervisor[]
  supervisorsAmbaOptions: ISelectFormat[]
}

const FormAdvisorAgenda: FC<IAdvisorAgenda> = ({
  onSubmitForm,
  onChangeDate,
  advisorsList,
  searchDate,
  tableDesktopHeaders,
  tableMobileHeaders,
  isLoading,
  supervisorsAmba,
  supervisorsAmbaOptions,
}) => {
  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues({
        fechaBusqueda: searchDate,
        asesores: advisorsList,
      })}
      validationSchema={advisorAgendaSchema}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false)

        onSubmitForm(values)
      }}
    >
      {(props: {
        errors: {
          searchDate: boolean
          exportList: boolean
        }
        touched: {
          searchDate: boolean
          exportList: boolean
        }
        onChange: () => TForm
        setFieldValue: any
        values: any
        setValues: any
        resetForm: any
        submitForm: any
      }) => (
        <Form>
          <Text size={'lg'} weight={'lg'} color={'mariner'}>
            Asignaciones
          </Text>
          <FormContent>
            <InputField
              name={exportList}
              id={exportList}
              type="number"
              hidden={true}
              warning={props.errors.exportList && props.touched.exportList}
            />
            <Block>
              <Calendar
                id={searchDate}
                name={searchDate}
                onChangeDate={(date: Date) => {
                  onChangeDate(date)
                  props.resetForm()
                }}
                onChange={props.setFieldValue}
                warning={props.errors.searchDate && props.touched.searchDate}
                showMonthYearPicker
              />
              <Button
                centered={false}
                type="submit"
                onClick={() => {
                  props.setFieldValue(exportList, 0)
                  props.submitForm()
                }}
              >
                Guardar
              </Button>
              <Button
                centered={false}
                type="button"
                onClick={() => {
                  props.setFieldValue(exportList, 1)
                  props.submitForm()
                }}
                outline={true}
              >
                Exportar
              </Button>
            </Block>
          </FormContent>
          <MonthSwiper
            name={searchDateFieldName}
            date={props.values.fechaBusqueda}
            onChange={props.setFieldValue}
            onChangeDate={(date: Date) => {
              onChangeDate(date)
              props.resetForm()
            }}
          />
          <Table
            data={props.values.asesores}
            tableDesktopHeaders={tableDesktopHeaders}
            tableMobileHeaders={tableMobileHeaders}
            isLoading={isLoading}
            item={(
              e: TAdvisorListViewModel,
              key: string,
              itemSource: TAdvisorListViewModel
            ) => {
              return (
                <>
                  {key === 'assignedSupervisor' ? (
                    <TableDropdown
                      id={advisors}
                      name={advisors}
                      onChange={(option: ISelectFormat) => {
                        const assign = props.values.asesores.map(
                          (asesor: any) => {
                            if (asesor.date === itemSource.date) {
                              const supervisor: any = supervisorsAmba.find(
                                (s: any) => s.codAsesor === option.value
                              )
                              asesor['assignedSupervisor'] = option
                              asesor['contactNumber'] = supervisor.telefono

                              return asesor
                            }

                            return asesor
                          }
                        )

                        props.setValues({
                          ...props.values,
                          asesores: assign,
                        })
                      }}
                      value={e[key]}
                      options={supervisorsAmbaOptions}
                    />
                  ) : (
                    <Text
                      capitalFirstLetter
                      color={'midnightBlue'}
                      textAlign={'center'}
                    >
                      {e[key]}
                    </Text>
                  )}
                </>
              )
            }}
          />
        </Form>
      )}
    </Formik>
  )
}

export default FormAdvisorAgenda
