import styled from 'styled-components'

export const PageContent = styled.div`
  margin: auto 16px;

  @media (min-width: ${({ theme }) => theme.breakPoints.small}) {
    width: 667px;
    margin: 25px auto;
  }
`

export const FormContent = styled.div`
  display: flex;
  padding: 20px 0px;

  @media (min-width: ${({ theme }) => theme.breakPoints.small}) {
    width: 667px;
    margin: 25px auto;
    padding: unset;
  }
`

export const Block = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  > button {
    margin-top: 16px;
  }

  @media (min-width: ${({ theme }) => theme.breakPoints.small}) {
    flex-direction: unset;
    justify-content: space-between;

    > button {
      margin-top: 0px;
      margin-left: 16px;
      align-items: center;
      justify-content: center;
    }
  }
`

export const MonthSwiper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 46px;
  background: #f5fcff;
  border: 1px solid #678fb8;
  box-sizing: border-box;
  border-radius: 4px;

  @media (min-width: ${({ theme }) => theme.breakPoints.small}) {
    padding: 0px 100px;
  }
`
