import styled from 'styled-components'
import { EditOutline } from '@styled-icons/evaicons-outline/EditOutline'
import * as Components from 'app/components'

export const CompanyDataWrapper = styled.div`
  text-align: center;
  background-color: #f5fcff;
  border-bottom: 3px solid #91d8f7;
  padding: 30px 0;
`
export const CompanyData = styled.div`
  width: 100%;

  > div {
    display: flex;
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
    justify-content: space-evenly;
    align-items: flex-start;
    gap: 0.5rem;
    margin-top: 2rem;

    > div {
      text-align: left;
      width: 24%;
    }

    label {
      color: #5f9cd8;
      font-size: 12px;
      font-weight: bold;
    }
    p {
      padding: 10px;
      border-bottom: solid 1px #4d4d4d;
    }

    button {
      margin: 15% 0 0;
      width: 100%;
      height: 40px;
    }
  }
`

export const RowCenter = styled.div`
  justify-content: center !important;
  gap: 3% !important;
`

export const InputWrapper = styled.div`
  > div {
    position: relative;
    color: #9b9b9b;
    opacity: 1;
    top: 0;
    > p {
      opacity: 0.6;
      width: 100%;
    }
  }
`
export const IconWrap = styled.a`
  position: absolute;
  color: #5f9cd8;
  right: 5px;
  cursor: pointer;
  pointer-events: auto;
`

export const BlueEditOutline = styled(EditOutline)`
  width: 18px;
  cursor: pointer;
`

export const Title = styled.div`
  margin-bottom: 1.2rem;
`
export const TitleGF = styled.div`
  margin-bottom: 4rem;
`
export const ReturtBt = styled.div`
  width: 15rem;
  display: flex;
`

export const OptionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const PageContent = styled.div`
  padding: 20px 15px;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakPoints.small}) {
    padding: 20px 45px;
    width: 100%;
  }
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
`

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`
export const SelectContainer = styled.fieldset`
  margin: 16px 0px;
  width: 100%;
  text-align: center;
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Source Sans Pro;

  > div {
    margin: 0px;
  }

  .react-select__control {
    border: none;
    padding: 0;
    font-size: 0.75rem;
    margin: auto;
    float: right;
  }

  .react-select__control:hover {
    border: none;
    border-bottom: none;
    cursor: pointer;
  }

  .react-select__single-value {
    color: ${props => props.color} !important;
    font-weight: bold;
    font-size: 18px;
  }

  .react-select__placeholder {
    color: ${props =>
      props.color && props.theme.colors[`'${props.color}'`]} !important;
    font-weight: bold;
    font-size: 18px;
  }

  .react-select__menu {
    width: 100%;
    text-align: center;
    border: 1px solid #d8d8d8;
    box-sizing: border-box;
    box-shadow: 0px 0px 8px rgb(0 0 0 / 12%);
    border-radius: 4px;
    font-family: Source Sans Pro;
    margin-rigth: 10px;
    margin-top: 6px;
    backgroun-color: white !important;
    font-weight: bold;
    font-size: 18px;
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
    font-size: 14px;

    .react-select__menu {
      width: 150px;
      font-size: 14px;
    }
  }
`

export const ObservationFormContainer = styled.div`
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`

export const ButtonsContainer = styled(Components.Row)`
  > button {
    margin: 0px 20px;
  }
`

export const MessageStatusWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 80%;
`

export const PaperModeButtonContainer = styled.div`
  position: relative;
`

export const ErrorMsg = styled.div`
  color: ${({ theme }) => theme.colors.emergency};
`
