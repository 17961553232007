import { AfilliationEventEnum } from 'domain/entities'

import { ISelectFormat } from 'app/components'

import {
  ProvinceParams,
  RangeDates,
  pagination,
} from 'domain/requestModel/types'

import { getFormatedDate, YearMonthDayWithLeadingZerosDashed } from 'helpers'

export type TGetAfilliationsParams = pagination & {
  dni?: number
  estadoBackOffice?: ISelectFormat
  provincia?: ProvinceParams
  advisor?: ISelectFormat[]
  fechaCotizacion?: RangeDates
  idEstado?: number
}

export type TGetAfilliationParams = {
  idAfiliacion: string
}

export type TGetAfilliationsByFilter = pagination & {
  dni?: string
  idEstado?: number
  idProvincia?: number
  asesores?: string[]
  fechaDesde?: string
  fechaHasta?: string
}

export type TPostAfilliationStateParams = {
  idAfiliacion: number
  observaciones: string
  estado: string
  tipoAfiliacion?: string
}

export const getAfilliationsByFilter = (
  raw: TGetAfilliationsParams
): TGetAfilliationsByFilter => {
  const mappIdsEstado = raw.advisor
    ?.filter((e: ISelectFormat) => e.value && e)
    .map((e: ISelectFormat) => e.value)

  return {
    cantidad: raw.cantidad,
    pagina: raw.pagina,
    dni: raw.dni ? raw.dni.toString() : undefined,
    idEstado:
      raw.estadoBackOffice?.value !== -1
        ? raw.estadoBackOffice?.value
        : undefined,
    idProvincia: raw.provincia?.value
      ? parseInt(raw.provincia.value)
      : undefined,
    asesores: mappIdsEstado || undefined,
    fechaDesde: raw.fechaCotizacion?.startDate
      ? getFormatedDate(
          raw.fechaCotizacion?.startDate,
          YearMonthDayWithLeadingZerosDashed
        ).toString()
      : undefined,
    fechaHasta: raw.fechaCotizacion?.endDate
      ? getFormatedDate(
          raw.fechaCotizacion?.endDate,
          YearMonthDayWithLeadingZerosDashed
        ).toString()
      : undefined,
  }
}

export type TPostEventParam = {
  afilliationEvent: ISelectFormat
  observation: string
  idAfilliation: number
  tipoAfiliacion?: string
}

export type TPostEvent = {
  eventoAfiliacion: AfilliationEventEnum.call | AfilliationEventEnum.observation
  observacion: string
  idAfiliacion: number
}

export const postEvent = (raw: TPostEventParam): TPostEvent => ({
  eventoAfiliacion: raw.afilliationEvent.value,
  observacion: raw.observation,
  idAfiliacion: raw.idAfilliation,
})

export type TGetReportMedicArea = {
  fechaDesde: Date
  fechaHasta: Date
}

export type TGetReportMedicAreaDates = {
  fechadesde: string
  fechahasta: string
}

export const getReportMedicAreaDates = (
  props: TGetReportMedicArea
): TGetReportMedicAreaDates => ({
  fechadesde: getFormatedDate(
    props.fechaDesde,
    YearMonthDayWithLeadingZerosDashed
  ),
  fechahasta: getFormatedDate(
    props.fechaHasta,
    YearMonthDayWithLeadingZerosDashed
  ),
})
