import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { TGetAdvisorByNameOrCode } from 'domain/requestModel'

import { getAdvisorByNameOrCode } from 'app/slices/advisorSlice'
import { getAllProvincies } from 'app/slices/provinceSlice'
import PADCompaniesFilter from './PADCompaniesFilter'
import { makeCompanyRequestModel } from 'domain/requestModel/companyPAD'
import {
  getCompanies,
  selectCompanies,
  getCompanyState,
  selectStatesForDropdowns,
} from 'app/slices/companyPAD'
import CompanyTable from './Table'
import { mapCompanyData } from './presenters'
import { useHistory, useParams } from 'react-router-dom'
import { Row, Text } from 'app/components'
import * as S from './style'
import { getHolders } from 'app/slices/holders'

interface ICompanyInfo {
  path: string
}

const Company = (props: ICompanyInfo) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const statesGF = useSelector(selectStatesForDropdowns)

  const companies = useSelector(selectCompanies)
  const { guid } = useParams<{ guid: string }>()
  const idPyme = Number(guid)

  const handleOnChangeAdvisor = (codeOrName: any) => {
    let param: TGetAdvisorByNameOrCode | any = {}

    isNaN(codeOrName)
      ? (param['nombre'] = codeOrName)
      : (param['codAsesor'] = codeOrName)

    dispatch(getAdvisorByNameOrCode(param))
  }

  useEffect(() => {
    dispatch(getHolders({ idPyme }))
  }, [dispatch, idPyme])

  const handleSubmit = (values: any) => {
    if (values.resetPageOnSubmit) {
      const resetPageNumber = Object.assign(values, { pagina: 0 })
      const params = makeCompanyRequestModel(resetPageNumber)
      dispatch(getCompanies(params))
    } else {
      const params = makeCompanyRequestModel(values)
      dispatch(getCompanies(params))
    }
  }

  const handleOnPressItem = (element: any) =>
    history.push(`empresas/titularCompany/${element.detalle}`)

  useEffect(() => {
    dispatch(getAllProvincies())
  }, [dispatch])

  useEffect(() => {
    dispatch(getCompanies(undefined))
  }, [dispatch])

  useEffect(() => {
    dispatch(getCompanyState({ idTipoEstado: 3 }))
  }, [dispatch])

  return (
    <>
      <PADCompaniesFilter
        onSubmit={handleSubmit}
        onChangeAdvisor={handleOnChangeAdvisor}
        statesGF={statesGF}
        paginationPageCount={companies.totalPaginas}
      >
        <Row>
          <S.ExportTitleContainer>
            <Text textAlign={'center'} size={'xl'} weight={'md'}>
              Grupos familiares
            </Text>
          </S.ExportTitleContainer>
        </Row>
        <CompanyTable
          data={mapCompanyData(companies).resultados}
          isLoading={companies.loading}
          onPressItem={handleOnPressItem}
        />
      </PADCompaniesFilter>
    </>
  )
}

export default Company
