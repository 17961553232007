import React, { useState, useEffect, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useAccessControl } from 'app/hooks/useAccessControl'
import useEffectOnlyOnce from 'app/hooks/useEffectOnlyOnce'

import { TGetAdvisorByNameOrCode } from 'domain/requestModel'

import {
  getLeads,
  selectLeads,
  selectLeadsIsFetching,
  getLeadStates,
  getLeadsExport,
  selectLeadStates,
  selectLeadStatesIsFetching,
  selectLeadsNumberOfPages,
  selectExportLeadsIsFetching,
  postImportLeads,
  selectImportLeadsIsFetching,
  selectError,
  selectImportedLeadsWereUploaded,
  getTemplateMasiveImport,
} from 'app/slices/leadsSlice'
import {
  getAllProvincies,
  selectProvincies,
  getProvinciesAndLocalitiesCodes,
} from 'app/slices/provinceSlice'
import {
  getAdvisorByNameOrCode,
  selectAdvisorFilteredByNameOrCode,
} from 'app/slices/advisorSlice'

import { viewModelLeads } from 'app/Pages/Leads/presenters'

import { LoadingSpinner, ModalContext, Text } from 'app/components'
import ImportExcelForm from 'app/Pages/Leads/importForm'
import { LeadsForm } from 'app/Pages/Leads/form'

import { PageContainer } from 'app/Pages/Leads/styles'

import { TForm } from 'app/Pages/Leads/constants'
import { functionalitiesCodes } from 'app/constants'

interface ILeadsPage {
  path: string
}

const Leads = (props: ILeadsPage) => {
  const [leadsForView, setLeadsForView] = useState([])
  const isAllowed = useAccessControl([functionalitiesCodes.displaySupervisor])

  const dispatch = useDispatch()
  const leads = useSelector(selectLeads)
  const leadsNumberOfPages = useSelector(selectLeadsNumberOfPages)
  const leadsIsFetching = useSelector(selectLeadsIsFetching)
  const allProvincies = useSelector(selectProvincies)
  const leadStates = useSelector(selectLeadStates)
  const leadStatesIsFetching = useSelector(selectLeadStatesIsFetching)
  const exportLeadsIsFetching = useSelector(selectExportLeadsIsFetching)
  const importLeadsIsLoading = useSelector(selectImportLeadsIsFetching)
  const leadError = useSelector(selectError)
  const importedLeadsWereUploaded = useSelector(selectImportedLeadsWereUploaded)
  const advisors = useSelector(selectAdvisorFilteredByNameOrCode)

  const {
    handleModalContent,
    handleDisplayModal,
    handleOnModalIsFetching,
    handleOnModalError,
  }: any = useContext(ModalContext)

  useEffect(() => {
    dispatch(getAllProvincies())
  }, [dispatch])

  useEffect(() => {
    dispatch(getLeadStates())
  }, [dispatch])

  useEffect(() => {
    const mapLeadsForView: any = viewModelLeads({
      leads: leads,
      provincies: allProvincies,
      states: leadStates,
      isAllowed: isAllowed,
    })

    setLeadsForView(mapLeadsForView)
  }, [allProvincies, leads, leadStates, isAllowed])

  useEffect(() => {
    handleOnModalIsFetching(importLeadsIsLoading)
  }, [importLeadsIsLoading, handleOnModalIsFetching])

  useEffect(() => {
    handleOnModalError(leadError)
  }, [leadError, handleOnModalError])

  useEffectOnlyOnce(
    () => {
      if (importedLeadsWereUploaded) {
        handleModalContent(<Text>Cargado con exito!</Text>)
      }
    },
    [importedLeadsWereUploaded, handleModalContent],
    (dependencies: any) => dependencies[0] === true
  )

  const fetchLeads = (values: TForm) => {
    dispatch(getLeads(values))
  }

  const handleOnPressSubmit = (formValues: TForm) => {
    if (formValues.resetPageOnSubmit) {
      const resetedPageNumber = Object.assign(formValues, { pagina: 0 })

      return dispatch(fetchLeads(resetedPageNumber))
    }

    if (formValues.exportList === 1) return dispatch(getLeadsExport(formValues))
    if (formValues.exportList === 0) return fetchLeads(formValues)
  }

  const handleOnSubmitImport = (values: any) =>
    dispatch(postImportLeads(values))

  const handleOnPressDownloadTemplate = () =>
    dispatch(getTemplateMasiveImport())

  const handleOnPressDownloadProvAndLoc = () =>
    dispatch(getProvinciesAndLocalitiesCodes())

  const handleOnPressImport = () => {
    handleDisplayModal(true)
    handleModalContent(
      <ImportExcelForm
        onSubmit={handleOnSubmitImport}
        onPressDownloadTemplate={handleOnPressDownloadTemplate}
        onPressDownloadProvAndLoc={handleOnPressDownloadProvAndLoc}
      />
    )
  }

  const handleOnChangeAdvisor = (codeOrName: any) => {
    let param: TGetAdvisorByNameOrCode | any = {}

    isNaN(codeOrName)
      ? (param['nombre'] = codeOrName)
      : (param['codAsesor'] = codeOrName)

    dispatch(getAdvisorByNameOrCode(param))
  }

  return (
    <LoadingSpinner isLoading={exportLeadsIsFetching} fullPage={true}>
      <PageContainer>
        <LeadsForm
          onSubmit={handleOnPressSubmit}
          provinces={allProvincies}
          leadStates={leadStates}
          leadStatesIsFetching={leadStatesIsFetching}
          leadsForView={leadsForView}
          leadsIsFetching={leadsIsFetching}
          path={props.path}
          paginationPageCount={leadsNumberOfPages}
          isAllowed={isAllowed}
          onPressImport={handleOnPressImport}
          onChangeAdvisor={handleOnChangeAdvisor}
          advisors={advisors.advisors}
          advisorsIsFetching={advisors.loading}
        />
      </PageContainer>
    </LoadingSpinner>
  )
}

export default Leads
