export const relationShips: { [keys: number]: string } = {
  '1': 'Conyuge',
  '2': 'Hijo/a',
  '3': 'Hermano/a',
  '4': 'Madre',
  '5': 'Menor bajo guardia',
  '6': 'Madre Politica',
  '7': 'Nieto a cargo',
  '8': 'Otro',
  '9': 'Padre',
  '10': 'Padre Politico',
}

export enum asociateTypes {
  child = 1,
  family = 4,
  spouse = 6,
  aditionalFamily = 7,
  disabledChild = 8,
  holder = 10,
}

export const asociateTypesStrings: {
  [key: number]: string
} = {
  [asociateTypes.child]: 'Hijo',
  [asociateTypes.family]: 'Familiar',
  [asociateTypes.spouse]: 'Cónyugue',
  [asociateTypes.aditionalFamily]: 'Familiar Adicional',
  [asociateTypes.disabledChild]: 'Hijo Incapacitado',
  [asociateTypes.holder]: 'Titular',
}
