import { Leads } from 'domain/entities'
import { Province } from 'domain/entities'
import { LeadState } from 'domain/entities'

import { idsCanalVenta } from 'app/constants'
import { DayMonthYearWithLeadingZerosSlashed, getFormatedDate } from 'helpers'

type viewModelLeadsType = {
  leads: Leads[]
  provincies: Province[]
  states: LeadState[]
  // permission required display field
  isAllowed: boolean | void
}

export const viewModelLeads = (props: viewModelLeadsType) => {
  return props.leads.map((e: Leads) => {
    const mapProvincie = props.provincies.find(a => a.id === e.provincia)

    const getDescription = () => {
      const findState =
        e.idEstadoDerivadora &&
        props.states &&
        props.states.find((item: any) => item.id === e.idEstadoDerivadora)
      if (findState) {
        return findState.descripcion
      } else {
        return ''
      }
    }

    const getCanalVentaLabel = () => {
      const canalVenta =
        e.idCanalVenta &&
        idsCanalVenta.find((item: any) => item.id === e.idCanalVenta)
      if (canalVenta) {
        return canalVenta.label
      } else {
        return ''
      }
    }

    const mappedLead = {
      nombre: e.nombre,
      eMail: e.eMail,
      telefono: e.telefono,
      edad: e.edad,
      provincia: mapProvincie?.nombre,
      linkContinuar: e.linkContinuar,
      observaciones: e.observaciones,
      idCanalVenta: getCanalVentaLabel(),
      idEstadoDerivadora: getDescription(),
      stateId: e.idEstadoDerivadora,
      detalle: e.id,
      fecha:
        e.fecha &&
        getFormatedDate(e.fecha, DayMonthYearWithLeadingZerosSlashed),
      tipoLead: e.tipoLead,
      asesor: e.nombreAsesor,
    }

    if (props.isAllowed)
      Object.assign(mappedLead, { supervisor: e.nombreSupervisor })

    return mappedLead
  })
}
