import styled from 'styled-components'

import { TAcordion } from 'app/components/ui/Acordion/index'

import { shadowedComponent } from 'app/components/ui/styles'

export const Details = styled.details<TAcordion>`
  position: relative;
  cursor: pointer;
  padding: 12px 12px;
  ${shadowedComponent}
  margin: 8px;
`

export const Summary = styled.summary`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;

  ::marker {
    display: none;
  }
`

export const DetailsContainer = styled.div`
  margin-bottom: 16px;

  @media (min-width: ${({ theme }) => theme.breakPoints.small}) {
    display: flex;
    justify-content: space-between;
  }
`

export const DetailsData = styled.div`
  width: 100%;
`

export const ActionBtnsContainer = styled.div``
