import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Supervisor } from 'domain/entities'
import { TUpdateListAdvisors } from 'domain/requestModel'

import { useAccessControl } from 'app/hooks/useAccessControl'

import {
  getAvisorsAgenda,
  selectAdvisorsList,
  selectAdvisorsListIsLoading,
  updateListAdvisors,
  exportAdvisorsExcel,
} from 'app/slices/advisorAgendaSlice'
import {
  getSupervisorsAmba,
  selectSupervisorsAmba,
  selectSupervisorsAmbaForDropdown,
} from 'app/slices/supervisorSlice'

import { PagesContainer, Inauthorized } from 'app/components'

import {
  tableDesktopHeaders,
  tableMobileHeaders,
} from 'app/Pages/AdvisorAgenda/constants'
import { viewModelPolicies } from 'app/Pages/AdvisorAgenda/presenter'
import * as Style from 'app/Pages/AdvisorAgenda/styles'
import FormAdvisorAgenda from 'app/Pages/AdvisorAgenda/form'
import { TAdvisorListViewModel } from 'app/Pages/AdvisorAgenda/types'

import { functionalitiesCodes } from 'app/constants'

interface IAdvisorAgenda {
  path: string
}

const AdvisorAgenda = (props: IAdvisorAgenda) => {
  const [viewModel, setViewModel] = useState<TAdvisorListViewModel[]>([])
  const [supervisors, setSupervisors] = useState<Supervisor[]>([])
  const [searchDate, setSearchDate] = useState<Date>(new Date())
  const haveAccess = useAccessControl([functionalitiesCodes.advisorAgendaList])
  const dispatch = useDispatch()

  const advisorsList = useSelector(selectAdvisorsList)
  const advisorsListIsLoading = useSelector(selectAdvisorsListIsLoading)
  const supervisorsAmba = useSelector(selectSupervisorsAmba)
  const supervisorsAmbaForDropdown = useSelector(
    selectSupervisorsAmbaForDropdown
  )

  useEffect(() => {
    dispatch(getSupervisorsAmba())
  }, [dispatch])

  useEffect(() => {
    const data: TAdvisorListViewModel[] = viewModelPolicies({
      advisorsList,
      supervisors,
    })

    setViewModel(data)
  }, [advisorsList, supervisors])

  useEffect(() => {
    supervisorsAmba.length && setSupervisors(supervisorsAmba)
  }, [supervisorsAmba])

  const handleGetListAdvisors = (date: Date) => {
    setSearchDate(date)

    dispatch(getAvisorsAgenda({ fechaBusqueda: date }))
  }

  const handleOnPressSubmit = (values: any) => {
    if (!values.asesores.length) return

    const updatedAdvisorList: TUpdateListAdvisors = {
      asesores: values.asesores.map((a: TAdvisorListViewModel) => ({
        fecha: a.date,
        codigo: a.assignedSupervisor.value,
      })),
    }

    if (values.exportList === 0)
      return dispatch(updateListAdvisors(updatedAdvisorList))

    dispatch(exportAdvisorsExcel({ fechaBusqueda: values.fechaBusqueda }))
  }

  if (!haveAccess) return <Inauthorized />

  return (
    <>
      <PagesContainer>
        <Style.PageContent>
          <FormAdvisorAgenda
            onSubmitForm={handleOnPressSubmit}
            onChangeDate={handleGetListAdvisors}
            searchDate={searchDate}
            advisorsList={viewModel}
            tableDesktopHeaders={tableDesktopHeaders}
            tableMobileHeaders={tableMobileHeaders}
            isLoading={advisorsListIsLoading}
            supervisorsAmba={supervisorsAmba}
            supervisorsAmbaOptions={supervisorsAmbaForDropdown}
          />
        </Style.PageContent>
      </PagesContainer>
    </>
  )
}

export default AdvisorAgenda
