import styled from 'styled-components'

import * as C from 'app/components'

export const HolderScreenContainer = styled(C.PagesContainer)`
  padding: 1rem 1rem;
`

export const HolderScreenContent = styled(C.PagesContainer)`
  display: flex;
  gap: 1rem;
  justify-content: center;
  padding: 3rem 0;

  > div {
    width: 668px;
  }
`

//historial
export const HistoryContainer = styled.div`
  padding: 1rem 0.9rem 0 0;
`

export const HistoryDualColumn = styled.div`
  display: flex;
  justify-content: space-between;
`

export const HistoryButton = styled.div`
  padding-top: 2rem;
  display: flex;
  justify-content: center;
`

export const RecentStateTitle = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.5rem;
`

export const PreviousStatesTitle = styled.div`
  margin-top: 2rem;
`

export const UserState = styled.div`
  display: flex;
  gap: 0.2rem;
`

export const PreviousStatesParent = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 0.5rem;
`

export const PreviousStatesChildrens = styled.div`
  padding-top: 0.5rem;
`

export const FirstColumn = styled.div``
export const SecondColumn = styled.div``
export const GroupData = styled.div`
  padding: 1rem 0;
`
export const DjsRows = styled.div`
  display: flex;
  justify-content: space-between;
  width: 95%;
  margin: auto;

  > span:first-child {
    width: 80%;
  }
`
export const AcordionContent = styled.div`
  > span {
    padding: 1rem 0;
  }
`

export const ModalBg = styled.div<{ show: boolean }>`
  display: ${props => (props.show ? 'none' : 'flex')};
  justify-content: center;
  background: rgba(0, 56, 126, 0.75);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 3.3rem;
  margin-left: -1rem;
`

export const ModalBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-color: white;
  gap: 2rem;
  margin-top: 4rem;
  width: 40rem;
  height: 26rem;
  border: 1px solid ${props => props.theme.colors.cornflowerBlue};
  border-radius: 5px;
`
export const ConfirmationStatus = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 24px 0;
  background-color: ${props => props.theme.colors.white};
  width: 460px;
  height: 340px;
  padding: 24px 40px;
  margin-top: 80px;
`
export const ConfirmationIcon = styled.img`
  margin: 24px;
`
export const CloseIcon = styled.div`
  margin: 10px 0;
  width: 100%;
  display: flex;
  justify-content: end;
`

export const ConfirmationButton = styled.div`
  width: 100%;
  display: flex;
  padding-top: 40px;

  > button {
    width: 100%;
    align-items: center;
    justify-content: center;
    max-width: unset;
  }
`
export const IconContainer = styled.div`
  margin: 8px;
  > div {
    display: flex;
  }
`

export const LinkDocumentation = styled.div`
  display: flex;
  color: ${props => props.theme.colors.chathamsBlue};
  margin: 16px 0;
`

export const NameDocumentation = styled.div`
  margin: 0 8px;
  text-decoration-line: underline;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`

export const QuantityFamily = styled.div`
  display: flex;
  padding: 0px 8px;
  align-items: center;
  gap: 4px;
  border-radius: 24px;
  background-color: ${props => props.theme.colors.blueLight};
  color: ${props => props.theme.colors.white};
  margin-left: 8px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
`

export const ModalBoxObservation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-color: white;
  gap: 2rem;
  margin-top: 4rem;
  width: 600px;
  height: 26rem;
  border: 1px solid ${props => props.theme.colors.cornflowerBlue};
  border-radius: 5px;
`

export const ModalBoxDocumentation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-color: white;
  gap: 2rem;
  margin-top: 4rem;
  width: 600px;
  height: 23rem;
  border: 1px solid ${props => props.theme.colors.cornflowerBlue};
  border-radius: 5px;
`
export const InputsBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`
export const ContainerBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0 90px;
  width: 90%;
`

export const Title = styled.div`
  margin-top: 1rem;
`
export const ButtonsBox = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
`

export const ErrorText = styled.div`
  color: red;
  position: fixed;
  margin-top: 3.2rem;
`

export const StateTitle = styled.div`
  display: flex;
  margin-left: 26rem;
  margin-top: -2.5rem;
`

export const CommentHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0 0;
`

export const ObservationFormContainer = styled.div`
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`

export const ButtonsContainer = styled(C.Row)`
  > button {
    margin: 0px 20px;
    align-items: center;
    justify-content: center;
  }
`

export const MessageContainer = styled.div`
  width: 95%;
  position: absolute;
  bottom: 0;
`

export const MessageError = styled.div`
  color: ${props => props.theme.colors.red};
`

export default {
  HolderScreenContainer,
  HolderScreenContent,
  HistoryContainer,
  HistoryDualColumn,
  HistoryButton,
  RecentStateTitle,
  PreviousStatesTitle,
  PreviousStatesChildrens,
  FirstColumn,
  SecondColumn,
  ModalBg,
  ModalBox,
  InputsBox,
  ButtonsBox,
  Title,
  StateTitle,
  GroupData,
  DjsRows,
  AcordionContent,
  CommentHead,
  ErrorText,
  MessageContainer,
}
