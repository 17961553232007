import { Advisor } from 'domain/entities'

import { IAdvisorEntityGateway } from 'domain/entityGateways/IAdvisorEntityGateway'

import { TGetAdvisorByNameOrCode } from 'domain/requestModel'

export class UseCaseAdvisor implements IAdvisorEntityGateway {
  private entityGateway: IAdvisorEntityGateway

  constructor(entityGateway: IAdvisorEntityGateway) {
    this.entityGateway = entityGateway
  }

  public async getAdvisorByNameOrCode(
    params: TGetAdvisorByNameOrCode
  ): Promise<Advisor[]> {
    const filteredListOfAdvisors: Advisor[] = await this.entityGateway.getAdvisorByNameOrCode(
      params
    )

    return filteredListOfAdvisors
  }
}
