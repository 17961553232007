import React, { FC } from 'react'
import { Formik, FormikHelpers, Form } from 'formik'

import { getPolicyStateByPolicyIdRequestModel } from 'domain/requestModel'

import { IPolicyStatesForm } from 'app/Pages/Policy/interfaces'

import {
  Button,
  Text,
  LoadingSpinner,
  Row,
  LeadsDropdown,
  LineHR,
  KeyValue,
} from 'app/components'

import { initialValues, policyStatesIds } from 'app/Pages/Policy/constants'

import { FormContainer } from 'app/Pages/Policy/style'

export const PolicyStatesForm: FC<IPolicyStatesForm> = ({
  onSubmit,
  policy,
  policyStates,
  policyStatesIsFetching,
  policyStateIsUpdating,
  selectedOption,
  setSelectedOption,
  disableSubmitForm,
  policyStateIsUpdated,
}) => {
  const fullName = `${policy.socio?.apellido}, ${policy.socio?.nombre}`
  const isDisabled =
    selectedOption.value !== policyStatesIds.errorPAreaMedica.id

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(
        values: getPolicyStateByPolicyIdRequestModel,
        { setSubmitting }: FormikHelpers<getPolicyStateByPolicyIdRequestModel>
      ) => {
        setSubmitting(false)
        onSubmit(values)
      }}
    >
      {(props: {
        errors: {
          idEstado: boolean
        }
        touched: {
          idEstado: boolean
        }
        setFieldValue: any
        values: any
      }) => (
        <Form>
          <FormContainer>
            <Text color={'mariner'} weight={'md'} size={'lg'}>
              Detalle
            </Text>
            <Row justifyContent={'space-between'} alignItems={'flex-end'}>
              <Text color={'midnightBlue'} weight={'md'} size={'lg'}>
                {fullName}
              </Text>
              <div>
                <LeadsDropdown
                  title={'Estado'}
                  value={selectedOption}
                  isLoading={policyStatesIsFetching}
                  onChange={(option: any) => {
                    props.setFieldValue(
                      'idEstado',
                      option ? option.value : null
                    )
                    setSelectedOption(option)
                  }}
                  isClearable={true}
                  name={'idEstado'}
                  id={'idEstado'}
                  placeholder="Seleccione"
                  options={policyStates}
                  isDisabled={isDisabled}
                  warning={props.errors.idEstado && props.touched.idEstado}
                />
              </div>
            </Row>
            <LineHR />
            {/* Print detail data */}
            {Object.keys(policy?.seguro).map((e: any, index: number) => (
              <KeyValue
                key={index}
                label={e}
                value={policy.seguro[e]}
                estadoBackoffice={selectedOption?.label}
              />
            ))}
            {policyStateIsUpdating ? (
              <LoadingSpinner isLoading={true} />
            ) : (
              <Button disabled={disableSubmitForm} type="submit">
                Editar estado
              </Button>
            )}
            {policyStateIsUpdated && !policyStateIsUpdating && (
              <Text>Estado actualizado</Text>
            )}
          </FormContainer>
        </Form>
      )}
    </Formik>
  )
}
