import { ISelectFormat } from 'app/components'

import { getFormatedDate, YearMonthDayWithLeadingZerosDashed } from 'helpers'

import {
  ProvinceParams,
  RangeDates,
  pagination,
} from 'domain/requestModel/types'

export type getPoliciesParams = pagination & {
  dni?: number
  estadoBackOffice?: ISelectFormat
  provincia?: ProvinceParams
  asesores?: string
  fechaVigencia?: RangeDates
  fechaCotizacion?: RangeDates
  codAsesor?: string
}

export type TGetPoliciesRequestModel = {
  dni?: number
  estadoBackOffice?: number
  idProvincia?: string
  asesores?: string
  fechaVigenciaDesde?: string
  fechaVigenciaHasta?: string
  fechaCotizacionDesde?: string
  fechaCotizacionHasta?: string
  codAsesor?: string
}

export type getPoliciesRequestModel = pagination & TGetPoliciesRequestModel

export const makePolicyReqParams = (
  raw: getPoliciesParams
): getPoliciesRequestModel => {
  return {
    pagina: raw.pagina,
    cantidad: raw.cantidad,
    dni: raw.dni ? raw.dni : undefined,
    estadoBackOffice:
      raw.estadoBackOffice?.value !== -1
        ? raw.estadoBackOffice?.value
        : undefined,
    idProvincia: raw.provincia?.value ? raw.provincia?.value : undefined,
    asesores: raw.asesores,
    fechaVigenciaDesde: raw.fechaVigencia?.startDate
      ? getFormatedDate(
          raw.fechaVigencia?.startDate,
          YearMonthDayWithLeadingZerosDashed
        ).toString()
      : undefined,
    fechaVigenciaHasta: raw.fechaVigencia?.endDate
      ? getFormatedDate(
          raw.fechaVigencia?.endDate,
          YearMonthDayWithLeadingZerosDashed
        ).toString()
      : undefined,
    fechaCotizacionDesde: raw.fechaCotizacion?.startDate
      ? getFormatedDate(
          raw.fechaCotizacion?.startDate,
          YearMonthDayWithLeadingZerosDashed
        ).toString()
      : undefined,
    fechaCotizacionHasta: raw.fechaCotizacion?.endDate
      ? getFormatedDate(
          raw.fechaCotizacion?.endDate,
          YearMonthDayWithLeadingZerosDashed
        ).toString()
      : undefined,
    codAsesor: raw.codAsesor ? raw.codAsesor : undefined,
  }
}

export type getPolicyRequestModel = {
  idSeguro: string
}

export type getPolicyStateByPolicyIdRequestModel = {
  idEstado: number
}

export type putPolicyStateRequestModel = {
  idSeguro: number
  estadoBackOffice: number
}

export type extendGetPoliciesParamsWithExportList = getPoliciesParams & {
  exportList: number
}

export const makePolicyExportReqParams = (
  raw: extendGetPoliciesParamsWithExportList
): TGetPoliciesRequestModel => {
  return {
    dni: raw.dni ? raw.dni : undefined,
    estadoBackOffice:
      raw.estadoBackOffice?.value !== -1
        ? raw.estadoBackOffice?.value
        : undefined,
    idProvincia: raw.provincia?.value ? raw.provincia?.value : undefined,
    asesores: raw.asesores,
    fechaVigenciaDesde: raw.fechaVigencia?.startDate
      ? getFormatedDate(
          raw.fechaVigencia?.startDate,
          YearMonthDayWithLeadingZerosDashed
        ).toString()
      : undefined,
    fechaVigenciaHasta: raw.fechaVigencia?.endDate
      ? getFormatedDate(
          raw.fechaVigencia?.endDate,
          YearMonthDayWithLeadingZerosDashed
        ).toString()
      : undefined,
    fechaCotizacionDesde: raw.fechaCotizacion?.startDate
      ? getFormatedDate(
          raw.fechaCotizacion?.startDate,
          YearMonthDayWithLeadingZerosDashed
        ).toString()
      : undefined,
    fechaCotizacionHasta: raw.fechaCotizacion?.endDate
      ? getFormatedDate(
          raw.fechaCotizacion?.endDate,
          YearMonthDayWithLeadingZerosDashed
        ).toString()
      : undefined,
    codAsesor: raw.codAsesor ? raw.codAsesor : undefined,
  }
}
