import React from 'react'

import { Styles, TBody } from 'app/components/ui/Table'

export const Body = (props: TBody) => {
  return (
    <>
      {props.items.map((item, index) => {
        return (
          <Styles.TTr key={index}>
            {Object.keys(item).map((key, j) => {
              return (
                <Styles.TTd key={j}>
                  {props.item(item, key, props.data[index])}
                </Styles.TTd>
              )
            })}
          </Styles.TTr>
        )
      })}
    </>
  )
}

export default Body
