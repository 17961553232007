import React, { useState, createContext, ReactNode } from 'react'

import {
  MessageContainer,
  MessageContent,
} from 'app/components/ui/Message/styles'
import { IconClose } from 'app/components/Icons'
import { Text } from 'app/components/ui'

export type MessageProps = {
  message: string
  displayMessage: boolean
  backgroundColor: string
  setMessageProps: (value: any) => void
  readonly children: ReactNode
} & typeof defaultProps

const defaultProps = {
  message: '¡mensaje por default!',
  displayMessage: false,
  backgroundColor: 'eucalyptus',
  setMessageProps: (value: any) => {},
}

export const MessageContext = createContext(defaultProps)

export const Message = (props: MessageProps) => {
  const params = {
    ...props,
    setMessageProps: (value: any) => {
      setMessageInfo({ ...messageInfo, ...value })
    },
  }

  const [messageInfo, setMessageInfo] = useState(params)

  return (
    <MessageContext.Provider value={messageInfo}>
      <MessageContainer {...messageInfo}>
        <Text color={'white'} weight={'md'}>
          {messageInfo.message}
        </Text>
        <MessageContent>
          <IconClose
            size={'sm'}
            centered={false}
            color={'white'}
            onClick={() =>
              messageInfo.setMessageProps({ displayMessage: false })
            }
          />
        </MessageContent>
      </MessageContainer>
      {props.children}
    </MessageContext.Provider>
  )
}

Message.defaultProps = defaultProps

export default Message
