import { Table } from './Table'
import { Header } from './Header'
import { Body } from './Body'
import * as Styles from './styles'

import { ItemDefaultProps, TableDefaultProps } from './constants'
import { TTable, THeader, TItem, TBody } from './types'

export default Table
export { Header, Body, Styles, ItemDefaultProps, TableDefaultProps }

export type { TTable, THeader, TItem, TBody }
