import React, { FC, ReactNode } from 'react'
import { Formik, Form } from 'formik'

import { initialValues } from './constants'
import { strings } from 'app/constants/strings'
import {
  InputsContainer,
  PageContainer,
  Title,
  BottomContainer,
  FootFields,
  FilterContainer,
  ShowFilterContainer,
  ShowFilterButton,
} from './style'
import {
  SelectAsync,
  IconArrowIosUpwardOutline,
  IconArrowIosDownwardOutline,
  Button,
  Pagination,
  Text,
  ISelectFormat,
} from 'app/components'
import Date from 'app/components/ui/forms/Date'
import { useToggle } from 'app/hooks/useToggle'

const {
  forms: {
    fieldsName: { exportList, listaEstados, page, resetPageOnSubmit },
    fieldsTitle: { stateTitle },
  },
} = strings

interface ICompanyFilter {
  onSubmit: (values: any) => void
  statesGF: ISelectFormat[]
  onChangeAdvisor: (codeOrName: string) => void
  paginationPageCount: number
  children: ReactNode
}

const CompanyFilter: FC<ICompanyFilter> = ({
  onSubmit,
  statesGF,
  paginationPageCount,
  children,
}) => {
  const { opened, toggle } = useToggle(true)
  const IconArrowComponent = opened
    ? IconArrowIosUpwardOutline
    : IconArrowIosDownwardOutline

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={(values: any, { setSubmitting }) => {
          setSubmitting(false)
          onSubmit(values)
        }}
      >
        {(props: {
          values: any
          errors: {
            fechaIngreso: boolean
            listaEstados: boolean
          }
          touched: {
            fechaIngreso: boolean
            listaEstados: boolean
          }
          onChange: any
          handleChange: any
          submitForm: any
          setFieldValue: any
          resetForm: any
        }) => (
          <Form>
            <PageContainer>
              <Title>
                <Text
                  textAlign={'center'}
                  size={'xl'}
                  color={'resolutionBlue'}
                  weight={'md'}
                >
                  Empresas ingresadas
                </Text>
              </Title>
              {opened && (
                <FilterContainer>
                  <Text textAlign={'center'} size={'xl'} weight={'md'}>
                    Filtrar
                  </Text>
                  <InputsContainer>
                    <Date
                      showMonthDropdown
                      showYearDropdown
                      label="Fecha de ingreso"
                      placeholder="DD/MM/AAAA"
                      name="ultimaActualizacion"
                      onChange={props.setFieldValue}
                      onChangeDate={date =>
                        props.setFieldValue('fechaIngreso', date)
                      }
                    />
                    <SelectAsync
                      onChange={(option: any) => {
                        props.setFieldValue(
                          listaEstados,
                          option ? option : null
                        )
                      }}
                      title={stateTitle}
                      name={listaEstados}
                      id={listaEstados}
                      placeholder="Seleccionar"
                      options={statesGF}
                      isClearable
                    />
                  </InputsContainer>

                  <BottomContainer>
                    <Button
                      type="submit"
                      onClick={() => {
                        props.setFieldValue(resetPageOnSubmit, true)
                        props.setFieldValue(exportList, 0)
                        props.submitForm()
                      }}
                    >
                      Buscar
                    </Button>
                  </BottomContainer>
                </FilterContainer>
              )}
              <ShowFilterContainer>
                <ShowFilterButton onClick={toggle}>
                  <IconArrowComponent color={'cornflowerBlue'} isCursor />
                  <Text
                    textAlign={'center'}
                    color={'cornflowerBlue'}
                    size={'sm'}
                    isCursor
                  >
                    {opened ? 'Ocultar filtros' : 'Mostrar filtros'}
                  </Text>
                </ShowFilterButton>
              </ShowFilterContainer>
            </PageContainer>
            {children}
            <FootFields>
              <Pagination
                pageFieldName={page}
                resetFieldName={resetPageOnSubmit}
                setFieldValue={props.setFieldValue}
                pageCount={paginationPageCount}
                submit={props.submitForm}
              />
            </FootFields>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default CompanyFilter
