import React from 'react'
import { strings } from 'helpers'

import { Text, KeyValue, LineHR } from 'app/components'

import * as S from 'app/components/ui/Acordion/style'

export type TAcordion = {
  title: string
  data: any
  children?: React.ReactNode
  open?: boolean
  actionButtons?: React.ReactNode[]
  headerButton?: React.ReactNode
  margin?: string
  tag?: React.ReactNode
} & typeof AcordionDefaultProps

const AcordionDefaultProps = {
  title: '',
  data: {},
  open: false,
}

const ArrayData = (props: any) => {
  if (Array.isArray(props.data))
    return props.data.map((element: any, index: number) => (
      <ArrayData key={strings.getRandomUniqueId(8)} data={element} />
    ))

  return (
    <>
      {Object.keys(props.data).map((a: any) => (
        <React.Fragment key={strings.getRandomUniqueId(8)}>
          {Array.isArray(props.data[a]) ? (
            <>
              <ArrayData data={props.data[a]} />
              <LineHR />
            </>
          ) : (
            <KeyValue label={a} value={props.data[a]} />
          )}
        </React.Fragment>
      ))}
    </>
  )
}

export const Acordion = (props: TAcordion) => {
  return (
    <S.Details {...props}>
      <S.Summary>
        <Text
          weight={'lg'}
          color={'mariner'}
          display="flex"
          margin={props.margin}
        >
          {props.title} {props.tag && <>{props.tag}</>}
        </Text>
        {props.headerButton && <div>{props.headerButton}</div>}
      </S.Summary>
      {props.children}
      <S.DetailsContainer>
        <S.DetailsData>
          {props.data ? (
            <ArrayData data={props.data} key={'acordion'} />
          ) : (
            'Sin datos'
          )}
        </S.DetailsData>
        {props.actionButtons?.length && (
          <S.ActionBtnsContainer>
            {props.actionButtons.map((buttons: any) => buttons)}
          </S.ActionBtnsContainer>
        )}
      </S.DetailsContainer>
    </S.Details>
  )
}

Acordion.defaultProps = AcordionDefaultProps

export default Acordion
