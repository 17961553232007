import React, { FC } from 'react'
import { Formik, FormikHelpers, Form } from 'formik'

import { Province, State } from 'domain/entities'

import { TForm, initialValues } from 'app/Pages/Policies/constants'

import { Pagination } from 'app/components'

import PoliciesTable, { IPoliciesTable } from 'app/Pages/Policies/Table'

import * as Styles from 'app/Pages/Policies/style'

import { policySchema } from 'app/Pages/Policies/validationSchema'

import FormInputs from 'app/Pages/Policies/Form/formInputs'

import { strings } from 'app/constants/strings'

const {
  forms: {
    fieldsName: { page, resetPageOnSubmit },
  },
} = strings

export interface IPoliciesForm {
  onSubmit: (values: TForm) => void
  provincies: Province[]
  states: State[]
  onPressExportRejectedPolicies: () => void
  onPressExportAbandonedQuotes: () => void
}

interface IPagination {
  paginationPageCount: number
}

const PoliciesForm: FC<IPoliciesForm & IPagination & IPoliciesTable> = ({
  onSubmit,
  provincies,
  states,
  paginationPageCount,
  data,
  isLoading,
  onPressItem,
  onPressExportRejectedPolicies,
  onPressExportAbandonedQuotes,
}) => {
  return (
    <Styles.PageContainer>
      <Formik
        initialValues={initialValues}
        validationSchema={policySchema}
        onSubmit={(values: TForm, { setSubmitting }: FormikHelpers<TForm>) => {
          setSubmitting(false)
          onSubmit(values)
        }}
      >
        {props => (
          <Form>
            <FormInputs
              {...props}
              provincies={provincies}
              states={states}
              onSubmit={onSubmit}
              onPressExportRejectedPolicies={onPressExportRejectedPolicies}
              onPressExportAbandonedQuotes={onPressExportAbandonedQuotes}
            />
            <PoliciesTable
              data={data}
              isLoading={isLoading}
              onPressItem={onPressItem}
            />
            <Styles.FootFields>
              <Pagination
                pageFieldName={page}
                resetFieldName={resetPageOnSubmit}
                setFieldValue={props.setFieldValue}
                pageCount={paginationPageCount}
                submit={props.submitForm}
              />
            </Styles.FootFields>
          </Form>
        )}
      </Formik>
    </Styles.PageContainer>
  )
}

export default PoliciesForm
