import { createSlice, createSelector } from '@reduxjs/toolkit'

import { RootState } from 'app/store/reducers'
import { UseCaseSupervisor } from 'domain/useCases'
import { Supervisor } from 'domain/entities'
import { supervisorsService } from 'app/services'

import { mapSupervisorsAmbaForDropdown } from 'app/slices/helper'

import { getAdvisorsBySupervisorRequestParams } from 'domain/requestModel'

const stateController = new UseCaseSupervisor(supervisorsService)

type initialStateReducer = {
  supervisorsAmbaIsFetchin: boolean
  advisorsBySupervisorIsFetching: boolean
  error: null
  supervisorsAmba: Supervisor[]
  advisorsBySupervisor: any
}

const INITIAL_STATE: initialStateReducer = {
  supervisorsAmbaIsFetchin: false,
  advisorsBySupervisorIsFetching: false,
  error: null,
  supervisorsAmba: [],
  advisorsBySupervisor: [],
}

export const slice = createSlice({
  name: 'state',
  initialState: INITIAL_STATE,
  reducers: {
    fetchSupervisorsAmbaStart: state => {
      state.supervisorsAmbaIsFetchin = true
      state.error = null
    },
    fetchSupervisorsAmbaSuccess: (state, { payload }) => {
      state.supervisorsAmbaIsFetchin = false
      state.error = null
      state.supervisorsAmba = payload
    },
    fetchSupervisorsAmbaFail: (state, { payload }) => {
      state.supervisorsAmbaIsFetchin = false
      state.error = payload.message
    },
    fetchAdvisorsBySupervisorStart: state => {
      state.advisorsBySupervisorIsFetching = true
      state.error = null
    },
    fetchAdvisorsBySupervisorSuccess: (state, { payload }) => {
      state.advisorsBySupervisorIsFetching = false
      state.error = null
      state.advisorsBySupervisor = payload
    },
    fetchAdvisorsBySupervisorFail: (state, { payload }) => {
      state.advisorsBySupervisorIsFetching = false
      state.error = payload.message
    },
  },
})

const {
  fetchSupervisorsAmbaStart,
  fetchSupervisorsAmbaSuccess,
  fetchSupervisorsAmbaFail,
  fetchAdvisorsBySupervisorStart,
  fetchAdvisorsBySupervisorSuccess,
  fetchAdvisorsBySupervisorFail,
} = slice.actions

export default slice.reducer

/**
 * Thunks
 */
export const getSupervisorsAmba = () => async (dispatch: any) => {
  try {
    dispatch(fetchSupervisorsAmbaStart())

    const response = await stateController.getSupervisorsAmba()

    dispatch(fetchSupervisorsAmbaSuccess(response))
  } catch (error) {
    dispatch(fetchSupervisorsAmbaFail(error))
  }
}

export const getAdvisorsBySupervisor = (
  params: getAdvisorsBySupervisorRequestParams
) => async (dispatch: any) => {
  try {
    dispatch(fetchAdvisorsBySupervisorStart())

    const response = await stateController.getAdvisorsBySupervisor(params)

    dispatch(fetchAdvisorsBySupervisorSuccess(response))
  } catch (error) {
    dispatch(fetchAdvisorsBySupervisorFail(error))
  }
}

/**
 * Selectors
 */

export const selectSelf = (state: RootState) => state.supervisors
export const selectSupervisorsAmba = createSelector(
  selectSelf,
  supervisors => supervisors.supervisorsAmba
)
export const selectSupervisorsAmbaIsFetching = createSelector(
  selectSelf,
  supervisors => supervisors.supervisorsAmbaIsFetchin
)
export const selectSupervisorsAmbaForDropdown = createSelector(
  selectSelf,
  supervisors => mapSupervisorsAmbaForDropdown(supervisors.supervisorsAmba)
)
export const selectAdvisorsBySupervisor = createSelector(
  selectSelf,
  supervisors => supervisors.advisorsBySupervisor
)
export const selectAdvisorsBySupervisorIsFetching = createSelector(
  selectSelf,
  supervisors => supervisors.advisorsBySupervisorIsFetching
)
