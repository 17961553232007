import React from 'react'
import styled from 'styled-components'

import { PagesContainer, Text } from 'app/components'

import { shadowedComponent } from 'app/components/ui/styles'

export const PageContent = styled(PagesContainer)`
  padding: 10px 16px;

  @media (min-width: ${({ theme }) => theme.breakPoints.small}) {
    padding: 20px 45px;
  }
`

export const BackLink = () => <Text isCursor={true}>Volver al Listado</Text>

export const FormContainer = styled.div`
  ${shadowedComponent}
  @media (min-width: ${({ theme }) => theme.breakPoints.small}) {
    width: 730px;
  }

  padding: 16px;
  margin: 14px 0px;

  > label {
    display: block;
  }
`
