import React from 'react'

import { Acordion, Button } from 'app/components'

const StateUpdate = (props: any) => (
  <Acordion
    title={'Estado'}
    open={true}
    headerButton={[
      <Button
        type="button"
        onClick={props.onPressStateUpdate}
        outline
        disabled={false}
        isLoading={false}
        key={'state-update-btn'}
      >
        Modificar Estado
      </Button>,
    ]}
    data={props.data}
  />
)

export default StateUpdate
