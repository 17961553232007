import { ILogger } from 'domain/entities'
import { endpoints } from 'app/constants'
import { IAcuerdoEntityGateway } from '../../domain/entityGateways/IAcuerdosEntityGateway'
import {
  Acuerdo,
  TGetAcuerdosParams,
  TUpdateAcuerdoParams,
} from '../../domain/requestModel/acuerdos'

export class AcuerdoService implements IAcuerdoEntityGateway {
  private httpClient: any
  private logger: any

  constructor(clientProvider: any, logger: ILogger) {
    this.httpClient = clientProvider
    this.logger = logger
  }

  public async getAcuerdos(params: TGetAcuerdosParams): Promise<Acuerdo[]> {
    try {
      const response = await this.httpClient.get(
        `${endpoints.getAcuerdos}?codObraSocial=${
          params.codObraSocial
        }&idEmpresa=${params.idEmpresa || ''}`
      )
      return response.data
    } catch (error) {
      this.logger.error({
        message: 'UseCase - getAcuerdos',
        data: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
      })
      throw new Error(error)
    }
  }

  public async updateAcuerdo(params: TUpdateAcuerdoParams): Promise<any> {
    try {
      const response = await this.httpClient.put(endpoints.updateAcuerdo, {
        guidverificador: params.guidverificador,
        acuerdo: params.acuerdo,
      })
      return response.data
    } catch (error) {
      this.logger.error({
        message: 'UseCase - updateAcuerdo',
        data: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
      })
      throw new Error(error)
    }
  }
}

export default {
  AcuerdoService,
}
