export const CardDefaultProps = {
  data: {},
  displayCard: false,
  className: '',
}

export const ItemDefaultProps = {
  data: {},
  displayItem: true,
  detailColumnExist: false,
}

export const TableDefaultProps = {
  data: [],
  isLoading: false,
}
