// Cod	Descripcion

// 1 Ver DJS
// 2 Ver llamados
// 3 Ver Eventos
// 4 Ver recibo de sueldo
// 5 Ver DNI
// 6 Ver Aporte
// 7 Agregar llamado
// 8 Agregar observacion
// 9 Ver marcas especiales
// 10 Ver filtro de asesores
// 11	Cambiar estado desregulacion
// 12	Cambiar estado afiliacion
// 13	Ver info del plan
// 14	Ver BlackList Asesores
// 15	Ver reporte aportes
// 16	Ver seguro
// 17 Ver detalle de poliza
// 18	Descargar excel seguros
// 19	Actualizar estado seguro
// 20 Ver listado de polizas
// 21	Ver leeds
// 25 Ver AgendaAsesores
// 24 Actualizar AgendaAsesores
// 31 Ver supervisor
// 32 Carga Masiva de Leads
// 37 Reportes area medica
// 38 Empresa
// 39 Cambiar estado de documentacion Pymes
// 40 Cambiar estado de empresa Pymes
// 41 Cambiar a estado papel Pymes
// 42 Dar de alta empresa Pymes
// 43 Modificar estado de grupo familiar Pymes
// 44 Agregar observaciones de grupo familiar Pymes

export const functionalitiesNames = {
  dashboard: 'Dashboard',
  policies: 'Polizas',
  policy: 'Poliza',
  leads: 'Leads',
  advisorAgendaList: 'AdvisorAgendaList',
  displaySupervisor: 'DisplaySupervisor',
  afilliations: 'Afilliations',
  medicAreaReports: 'medicAreaReports',
  companyInfo: 'CompanyInformation',
}

// funcionality: codes
export const functionalitiesCodes = {
  dashboard: '10',
  policy: '16',
  stateUpdate: '19',
  leads: '21',
  policies: '20',
  advisorAgendaList: '25',
  displaySupervisor: '31',
  leadsMasiveLoad: '32',
  afilliations: '36',
  medicAreaReports: '36',
  companyInfo: '38',
  changeCompanyDocumentationState: '39',
  changeCompanyState: '40',
  changeCompanyPaperState: '41',
  registerNewCompany: '42',
  modifyFamilyGroupState: '43',
  addFamilyGroupObservations: '44',
}

export type TFunctionalitiesCodes = keyof typeof functionalitiesCodes
