import {
  getHoldersRequestParam,
  TPostHolderCommentParams,
} from 'domain/requestModel/holders'
import { IHoldersEntityGateway } from 'domain/entityGateways/IHoldersEntityGateway'

export class UseCaseHolders implements IHoldersEntityGateway {
  private entityGateway: IHoldersEntityGateway

  constructor(entityGateway: IHoldersEntityGateway) {
    this.entityGateway = entityGateway
  }

  public async getHolders(params: getHoldersRequestParam): Promise<any> {
    const holders = await this.entityGateway.getHolders(params)

    return holders
  }

  public async postEventPyme(params: TPostHolderCommentParams) {
    const result = await this.entityGateway.postEventPyme(params)

    return result
  }
}
