import { createSlice } from '@reduxjs/toolkit'
import { UseCaseState } from 'domain/useCases'
import { State } from 'domain/entities'
import { stateService } from 'app/services'
import {
  TGetStates,
  TGetAvailableStateParams,
  TPostStateDocumentsParam,
  TPostUpdateStatePymeParam,
} from 'domain/requestModel'
import { RootState } from 'app/store/reducers'
import { ISelectFormat } from 'app/components'

const stateController = new UseCaseState(stateService)

type initialStateReducer = {
  loading: boolean
  statesByStateTypeId: boolean
  error: string
  states: State[]
  documentationState: any
  pymeState: any
  availableStates: any
  availableStatesBO: State[]
  event: boolean
  showStatusNotification: boolean
}

const INITIAL_STATE: initialStateReducer = {
  loading: false,
  statesByStateTypeId: false,
  error: '',
  states: [],
  documentationState: [],
  pymeState: [],
  availableStates: [],
  availableStatesBO: [],
  event: false,
  showStatusNotification: false,
}

export const slice = createSlice({
  name: 'state',
  initialState: INITIAL_STATE,
  reducers: {
    fetchStatesStart: state => {
      state.statesByStateTypeId = true
      state.error = ''
    },
    fetchStatesSuccess: (state, { payload }) => {
      state.statesByStateTypeId = false
      state.error = ''
      state.states = payload
    },
    fetchStatesFail: (state, { payload }) => {
      state.statesByStateTypeId = false
      state.error = payload.message
    },
    fetchAfilliationAvailableStatesStart: state => {
      state.loading = true
      state.error = ''
    },
    fetchAfilliationAvailableStatesSuccess: (state, { payload }) => {
      state.loading = false
      state.error = ''
      state.availableStates = payload
    },
    fetchAfilliationAvailableStatesFail: (state, { payload }) => {
      state.loading = false
      state.error = payload.message
    },
    fetchAvailableStatesBOStart: state => {
      state.loading = true
      state.error = ''
    },
    fetchAvailableStatesBOSuccess: (state, { payload }) => {
      state.loading = false
      state.error = ''
      state.availableStatesBO = payload
    },
    fetchAvailableStatesBOFail: (state, { payload }) => {
      state.loading = false
      state.error = payload.message
    },
    fetchDocumentationStateStart: state => {
      state.loading = true
      state.error = ''
    },
    fetchDocumentationStateSuccess: (state, { payload }) => {
      state.loading = false
      state.error = ''
      state.documentationState = payload
    },
    fetchDocumentationStateFail: (state, { payload }) => {
      state.loading = false
      state.error = payload.message
    },
    fetchAvailablePymeStatesStart: state => {
      state.loading = true
      state.error = ''
    },
    fetchAvailablePymeStatesSuccess: (state, { payload }) => {
      state.loading = false
      state.error = ''
      state.pymeState = payload
    },
    fetchAvailablePymeStatesFail: (state, { payload }) => {
      state.loading = false
      state.error = payload.message
    },
    fetchUpdateDocumentationStateStart: state => {
      state.loading = true
      state.error = ''
      state.event = false
    },
    fetchUpdateDocumentationStateSuccess: (state, { payload }) => {
      state.loading = false
      state.error = ''
      state.event = true
    },
    fetchUpdateDocumentationStateFail: (state, { payload }) => {
      state.loading = false
      state.error = payload.message
    },
    fetchChangeStatePymeStart: state => {
      state.loading = true
      state.error = ''
      state.event = false
    },
    fetchChangeStatePymeSuccess: (state, { payload }) => {
      state.loading = false
      state.error = ''
      state.event = true
      state.showStatusNotification = payload.showStatusNotification || false
    },
    fetchChangeStatePymeFail: (state, { payload }) => {
      state.loading = false
      state.error = payload.message
      state.showStatusNotification = false
    },
    clearStatusNotificationChangeStatePyme: state => {
      state.loading = false
      state.showStatusNotification = false
      state.error = ''
    },
  },
})

const {
  fetchStatesStart,
  fetchStatesSuccess,
  fetchStatesFail,
  fetchAfilliationAvailableStatesStart,
  fetchAfilliationAvailableStatesSuccess,
  fetchAfilliationAvailableStatesFail,
  fetchAvailableStatesBOStart,
  fetchAvailableStatesBOSuccess,
  fetchAvailableStatesBOFail,
  fetchDocumentationStateStart,
  fetchDocumentationStateSuccess,
  fetchDocumentationStateFail,
  fetchUpdateDocumentationStateStart,
  fetchUpdateDocumentationStateSuccess,
  fetchUpdateDocumentationStateFail,
  fetchChangeStatePymeStart,
  fetchChangeStatePymeSuccess,
  fetchChangeStatePymeFail,
  fetchAvailablePymeStatesStart,
  fetchAvailablePymeStatesSuccess,
  fetchAvailablePymeStatesFail,
  clearStatusNotificationChangeStatePyme,
} = slice.actions

export default slice.reducer

/**
 * Thunks
 */

export const clearStatusNotificationStatePyme = () => async (dispatch: any) => {
  dispatch(clearStatusNotificationChangeStatePyme())
}

export const getStates = (params: TGetStates) => async (dispatch: any) => {
  try {
    dispatch(fetchStatesStart())
    const response = await stateController.getStates(params)

    dispatch(fetchStatesSuccess(response))
  } catch (error) {
    dispatch(fetchStatesFail(error))
  }
}

export const getAvailableStates = (params: TGetAvailableStateParams) => async (
  dispatch: any
) => {
  try {
    dispatch(fetchAfilliationAvailableStatesStart())

    const response = await stateController.getAvailableStates(params)

    dispatch(fetchAfilliationAvailableStatesSuccess(response))
  } catch (error) {
    dispatch(fetchAfilliationAvailableStatesFail(error))
  }
}

export const getAvalilableStatesBO = (
  params: TGetAvailableStateParams
) => async (dispatch: any) => {
  try {
    dispatch(fetchAvailableStatesBOStart())

    const response = await stateController.getAvailableStatesBO(params)

    dispatch(fetchAvailableStatesBOSuccess(response))
  } catch (error) {
    dispatch(fetchAvailableStatesBOFail(error))
  }
}

export const getDocumentationStates = (
  params: TGetAvailableStateParams
) => async (dispatch: any) => {
  try {
    dispatch(fetchDocumentationStateStart())
    const response = await stateController.getDocumentationState(params)
    dispatch(fetchDocumentationStateSuccess(response))
  } catch (error) {
    dispatch(
      fetchDocumentationStateFail(
        'Hubo un error al obtener los estados de la documentacion'
      )
    )
  }
}

export const getAvailablePymeStates = (
  params: TGetAvailableStateParams
) => async (dispatch: any) => {
  try {
    dispatch(fetchAvailablePymeStatesStart())
    const response = await stateController.getAvailablePymeStates(params)
    dispatch(fetchAvailablePymeStatesSuccess(response))
  } catch (error) {
    dispatch(
      fetchAvailablePymeStatesFail(
        'Hubo un error al obtener los estados de la pyme'
      )
    )
  }
}

export const postUpdateDocumentationState = (
  props: TPostStateDocumentsParam
) => async (dispatch: any) => {
  try {
    dispatch(fetchUpdateDocumentationStateStart())

    const response = await stateController.updateDocumentationStates(props)

    dispatch(fetchUpdateDocumentationStateSuccess(response))
  } catch (error) {
    dispatch(
      fetchUpdateDocumentationStateFail(
        'Hubo un error al actualizar el estado.'
      )
    )
  }
}

export const postChangeStatePyme = (props: TPostUpdateStatePymeParam) => async (
  dispatch: any
) => {
  try {
    dispatch(fetchChangeStatePymeStart())
    const response = await stateController.updateStatusPyme(props)
    dispatch(fetchChangeStatePymeSuccess(response))
  } catch (error) {
    dispatch(
      fetchChangeStatePymeFail({
        message: 'Hubo un error al actualizar el estado',
      })
    )
  }
}

export const postChangeStatePaperModePyme = (
  props: TPostUpdateStatePymeParam
) => async (dispatch: any) => {
  try {
    dispatch(fetchChangeStatePymeStart())
    await stateController.updateStatusPyme(props)
    dispatch(fetchChangeStatePymeSuccess({ showStatusNotification: true }))
  } catch (error) {
    dispatch(
      fetchChangeStatePymeFail({
        message: 'Hubo un error al actualizar el estado',
      })
    )
  }
}

/**
 * Selectors
 */

export const selectStates = (state: RootState) => state.states.states

export const selectStatesByStateTypeIdIsFetching = (state: RootState) =>
  state.states.statesByStateTypeId

export const selectStatesForDropdowns = (state: RootState): ISelectFormat[] =>
  state.states.states.map(
    (s: State): ISelectFormat => ({
      value: s.id,
      label: s.descripcion,
    })
  )

export const selectAvailableStates = (state: RootState) =>
  state.states.availableStates

export const selectAvailableStatesBO = (state: RootState): ISelectFormat[] => {
  const mapped = state.states.availableStatesBO.map(
    (s: State): ISelectFormat => {
      return {
        value: s.id,
        label: s.descripcion,
      }
    }
  )
  return mapped
}
export const selectStatusDocs = (state: RootState) =>
  state.states.documentationState
export const selectPostEvent = (state: RootState) => state.states.event

export const selectAvailablesPymeStates = (state: RootState) =>
  state.states.pymeState

export const selectShowNotificationStatus = (state: RootState) =>
  state.states.showStatusNotification

export const selectPymeStatusError = (state: RootState) => state.states.error

export const isLoadingSelectPymeStatus = (state: RootState) =>
  state.states.loading
