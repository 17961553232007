// States and descriptions are in src/domain/requestModel/state.tsx
export const pymeStates: { [key: string]: number } = {
  CARGA_DE_DATOS_INCOMPLETA: 39,
  EN_ANALISIS: 40,
  ERROR_DE_PROCESO_NO_VALORIZA: 41,
  DESCARTADO: 42,
  ERROR_P_AREA_MEDICA: 43,
  PENDIENTE_DE_CONTROL: 44,
  EX_SOCIO_EN_ANALISIS: 45,
  APROBADO: 46,
  PENDIENTE_DE_REVISION: 47,
  PENDIENTE_CON_SOCIO_EN_AUDITORIA_MEDICA: 48,
  PASA_PAPEL_AMBITO_ACTUACION: 49,
  PENDIENTE_DOCUMENTACION: 50,
  FALTA_DOCUMENTACION: 51,
  DOCUMENTACION_COMPLETA: 52,
  ALTA_EMPRESA_FALLIDA: 53,
  ALTA_EMPRESA_PARCIAL: 54,
  ALTA_EMPRESA_OK: 56,
  PENDIENTE_COMERCIAL: 59,
}

export const pymeStatesLabel: { [key: string]: string } = {
  [pymeStates.CARGA_DE_DATOS_INCOMPLETA]: 'Carga de Datos Incompleta',
  [pymeStates.EN_ANALISIS]: 'En Análisis',
  [pymeStates.ERROR_DE_PROCESO_NO_VALORIZA]: 'Error de Proceso – no valoriza',
  [pymeStates.DESCARTADO]: 'Descartado',
  [pymeStates.ERROR_P_AREA_MEDICA]: 'Error p/ Área Médica',
  [pymeStates.PENDIENTE_DE_CONTROL]: 'Pendiente de control',
  [pymeStates.EX_SOCIO_EN_ANALISIS]: 'Ex Socio / En análisis',
  [pymeStates.APROBADO]: 'Aprobado',
  [pymeStates.PENDIENTE_DE_REVISION]: 'Pendiente de revisión',
  [pymeStates.PENDIENTE_CON_SOCIO_EN_AUDITORIA_MEDICA]:
    'Pendiente Con Socio en Auditoria Medica',
  [pymeStates.PASA_PAPEL_AMBITO_ACTUACION]: 'Pasa papel / Ámbito actuación',
  [pymeStates.PENDIENTE_DOCUMENTACION]: 'Pendiente documentación',
  [pymeStates.FALTA_DOCUMENTACION]: 'Falta documentación',
  [pymeStates.DOCUMENTACION_COMPLETA]: 'Documentación completa',
  [pymeStates.ALTA_FALLIDA]: 'Alta Empresa Fallida',
  [pymeStates.ALTA_PARCIAL]: 'Alta Empresa Parcial',
  [pymeStates.ALTA_EMPRESA_OK]: 'Alta Empresa Ok',
  [pymeStates.PENDIENTE_COMERCIAL]: 'Pendiente Comercial',
}

export default {
  pymeStatesLabel,
  pymeStates,
}
