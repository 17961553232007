import React from 'react'
import { FormikProps } from 'formik'

import { TForm } from 'app/Pages/Afilliations/constants'
import { IAfilliationForm } from 'app/Pages/Afilliations/AfilliationForm'

import { Row } from 'app/components'
import {
  InputField,
  SelectAsync,
  RangeDatePicker,
} from 'app/components/ui/forms'
import { Button, Text } from 'app/components/ui'
import { InputsFormContainer } from 'app/components/InputsFormContainer'

import * as Styles from 'app/Pages/Afilliations/styles'

import { strings } from 'app/constants/strings'

const {
  forms: {
    fieldsName: {
      dni,
      estadoBackOffice,
      province,
      fechaCotizacion,
      advisor,
      exportList,
      resetPageOnSubmit,
    },
    fieldsTitle: {
      dniTitle,
      estadoBackOfficeTitle,
      provinceTitle,
      fechaCotizacionTitle,
      advisorTitle,
    },
  },
  general: { filterAfilliations },
} = strings

const FormInputs = (props: IAfilliationForm & FormikProps<TForm>) => (
  <InputsFormContainer>
    <Text textAlign={'center'} color={'resolutionBlue'} size={'lg'}>
      {filterAfilliations}
    </Text>
    <Styles.FormAfilliation>
      <Row flexDirectionColumn={true}>
        <InputField
          name={dni}
          id={dni}
          title={dniTitle}
          type="number"
          warning={props.errors.dni && props.touched.dni}
        />
        <RangeDatePicker
          id={fechaCotizacion}
          name={fechaCotizacion}
          title={fechaCotizacionTitle}
          onChange={props.setFieldValue}
        />
        <SelectAsync
          onChange={(option: any) => {
            props.setFieldValue(estadoBackOffice, option ? option : null)
          }}
          isClearable
          title={estadoBackOfficeTitle}
          name={estadoBackOffice}
          id={estadoBackOffice}
          placeholder="Seleccione"
          options={props.states?.map((b: any) => ({
            label: b.descripcion,
            value: b.id,
          }))}
          warning={
            props.errors.estadoBackOffice && props.touched.estadoBackOffice
          }
        />
      </Row>
      <Row flexDirectionColumn={true}>
        <SelectAsync
          isLoading={props.advisorsIsFetching}
          onChange={(option: any) => {
            props.setFieldValue(advisor, option ? option : null)
          }}
          onInputChange={props.onChangeAdvisor}
          title={advisorTitle}
          name={advisor}
          id={advisor}
          placeholder="Seleccione"
          options={props.advisors}
          warning={props.errors.advisor && props.touched.advisor}
          isClearable
          isMulti={true}
        />
        <SelectAsync
          onChange={(option: any) => {
            props.setFieldValue(province, option ? option : null)
          }}
          isClearable
          title={provinceTitle}
          name={province}
          id={province}
          placeholder="Seleccione"
          options={props.provincies?.map((b: any) => ({
            label: b.nombre,
            value: b.id,
          }))}
          warning={props.errors.provincia && props.touched.provincia}
        />
      </Row>
      <Row flexDirectionColumn={true}>
        <div>
          <Button
            type="submit"
            onClick={() => {
              props.setFieldValue(resetPageOnSubmit, true)
              props.setFieldValue(exportList, 0)
            }}
          >
            Buscar
          </Button>
        </div>
      </Row>
    </Styles.FormAfilliation>
  </InputsFormContainer>
)

export default FormInputs
