import React, { useState } from 'react'

import * as C from 'app/components'
import * as S from '../../../Pages/Holder/styles'
import {
  DayMonthYearWithLeadingZerosSlashedWithHrAndDash,
  getFormatedDate,
} from '../../../../helpers'

type HistoryProps = {
  props: any
  lead?: any
  page: string
}

const History: React.FC<HistoryProps> = ({ props, lead, page }) => {
  const [showStates, setShowStates] = useState(false)
  const seeMoreHandler = () => {
    setShowStates(!showStates)
  }

  const recentUser =
    page === 'lead'
      ? lead?.historial.slice(-1)[0]?.usuario
      : props.familyGroupDetail?.historial.slice(-1)[0]?.usuario

  const recentStateDate =
    page === 'lead'
      ? getFormatedDate(
          lead?.historial.slice(-1)[0]?.fecha,
          DayMonthYearWithLeadingZerosSlashedWithHrAndDash
        )
      : getFormatedDate(
          props.familyGroupDetail?.historial.slice(-1)[0]?.fecha,
          DayMonthYearWithLeadingZerosSlashedWithHrAndDash
        )

  const RecentState = () => {
    const observacionFamily = props.familyGroupDetail?.historial.slice(-1)[0]
      ?.observacion
    const detalleFamily = props.familyGroupDetail?.historial.slice(-1)[0]
      ?.detalle

    return page === 'lead'
      ? lead?.historial.slice(-1)[0]?.observacion
      : (observacionFamily ? observacionFamily : '') +
          ' ' +
          (detalleFamily ? detalleFamily : '')
  }

  const history =
    page === 'lead' ? lead?.historial : props.familyGroupDetail?.historial

  const mapStates = () => {
    let value =
      history
        ?.slice()
        .reverse()
        .splice(1)
        .map((obs: any, index: any) => {
          return (
            <>
              <S.PreviousStatesParent>
                <S.PreviousStatesChildrens key={index}>
                  <p>{obs.observacion}</p>
                  {obs.detalle && <p>{obs.detalle}</p>}
                  <p>
                    {getFormatedDate(
                      obs.fecha,
                      DayMonthYearWithLeadingZerosSlashedWithHrAndDash
                    )}
                  </p>
                </S.PreviousStatesChildrens>
                <S.PreviousStatesChildrens>
                  Usuario: <strong>{obs.usuario}</strong>
                </S.PreviousStatesChildrens>
              </S.PreviousStatesParent>
              <hr />
            </>
          )
        }) || ''
    return value
  }

  return (
    <C.Acordion open title="Historial">
      <S.HistoryContainer>
        <S.RecentStateTitle>
          <C.Text color="chathamsBlue" weight="md">
            Cambio de estado
          </C.Text>
          <C.Text color="chathamsBlue" weight="sm">
            <S.UserState>
              Usuario: <strong>{recentUser}</strong>
            </S.UserState>
          </C.Text>
        </S.RecentStateTitle>
        <C.Text color="chathamsBlue">{RecentState()}</C.Text>
        <C.Text>{recentStateDate}</C.Text>
        {showStates && (
          <>
            <S.PreviousStatesTitle>
              <C.Text color="chathamsBlue" weight="md">
                Estados previos
              </C.Text>
            </S.PreviousStatesTitle>
            <C.Text color="chathamsBlue">{mapStates()}</C.Text>
          </>
        )}
        <S.HistoryButton>
          <C.Text
            color="mariner"
            weight="md"
            underlined
            onClick={seeMoreHandler}
          >
            {!showStates ? 'Ver más' : 'Ver menos'}
          </C.Text>
        </S.HistoryButton>
      </S.HistoryContainer>
    </C.Acordion>
  )
}

export default History
