import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Formik, Form } from 'formik'

import { Button, LeadsDropdown, LoadingSpinner, Text } from 'app/components'

import {
  selectLeadStates,
  selectputLeadStateIsLoading,
  selectLeadIsUpdated,
} from 'app/slices/leadsSlice'
import { ILeadStatesForm } from 'app/Pages/Lead/interfaces'
import * as S from 'app/Pages/Lead/style'

const LeadStateForm: FC<ILeadStatesForm> = ({
  updatedObject,
  onSubmit,
  selectedOption,
  setSelectedOption,
  disableSubmitForm,
  pageType,
}) => {
  const leadStates = useSelector(selectLeadStates)
  const isUpdated = useSelector(selectLeadIsUpdated)
  const leadStateIsLoading = useSelector(selectputLeadStateIsLoading)
  const [currentColor, setCurrentColor] = useState('')

  const stateColorPicker = (state: string) => {
    switch (state) {
      case 'Pendiente':
        return '#851D16'
      case 'No aplica':
      case 'Sin continuidad':
      case 'Ya fue contactado':
        return '#d6d6d6'
      case 'Consulta socio':
        return '#417505'
      default:
        return '#00205C'
    }
  }

  useEffect(() => {
    const initialStateColor = stateColorPicker(selectedOption.label)
    setCurrentColor(initialStateColor)
  }, [selectedOption])

  return (
    <Formik
      initialValues={{}}
      onSubmit={(values: any) => {
        onSubmit(values.Estado.value)
      }}
    >
      {(props: {
        values: any
        errors: {
          idsLeadState: boolean
        }
        touched: {
          idsLeadState: boolean
        }
        onChange: any
        setFieldValue: any
      }) => (
        <Form>
          <S.FormContainer>
            <S.ButtonContainer>
              <LeadsDropdown
                onChange={(option: any) => {
                  props.setFieldValue('Estado', option ? option : null)
                  setSelectedOption(option)
                  const color = stateColorPicker(option.label)
                  setCurrentColor(color)
                }}
                name={'Estado'}
                id={'Estado'}
                value={selectedOption}
                options={
                  leadStates &&
                  leadStates?.map((b: any) => ({
                    label: b.descripcion,
                    value: b.id,
                  }))
                }
                warning={
                  props.errors.idsLeadState && props.touched.idsLeadState
                }
                isLoading={leadStateIsLoading}
                color={currentColor}
              />
            </S.ButtonContainer>
            {updatedObject && leadStateIsLoading ? (
              <LoadingSpinner isLoading={true} />
            ) : pageType === 'detail' && leadStateIsLoading ? (
              <LoadingSpinner isLoading={true} />
            ) : (
              <S.ButtonContainer>
                <Button
                  disabled={disableSubmitForm}
                  type="submit"
                  display={'inline-table'}
                >
                  Editar estado
                </Button>
              </S.ButtonContainer>
            )}

            {updatedObject && isUpdated && !leadStateIsLoading ? (
              <Text color={'green'} textAlign={'right'}>
                Estado actualizado
              </Text>
            ) : (
              pageType === 'detail' &&
              isUpdated &&
              !leadStateIsLoading && (
                <Text color={'green'} textAlign={'right'}>
                  Estado actualizado
                </Text>
              )
            )}
          </S.FormContainer>
        </Form>
      )}
    </Formik>
  )
}

export default LeadStateForm
