import React from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { ThemeProvider } from 'styled-components'
import { BrowserRouter as Router } from 'react-router-dom'

import { store, persistor } from 'app/store'
import GlobalStyle from 'app/styles/global'
import theme from 'app/styles/theme'

import { ModalProvider } from 'app/components'

export default (props: any) => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <Router>
          <ModalProvider>{props.children}</ModalProvider>
        </Router>
      </ThemeProvider>
    </PersistGate>
  </Provider>
)
