import React, { FC, useState } from 'react'
import { Formik, FormikHelpers, Form } from 'formik'

import * as Components from 'app/components'
import * as S from '../styles'

import constants, { TStatePymeModalContent, TStateForm } from '../constants'

import { strings } from 'app/constants/strings'

const {
  forms: {
    fieldsName: { state },
    fieldsTitle: { stateUpdateTitle },
  },
} = strings

const ModalContent: FC<TStatePymeModalContent> = ({
  onSubmit,
  onCancel,
  availableStates,
  companyState,
}) => {
  const [btnStatus, setbtnStatus] = useState(true)
  const mappedStates = availableStates.map(state => ({
    label: state.descripcion,
    value: state.id,
  }))
  return (
    <Formik
      initialValues={constants.stateUpdateInitialValues}
      validationSchema={constants.newStateUpdateSchema}
      onSubmit={(
        values: TStateForm,
        { setSubmitting }: FormikHelpers<TStateForm>
      ) => {
        setSubmitting(false)
        onSubmit(values)
      }}
    >
      {(props: {
        values: any
        onChange: any
        setFieldValue: any
        errors: any
        touched: any
      }) => (
        <Form>
          <S.ObservationFormContainer>
            <Components.Text size={'lg'} weight={'md'} color={'cornflowerBlue'}>
              Modificar estado de la empresa
            </Components.Text>
            <Components.SelectAsync
              onChange={(option: any) => {
                props.setFieldValue(state, option ? option : null)
                setbtnStatus(false)
              }}
              title={stateUpdateTitle}
              name={state}
              id={state}
              options={mappedStates}
              placeholder={companyState || 'Seleccionar'}
              warning={props.errors.observations && props.touched.observations}
            />
            <S.ButtonsContainer flexDirectionColumn={true}>
              <Components.Button type="button" outline onClick={onCancel}>
                Cancelar
              </Components.Button>
              <Components.Button type="submit" disabled={btnStatus}>
                Confirmar
              </Components.Button>
            </S.ButtonsContainer>
          </S.ObservationFormContainer>
        </Form>
      )}
    </Formik>
  )
}

export default ModalContent
