import * as Yup from 'yup'

import { AfilliationEventEnum } from 'domain/entities'

import { strings } from 'app/constants/strings'

import * as C from 'app/components'

const {
  forms: {
    fieldsName: { observations, detail, state },
    requiredFields: { requiredField },
  },
} = strings

export type TForm = {
  observations: any
  detail: string
}

export type TStateForm = {
  observations: any
  state: any
}

export type TModalContent = {
  onSubmit: (values: TForm) => void
  onCancel: () => void
}

export type TStateModalContent = {
  availableStates: TAvailableStatesType[]
  onSubmit: (values: TStateForm) => void
  onCancel: () => void
}

type TObservationsType = {
  label: string
  value: AfilliationEventEnum
}

type TAvailableStatesType = {
  id: number
  descripcion: string
}

const nameSelectInitialValue = {
  label: '',
  value: 0,
}

const newObservationInitialValues = {
  observations: {
    label: '',
    value: '',
  },
  detail: '',
}

const stateUpdateInitialValues = {
  state: {
    label: '',
    value: '',
  },
  observations: '',
}

const observationsType: TObservationsType[] = [
  {
    label: 'observacion',
    value: AfilliationEventEnum.observation,
  },
  {
    label: 'llamado',
    value: AfilliationEventEnum.call,
  },
]

const newObservationValidationSchema = Yup.object().shape({
  [observations]: Yup.mixed().test(
    observations,
    requiredField,
    value => value.value
  ),
  [detail]: Yup.string().required(requiredField),
})

const newStateUpdateSchema = Yup.object().shape({
  [state]: Yup.mixed().test(state, requiredField, value => value.value),
  [observations]: Yup.string(),
})

const detailFormInitialValues = (
  associates: C.ISelectFormat[]
): {
  associate: C.ISelectFormat
} => ({ associate: associates[0] })

export default {
  nameSelectInitialValue,
  newObservationValidationSchema,
  newObservationInitialValues,
  observationsType,
  stateUpdateInitialValues,
  newStateUpdateSchema,
  detailFormInitialValues,
}
