import React, { FC, ReactNode } from 'react'
import styled, { css } from 'styled-components'

interface IRow {
  readonly children: ReactNode

  // force flex direction when want elements inside Row spread them one below each others in mobile
  flexDirectionColumn?: boolean

  justifyContent?:
    | 'center'
    | 'end'
    | 'flex-end'
    | 'flex-start'
    | 'start'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
  alignItems?:
    | 'center'
    | 'start'
    | 'end'
    | 'flex-start'
    | 'flex-end'
    | 'self-start'
    | 'self-end'
  gap?: string
}

const RowElement = styled.div<IRow>`
  display: flex;
  ${({ justifyContent }) =>
    justifyContent &&
    css`
      justify-content: ${justifyContent};
    `}
  ${({ alignItems }) =>
    alignItems &&
    css`
      align-items: ${alignItems};
    `}
  ${({ gap }) =>
    gap &&
    css`
      gap: ${gap};
    `}
  @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
    ${({ flexDirectionColumn }) =>
      flexDirectionColumn &&
      css`
        flex-direction: column;
      `}
  }
`

export const Row: FC<IRow> = props => (
  <RowElement {...props}>{props.children}</RowElement>
)
